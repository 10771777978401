import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const addNotification = createAsyncThunk(
    'notification/add',
    async ({ type, category, subcategory, notifyFor }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/notification/add',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                    },
                    body: JSON.stringify({
                        type,
                        category,
                        subcategory,
                        notifyFor
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const notificationSlice = createSlice({
    name: "notification",
    initialState: {
    },
    reducers: {
    },
});
export default notificationSlice.reducer;