import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CompanyJobsNavbar from './CompanyJobsNavbar';
import CompanyJobsLogo from './CompanyJobsLogo';

const CompanyJobsAboutUs = () => {
    return (
        <>
            <CompanyJobsNavbar />

            <div className="dk-companyJobs-main">
                <div className="row">
                    <div className="col-md-10">
                        <div className="dk-companyJobs-aboutUs">
                            <div className="dk-companyJobsInfo">
                                <div className="row">
                                    <div className="col-md-3 pr-0">
                                        <div className="dk-jobInfoHead">Founded</div>
                                    </div>
                                    <div className="col-md-9 pl-0">
                                        <div className="dk-jobInfoContent">
                                            2013
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 pr-0">
                                        <div className="dk-jobInfoHead">Locations</div>
                                    </div>
                                    <div className="col-md-9 pl-0">
                                        <div className="dk-jobInfoContent">
                                            Dublin, Ireland
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 pr-0">
                                        <div className="dk-jobInfoHead">Industry</div>
                                    </div>
                                    <div className="col-md-9 pl-0">
                                        <div className="dk-jobInfoContent">
                                            Test Indus
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 pr-0">
                                        <div className="dk-jobInfoHead">Company Size</div>
                                    </div>
                                    <div className="col-md-9 pl-0">
                                        <div className="dk-jobInfoContent">
                                            15 Employees
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 pr-0">
                                        <div className="dk-jobInfoHead">Headquarters</div>
                                    </div>
                                    <div className="col-md-9 pl-0">
                                        <div className="dk-jobInfoContent">
                                            1
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 pr-0">
                                        <div className="dk-jobInfoHead">Company Type</div>
                                    </div>
                                    <div className="col-md-9 pl-0">
                                        <div className="dk-jobInfoContent">
                                            Direct Hiring Company
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 pr-0">
                                        <div className="dk-jobInfoHead">Areas Served</div>
                                    </div>
                                    <div className="col-md-9 pl-0">
                                        <div className="dk-jobInfoContent">
                                            Web Dev
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 pr-0">
                                        <div className="dk-jobInfoHead">Web Address</div>
                                    </div>
                                    <div className="col-md-9 pl-0">
                                        <div className="dk-jobInfoContent">
                                            <a href="//www.dkglobaljobs.com" target="_blank">www.dkglobaljobs.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <article>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </article>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <CompanyJobsLogo />
                    </div>
                </div>
            </div >
        </>
    )
}

export default CompanyJobsAboutUs;
