import React, { useState } from 'react';
import { Route, Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import JobFaqModalContent from "./JobFaqModalContent";

const JobFaqModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <React.Fragment>
            <Link to="#" onClick={handleShow}>FAQ</Link>

            <Modal className="dk-faqModalMain" centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Job FAQ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <JobFaqModalContent />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
export default JobFaqModal;
