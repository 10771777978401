import React from 'react';
import { Link } from 'react-router-dom'
import './footer.scss'
import Flag from '../../../assets/images/ind-flag.jpg'
import blog from '../../../assets/images/blog.jpg'

const Footer = () => {
    return (
        <>
            <footer className="dk-jobspage-footerCon">
                <div className="ftop">
                    <div className="container-fluid dkjobs-topCon">
                        <div className="row">
                            <div className="col-md-2 text-center text-uppercase bdr-r">
                                <h4>Our Presence</h4>
                            </div>
                            <div className="col-md-8 text-center text-uppercase bdr-r">
                                <h4>Site Selections</h4>
                            </div>
                            <div className="col-md-2 text-center text-uppercase">
                                <h4>Social Media</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fMiddle">
                    <div className="container-fluid dkjobs-MiddleCon">
                        <div className="row">
                            <div className="col-md-2 pb-3 bdr-r">
                                <div className="dkjobs-flagCon pt-4">
                                    <Link to="#" className="facebookicon">
                                        <img src={Flag} className="dkfooterFlag" alt="Flag" />
                                    </Link>
                                    <Link to="#">
                                        <img src={Flag} className="dkfooterFlag" alt="Flag" />
                                    </Link>
                                    <Link to="#">
                                        <img src={Flag} className="dkfooterFlag" alt="Flag" />
                                    </Link>
                                    <Link to="#">
                                        <img src={Flag} className="dkfooterFlag" alt="Flag" />
                                    </Link>
                                    <Link to="#">
                                        <img src={Flag} className="dkfooterFlag" alt="Flag" />
                                    </Link>
                                    <Link to="#">
                                        <img src={Flag} className="dkfooterFlag" alt="Flag" />
                                    </Link>
                                    <Link to="#">
                                        <img src={Flag} className="dkfooterFlag" alt="Flag" />
                                    </Link>
                                    <Link to="#">
                                        <img src={Flag} className="dkfooterFlag" alt="Flag" />
                                    </Link>
                                    <Link to="#">
                                        <img src={Flag} className="dkfooterFlag" alt="Flag" />
                                    </Link>
                                    <Link to="#">
                                        <img src={Flag} className="dkfooterFlag" alt="Flag" />
                                    </Link>
                                    <Link to="#">
                                        <img src={Flag} className="dkfooterFlag" alt="Flag" />
                                    </Link>
                                    <Link to="#">
                                        <img src={Flag} className="dkfooterFlag" alt="Flag" />
                                    </Link>
                                    <Link to="#">
                                        <img src={Flag} className="dkfooterFlag" alt="Flag" />
                                    </Link>
                                    <Link to="#">
                                        <img src={Flag} className="dkfooterFlag" alt="Flag" />
                                    </Link>
                                    <Link to="#">
                                        <img src={Flag} className="dkfooterFlag" alt="Flag" />
                                    </Link>
                                    <Link to="#">
                                        <img src={Flag} className="dkfooterFlag" alt="Flag" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-8 pb-3 dkjobs-footerCtreCon bdr-r">
                                <div className="row justify-content-around pt-4">
                                    <div className="dkjobs-Ctrecols">
                                        <h4>DK GLOBAL</h4>
                                        <ul>
                                            <li>
                                                <Link to="/about-us">About Us</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Testimonials</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Feedback</Link>
                                            </li>
                                            <li>
                                                <Link to="/contact-us">Contact us</Link>
                                            </li>
                                            <li>
                                                <Link to="/faq">Visitor FAQ </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dkjobs-Ctrecols">
                                        <h4>Jobseekers</h4>
                                        <ul>
                                            <li>
                                                <Link to="#">Private Mailbox</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Subscription Model</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Jobseekers Community</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Advance Jobs Management</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Lucky Draws &amp; Other Benefits</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dkjobs-Ctrecols">
                                        <h4>Employers</h4>
                                        <ul>
                                            <li>
                                                <Link to="#">Private Mailbox</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Subscription Model</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Live Jobseekers Profile</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Free Resume Database</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Adv Candidates Management</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dkjobs-Ctrecols">
                                        <h4>Legal</h4>
                                        <ul>
                                            <li>
                                                <Link to="/terms-of-use">Terms of Use</Link>
                                            </li>
                                            <li>
                                                <Link to="/privacy-policy">Privacy Policy</Link>
                                            </li>
                                            <li>
                                                <Link to="/gdpr-explained">GDPR Explained</Link>
                                            </li>
                                            <li>
                                                <Link to="/access-your-data">Access Your Data</Link>
                                            </li>
                                            <li>
                                                <Link to="/privacy-contact">Contact for Privacy</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 ">
                                <div className="dk-footerrightCols pt-4 pb-3">
                                    <h4>Instagram</h4>
                                    <div className="dkjobs-instaCon">
                                        <img src={blog} className="blog-img" alt="Blog_Img" />
                                        <img src={blog} className="blog-img" alt="Blog_Img" />
                                        <img src={blog} className="blog-img" alt="Blog_Img" />
                                        <img src={blog} className="blog-img" alt="Blog_Img" />
                                        <img src={blog} className="blog-img" alt="Blog_Img" />
                                        <img src={blog} className="blog-img" alt="Blog_Img" />
                                        <img src={blog} className="blog-img" alt="Blog_Img" />
                                        <img src={blog} className="blog-img" alt="Blog_Img" />
                                    </div>
                                    <div className="footer-social-links p-2">
                                        <Link to="#" className="facebookicon">
                                            <i class="fab fa-facebook-f"></i>
                                        </Link>
                                        <Link to="#" className="instaicon">
                                            <i class="fab fa-instagram"></i>
                                        </Link>
                                        <Link to="#" className="linkicon">
                                            <i class="fab fa-linkedin-in"></i>
                                        </Link>
                                        <Link to="#" className="youtubeicon">
                                            <i class="fab fa-youtube"></i>
                                        </Link>
                                        <Link to="#" className="twittericon">
                                            <i class="fab fa-twitter"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fbottom">
                    <div className="container-fluid dkjobs-bottomCon">
                        <p>Copyright © 2021, DK Global Jobs, All Rights Reserved.</p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
