import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Layout from "../../layout/employer/Standard";
import { Unauthorized } from "../../pages/error/Unauthorized";
import { useSelector } from "react-redux";
const EMPStandardRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticate, userInfo } = useSelector(state => state.employerAuth)

    return (
        <Route {...rest} render={props => {
            if (isAuthenticate && userInfo.employer_details.accountType !== "Standard")
                return <Unauthorized />
            if (isAuthenticate && userInfo.employer_details.accountType === "Standard" && userInfo.employer_details.accountTrail)
                return <Layout><Component {...props} /></Layout>
            return <Redirect
                to={{
                    pathname: "/login/employer-login"
                    , state: { from: props.location }
                }}
            />
        }} />
    )
}
export default EMPStandardRoute;