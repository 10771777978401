import React, { useEffect, useState } from 'react';
import { Link } from '@material-ui/core';
import './jobseekersignup.scss';
import SubmitButton from "../../../ui/submitButton";
import { showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import { signupUser, clearState } from "../../../../slice/account/jobseeker/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Constants from '../../../constants';
import { useHistory } from 'react-router-dom'

const JobseekerSignup = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { isLoading, isSuccess, isError, message } = useSelector(state => state.jobseekerAuth);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const accountType = Constants.SYSTEM_CONSTANTS.JobSeeker.Account.AccountType;
    const accountTrail = Constants.SYSTEM_CONSTANTS.JobSeeker.Account.AccountTrail;

    const [title, setTitle] = useState('Title');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const clearValues = () => {
        setTitle('Title')
        setFirstName('')
        setLastName('')
        setAddress('')
        setCity('')
        setState('')
        setCountry('')
        setZipCode('')
        setMobile('')
        setEmail('')
        setPassword('')
        setConfirmPassword('')
    }

    useEffect(() => {
        document.title = "Create Jobseeker Account | DK Global Jobs"
        dispatch(clearMessage());
        dispatch(clearState());
    }, [dispatch])

    const validateForm = () => {
        let formIsValid = true;
        let pattern = '';

        if (title === 'Title' || !firstName || !lastName || !address || !city || !country || !state || !country || !zipCode || !mobile || !email || !password || !confirmPassword) {
            formIsValid = false;
            dispatch(showError({ msg: "Please fill all required details" }))
        }

        if (typeof firstName !== "undefined") {
            if (!firstName.match(Constants.SYSTEM_CONSTANTS.Validation.Aplphabet)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Please enter alphabet characters only on First Name." }));
            } else {
                if (firstName.length < 3) {
                    formIsValid = false;
                    dispatch(showError({ msg: "*First Name should be atleast 3 character." }));
                }
            }
        }

        if (typeof lastName !== "undefined") {
            if (!lastName.match(Constants.SYSTEM_CONSTANTS.Validation.Aplphabet)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Please enter alphabet characters only on Last Name." }));
            } else {
                if (lastName.length < 3) {
                    formIsValid = false;
                    dispatch(showError({ msg: "*Last Name should be atleast 3 character." }));
                }
            }
        }

        if (typeof address !== "undefined") {
            if (address.length < 10) {
                formIsValid = false;
                dispatch(showError({ msg: "*Address should be atleast 10 character." }));
            }
        }

        if (typeof city !== "undefined") {
            if (!city.match(Constants.SYSTEM_CONSTANTS.Validation.Aplphabet)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Invalid City." }));
            }
        }

        if (typeof zipCode !== "undefined") {
            if (!zipCode.match(Constants.SYSTEM_CONSTANTS.Validation.Alpha_Numeric)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Invalid Postal Code." }));
            }
        }

        if (typeof state !== "undefined") {
            if (!state.match(Constants.SYSTEM_CONSTANTS.Validation.Aplphabet)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Invalid State." }));
            }
        }

        if (typeof country !== "undefined") {
            if (!country.match(Constants.SYSTEM_CONSTANTS.Validation.Aplphabet)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Invalid Country." }));
            }
        }

        if (typeof email !== "undefined") {
            //regular expression for email validation
            pattern = new RegExp(Constants.SYSTEM_CONSTANTS.Validation.Email);
            if (!pattern.test(email)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Please enter valid email." }));
            }
        }

        if (typeof mobile !== "undefined") {
            //regular expression for Phone validation
            pattern = new RegExp(Constants.SYSTEM_CONSTANTS.Validation.Phone);
            if (!pattern.test(mobile)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Please enter valid mobile number." }));
            }
        }

        if (typeof password !== "undefined") {
            //regular expression for Password validation
            pattern = new RegExp(Constants.SYSTEM_CONSTANTS.Validation.Password);
            if (!pattern.test(password)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Please enter secure and strong password." }));
            } else {
                if (password.length < 8) {
                    formIsValid = false;
                    dispatch(showError({ msg: "*Password should be atleast 8 character." }));
                }
            }
        }

        if (typeof confirmPassword !== "undefined") {
            if (password !== confirmPassword) {
                formIsValid = false;
                dispatch(showError({ msg: "*Confirm password do not matchwd with password." }));
            }
        }

        return formIsValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validateForm()) {
            try {
                dispatch(clearMessage())
                await dispatch(signupUser({ accountType, accountTrail, title, firstName, lastName, address, city, state, country, zipCode, mobile, email, password }))
                if (isLoading) {
                    setSuccess(false);
                    setLoading(true);
                }
            } catch (err) {
                setSuccess(false);
                setLoading(false);
                dispatch(showError({ msg: 'Something went wrong! Try again.' }))
            }
        }
    }

    useEffect(() => {
        if (isError) {
            setSuccess(false);
            setLoading(false);
            dispatch(clearState());
            dispatch(showError({ msg: message }))
        }
        if (isSuccess) {
            dispatch(clearState());
            clearValues();
            setSuccess(true);
            setLoading(false);
            setTimeout(() => history.push('/sign-up/thankyou'), 300);
        }
    }, [isError, isSuccess, dispatch, message, history])

    return (
        <>
            <div className="dk-jobsekerSingupMain container-fluid">
                <div className="row">
                    <div className="col-md-8 col-sm-12 m-auto">
                        <div className="dk-topsingupCont">
                            <h2 className="title">Create Jobseeker Account</h2>
                        </div>
                        <div className="dk-FormContiner">
                            <div className="dk-formContent">
                                <form>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <select name="title" className="browser-default custom-select" value={title} onChange={(e) => setTitle(e.target.value)}>
                                                    <option value="Title">Title</option>
                                                    <option value="Mr.">Mr.</option>
                                                    <option value="Mrs.">Mrs.</option>
                                                    <option value="Miss.">Miss.</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <input type="text" className="form-control effect" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name*" />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name*" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <textarea className="form-control" id="textArea" rows="3" placeholder="Full Home Address*" value={address} onChange={(e) => setAddress(e.target.value)}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="city" placeholder="City/Town*" value={city} onChange={(e) => setCity(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="postcode" placeholder="Post Code*" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="state" placeholder="State*" value={state} onChange={(e) => setState(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="country" placeholder="Country*" value={country} onChange={(e) => setCountry(e.target.value)} />
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="email" className="form-control" id="email" placeholder="Email*" value={email} onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="email" className="form-control" id="mobile" placeholder="Mobile*" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="password" className="form-control" id="password" placeholder="password*" value={password} onChange={(e) => setPassword(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="password" className="form-control" id="confirmPass" placeholder="Confirm Password*" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="dk-termsplicytextCon">
                                                <p className="pt-2">By continuing, you acknowledge that you accept our
                                                    <Link to="#">Terms &amp; Conditions</Link> and
                                                    <Link to="#">Privacy Policy.</Link>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="dk-formsubmit-buttonCon mt-2">
                                            <SubmitButton txt="Create Account" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="btn formsubmit-button" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JobseekerSignup;
