import React, { useState } from 'react';
import PlanIcon from "../../../assets/images/plan_icon.png";
import InfoModal from "./InfoModal";

const EmployerAccountPlan = () => {
    const [showInfo, setInfoShow] = useState(false);
    const showInfoModal = () => {
        setInfoShow(true)
    }
    const hideInfoModal = () => {
        setInfoShow(false)
    }
    return (
        <>
            {
                showInfo ?
                    <InfoModal handleClose={hideInfoModal} /> : null
            }
            <div class="dk-pricePlan-mainContainer">
                <div class="dk-pricePlan-main">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>EMPLOYER SUBSCRIPTIONS</th>
                                    <th>
                                        <span>
                                            <img src={PlanIcon} alt="" />
                                        </span>
                                        Standard
                                    </th>
                                    <th>
                                        <span>
                                            <img src={PlanIcon} alt="" />
                                        </span>
                                        Premium
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <table class="table table_2">
                            <thead>
                                <tr>
                                    <th>SN</th>
                                    <th>Additional New Features</th>
                                    <th>Free</th>
                                    <th>50€ /pm</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="active">1</td>
                                    <td>
                                        Dashboard <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="check"><i class="fas fa-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>
                                        Mailbox <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="check"><i class="fas fa-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>
                                        Post Profile <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="check"><i class="fas fa-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>
                                        Community <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="check"><i class="fas fa-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>
                                        Forum Access <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="check"><i class="fas fa-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>
                                        Lucky Draw <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="check"><i class="fas fa-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>
                                        Jobs Pipeline <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="check"><i class="fas fa-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>
                                        Message with Job <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="check"><i class="fas fa-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>
                                        Manage Employers <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="check"><i class="fas fa-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>
                                        Track Profile Views <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="check"><i class="fas fa-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>
                                        Referral Scheme <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td>
                                        Jobs Pipeline Pro <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>
                                        Interview Prep Email <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>14</td>
                                    <td>
                                        Get Discount Offers <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>
                                        Access to Recruiters <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>16</td>
                                    <td>
                                        Job Search Advice <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>17</td>
                                    <td>
                                        Free New Updates <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>18</td>
                                    <td>
                                        Tasks Notifications <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>19</td>
                                    <td>
                                        Live Chat Employers <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>20</td>
                                    <td>
                                        Admin Rapid Support <span class="pull-right" onClick={showInfoModal}><i class="fas fa-question-circle"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                    <td>
                                        <span class="cross"><i class="fas fa-times"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td><a href="/pricing">Get Started</a></td>
                                    <td><a href="/pricing">Get Started</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>
    )
}

export default EmployerAccountPlan;
