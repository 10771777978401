import React from 'react';
import { Link } from 'react-router-dom'
import './contactprivacy.scss'

const ContactPrivacy = () => {
    return (
        <>
            <div className="dk-privacyContactPage">
                <div className="container">
                    <h1 className="text-center mb-4">Data Privacy Contact </h1>
                    <div className="col-md-12">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-5 pr-0">
                                <div className="contact_detail">
                                    <h3>DK Global Recrutiers</h3>
                                    <ul>
                                        <li>
                                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                                            <Link href="#/privacy-contact">Gloucester Square, Railway street Dublin 1 Ireland</Link>
                                        </li>
                                        <li>
                                            <i className="fa fa-phone"></i>
                                            <Link href="#/privacy-contact">+353 1551 2444</Link>
                                        </li>
                                        <li>
                                            <i className="fa fa-envelope" aria-hidden="true"></i>
                                            <Link href="#/privacy-contact">Office@dkglobalrecruitment.com</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-md-7 pl-0">
                                <div className="dk-rightprivacyCard">
                                    <form action="">
                                        <div className="row">
                                            <div className="col-12 col-md-9">
                                                <h2>Send your Message</h2>
                                                <div className="form-group">
                                                    <input type="text" name="" placeholder="Your Name" id="" className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="" placeholder="Your Email" id="" className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="" placeholder="Your Phone" id="" className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="" placeholder="Your Subject" id="" className="form-control" />
                                                </div>
                                                <div className="form-group textarea">
                                                    <textarea name="" id="" className="form-control" placeholder="Message"></textarea>
                                                </div>
                                                <p className="termsuser">
                                                    <input type="checkbox" name="" id="" />I agree to the
                                                    <Link href="/privacy-contact">Terms of Use</Link>&nbsp; and&nbsp;
                                                    <Link href="/privacy-contact">Privacy policy</Link>
                                                </p>
                                                <button className="btn btn-light">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactPrivacy
