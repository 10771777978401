import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './paymentmtedit.scss';

const AddCardModal = ({ hidepoup }) => {
    return (
        <div>
            <Modal
                show={true}
                onHide={hidepoup}
                aria-labelledby="contained-modal-title-vcenter"
                className="dk-paymenteditModal"
            >
                <Modal.Header closeButton className="dk-paymenteditModalHeader">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add New Card
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-paymenteditModalBody">
                    <form action="">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="form-group">
                                    <label htmlFor="">Card Number</label>
                                    <label htmlFor="" className="dkcard-number">
                                        <input type="text" name="" id="" className="form-control" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="">Exp. Date</label>
                                    <label htmlFor="">
                                        <input type="text" name="" id="" placeholder="MM/YY" className="form-control" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9">
                                <div className="form-group">
                                    <label htmlFor="">Card Holder Name</label>
                                    <label htmlFor="">
                                        <input type="text" name="" id="" className="form-control" value="Deepak Kumar" />
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="">CVV</label>
                                    <label htmlFor="">
                                        <input type="text" name="" id="" className="form-control" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group">
                                <div className="col-md-12">
                                    <div className="dkmake-primary d-flex align-items-center">
                                        <input type="checkbox" name="" id="make" />
                                        <label for="make">Make primary</label>
                                    </div>
                                    <div className="dkmake-primary d-flex align-items-center mt-3">
                                        <input type="checkbox" name="" id="billing" />
                                        <label for="billing">Billing address same as contact address</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            <button type="button" className="dk-paymentEditBtn">
                                Cancel
                            </button>
                            <button type="button" className="dk-paymentEditBtn">
                                Confirm
                            </button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddCardModal;
