import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './joblisting.scss';
import JobsFilter from './JobsFilter';
import JobListingCategory from './JobListingCategory';
import CompanyLogo from './CompanyLogo';
import JobFaqModal from './JobFaqModal';

const JobListing = () => {
    return (
        <>
            <div className="dk-jobListingMain">
                <div className="dk-jobListing-head">
                    <div className="container">
                        <h2 className="title">FIND YOUR NEXT JOB HERE <JobFaqModal /></h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        <JobsFilter />
                    </div>
                    <div className="col-md-8">
                        <div className="dk-jobslisting">
                            <div className="dk-jobslisting-category">
                                <JobListingCategory />
                            </div>
                            <div className="dk-jobslisting-tagselect">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="dk-jobs-list">1-50 of 11326 Jobs</div>
                                    <div className="dk-jobs-cate-link">
                                        <Link className="box" to="#">Last 24 hours <i className="fas fa-times"></i></Link>
                                        <Link className="box" to="#">Full Time <i className="fas fa-times"></i></Link>
                                        <Link to="#">Clear all</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="dk-boxes-cols">
                                <div className="d-sm-flex align-items-center">
                                    <div className="col-md-2">
                                        <div className="dk-boxes-cols-img">
                                            <img src="https://dkglobaljobs.com/static/media/fulltime.7d42172d.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="dk-boxes-cols-right">
                                            <div className="d-md-flex">
                                                <div className="dk-boxes-cols-right-cols">
                                                    <div className="title">
                                                        REGIONAL SALES MANAGER SOUTH EAST ASIA
                                                    </div>
                                                    <div className="dk-boxes-right-cols-desc">
                                                        <ul>
                                                            <li className="dk-currency"><i className="fas fa-briefcase"></i> Job Title</li>
                                                            <li className="dk-calender"><i className="fas fa-euro-sign"></i>salary</li>
                                                            <li className="dk-location"><i className="fas fa-building"></i>Employer Name</li>
                                                        </ul>
                                                        <ul>
                                                            <li className="dk-calender"><i className="fas fa-language"></i>Language</li>
                                                            <li className="dk-calender"><i className="fas fa-map-marker-alt"></i>Location</li>
                                                            <li className="dk-currency"><i className="far fa-calendar-alt"></i>Start Date</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pr-0">
                                        <div className="dk-boxes-cols-right-cols-viewjobs">
                                            <Link to="/jobdetails/job-details"><i className="far fa-eye"></i> View Job</Link>
                                            <Link to="#"><i className="far fa-clock"></i> Full Time</Link>
                                            <Link to="#"><i className="far fa-heart"></i> Favourite</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dk-boxes-cols">
                                <div className="d-sm-flex align-items-center">
                                    <div className="col-md-2">
                                        <div className="dk-boxes-cols-img">
                                            <img src="https://dkglobaljobs.com/static/media/parttime.67797b9c.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="dk-boxes-cols-right">
                                            <div className="d-md-flex">
                                                <div className="dk-boxes-cols-right-cols">
                                                    <div className="title">
                                                        REGIONAL SALES MANAGER SOUTH EAST ASIA
                                                    </div>
                                                    <div className="dk-boxes-right-cols-desc">
                                                        <ul>
                                                            <li className="dk-currency"><i className="fas fa-briefcase"></i> Job Title</li>
                                                            <li className="dk-calender"><i className="fas fa-euro-sign"></i>salary</li>
                                                            <li className="dk-location"><i className="fas fa-building"></i>Employer Name</li>
                                                        </ul>
                                                        <ul>
                                                            <li className="dk-calender"><i className="fas fa-language"></i>Language</li>
                                                            <li className="dk-calender"><i className="fas fa-map-marker-alt"></i>Location</li>
                                                            <li className="dk-currency"><i className="far fa-calendar-alt"></i>Start Date</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pr-0">
                                        <div className="dk-boxes-cols-right-cols-viewjobs">
                                            <Link to="/jobdetails/job-details"><i className="far fa-eye"></i> View Job</Link>
                                            <Link to="#"><i className="far fa-clock"></i> Full Time</Link>
                                            <Link to="#"><i className="far fa-heart"></i> Favourite</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dk-boxes-cols">
                                <div className="d-sm-flex align-items-center">
                                    <div className="col-md-2">
                                        <div className="dk-boxes-cols-img">
                                            <img src="https://dkglobaljobs.com/static/media/othertime.2aeaf99a.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="dk-boxes-cols-right">
                                            <div className="d-md-flex">
                                                <div className="dk-boxes-cols-right-cols">
                                                    <div className="title">
                                                        REGIONAL SALES MANAGER SOUTH EAST ASIA
                                                    </div>
                                                    <div className="dk-boxes-right-cols-desc">
                                                        <ul>
                                                            <li className="dk-currency"><i className="fas fa-briefcase"></i> Job Title</li>
                                                            <li className="dk-calender"><i className="fas fa-euro-sign"></i>salary</li>
                                                            <li className="dk-location"><i className="fas fa-building"></i>Employer Name</li>
                                                        </ul>
                                                        <ul>
                                                            <li className="dk-calender"><i className="fas fa-language"></i>Language</li>
                                                            <li className="dk-calender"><i className="fas fa-map-marker-alt"></i>Location</li>
                                                            <li className="dk-currency"><i className="far fa-calendar-alt"></i>Start Date</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pr-0">
                                        <div className="dk-boxes-cols-right-cols-viewjobs">
                                            <Link to="/jobdetails/job-details"><i className="far fa-eye"></i> View Job</Link>
                                            <Link to="#"><i className="far fa-clock"></i> Full Time</Link>
                                            <Link to="#"><i className="far fa-heart"></i> Favourite</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dk-boxes-cols">
                                <div className="d-sm-flex align-items-center">
                                    <div className="col-md-2">
                                        <div className="dk-boxes-cols-img">
                                            <img src="https://dkglobaljobs.com/static/media/fulltime.7d42172d.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="dk-boxes-cols-right">
                                            <div className="d-md-flex">
                                                <div className="dk-boxes-cols-right-cols">
                                                    <div className="title">
                                                        REGIONAL SALES MANAGER SOUTH EAST ASIA
                                                    </div>
                                                    <div className="dk-boxes-right-cols-desc">
                                                        <ul>
                                                            <li className="dk-currency"><i className="fas fa-briefcase"></i> Job Title</li>
                                                            <li className="dk-calender"><i className="fas fa-euro-sign"></i>salary</li>
                                                            <li className="dk-location"><i className="fas fa-building"></i>Employer Name</li>
                                                        </ul>
                                                        <ul>
                                                            <li className="dk-calender"><i className="fas fa-language"></i>Language</li>
                                                            <li className="dk-calender"><i className="fas fa-map-marker-alt"></i>Location</li>
                                                            <li className="dk-currency"><i className="far fa-calendar-alt"></i>Start Date</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pr-0">
                                        <div className="dk-boxes-cols-right-cols-viewjobs">
                                            <Link to="/jobdetails/job-details"><i className="far fa-eye"></i> View Job</Link>
                                            <Link to="#"><i className="far fa-clock"></i> Full Time</Link>
                                            <Link to="#"><i className="far fa-heart"></i> Favourite</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dk-boxes-cols">
                                <div className="d-sm-flex align-items-center">
                                    <div className="col-md-2">
                                        <div className="dk-boxes-cols-img">
                                            <img src="https://dkglobaljobs.com/static/media/parttime.67797b9c.jpg" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="dk-boxes-cols-right">
                                            <div className="d-md-flex">
                                                <div className="dk-boxes-cols-right-cols">
                                                    <div className="title">
                                                        REGIONAL SALES MANAGER SOUTH EAST ASIA
                                                    </div>
                                                    <div className="dk-boxes-right-cols-desc">
                                                        <ul>
                                                            <li className="dk-currency"><i className="fas fa-briefcase"></i> Job Title</li>
                                                            <li className="dk-calender"><i className="fas fa-euro-sign"></i>salary</li>
                                                            <li className="dk-location"><i className="fas fa-building"></i>Employer Name</li>
                                                        </ul>
                                                        <ul>
                                                            <li className="dk-calender"><i className="fas fa-language"></i>Language</li>
                                                            <li className="dk-calender"><i className="fas fa-map-marker-alt"></i>Location</li>
                                                            <li className="dk-currency"><i className="far fa-calendar-alt"></i>Start Date</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pr-0">
                                        <div className="dk-boxes-cols-right-cols-viewjobs">
                                            <Link to="/jobdetails/job-details"><i className="far fa-eye"></i> View Job</Link>
                                            <Link to="#"><i className="far fa-clock"></i> Full Time</Link>
                                            <Link to="#"><i className="far fa-heart"></i> Favourite</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-2 dk-col-md-2">
                        <CompanyLogo />
                    </div>
                </div>
            </div>
        </>
    )
}

export default JobListing;
