import React from 'react';
import './termsuse.scss';
import FaqAccordion from '../signup/FaqAccordion';

const TermsUse = () => {
    return (
        <>
            <div className="container-fluid dk-privacyPolicyMain">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="pageTitle">
                            <h1 className="text-center">Terms of <span>Use</span></h1>
                        </div>
                        <div className="dk-privacyAccordiaon">
                            <FaqAccordion />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsUse
