import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

const AdminFilterModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <button className="dk-userFilerBtn" onClick={handleShow}><i className="fas fa-user"></i></button>

            <Modal className="dk-adminFilterMain" centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Admin Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className="user_filter_ul">
                        <li className="filter_admin">
                            <Link to="#" className="d-flex">
                                <span className="thumb-pic">
                                    <img src="http://lara-test.dkglobaljobs.com/uploads/profile_picture/dpk-img.png" className="img-fluid img-circle" alt="" />
                                </span>
                                <span className="thum-name">Deepak Kumar User</span>
                                <span className="thum-number">1</span>
                            </Link>
                        </li>
                    </ul>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AdminFilterModal;
