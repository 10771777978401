import React from 'react';
import { Link } from 'react-router-dom'
import ResumeImg from '../../../../../../../../assets/images/resume_img.png';


const WorkExperience = () => {
    return (
        <>
            <div className="row dk-workexpMainCon">
                <div className="col-md-8 dk-workexperienceLeftCon">
                    <div className="headingCon">
                        <h3>Personal details</h3>
                        <h6 class="pt-0">Start with your last employment. Write down also volunteer activities, student internships, work experience etc.</h6>
                    </div>
                    <form action="">
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Job position</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Employer/Name of the company</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">From</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" placeholder="MM/YY" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">

                                </label>
                            </div>
                            <div className="rightCols w-20">
                                <label for="" class="d-flex dk-currentJob">
                                    <input type="checkbox" name="" id="" />
                                    <span>My Current Job</span></label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Till</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" placeholder="MM/YY" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Job Description</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <textarea name="" id="" class="form-control dk-eductionteatArea"></textarea>
                                </label>
                            </div>
                        </div>
                        <h3 className="mt-5 mb-4">Further Information</h3>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">
                                    Additional information on employment history
                                </label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <textarea name="" id="" class="form-control dk-eductionteatArea"></textarea>
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group mt-4">
                            <div className="leftCols">
                                <Link className="dk-backBtn" to="#/jobseeker/standard/myresume/personal_details">
                                    <i className="fa fa-arrow-circle-left"></i> Back (1/6)
                                </Link>
                            </div>
                            <div className="rightCols">
                                <Link to="#/jobseeker/standard/myresume/personal_details">Next(3/6)
                                    <i className="fa fa-arrow-circle-right ml-2"></i>
                                </Link>
                                <Link to="#/jobseeker/standard/myresume/personal_details">Save All Changes
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-4 dk-workexperienceRightCon">
                    <div className="dk-resumeImg-cols">
                        <Link to="#">
                            <div className="title">Preview</div>
                            <div className="resumeimgCon">
                                <img src={ResumeImg} alt="Resume" />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WorkExperience
