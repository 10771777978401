import React from 'react'
import { Link } from 'react-router-dom'
import './submenu.scss'

const SettingSubmenu = () => {
  let pathname = window.location.pathname;
  return (
    <>
      <div className="container-fluid">
        <div className="dk-ds-sub-menu col-md-11 pl-0 ml-auto">
          <div class="dk-ds-sub-menu-cols">
            <ul>
              <li>
                <Link className={pathname === "/jobseeker/standard/settings/faq" ? 'sub-active' : ''} to="/jobseeker/standard/settings/faq">FAQ</Link>
              </li>
              <li>
                <Link className={pathname === "/jobseeker/standard/settings/my-documents" ? 'sub-active' : ''} to="/jobseeker/standard/settings/my-documents">My Documents</Link>
              </li>
              <li>
                <Link className={pathname === "/jobseeker/standard/settings/my-contracts" ? 'sub-active' : ''} to="/jobseeker/standard/settings/my-contracts">My Contracts</Link>
              </li>
              <li>
                <Link className={pathname === "/jobseeker/standard/settings/compliance" ? 'sub-active' : ''} to="/jobseeker/standard/settings/compliance">Compliance</Link>
              </li>
              <li>
                <Link className={pathname === "/jobseeker/standard/settings/notifications" ? 'sub-active' : ''} to="/jobseeker/standard/settings/notifications">Notifications</Link>
              </li>
              <li>
                <Link className={pathname === "/jobseeker/standard/settings/consent" ? 'sub-active' : ''} to="/jobseeker/standard/settings/consent">Consent</Link>
              </li>
              <li>
                <Link className={pathname === "/jobseeker/standard/settings/change_logins" ? 'sub-active' : ''} to="/jobseeker/standard/settings/change_logins">Change Logins</Link>
              </li>
              <li>
                <Link className={pathname === "/jobseeker/standard/settings/disable_account" ? 'sub-active' : ''} to="/jobseeker/standard/settings/disable_account">Disable Account</Link>
              </li>
              <li>
                <Link className={pathname === "/jobseeker/standard/settings/delete-account" ? 'sub-active' : ''} to="/jobseeker/standard/settings/delete-account">Delete Account</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingSubmenu
