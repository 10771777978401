import React, { useEffect, useCallback, useState, useRef } from 'react'
import './companyprofile.scss';
import { Tab, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import CompanyLogo from "./tabs/Logo";
import AboutCompany from "./tabs/About";
import ContactCompany from "./tabs/Contact";
const CompanyProfile = () => {

    useEffect(() => {
        document.title = "Employer Owner Profile | DK Global Jobs"
    }, [])

    return (
        <React.Fragment>
            <div className="container-fluid dk-empCompProfilePage">
                <div className="row">
                    <div className="col-md-12">
                        <Tab.Container id="left-tabs-example" className="dk-comProfileTabCon" defaultActiveKey="uploadlogo">
                            <div className="row">
                                <div className="col-md-2 pr-0">
                                    <Nav variant="pills" className="flex-column dk-comProfilepills">
                                        <Nav.Item className="dk-comProfileNavItem">
                                            <Nav.Link eventKey="uploadlogo">Upload Logo</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dk-comProfileNavItem">
                                            <Nav.Link eventKey="aboutus">About Us</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dk-comProfileNavItem">
                                            <Nav.Link eventKey="contactus">Contact Us</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="dk-comProfileNavItem">
                                            <Nav.Link eventKey="preview">Preview</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                <div className="col-md-10 pl-0">
                                    <Tab.Content className="dk-comProfileTabContent">
                                        <Tab.Pane className="dk-comProfileTabPane uploadLogo" eventKey="uploadlogo">
                                            <CompanyLogo />
                                        </Tab.Pane>
                                        <Tab.Pane className="dk-comProfileTabPane aboutUs" eventKey="aboutus">
                                            <AboutCompany />
                                        </Tab.Pane>
                                        <Tab.Pane className="dk-comProfileTabPane contactUs" eventKey="contactus">
                                            <ContactCompany />
                                        </Tab.Pane>
                                        <Tab.Pane className="dk-comProfileTabPane previewCon" eventKey="preview">
                                            <div className="dk-empBtnprviewCon">
                                                <Link to="#" className="dk-empBtnprview">Profile Preview <i className="fas fa-external-link-alt ml-2" aria-hidden="true"></i></Link>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default CompanyProfile;