import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import OtpInput from 'react-otp-input';
import SubmitButton from "../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassword, verifyOTP, clearState } from "../../../slice/account/forgetPassword/forgetPasswordSlice";
import { showSuccess, showError, clearMessage } from "../../../slice/utils/message/messageSlice";

const VerifyOTP = (props) => {
    const dispatch = useDispatch();
    const { isError, isVerifyOTP, message } = useSelector(state => state.forgetPassword);
    const { account, email } = props.match.params;

    const history = useHistory();

    const [otp, setOtp] = useState('');
    const [accountType, setAccountType] = useState('');
    const [accountEmail, setAccountEmail] = useState('');

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setAccountType(atob(account))
        setAccountEmail(atob(email))

        document.title = "Verify OTP | DK Global Jobs"
        dispatch(clearMessage());
        dispatch(clearState());
    }, [dispatch, account, email])

    const handleChange = (otp) => {
        setOtp(otp);
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (otp.length < 6) {
            dispatch(showError({ msg: 'Invalid OTP' }))
            dispatch(clearState());
            dispatch(clearMessage());
        } else {
            setSuccess(false);
            setLoading(true);
            await dispatch(verifyOTP({ account: accountType, email: accountEmail, otp }))
        }
    }

    const resendOtp = async (e) => {
        await dispatch(forgetPassword({ account: accountType, email: accountEmail }))
        setOtp('')
        dispatch(showSuccess({ msg: 'Email OTP resend successfully' }))
        dispatch(clearState());
    }

    useEffect(() => {
        if (isError) {
            dispatch(clearState());
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: message }))
        }
        if (isVerifyOTP) {
            setSuccess(false);
            setLoading(false);
            dispatch(clearState());
            dispatch(showSuccess({ msg: message }))
            setTimeout(() =>
                history.push('/reset-password/' + btoa(accountType) + '/' + btoa(accountEmail)
                ), 1000);
        }
    }, [isError, isVerifyOTP, dispatch, message, history, accountType, accountEmail])

    return (
        <React.Fragment>
            <div className="dk-forgotPassMain">
                <div className="dk-forgotCols">
                    <h2 className="title">Verify OTP</h2>
                    <div className="dk-forgotContainer">
                        <form action="">
                            <label htmlFor="" className="dk-otpInput">
                                <OtpInput
                                    value={otp}
                                    onChange={handleChange}
                                    numInputs={6}
                                />
                            </label>
                            <label htmlFor="" className="text-center mt-3 mb-0">
                                <SubmitButton txt="Send OTP" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="dk-sendBtn" />
                            </label>
                            <Link to="#" className="dk-resendBtn" onClick={resendOtp}>Resend OTP</Link>
                        </form>
                    </div>
                    <div className="text-center mt-3">
                        <Link to="/login" className="dk-backLogin">Back to Login</Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default VerifyOTP;