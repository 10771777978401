import React, {useState} from 'react';
import { Link } from 'react-router-dom';

const JobListingCategory = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <ul className="filterUl">
                    <li><Link to="/jobs/jobs-listing" className={`${pathname.match('/jobs/jobs-listing') ? 'active' : ''}`}>All Jobs</Link></li>
                    <li><Link to="/jobs/job-search-by-industry" className={`${pathname.match('/jobs/job-search-by-industry') ? 'active' : ''}`}>Industry</Link></li>
                    <li><Link to="/jobs/job-search-by-level" className={`${pathname.match('/jobs/job-search-by-level') ? 'active' : ''}`}>Level</Link></li>
                    <li><Link to="/jobs/job-search-by-job-profile" className={`${pathname.match('/jobs/job-search-by-job-profile') ? 'active' : ''}`}>Job Profile</Link></li>
                    <li><Link to="/jobs/job-search-by-country" className={`${pathname.match('/jobs/job-search-by-country') ? 'active' : ''}`}>Country</Link></li>
                    <li><Link to="/jobs/job-search-by-city" className={`${pathname.match('/jobs/job-search-by-city') ? 'active' : ''}`}>City</Link></li>
                    <li><Link to="/jobs/job-search-by-language" className={`${pathname.match('/jobs/job-search-by-language') ? 'active' : ''}`}>Language</Link></li>
                    <li><Link to="/jobs/job-search-by-company" className={`${pathname.match('/jobs/job-search-by-company') ? 'active' : ''}`}>Company</Link></li>
                    <li><Link to="/jobs/job-search-by-work-permit" className={`${pathname.match('/jobs/job-search-by-work-permit') ? 'active' : ''}`}>Work Permit</Link></li>
                </ul>
            </div>
        </>
    )
}

export default JobListingCategory;
