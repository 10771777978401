import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { verifyEmail, resendLink } from "../../../../slice/account/acountVerify/acountVerifySlice";
import { useDispatch, useSelector } from "react-redux";
import './accountverify.scss';
import { FaRegCheckCircle } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import SubmitButton from "../../../ui/submitButton";
import UseAnimations from "react-useanimations";
import { showSuccess } from '../../../../slice/utils/message/messageSlice';

const Index = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { isLoading, isResendLoading, isSuccess, isError } = useSelector(state => state.emailVerify);

    var query = new URLSearchParams(window.location.search)
    const token = query.get('token');
    const accountType = query.get('account');
    const accountid = query.get('id');
    const accountEmail = query.get('email');

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        document.title = "Account Verification | DK Global Jobs"
        dispatch(verifyEmail({ token, accountType }))
    }, [dispatch, token])

    const handleResend = async () => {
        setSuccess(false);
        setLoading(true);
        await dispatch(resendLink({ email: accountEmail, id: accountid, account: accountType }))
    }

    const handleGDPR = () => {
        setSuccess(false);
        setLoading(true);
        if (accountType === "jobseeker") {
            setTimeout(() => history.push('/submission-gdpr/jobseeker/' + btoa(accountid)), 1000);
        } else {
            setTimeout(() => history.push('/submission-gdpr/employer/' + btoa(accountid)), 1000);
        }
    }

    useEffect(() => {
        if (isResendLoading) {
            setSuccess(true);
            setLoading(false);
            dispatch(showSuccess({ msg: 'Email resent successfully.' }))
        }
    }, [isError, isSuccess, isLoading, isResendLoading, dispatch])

    return (
        <React.Fragment>
            <div className="dk-darMain">
                <div className="sm-container">
                    <div className="title">ACCOUNT VERIFICATION</div>
                    {
                        (isLoading) ?
                            <div className="dk-cols-12">
                                <UseAnimations className="dk-loading"
                                    animationKey="loading"
                                    size={56}
                                    style={{ padding: 100 }}
                                />
                            </div>
                            :
                            <div className="dk-cols-12">
                                {
                                    (isError) ?
                                        <React.Fragment>
                                            <FaTimesCircle style={{ color: 'indianred' }} />
                                            <p className="pt-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                            <div className="dk-formsubmit-buttonCon mt-2">
                                                <SubmitButton txt="Resend Link" loading={loading} success={success} onClick={handleResend} position="justify-content-center" className="btn formsubmit-button" />
                                            </div>
                                        </React.Fragment> :
                                        <React.Fragment>
                                            <FaRegCheckCircle style={{ color: 'green' }} />
                                            <p className="pt-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                            <div className="dk-formsubmit-buttonCon mt-2">
                                                <SubmitButton txt="Submit GDPR" loading={loading} success={success} onClick={handleGDPR} position="justify-content-center" className="btn formsubmit-button" />
                                            </div>
                                        </React.Fragment>
                                }
                            </div>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
export default Index;