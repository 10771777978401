import React, { useEffect, useState } from 'react';
import './accountverify.scss';
import { FaTimesCircle } from "react-icons/fa";
import { FaRegCheckCircle } from "react-icons/fa";
import SubmitButton from "../../../ui/submitButton";
import { resendLink, clearState } from "../../../../slice/account/acountVerify/acountVerifySlice";
import { showError } from '../../../../slice/utils/message/messageSlice';
import { useDispatch, useSelector } from "react-redux";

const EmailNotVerify = () => {
    const dispatch = useDispatch();
    const { isResendLoading, isSuccess, isError, message } = useSelector(state => state.emailVerify);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    var query = new URLSearchParams(window.location.search)
    const accountType = atob(query.get('account'));
    const accountEmail = atob(query.get('email'));

    useEffect(() => {
        document.title = "Resend Verification Email | DK Global Jobs"
    }, [dispatch])

    const handleResend = async () => {
        await dispatch(resendLink({ email: accountEmail, account: accountType }))
        try {
            if (isResendLoading) {
                setSuccess(false);
                setLoading(true);
            }
        } catch (err) {
            dispatch(showError({ msg: '' }))
        }
    }

    useEffect(() => {
        if (isError) {
            setSuccess(false);
            setLoading(false);
            dispatch(clearState());
            dispatch(showError({ msg: message }))
        }
        if (isSuccess) {
            setSuccess(true);
            setLoading(false);
        }
    }, [isSuccess, isError, dispatch, message])

    return (
        <React.Fragment>
            <div className="dk-darMain">
                <div className="sm-container">
                    <div className="title">Email Not Verified</div>
                    {
                        (isSuccess) ?
                            <React.Fragment>
                                <div className="dk-cols-12">
                                    <FaRegCheckCircle style={{ color: 'green' }} />
                                    <p className="pt-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                    <div className="dk-formsubmit-buttonCon mt-2">
                                        <SubmitButton txt="Resend Link" loading={loading} success={success} onClick={handleResend} position="justify-content-center" className="btn formsubmit-button" />
                                    </div>
                                </div>
                            </React.Fragment>
                            :
                            <div className="dk-cols-12">
                                <FaTimesCircle style={{ color: 'indianred' }} />
                                <p className="pt-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                <div className="dk-formsubmit-buttonCon mt-2">
                                    <SubmitButton txt="Resend Link" loading={loading} success={success} onClick={handleResend} position="justify-content-center" className="btn formsubmit-button" />
                                </div>
                            </div>
                    }
                </div>
            </div>
        </React.Fragment >
    )
}
export default EmailNotVerify