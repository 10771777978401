import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import Success from "./components/utils/messages/success";
import Error from "./components/utils/messages/error";
let persistor = persistStore(store);
const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
      contrastText: '#333',
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Success />
        <Error />
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);


