import React from 'react';
import "./singupfaq.scss";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { IoIosArrowDown } from "react-icons/io";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    }
}));
const FaqAccordion = () => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <div className={classes.root}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<IoIosArrowDown />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography className={classes.heading}>Can I get a refund after payment has been made?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<IoIosArrowDown />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Typography className={classes.heading}>Are black and white and sepia images accepted?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<IoIosArrowDown />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <Typography className={classes.heading}>There are many variations of passages</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                        expandIcon={<IoIosArrowDown />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography className={classes.heading}>Will your organisation assume copyright over my pictures?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary
                        expandIcon={<IoIosArrowDown />}
                        aria-controls="panel5bh-content"
                        id="panel5bh-header"
                    >
                        <Typography className={classes.heading}>Can I enter an image that has been published?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary
                        expandIcon={<IoIosArrowDown />}
                        aria-controls="panel6bh-content"
                        id="panel6bh-header"
                    >
                        <Typography className={classes.heading}>Do I have to live in India to enter?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary
                        expandIcon={<IoIosArrowDown />}
                        aria-controls="panel7bh-content"
                        id="panel7bh-header"
                    >
                        <Typography className={classes.heading}>Do I need a special professional camera to enter?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <AccordionSummary
                        expandIcon={<IoIosArrowDown />}
                        aria-controls="panel8bh-content"
                        id="panel8bh-header"
                    >
                        <Typography className={classes.heading}>Can I enter the same photos again in a different month competition or annual competition?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                    <AccordionSummary
                        expandIcon={<IoIosArrowDown />}
                        aria-controls="panel9bh-content"
                        id="panel9bh-header"
                    >
                        <Typography className={classes.heading}>How should I prepare my pictures?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                    <AccordionSummary
                        expandIcon={<IoIosArrowDown />}
                        aria-controls="panel10bh-content"
                        id="panel10bh-header"
                    >
                        <Typography className={classes.heading}>How do I enter the contest?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                    <AccordionSummary
                        expandIcon={<IoIosArrowDown />}
                        aria-controls="panel11bh-content"
                        id="panel11bh-header"
                    >
                        <Typography className={classes.heading}>What is the deadline for the contest?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                    <AccordionSummary
                        expandIcon={<IoIosArrowDown />}
                        aria-controls="panel12bh-content"
                        id="panel12bh-header"
                    >
                        <Typography className={classes.heading}>Can I review my images before entering the competition?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                    <AccordionSummary
                        expandIcon={<IoIosArrowDown />}
                        aria-controls="panel13bh-content"
                        id="panel13bh-header"
                    >
                        <Typography className={classes.heading}>Can I make changes to my entry after I have paid?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
                    <AccordionSummary
                        expandIcon={<IoIosArrowDown />}
                        aria-controls="panel14bh-content"
                        id="panel14bh-header"
                    >
                        <Typography className={classes.heading}>I entered an earlier inPA competition and now I would like to enter the current one. Do I have to register again?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}

export default FaqAccordion;
