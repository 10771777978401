import React, { useState } from 'react';
import './compliance.scss';
import { Link } from 'react-router-dom';
import { Tab, Row, Col, Nav } from 'react-bootstrap';

const Index = () => {
    return (
        <React.Fragment>
            <div className="container-fluid dk-compliancePage">
                <div className="col-md-11 pl-0 ml-auto">
                    <div className="d-flex">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Col className="dk-complianceLeft">
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">GDPR</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Privacy</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">Cookies</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="forth">Terms of use</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col className="dk-complianceRight">
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="dk-bodyPanel">
                                            <h2>GDPR</h2>
                                            <div className="dk-panelCols">
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, eum eaque dignissimos at dolores repellat nihil, molestias aliquid incidunt officiis in, esse labore! Unde deleniti ratione optio illum. Dicta, maxime!</p>
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis obcaecati rerum dignissimos exercitationem, doloremque voluptates maxime dolorum sed facilis esse harum magni repellendus aliquid aliquam id, dolore vitae possimus. Rem! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni similique cupiditate asperiores fuga expedita, consequatur laborum distinctio id repudiandae enim illo quo quidem quas mollitia. Deserunt beatae eveniet culpa labore.</p>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="dk-bodyPanel">
                                            <h2>Privacy</h2>
                                            <div className="dk-panelCols">
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, eum eaque dignissimos at dolores repellat nihil, molestias aliquid incidunt officiis in, esse labore! Unde deleniti ratione optio illum. Dicta, maxime!</p>
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis obcaecati rerum dignissimos exercitationem, doloremque voluptates maxime dolorum sed facilis esse harum magni repellendus aliquid aliquam id, dolore vitae possimus. Rem! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni similique cupiditate asperiores fuga expedita, consequatur laborum distinctio id repudiandae enim illo quo quidem quas mollitia. Deserunt beatae eveniet culpa labore.</p>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <div className="dk-bodyPanel">
                                            <h2>Cookies</h2>
                                            <div className="dk-panelCols">
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, eum eaque dignissimos at dolores repellat nihil, molestias aliquid incidunt officiis in, esse labore! Unde deleniti ratione optio illum. Dicta, maxime!</p>
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis obcaecati rerum dignissimos exercitationem, doloremque voluptates maxime dolorum sed facilis esse harum magni repellendus aliquid aliquam id, dolore vitae possimus. Rem! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni similique cupiditate asperiores fuga expedita, consequatur laborum distinctio id repudiandae enim illo quo quidem quas mollitia. Deserunt beatae eveniet culpa labore.</p>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="forth">
                                        <div className="dk-bodyPanel">
                                            <h2>Terms Of Use</h2>
                                            <div className="dk-panelCols">
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, eum eaque dignissimos at dolores repellat nihil, molestias aliquid incidunt officiis in, esse labore! Unde deleniti ratione optio illum. Dicta, maxime!</p>
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis obcaecati rerum dignissimos exercitationem, doloremque voluptates maxime dolorum sed facilis esse harum magni repellendus aliquid aliquam id, dolore vitae possimus. Rem! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni similique cupiditate asperiores fuga expedita, consequatur laborum distinctio id repudiandae enim illo quo quidem quas mollitia. Deserunt beatae eveniet culpa labore.</p>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Index;