import React from 'react'
import { Link } from 'react-router-dom'

const Documents = () => {
    return (
        <>
            <div className="dk-DocmentsCon">
                <div className="dk-docHeader">
                    <h3 className="title">My Profile Documents</h3>
                </div>
                <div className="dk-doctableCon">
                    <table className="dk-doctable table table-bordered">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Name of Document</th>
                                <th>File Type</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Cover Letter</td>
                                <td>
                                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                </td>
                                <td>
                                    <div className="dk-actioncols">
                                        <Link to="#" className="vewbtn">View</Link>
                                        <Link to="#" className="downloadbtn">Download</Link>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Cover Letter</td>
                                <td>
                                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                </td>
                                <td>
                                    <div className="dk-actioncols">
                                        <Link to="#" className="vewbtn">View</Link>
                                        <Link to="#" className="downloadbtn">Download</Link>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Cover Letter</td>
                                <td>
                                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                </td>
                                <td>
                                    <div className="dk-actioncols">
                                        <Link to="#" className="vewbtn">View</Link>
                                        <Link to="#" className="downloadbtn">Download</Link>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Cover Letter</td>
                                <td>
                                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                </td>
                                <td>
                                    <div className="dk-actioncols">
                                        <Link to="#" className="vewbtn">View</Link>
                                        <Link to="#" className="downloadbtn">Download</Link>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Documents
