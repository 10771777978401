import React, { useState } from 'react'
import { Dropdown, FormControl } from 'react-bootstrap'

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className="dk-formcontrol"
                    placeholder="Search..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value.toString() || child.props.children.toLowerCase().startsWith(value))}
                </ul>
            </div>
        );
    },
);
const SearchInputDrpDwn = (referanceAdd) => {
    return (
        <>
            <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    {referanceAdd.toggleTitle}
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenu}>
                    <Dropdown.Item eventKey="1">India</Dropdown.Item>
                    <Dropdown.Item eventKey="2">Ireland</Dropdown.Item>
                    <Dropdown.Item eventKey="3" active>England</Dropdown.Item>
                    <Dropdown.Item eventKey="1">Germany</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>,
        </>
    )
}

export default SearchInputDrpDwn
