import React from 'react'
import { Link } from 'react-router-dom'
import ResumeImg from '../../../../../../../../assets/images/resume_img.png';
import CameraImg from '../../../../../../../../assets/images/camera.png';


const PersonalDetails = () => {
    return (
        <>
            <div className="row dk-persionalDetailCon">
                <div className="col-md-8 dk-persionalLeftCon">
                    <h3>Personal details</h3>
                    <form action="">
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Gender*</label>
                            </div>
                            <div className="rightCols d-flex align-items-center">
                                <div className="gender">
                                    <input type="radio" name="gender" id="male" />
                                    <label for="male">Male</label>
                                </div>
                                <div className="gender">
                                    <input type="radio" name="gender" id="female" />
                                    <label for="female">Female</label>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Academic degre</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Name*</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Surname*</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Email*</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Phone</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Date of Birth</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group dk-daymonthyear">
                            <div className="leftCols">
                                <label for="" className="textCols">Surname*</label>
                            </div>
                            <div className="rightCols">
                                <label for="" className="d-flex">
                                    <div className="mr-2">
                                        <span>Day</span>
                                        <input type="text" name="" id="" className="form-control" />
                                    </div>
                                    <div className="mr-2">
                                        <span>Month</span>
                                        <input type="text" name="" id="" className="form-control" />
                                    </div>
                                    <div>
                                        <span>Year</span>
                                        <input type="text" name="" id="" className="form-control" />
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group fileUpload-cols">
                            <div className="leftCols">
                                <label for="" className="textCols">Photo*</label>
                            </div>
                            <div className="rightCols">
                                <div className="dk-fileUpload d-flex align-items-center">
                                    <label for="" className="uploadCols">
                                        <input type="file" name="" id="" />
                                        <span>
                                            <img src={CameraImg} className="img-fluid" alt="" />
                                        </span>
                                    </label>
                                    <label for="" className="photoDeleteBtn">Delete Phone</label>
                                </div>
                            </div>
                        </div>
                        <h3 className="mt-5">Contact Address</h3>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Street, number</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Postal code</label>
                            </div>
                            <div className="rightCols w-20">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">City</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Country</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <select name="" id="" className="form-control">
                                        <option value="">India</option>
                                        <option value="">USA</option>
                                        <option value="">England</option>
                                        <option value="">South Africa</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group mt-4">
                            <div className="leftCols">
                                <Link className="dk-backBtn" to="#/jobseeker/standard/myresume/personal_details">
                                    <i className="fa fa-arrow-circle-left"></i> Back (1/6)
                                </Link>
                            </div>
                            <div className="rightCols">
                                <Link to="#/jobseeker/standard/myresume/personal_details">Next(3/6)
                                    <i className="fa fa-arrow-circle-right"></i>
                                </Link>
                                <Link to="#/jobseeker/standard/myresume/personal_details">Save All Changes
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-4 dk-persionalRightCon">
                    <div className="dk-resumeImg-cols">
                        <Link to="#">
                            <div className="title">Preview</div>
                            <div className="resumeimgCon">
                                <img src={ResumeImg} alt="Resume" />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PersonalDetails
