import React, { useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import './mydocuments.scss'
import { InputGroup, FormControl } from 'react-bootstrap'
import ModalTitleInfo from './modal/TitleInfo';
import ModalViewDocument from './modal/ViewDocument';
import ModalAddNewFile from './modal/AddNewFile';
import ModalEditFile from './modal/EditFile';
import { documentsList, documentsDelete, documentsDetails, clearState } from "../../../../../../../slice/account/documents/documentsSlice";
import { showSuccess, showError, clearMessage } from "../../../../../../../slice/utils/message/messageSlice";
import TableLoader from "../../../../../../ui/tableLoader";
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { useDispatch, useSelector } from "react-redux";
import CustomDateFormat from "../../../../../../utils/dateFormats";
import ShortDetails from '../../../../../../utils/shortDetails';
import { GlobalFilter } from "./GlobalFilter";
import TablePagination from "../../../../../../ui/pagination";
import Delete from "../../../../../../ui/delete/Delete";

function Table({ loading, columns, data }) {
    const [isTitleInfo, setIsTitleInfo] = useState(false);
    const [isAddNewFile, setIsAddNewFile] = useState(false);

    const showModalTitleInfo = () => {
        setIsTitleInfo(true);
    }
    const hideModalTitleInfo = () => {
        setIsTitleInfo(false);
    }

    const showaddnewfileModal = () => {
        setIsAddNewFile(true);
    }
    const hideaddnewfileModal = () => {
        setIsAddNewFile(false);
    }

    const tableInstance = useTable({
        columns: columns,
        data: data,
        initialState: {
            pageIndex: 0, pageSize: 100
        }
    }, useGlobalFilter, useFilters, useSortBy, usePagination)

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, state, setGlobalFilter, setFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize } } = tableInstance
    const { globalFilter } = state

    const handlePageChange = (event, value) => {
        gotoPage(Number(value) - 1);
    };

    return (
        <React.Fragment>
            {
                (isTitleInfo) ? <ModalTitleInfo hidepopup={hideModalTitleInfo} /> : null
            }
            {
                (isAddNewFile) ? <ModalAddNewFile hidepopup={hideaddnewfileModal} /> : null
            }
            <div className="dk-mydocpageHeader">
                <div className className="dk-mydocLeftCon">
                    <div className="dk-searchFieldBox">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                    </div>
                </div>
                <div className className="dk-mydocCentreCon">
                    <h3 className="dk-tableTitle">
                        SAVE YOUR PRIVATE FILES <span className="dk-infoModal" onClick={showModalTitleInfo}><i className="fa fa-info-circle"></i></span>
                    </h3>
                </div>
                <div className className="dk-mydocRightCon">
                    <div className="dk-myDocs-btnCon">
                        <Link to="#" className="dk-myDocs-btn" onClick={showaddnewfileModal}>
                            Add New File
                        </Link>
                    </div>
                </div>
            </div>
            <div className="dk-mydocumentTableCon table-responnsive">
                <table className="dk-mydocumentTable table table-bordered" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {
                        (loading) ? <TableLoader colspan="16" /> : <tbody {...getTableBodyProps()}>
                            {page.map(
                                (row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                )
                                            })}
                                        </tr>
                                    )
                                }
                            )}
                        </tbody>
                    }
                </table>
            </div>
            <div className="col-md-12">
                <div className="dk-resPaginationCon">
                    <div className="dk-resPagination">
                        <div className="dk-resLeftPag">
                            <div className="dk-texCounter">Showing {' '}{pageIndex + 1} to {pageOptions.length} (of {tableInstance.rows.length} entries)</div>
                        </div>
                        <div className="dk-resrightPag">
                            <TablePagination count={pageCount} onChange={handlePageChange} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const MyDocuments = () => {
    const dispatch = useDispatch();
    const { dataList, dataDetails, isLoading, isSuccess, isInsert, isDelete, isError, msg } = useSelector(state => state.accountDocuments);
    const { userInfo } = useSelector(state => state.employerAuth);

    const [data, setData] = useState([]);

    const [rowId, setRowId] = useState('0');
    const [showDelete, setShowDelete] = useState(false);

    const [isViewDocument, setIsViewDocument] = useState(false);
    const [isEditModal, setIsEditModal] = useState(false);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(documentsList({ uid: userInfo.uid }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [dispatch])

    useEffect(() => {
        if (!isLoading && dataList.length > -1) {
            setData(dataList)
        }
        if (isInsert) {
            setData(dataList)
        }
        if (isDelete) {
            setData(dataList)
            setRowId('0')
            setShowDelete(false)
            dispatch(showSuccess({ msg }))
            dispatch(clearState())
        }
        if (isSuccess) {
            window.location = process.env.REACT_APP_IMAGE_BASE_URL + dataDetails.file_name
            dispatch(clearState())
        }
    }, [isLoading, isInsert, isSuccess, isDelete])

    const showviewDocumentModal = async (id) => {
        await dispatch(documentsDetails({ ref: 'documents', id }))
        setIsViewDocument(true);
    }
    const hideviewDocumentModal = async () => {
        setIsViewDocument(false);
    }

    const showeditModal = () => {
        setIsEditModal(true);
    }
    const hideeditModal = () => {
        setIsEditModal(false);
    }

    const showDeleteModal = async (id) => {
        setRowId(id)
        setShowDelete(true)
    }
    const hideDeleteModal = async (id) => {
        setRowId('0')
        setShowDelete(false)
    }

    const deleteItem = async () => {
        setSuccess(false);
        setLoading(true);
        await dispatch(documentsDelete({ id: rowId }))
    }

    const downloadFile = async (id) => {
        await dispatch(documentsDetails({ ref: 'documents', id }))
    }


    const columns = useMemo(() => [
        {
            Header: 'No.',
            title: 'No.',
            Cell: (row) => Number(row.row.id) + 1
        },
        {
            id: "createDate",
            Header: "Date",
            accessor: "createdAt",
            Cell: ({ cell }) => (
                <CustomDateFormat date={cell.row.original.createdAt} format='DD MMM YYYY' />
            )
        },
        {
            Header: "File Name",
            accessor: "name"
        },
        {
            Header: "File Category",
            accessor: "category"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <ShortDetails details={cell.row.original.description} maxLength={50} />
            )
        },
        {
            Header: 'Action',
            accessor: 'id',
            Cell: ({ cell }) => (
                <React.Fragment>
                    <div className="dk-actionsCols">
                        <Link class="dk-view-btn mr-2" id={cell.row.original.id} onClick={() => showviewDocumentModal(cell.row.original.id)} to="#">View</Link>
                        <Link class="dk-download-btn mr-2" id={cell.row.original.id} onClick={() => downloadFile(cell.row.original.id)} to="#">Download</Link>
                        <Link class="dk-edit-btn mr-2" onClick={showeditModal} to="#">Edit</Link>
                        <Link class="dk-delete-btn" to="#" onClick={() => showDeleteModal(cell.row.original.id)}>Delete</Link>
                    </div>
                </React.Fragment>
            )
        }
    ], [])

    return (
        <>
            {
                (isViewDocument) ? <ModalViewDocument hidepopup={hideviewDocumentModal} /> : null
            }
            {
                (isEditModal) ? <ModalEditFile hidepopup={hideeditModal} /> : null
            }
            {
                (showDelete) ? <Delete id={rowId} onHide={hideDeleteModal} deleteItem={deleteItem} handleClose={hideDeleteModal} loading={loading} success={success} /> : null
            }
            <div className="container-fluid dk-settDocPageMain">
                <div className="dk-mydocPageCon">
                    <div className="col-md-12 ">
                        <Table
                            data={data}
                            columns={columns}
                            loading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyDocuments
