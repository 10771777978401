import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const profileDetails = createAsyncThunk(
    'account/employer/profile/details',
    async ({ uid }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkjobs-authentication"));
            const response = await fetch(process.env.REACT_APP_API_URL + '/account/employer/profile/detail/' + uid,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                        'Authorization': 'Bearer ' + token,
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateLogo = createAsyncThunk(
    'account/employer/profile/update/logo',
    async ({ uid, companyImage }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkjobs-authentication"));
            const response = await fetch(process.env.REACT_APP_API_URL + '/account/employer/profile/update/' + uid,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({
                        companyImage
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateAbout = createAsyncThunk(
    'account/employer/profile/update/about',
    async ({ uid, businessName, businessFounded, businessLocation, businessIndustry, businessHeadquarters, businessType, businessAreaServed, businessWebPage, aboutUs }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkjobs-authentication"));
            const response = await fetch(process.env.REACT_APP_API_URL + '/account/employer/profile/update/' + uid,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({
                        businessName,
                        businessFounded,
                        businessLocation,
                        businessIndustry,
                        businessHeadquarters,
                        businessType,
                        businessAreaServed,
                        businessWebPage,
                        aboutUs
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateContact = createAsyncThunk(
    'account/employer/profile/update/contact',
    async ({ uid, businessName, businessPhone, businessPhone2, businessEmail1, businessEmail2, businessWebPage, businessFacebook, businessTwitter, businessInstagram, businessLinkedin }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkjobs-authentication"));
            const response = await fetch(process.env.REACT_APP_API_URL + '/account/employer/profile/update/' + uid,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({
                        businessName,
                        businessPhone,
                        businessPhone2,
                        businessEmail1,
                        businessEmail2,
                        businessWebPage,
                        businessFacebook,
                        businessTwitter,
                        businessInstagram,
                        businessLinkedin
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const profileSlice = createSlice({
    name: "employer_profile",
    initialState: {
        details: {},
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: ""
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        }
    },
    extraReducers: {
        [profileDetails.pending]: (state) => {
            state.isLoading = true;
        },
        [profileDetails.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.details = payload.result;
            state.message = payload.message;
        },
        [profileDetails.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.message = payload.message;
            }
            state.isLoading = false;
        },
        [updateLogo.pending]: (state) => {
            state.isLoading = true;
        },
        [updateLogo.fulfilled]: (state, { payload }) => {
            state.isSuccess = true;
            state.message = payload.message;
        },
        [updateLogo.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.message = payload.message;
            }
            state.isLoading = false;
        },
        [updateAbout.pending]: (state) => {
        },
        [updateAbout.fulfilled]: (state, { payload }) => {
            state.isSuccess = true;
            state.message = payload.message;
        },
        [updateAbout.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.message = payload.message;
            }
        },
        [updateContact.pending]: (state) => {
        },
        [updateContact.fulfilled]: (state, { payload }) => {
            state.isSuccess = true;
            state.message = payload.message;
        },
        [updateContact.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.message = payload.message;
            }
        }
    }
})
export const { clearState } = profileSlice.actions;

export default profileSlice.reducer;