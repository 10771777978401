import React, { useEffect, useState } from 'react';
import '../forgot.scss';
import { Link, useHistory } from "react-router-dom";
import SubmitButton from "../../../ui/submitButton";
import { forgetPassword, clearState } from "../../../../slice/account/forgetPassword/forgetPasswordSlice";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import Constants from '../../../constants';

const Index = () => {
    const dispatch = useDispatch();
    const { isLoading, isSuccess, isError, message } = useSelector(state => state.forgetPassword);

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const account = 'employer';
    const [email, setEmail] = useState('');

    useEffect(() => {
        document.title = "Forget Password - Employer | DK Global Jobs"
        dispatch(clearMessage());
        dispatch(clearState());
    }, [dispatch])

    const validateForm = () => {
        let formIsValid = true;
        let pattern = '';
        if (!email) {
            formIsValid = false;
            dispatch(showError({ msg: "Please enter your account email." }))
        }
        if (typeof email !== "undefined") {
            //regular expression for email validation
            pattern = new RegExp(Constants.SYSTEM_CONSTANTS.Validation.Email);
            if (!pattern.test(email)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Please enter valid email." }));
            }
        }
        return formIsValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validateForm()) {
            try {
                dispatch(clearMessage())
                await dispatch(forgetPassword({ account, email }))
                if (isLoading) {
                    setSuccess(false);
                    setLoading(true);
                }
            } catch (err) {
                setSuccess(false);
                setLoading(false);
                dispatch(showError({ msg: 'Something went wrong! Try again.' }))
            }
        }
    }

    useEffect(() => {
        if (isError) {
            setSuccess(false);
            setLoading(false);
            dispatch(clearState());
            dispatch(showError({ msg: message }))
        }
        if (isSuccess) {
            dispatch(clearState());
            dispatch(showSuccess({ msg: message }))
            setSuccess(true);
            setLoading(false);
            setTimeout(() =>
                history.push('/forget-password/verify-otp/' + btoa(account) + '/' + btoa(email)
                ), 2000);
        }
    }, [isError, isSuccess, dispatch, message, account, email, history])

    return (
        <React.Fragment>
            <div className="dk-forgotPassMain">
                <div className="dk-forgotCols">
                    <h2 className="title">Employer Forgot Password</h2>
                    <div className="dk-forgotContainer">
                        <form action="">
                            <label htmlFor="">
                                <input type="text" placeholder="Employer Account Email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </label>
                            <label htmlFor="" className="text-center mt-3 mb-0">
                                <SubmitButton txt="Send OTP" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="dk-sendBtn" />
                            </label>
                        </form>
                    </div>
                    <div className="text-center mt-3">
                        <Link to="/login/employer-login" className="dk-backLogin">Back to Login</Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Index;