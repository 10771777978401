import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import './singupmain.scss';
import { FaUserTie, FaBuilding } from "react-icons/fa";
import SingupFaqModal from './SingupFaqModal';

const SingupMain = () => {
    const [isSignupFaqModal, setIsSignupFaqModal] = useState(false);
    const showsignupfaqModal = () => {
        setIsSignupFaqModal(true);
    }
    const hidesignupfaqModal = () => {
        setIsSignupFaqModal(false);
    }
    return (
        <>
            {
                (isSignupFaqModal) ? <SingupFaqModal onHide={hidesignupfaqModal} /> : null
            }
            <div className="dk-signupPage-main">
                <div className="dk-signColbox">
                    <div className="title">
                        Create an Account
                        <div className="dk-signupfaqBtn">
                            <Link to="#" onClick={showsignupfaqModal}><i className="fa fa-question-circle mr-1" aria-hidden="true"></i>SIGN UP FAQ</Link>
                        </div>
                    </div>
                    <div className="dk-signCols">
                        <div className="sbtitle">Choose account type:</div>
                        <div className="d-flex justify-content-center">
                            <Link to="/sign-up/jobseeker-signup" >
                                <span><FaUserTie /> JOBSEEKER</span>
                            </Link>
                            <Link to="/sign-up/employer-signup" >
                                <span><FaBuilding />EMPLOYER</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SingupMain;
