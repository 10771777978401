import React from 'react'
import { Link } from 'react-router-dom'
import './submenu.scss'

const ProfileSubmenu = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="container-fluid">
                <div className="dk-ds-sub-menu col-md-11 pl-0 ml-auto">
                    <div class="dk-ds-sub-menu-cols">
                        <ul>
                            <li>
                                <Link className={pathname === "/jobseeker/standard/profile/basic" ? 'sub-active' : ''} to="/jobseeker/standard/profile/basic">Basic Profile</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/jobseeker/standard/profile/full" ? 'sub-active' : ''} to="/jobseeker/standard/profile/full">Full Profile</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProfileSubmenu;