import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import ActiveImg from "../../../../../../assets/images/envelop.png";
import './empspackages.scss';
import UpgradeServicePack from './UpgradeServicePack';

const CurrentPackages = () => {
    return (
        <>
            <div className="dk-packagesMainEmpSt">
                <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                    <div className="d-md-flex">
                        <div className="dk-leftTabs">
                            <Nav variant="pills">
                                <Nav.Item>
                                    <Nav.Link eventKey="tabs_1">Current</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tabs_2">History</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tabs_3">Buy Now</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>

                        <Tab.Content>
                            <Tab.Pane eventKey="tabs_1">
                                <h3 className="title">CURRENT SERVICES PLAN</h3>
                                <div className="dk-tabsPanel-body">
                                    <div className="dk-tabsPanel-cols">
                                        <div className="dk-renewCols mb-3">
                                            <div className="dk-renewBox">
                                                <div className="d-md-flex align-items-center justify-content-between">
                                                    <div className="dk-renewLeft d-flex align-items-center justify-content-between">
                                                        <div className="dk-itemsImg d-flex align-items-center">
                                                            <img src={ActiveImg} className="img-fluid" alt="" />
                                                            <div className="expiredText active">Active</div>
                                                        </div>
                                                        <div className="dk-renewPlan">
                                                            <div className="title">Subscription Plan</div>
                                                            <p>3 CPU, 6 GB RAM, 40 GB SSD</p>
                                                        </div>
                                                        <div className="dk-renew-Expires greenText">Activated, 15 Sep 2020</div>
                                                        <div className="dk-renew-Expires">Expires Sep 30, 2020</div>
                                                        <div className="dk-renewPrice">64.00€ EXCL. VAT</div>
                                                        <div className="viewDetail"><Link to="#">View Details</Link></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tabs_2">
                                <h3 className="title">SERVICES PLAN HISTORY</h3>
                                <div className="dk-tabsPanel-body">
                                    <div className="dk-tabsPanel-cols">
                                        <div className="dk-renewCols mb-3">
                                            <div className="dk-renewBox">
                                                <div className="d-md-flex align-items-center justify-content-between">
                                                    <div className="dk-renewLeft d-flex align-items-center justify-content-between">
                                                        <div className="dk-itemsImg d-flex align-items-center">
                                                            <img src={ActiveImg} className="img-fluid" alt="" />
                                                            <div className="expiredText inactive">Expired</div>
                                                        </div>
                                                        <div className="dk-renewPlan">
                                                            <div className="title">Subscription Plan</div>
                                                            <p>3 CPU, 6 GB RAM, 40 GB SSD</p>
                                                        </div>
                                                        <div className="dk-renew-Expires greenText">Activated, 15 Sep 2020</div>
                                                        <div className="dk-renew-Expires">Expires Sep 30, 2020</div>
                                                        <div className="dk-renewPrice">64.00€ EXCL. VAT</div>
                                                        <div className="viewDetail"><Link to="#">View Details</Link></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tabs_3">
                                <h3 className="title">UPGRADE SUBSCRIPTION</h3>
                                <div className="row dk-package-mt">
                                    <div className="col-md-9">
                                        <UpgradeServicePack />
                                    </div>
                                    <div className="col-md-3">
                                        <div className="dk-buyRight">
                                            <div className="dk-buyRIght-img">
                                                {/* <img src={Trial} className="img-fluid" alt="" /> */}
                                            </div>
                                            <div className="dk-buyRightPrice">
                                                <p><span>ADS POST CREDITS </span><b>30</b></p>
                                                <p><span>EMPLOYERS POSTS </span><b>150</b></p>
                                                <p><span>CV SEARCH CREDITS </span><b>112</b></p>
                                                <p><span>SOCIAL MEDIA CAMPAIGN </span><b>78</b></p>
                                            </div>
                                            <div className="dk-buyRightDetails">
                                                <p>88.00€/mo</p>
                                                <p className="sbTtile">EXCL. VAT</p>
                                            </div>
                                            <div className="dk-buyPlanBtn">
                                                <button>Select Plan</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tabs_4">

                            </Tab.Pane>
                        </Tab.Content>

                    </div>
                </Tab.Container>
            </div>
        </>
    )
}

export default CurrentPackages;
