import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import SearchJobsSummary from './SearchJobsSummary';
import SearchCvProfileSkills from './SearchCvProfileSkills';
import SearchCvExperince from './SearchCvExperince';
import SearchCvInterests from './SearchCvInterests';
import SearchCvDownload from './SearchCvDownload';
import SearchAchivements from './SearchAchivements';
import SearchQualification from './SearchQualification';

const SearchCvViewModal = ({ handleClose }) => {
    return (
        <>
            <Modal className="dk-searchCvModalMain right" show={true} onHide={handleClose}>
                <Modal.Body>
                    <div class="saveTag skyblue"><i class="far fa-snowflake"></i></div>
                    <div className="row">
                        <div className="col-md-2">
                            <div className="avatar mx-auto white">
                                <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20%2810%29.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div className="dk-searchModal-useDetail">
                                <div className="title">Martha Smith</div>
                                <div className="d-flex">
                                    <div className="col-12 p-0 pt-3">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="d-md-flex align-items-center">
                                                    <ul className="mr-5">
                                                        <li className="dk-icon"><i className="fa fa-briefcase mr-3"></i>Senior Manager, Logistic</li>
                                                        <li className="dk-icon"><i className="fa fa-map-marker-alt mr-3"></i>Dublin, Ireland</li>
                                                    </ul>
                                                    <ul>
                                                        <li className="dk-icon"><i className="far fa-envelope mr-3"></i>deepak@dkglobaljobs.com</li>
                                                        <li className="dk-icon"><i className="fa fa-phone-alt mr-3"></i>+353, 15512444</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="dk-searchJobsTabs">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_1">Summary</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_2">Profile Skills</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_3">Experince</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_4">Qualification</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_5">Achivements</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_6">Interests</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tabs_7">Download</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="tabs_1">
                                        <SearchJobsSummary />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_2">
                                        <SearchCvProfileSkills />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_3">
                                        <SearchCvExperince />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_4">
                                        <SearchQualification />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_5">
                                        <SearchAchivements />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_6">
                                        <SearchCvInterests />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tabs_7">
                                        <SearchCvDownload />
                                    </Tab.Pane>

                                </Tab.Content>
                            </Tab.Container>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SearchCvViewModal;
