import React from 'react'
import './paymentto.scss';
import BankIcon from '../../../../../../../assets/images/banklogo.jpg';
const PaymentTo = () => {
    return (
        <>
            <div className="pt-5 dk-paymentToMainCon">
                <div className="row">
                    <div className="col-md-5 pr-4">
                        <div className="dk-bankinstruction">
                            <div className="title">Instruction</div>
                            <p>
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Blanditiis esse iusto labore pariatur consequuntur voluptas accusamus debitis corrupti exercitationem? Laboriosam, at aliquam! Autem voluptates eum fugit dolore,
                                sit tenetur rerum? Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-7 ml-auto pl-4">
                        <div className="dk-bankinstructionInfo">
                            <div className="dk-bankHead">
                                <img
                                    src={BankIcon}
                                    className="img-fluid"
                                    alt="BankIcon"
                                />
                                <span>DK Global Bank Transfer Details</span>
                            </div>
                            <form action="">
                                <div className="form-group">
                                    <div className="d-md-flex align-items-center">
                                        <label for="">Name of the Bank</label><label for=""><input type="text" className="form-control" name="" id="" /></label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="d-md-flex align-items-center">
                                        <label for="">Address of the Bank</label><label for=""><input type="text" className="form-control" name="" id="" /></label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="d-md-flex align-items-center">
                                        <label for="">Name Account Holder</label><label for=""><input type="text" className="form-control" name="" id="" /></label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="d-md-flex align-items-center">
                                        <label for="">Address of the Account Holder</label><label for=""><input type="text" className="form-control" name="" id="" /></label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="d-md-flex align-items-center">
                                        <label for="">Account Number</label><label for=""><input type="text" className="form-control" name="" id="" /></label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="d-md-flex align-items-center">
                                        <label for="">BIC/Swift Code/Sort Code</label><label for=""><input type="text" className="form-control" name="" id="" /></label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="d-md-flex align-items-center">
                                        <label for="">IBAN</label><label for=""><input type="text" className="form-control" name="" id="" /></label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentTo;
