import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import alertMessagesReducer from '../slice/utils/message/messageSlice';
import authJobseekerReducer from '../slice/account/jobseeker/auth/authSlice';
import authEmployerReducer from '../slice/account/employer/auth/authSlice';
import acountVerifyReducer from '../slice/account/acountVerify/acountVerifySlice';
import forgetPassswordReducer from '../slice/account/forgetPassword/forgetPasswordSlice';
import gdprReducer from '../slice/account/gdpr/gdprSlice';
import accountFaqReducer from '../slice/account/faqs/faqSlice';
import jobseekerSupportReducer from '../slice/account/jobseeker/support/supportSlice';
import employerSupportReducer from '../slice/account/employer/support/supportSlice';
import jobseekerProfileReducer from '../slice/account/jobseeker/profile/basicProfileSlice';
import accountsDocumentsReducer from '../slice/account/documents/documentsSlice';
import contractsDocumentsReducer from '../slice/account/contracts/contractsSlice';

import employerProfileReducer from '../slice/account/employer/profile/profileSlice';
import employerUserProfileReducer from '../slice/account/employer/profile/userProfile/userProfileSlice';

const reducers = combineReducers({
    alertMessage: alertMessagesReducer,
    jobseekerAuth: authJobseekerReducer,
    employerAuth: authEmployerReducer,
    emailVerify: acountVerifyReducer,
    forgetPassword: forgetPassswordReducer,
    gdpr: gdprReducer,
    accountFaq: accountFaqReducer,
    jobseekerSupport: jobseekerSupportReducer,
    employerSupport: employerSupportReducer,
    jobseekerProfile: jobseekerProfileReducer,
    accountDocuments: accountsDocumentsReducer,
    contractsDocuments: contractsDocumentsReducer,
    employerUserProfile: employerUserProfileReducer,
    employerProfile: employerProfileReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['jobseekerAuth', 'employerAuth']
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});