import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const signupUser = createAsyncThunk(
    'account/employer/auth/signup',
    async ({ accountType, accountTrail, businessName, businessType, businessIndustry, businessWebPage, businessCity, businessCountry, businessAdderss, businessPhone, adminName, currentPosition, adminCity, adminPhone, adminEmail, preferedContact, hearUs }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/auth/employer/add_employer',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                    },
                    body: JSON.stringify({
                        accountType,
                        accountTrail,
                        businessName,
                        businessType,
                        businessIndustry,
                        businessWebPage,
                        businessCity,
                        businessCountry,
                        businessAdderss,
                        businessPhone,
                        adminName,
                        currentPosition,
                        adminCity,
                        adminPhone,
                        adminEmail,
                        preferedContact,
                        hearUs
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const loginUser = createAsyncThunk(
    'account/employer/auth/login',
    async ({ accountType, email, password }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/auth/employer/login',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                    },
                    body: JSON.stringify({
                        accountType,
                        email,
                        password,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                localStorage.setItem(
                    "dkjobs-authentication",
                    JSON.stringify({
                        token: data.result.token.access_token,
                        refreshToken: data.result.token.refresh_token
                    })
                );
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const disableProfile = createAsyncThunk(
    'account/employer/profile/disable',
    async ({ isDisable, uid }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkjobs-authentication"));
            const response = await fetch(process.env.REACT_APP_API_URL + '/account/employer/profile/update/' + uid,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({
                        isDisable,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

export const deleteProfile = createAsyncThunk(
    'account/employer/profile/delete',
    async ({ category, accountType, userId }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/queries',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                    },
                    body: JSON.stringify({
                        category,
                        accountType,
                        userId
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
)

export const changeLogin = createAsyncThunk(
    'account/employer/auth/change_login',
    async ({ old_password, new_password }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkjobs-authentication"));
            const response = await fetch(process.env.REACT_APP_API_URL + '/account/employer/profile/change-password',
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({
                        old_password,
                        new_password,
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const authSlice = createSlice({
    name: "auth",
    initialState: {
        userInfo: {},
        loginError: {},
        tokenExpire: 0,
        isAuthenticate: false,
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: ""
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        },
        logoutUser: (state) => {
            state.userInfo = {};
            state.tokenExpire = 0;
            state.isAuthenticate = false;
            localStorage.removeItem("dkjobs-authentication");
        }
    },
    extraReducers: {
        [signupUser.pending]: (state) => {
            state.isLoading = true;
        },
        [signupUser.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
        },
        [signupUser.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.message = payload.message;
            }
            state.isLoading = false;
        },
        [loginUser.pending]: (state) => {
            state.isLoading = true;
        },
        [loginUser.fulfilled]: (state, { payload }) => {
            state.isAuthenticate = true;
            state.userInfo = payload.result
            state.tokenExpire = payload.result.token.accessTokenExpiresIn;
            state.isSuccess = true;
            state.isLoading = false;
            state.message = payload.message;
        },
        [loginUser.rejected]: (state, action) => {
            if (!action.payload.status) {
                state.isError = true;
                state.message = action.payload.message;
                if (action.payload.result) {
                    state.loginError = {
                        account: 'employer',
                        email: action.meta.arg.email,
                        emailVerify: action.payload.result.emailVerify,
                        gdpr: action.payload.result.gdpr,
                        isDeleted: action.payload.result.isDeleted,
                        isDisable: action.payload.result.isDisable,
                        status: action.payload.result.status,
                        uid: action.payload.result.uid
                    }
                } else {
                    state.loginError = {}
                }
            }
            state.isLoading = false;
        },
        [changeLogin.pending]: (state) => {
            state.isLoading = true;
        },
        [changeLogin.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
        },
        [changeLogin.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.message = payload.message;
            }
            state.isLoading = false;
        },
        [deleteProfile.pending]: (state) => {
            state.isLoading = true;
        },
        [deleteProfile.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
        },
        [deleteProfile.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.message = payload.message;
            }
            state.isLoading = false;
        },

    }
});
export const { clearState, logoutUser } = authSlice.actions;

export default authSlice.reducer;