import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const SearchCvProfileSkills = () => {
    return (
        <>
            <div className="dk-profileSkill">
                <div className="row d-flex justify-content-between">
                    <div className="col-md-4">
                        <div className="dk-profileSkll-cols">
                            <div className="title">Language</div>
                            <div className="dk-profileSkll-box">
                                <table className="table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Name of Skill</th>
                                            <th>Level</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>French</td>
                                            <td>Native</td>
                                        </tr>
                                        <tr>
                                            <td>Spanish</td>
                                            <td>Fluent</td>
                                        </tr>
                                        <tr>
                                            <td>English</td>
                                            <td>Basic</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="dk-profileSkll-cols">
                            <div className="title">Professional</div>
                            <div className="dk-profileSkll-box">
                                <table className="table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Name of Skill</th>
                                            <th>Level</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Business Management</td>
                                            <td>Expert</td>
                                        </tr>
                                        <tr>
                                            <td>Communication Skills</td>
                                            <td>Expert</td>
                                        </tr>
                                        <tr>
                                            <td>Team Management</td>
                                            <td>Good</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="dk-profileSkll-cols">
                            <div className="title">Technical</div>
                            <div className="dk-profileSkll-box">
                                <table className="table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Name of Skill</th>
                                            <th>Level</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>JAVA</td>
                                            <td>Core</td>
                                        </tr>
                                        <tr>
                                            <td>C++</td>
                                            <td>Core</td>
                                        </tr>
                                        <tr>
                                            <td>Node.js</td>
                                            <td>Advance</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SearchCvProfileSkills;
