import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from "../../../assets/images/dkglobal-logo.png";
import './companyjobs.scss';

const CompanyJobsNavbar = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="dk-companyJobsHeader">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 m-auto">
                            <div className="dk-companyJobs-logo">
                                <img src={Logo} className="img-fluid" alt="" />
                            </div>
                            <div className="company-nav">
                                <Link to="/company-jobs" className={pathname === "/company-jobs" ? 'active' : ''}>All Jobs</Link>
                                <Link to="/company-jobs/about-us" className={pathname === "/company-jobs/about-us" ? 'active' : ''}>About Us</Link>
                                <Link to="/company-jobs/contact-us" className={pathname === "/company-jobs/contact-us" ? 'active' : ''}>Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyJobsNavbar
