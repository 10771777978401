import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom';
import './basicprofile.scss';
import SubmitButton from '../../../../../ui/submitButton';
import Modal from 'react-bootstrap/Modal'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { showSuccess, showError, clearMessage } from '../../../../../../slice/utils/message/messageSlice';
import { useDispatch, useSelector } from "react-redux";
import Constants from '../../../../../constants';
import AWS from 'aws-sdk';
import { updateBasic, clearState } from '../../../../../../slice/account/jobseeker/profile/basicProfileSlice';
import { updateState } from '../../../../../../slice/account/jobseeker/auth/authSlice';

const BasicProfile = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.jobseekerAuth);
    const { isLoading, isSuccess, isError, message } = useSelector(state => state.jobseekerProfile);

    const [isImageUpdate, setIsImageUpdate] = useState(false)
    const [newImageData, setNewImageData] = useState('')
    const [profileImage, setProfileImage] = useState(userInfo.profileImage)
    const [firstName, setFirstName] = useState(userInfo.firstName)
    const [lastName, setLastName] = useState(userInfo.lastName)
    const [email, setEmail] = useState(userInfo.email)
    const [mobile, setMobile] = useState(userInfo.mobile)
    const [city, setCity] = useState(userInfo.city)
    const [zipCode, setZipCode] = useState(userInfo.zipCode)
    const [state, setState] = useState(userInfo.state)
    const [country, setCountry] = useState(userInfo.country)
    const [address, setAddress] = useState(userInfo.address)

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const fileInput = useRef();
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState(
        {
            unit: 'px',
            width: 150,
            height: 150,
            aspect: 1 / 1
        }
    );
    const [completedCrop, setCompletedCrop] = useState(null);
    const [cropModal, setCropModal] = useState(false)

    useEffect(() => {
        document.title = "Jobseeker Profile | DK Global Jobs"
    }, [])

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
            setCropModal(true)
        }
    }

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
        const base64Image = canvas.toDataURL("image/jpeg");

        setIsImageUpdate(true)
        setNewImageData(base64Image)
    }, [completedCrop]);

    const handleClose = () => {
        setCropModal(false);
        fileInput.current.value = ''
    }

    const handleCropDone = () => {
        setCropModal(false);
    }

    const cancelCrop = () => {
        setCropModal(false);
        setNewImageData('')
        setIsImageUpdate(false)
        setCompletedCrop(null)
    }

    const validateForm = () => {
        let formIsValid = true;
        let pattern = '';
        if (!firstName || !lastName || !mobile || !city || !zipCode || !state || !country || !address) {
            formIsValid = false;
            dispatch(showError({ msg: "Please fill all required details" }))
        } else {
            if (typeof firstName !== "undefined") {
                if (!firstName.match(Constants.SYSTEM_CONSTANTS.Validation.Aplphabet)) {
                    formIsValid = false;
                    dispatch(showError({ msg: "*Please enter alphabet characters only on First Name." }));
                } else {
                    if (firstName.length < 3) {
                        formIsValid = false;
                        dispatch(showError({ msg: "*First Name should be atleast 3 character." }));
                    }
                }
            }

            if (typeof lastName !== "undefined") {
                if (!lastName.match(Constants.SYSTEM_CONSTANTS.Validation.Aplphabet)) {
                    formIsValid = false;
                    dispatch(showError({ msg: "*Please enter alphabet characters only on Last Name." }));
                } else {
                    if (lastName.length < 3) {
                        formIsValid = false;
                        dispatch(showError({ msg: "*Last Name should be atleast 3 character." }));
                    }
                }
            }

            if (typeof mobile !== "undefined") {
                //regular expression for Phone validation
                pattern = new RegExp(Constants.SYSTEM_CONSTANTS.Validation.Phone);
                if (!pattern.test(mobile)) {
                    formIsValid = false;
                    dispatch(showError({ msg: "*Please enter valid mobile number." }));
                }
            }

            if (typeof city !== "undefined") {
                if (!city.match(Constants.SYSTEM_CONSTANTS.Validation.Aplphabet)) {
                    formIsValid = false;
                    dispatch(showError({ msg: "*Invalid City." }));
                }
            }

            if (typeof state !== "undefined") {
                if (!state.match(Constants.SYSTEM_CONSTANTS.Validation.Aplphabet)) {
                    formIsValid = false;
                    dispatch(showError({ msg: "*Invalid State." }));
                }
            }

            if (typeof country !== "undefined") {
                if (!country.match(Constants.SYSTEM_CONSTANTS.Validation.Aplphabet)) {
                    formIsValid = false;
                    dispatch(showError({ msg: "*Invalid Country." }));
                }
            }

            if (typeof address !== "undefined") {
                if (address.length < 10) {
                    formIsValid = false;
                    dispatch(showError({ msg: "*Address should be atleast 10 character." }));
                }
            }
        }
        return formIsValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validateForm()) {
            try {
                dispatch(clearMessage())
                setSuccess(false);
                setLoading(true);
                if (isImageUpdate) {
                    let s3bucket = new AWS.S3({
                        accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
                        secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
                        Bucket: process.env.REACT_APP_BUCKET_NAME + '/users/jobseeker/images'
                    });
                    let buf = new Buffer(newImageData.replace(/^data:image\/\w+;base64,/, ""), 'base64')
                    s3bucket.createBucket(function () {
                        var params = {
                            Bucket: process.env.REACT_APP_BUCKET_NAME + '/users/jobseeker/images',
                            Key: userInfo.uid + '-' + Date.now() + '.png',
                            ContentEncoding: 'base64',
                            ContentType: 'image/png',
                            Body: buf
                        };
                        s3bucket.upload(params, function (err, data) {
                            if (err) {
                                console.log(err)
                            } else {
                                setProfileImage("/" + data.key)
                                dispatch(updateBasic({ uid: userInfo.uid, firstName, lastName, mobile, city, zipCode, state, country, address, profileImage: "/" + data.key }))
                            }
                        });
                    })
                } else {
                    await dispatch(updateBasic({ uid: userInfo.uid, firstName, lastName, mobile, city, zipCode, state, country, address, profileImage }))
                }
            } catch (err) {
                setSuccess(false);
                setLoading(false);
                dispatch(showError({ msg: 'Something went wrong! Try again. ' + err }))
            }
        }
    }

    const clearForm = (e) => {
        e.preventDefault()
        setCompletedCrop(null)
        setIsImageUpdate(false)
        setNewImageData('')
        setProfileImage(userInfo.profileImage)
        setFirstName(userInfo.firstName)
        setLastName(userInfo.lastName)
        setEmail(userInfo.email)
        setMobile(userInfo.mobile)
        setCity(userInfo.city)
        setZipCode(userInfo.zipCode)
        setState(userInfo.state)
        setCountry(userInfo.country)
        setAddress(userInfo.address)
        fileInput.current.value = ''
    }

    useEffect(() => {
        if (isSuccess) {
            setSuccess(false);
            setLoading(false);
            dispatch(showSuccess({ msg: 'Profile Updated successfully' }))
            dispatch(updateState({ firstName, lastName, city, mobile, state, zipCode, country, address, profileImage }))
            dispatch(clearState())
            fileInput.current.value = ''
        }
        if (isError) {
            dispatch(showError({ msg: message }))
            dispatch(clearState())
        }
    }, [isLoading, isSuccess, isError, message, dispatch, firstName, lastName, city, mobile, state, zipCode, country, address, profileImage])

    return (
        <React.Fragment>
            {
                (cropModal) ?
                    <Modal className="dk-cropImgModal" show={true} onHide={handleClose}>
                        <Modal.Body>
                            <ReactCrop
                                src={upImg}
                                onImageLoaded={onLoad}
                                crop={crop}
                                onChange={(c) => setCrop(c)}
                                onComplete={(c) => setCompletedCrop(c)}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12">
                                <div class="d-flex justify-content-center btn-container">
                                    <button class="dk-cancelBtn" onClick={cancelCrop}>Cancel</button>
                                    <SubmitButton txt="Use This" loading={loading} success={success} onClick={handleCropDone} position="" className="dk-updateBtn" />
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal> : null
            }
            <div className="container-fluid dk-basicProfile">
                <div className="dk-basicProfileCon">
                    <div className="col-md-11 pl-0 ml-auto">
                        <div className="row ml-0 mr-0">
                            <div className="col-md-12 p-0 m-auto dk-boxsCon">
                                <h3>Jobseeker Basic Profile</h3>
                                <form>
                                    <div className="row">
                                        <div className="d-md-flex align-items-center justify-content-between w-100 mb-4">
                                            <div className="col-md-6">
                                                <div className="form-group uplaod-image">
                                                    <div className="d-flex align-items-center">
                                                        <div className="dk-contactPerson-image">
                                                            {
                                                                (completedCrop) ?
                                                                    <canvas
                                                                        ref={previewCanvasRef}
                                                                    />
                                                                    : <img alt="Crop" id="cropImgId" className="profile-img" src={process.env.REACT_APP_IMAGE_BASE_URL + profileImage} />
                                                            }
                                                        </div>
                                                        <div className="dk-uploadBtnText">
                                                            <div className="dk-contactPerson-uploadBtn">
                                                                <input type="file" accept="image/*" name="jobseekerLogo" onChange={onSelectFile} ref={fileInput} />
                                                                <span>Upload Image</span>
                                                            </div>
                                                            <div className="dk-sbTitle text-center">
                                                                <Link to="#">Remove Photo</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="dk-informationCols">
                                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo officia illum repellat veniam porro.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label for="">First Name <span class="colorRequired">*</span></label>
                                                <label for="" className="d-flex">
                                                    <input type="text" className="form-control" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label for="">Last Name <span class="colorRequired">*</span></label>
                                                <label for="" className="d-flex">
                                                    <input type="text" className="form-control" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label for="">Email</label>
                                                <label for="" className="d-flex">
                                                    <input type="text" className="form-control" value={email} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label for="">Phone <span class="colorRequired">*</span></label>
                                                <label for="" className="d-flex">
                                                    <input type="text" className="form-control" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label for="">City/Town <span class="colorRequired">*</span></label>
                                                <label for="" className="d-flex">
                                                    <input type="text" className="form-control" value={city} onChange={(e) => setCity(e.target.value)} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label for="">Post code <span class="colorRequired">*</span></label>
                                                <label for="" className="d-flex">
                                                    <input type="text" className="form-control" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label for="">State/Province <span class="colorRequired">*</span></label>
                                                <label for="" className="d-flex">
                                                    <input type="text" className="form-control" value={state} onChange={(e) => setState(e.target.value)} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label for="">Country <span class="colorRequired">*</span></label>
                                                <label for="" className="d-flex">
                                                    <input type="text" className="form-control" value={country} onChange={(e) => setCountry(e.target.value)} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label for="">Home Address <span class="colorRequired">*</span></label>
                                                <label for="" className="d-flex">
                                                    <input type="text" className="form-control" value={address} onChange={(e) => setAddress(e.target.value)} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="d-flex justify-content-center btn-container">
                                                <button class="dk-cancelBtn" onClick={clearForm}>Cancel</button>
                                                <SubmitButton txt="Update Profile" loading={loading} success={success} onClick={handleSubmit} position="" className="dk-updateBtn" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
export default BasicProfile;