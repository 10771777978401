import React from 'react';
import { Link } from 'react-router-dom'
import './employer.scss';
import { Nav } from 'react-bootstrap'

const EmployerPremimum = () => {
    return (
        <>
            <div className="dk-loginEmpMainBg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 m-auto col-sm-12">
                            <div className="dk-job-empFromCon">
                                <div className="dk-job-empFromTitle">
                                    <h2>LOGIN TO YOUR ACCOUNT</h2>
                                </div>
                                <div className="dk-job-empFromBox">
                                    <div className="dklogin-tabCon">
                                        <div className="row">
                                            <div className="col-12">
                                                <Nav variant="pills dk-loginTabs" className="flex-row">
                                                    <li>
                                                        <Link to="/login/employer-login" className="emp-standard">Employer Standard</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/login/employer-premimum" className="emp-premium active">Employer Premium</Link>
                                                    </li>
                                                </Nav>
                                            </div>
                                            <div className="dk-loginTabpaneCon">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <input type="text" className="form-control" name="Email" placeholder="Email" />
                                                            </div>
                                                            <div className="form-group">
                                                                <input type="password" className="form-control" name="Password" placeholder="Password" />
                                                            </div>
                                                            <div className="dklogin-checkBoxCon">
                                                                <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                                                <label class="form-check-label" for="exampleCheck1">Keep me signed in on this device.</label>
                                                            </div>
                                                            <div className="agree-termsCon">
                                                                <p>By signing in to your account, you agree to our
                                                                    <Link to="#">Terms of Services</Link> and
                                                                    <Link to="#">Privacy Policy.</Link>
                                                                </p>
                                                            </div>
                                                            <div className="loginsubmit-buttonCon">
                                                                <button className="btn loginsubmit-btn">Login</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="login-bottomSec">
                                    <li>
                                        <Link to="/sign-up/employer-signup">Not a member? Create an account</Link>
                                    </li>
                                    <li>
                                        <Link to="/forget-password/employer">Forgot Your Password?</Link>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployerPremimum;
