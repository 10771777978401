import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FullTime from "../../../../../../assets/images/fulltime.jpg";
import PartTime from "../../../../../../assets/images/parttime.jpg";
import OtherTime from "../../../../../../assets/images/othertime.jpg";
import Companies from "../../../../../../assets/images/companies.jpg";
import Companies2 from "../../../../../../assets/images/companies_2.jpg";
import Companies3 from "../../../../../../assets/images/companies_3.jpg";
import Companies4 from "../../../../../../assets/images/companies_4.jpg";
import './savedjobs.scss';
import SavedJobsPagination from './SavedJobsPagination';

const SavedJobs = () => {
    return (
        <>
            <div className="dk-savedJobsMain">
                <div className="title">MY SAVED JOBS</div>
                <div className="dk-savedJobs-fluid">
                    <div className="dk-savedJobs-container">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="dk-savedJobs-cols">
                                    <div className="d-flex">
                                        <div className="dk-boxes-cols">
                                            <div className="d-flex">
                                                <div className="col-md-2">
                                                    <div className="dk-boxes-cols-img">
                                                        <img src={FullTime} className="img-fluid" alt="" />
                                                        <Link to="#" className="dk-boxes-btn">Full Time</Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="dk-boxes-cols-right">
                                                        <div className="dk-boxes-cols-right-cols">
                                                            <div className="title">REGIONAL SALES MANAGER SOUTH EAST ASIA</div>
                                                            <div className="dk-boxes-right-cols-desc">
                                                                <div className="d-md-flex w-100 justify-content-between">
                                                                    <ul>
                                                                        <li><i className="fas fa-briefcase"></i> Job Title</li>
                                                                        <li><i className="fas fa-euro-sign"></i> Salary</li>
                                                                        <li><i className="fas fa-building"></i> Employer Name</li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li><i className="fas fa-language"></i> Language</li>
                                                                        <li><i className="fas fa-map-marker-alt"></i> Location</li>
                                                                        <li><i className="far fa-calendar-alt"></i> Start Date</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dk-actionBtn">
                                            <Link to="#">Saved</Link>
                                            <Link to="#">Removed</Link>
                                            <Link to="#"></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-savedJobs-cols">
                                    <div className="d-flex">
                                        <div className="dk-boxes-cols">
                                            <div className="d-flex">
                                                <div className="col-md-2">
                                                    <div className="dk-boxes-cols-img">
                                                        <img src={PartTime} className="img-fluid" alt="" />
                                                        <Link to="#" className="dk-boxes-btn">Full Time</Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="dk-boxes-cols-right">
                                                        <div className="dk-boxes-cols-right-cols">
                                                            <div className="title">REGIONAL SALES MANAGER SOUTH EAST ASIA</div>
                                                            <div className="dk-boxes-right-cols-desc">
                                                                <div className="d-md-flex w-100 justify-content-between">
                                                                    <ul>
                                                                        <li><i className="fas fa-briefcase"></i> Job Title</li>
                                                                        <li><i className="fas fa-euro-sign"></i> Salary</li>
                                                                        <li><i className="fas fa-building"></i> Employer Name</li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li><i className="fas fa-language"></i> Language</li>
                                                                        <li><i className="fas fa-map-marker-alt"></i> Location</li>
                                                                        <li><i className="far fa-calendar-alt"></i> Start Date</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dk-actionBtn">
                                            <Link to="#">Saved</Link>
                                            <Link to="#">Removed</Link>
                                            <Link to="#"></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-savedJobs-cols">
                                    <div className="d-flex">
                                        <div className="dk-boxes-cols">
                                            <div className="d-flex">
                                                <div className="col-md-2">
                                                    <div className="dk-boxes-cols-img">
                                                        <img src={OtherTime} className="img-fluid" alt="" />
                                                        <Link to="#" className="dk-boxes-btn">Full Time</Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="dk-boxes-cols-right">
                                                        <div className="dk-boxes-cols-right-cols">
                                                            <div className="title">REGIONAL SALES MANAGER SOUTH EAST ASIA</div>
                                                            <div className="dk-boxes-right-cols-desc">
                                                                <div className="d-md-flex w-100 justify-content-between">
                                                                    <ul>
                                                                        <li><i className="fas fa-briefcase"></i> Job Title</li>
                                                                        <li><i className="fas fa-euro-sign"></i> Salary</li>
                                                                        <li><i className="fas fa-building"></i> Employer Name</li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li><i className="fas fa-language"></i> Language</li>
                                                                        <li><i className="fas fa-map-marker-alt"></i> Location</li>
                                                                        <li><i className="far fa-calendar-alt"></i> Start Date</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dk-actionBtn">
                                            <Link to="#">Saved</Link>
                                            <Link to="#">Removed</Link>
                                            <Link to="#"></Link>
                                        </div>
                                    </div>
                                </div>

                                <SavedJobsPagination />
                            </div>
                            <div className="col-md-3">
                                <div className="dk-savedJob-company">
                                    <div className="dk-companiesBox">
                                        <img src={Companies} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dk-companiesBox">
                                        <img src={Companies2} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dk-companiesBox">
                                        <img src={Companies3} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dk-companiesBox">
                                        <img src={Companies4} className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SavedJobs;
