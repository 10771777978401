import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

export default function FilterColumn() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <React.Fragment>
            <div className="dk-jobsfilter-search">
                <label htmlFor="" className="d-flex align-items-center">
                    <input type="text" className="form-control" placeholder="Search Keywords" />
                    <button className="dk-filter-search-btn"><i class="fas fa-search"></i></button>
                </label>
            </div>
            <div className="dk-jobsFilter-main">
                <div className={classes.root}>
                    <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content"
                            id="panel1bh-header">
                            <Typography className={classes.heading}>Favorite</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <ul>
                                    <li>
                                        <a href="#" class="">
                                            <i class="far fa-heart"></i> Favorite<span>20</span>
                                        </a>
                                    </li>
                                </ul>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content"
                            id="panel2bh-header">
                            <Typography className={classes.heading}>Job Type</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <ul>
                                    <li>
                                        <a href="#" class="">
                                            Full Time<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Part Time<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Night Time<span>20</span>
                                        </a>
                                    </li>
                                </ul>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content"
                            id="panel3bh-header">
                            <Typography className={classes.heading}>Salary</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <ul>
                                    <li>
                                        <a href="#" class="">
                                            1 Lakh to 3 Lakh<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            3 Lakh to 6 Lakh<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            6 Lakh to 9 Lakh<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            9 Lakh to 12 Lakh<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            12 Lakh to 15 Lakh<span>20</span>
                                        </a>
                                    </li>
                                </ul>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content"
                            id="panel4bh-header">
                            <Typography className={classes.heading}>Country</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <ul>
                                    <li>
                                        <a href="#" class="">
                                            Afghanistan<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Albania<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Brazil<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Canada<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Denmark<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Colombia<span>20</span>
                                        </a>
                                    </li>
                                </ul>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content"
                            id="panel5bh-header">
                            <Typography className={classes.heading}>City</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <ul>
                                    <li>
                                        <a href="#" class="">
                                            Afghanistan<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Albania<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Brazil<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Canada<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Denmark<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Colombia<span>20</span>
                                        </a>
                                    </li>
                                </ul>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6bh-content"
                            id="panel6bh-header">
                            <Typography className={classes.heading}>Language</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <ul>
                                    <li>
                                        <a href="#" class="">
                                            German<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            French<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Spanish<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Turkish<span>20</span>
                                        </a>
                                    </li>
                                </ul>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel7bh-content"
                            id="panel7bh-header">
                            <Typography className={classes.heading}>Sector</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <ul>
                                    <li>
                                        <a href="#" class="">
                                            Accounting<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Finance<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Finance<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Agriculture<span>20</span>
                                        </a>
                                    </li>
                                </ul>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel8bh-content"
                            id="panel8bh-header">
                            <Typography className={classes.heading}>Category</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <ul>
                                    <li>
                                        <a href="#" class="">
                                            IT<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Customer Services<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Technical Support<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Account Payble<span>20</span>
                                        </a>
                                    </li>
                                </ul>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel9bh-content"
                            id="panel9bh-header">
                            <Typography className={classes.heading}>Role/Skills</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <ul>
                                    <li>
                                        <a href="#" class="">
                                            Java<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Php<span>20</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="">
                                            Angular<span>20</span>
                                        </a>
                                    </li>
                                </ul>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                </div>
            </div>
        </React.Fragment>
    );
}