import React, { useState } from 'react';
import { Route, Link } from 'react-router-dom';
import DefultImg from "../../../assets/images/profile_pic.png";
import DefultImg2 from "../../../assets/images/deepak.png";
import IrelandFlag from "../../../assets/images/ire_flag.jpg";
import IndiaFlag from "../../../assets/images/ind_flag.jpg";
import UsFlag from "../../../assets/images/us_flag.jpg";
import BritainFlag from "../../../assets/images/bri_flag.jpg";
import './frontjobseekers.scss';
import FrontJobseekersFilter from './FrontJobseekersFilter';
import JobseekerDetails from './JobseekerDetails';

const FrontJobSeekers = () => {
    const [showJobseekerDetails, setDetailsShow] = useState(false);
    const showDetailsModal = () => {
        setDetailsShow(true)
    }
    const hideDetailsModal = () => {
        setDetailsShow(false)
    }
    return (
        <>
            {
                showJobseekerDetails ?
                    <JobseekerDetails handleClose={hideDetailsModal} /> : null
            }
            <div className="dk-frontJobseekerMain">
                <div class="dk-frontJobhead">
                    <h3 class="title">
                        JOBSEEKERS PROFILES <span><i class="fas fa-info-circle"></i></span>
                        <div class="dk-jobFront-infoModal"></div>
                    </h3>
                </div>

                <div className="dk-frontJobseekers-filter">
                    <FrontJobseekersFilter />
                </div>

                <div className="dk-frontJobInner">
                    <div className="dk-frontJobTags">
                        <div className="d-flex">
                            <Route>
                                <Link to="#">Active <span><i class="fas fa-times"></i></span></Link>
                            </Route>
                        </div>
                    </div>

                    <div className="dk-frontJobScroll">
                        <div className="col-md-12 pl-4 pr-4">
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="dk-frontJobInner-cols">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="dk-frontJob-times"><i class="far fa-clock"></i> May 5, 2021</div>
                                            <div className="tagStatus job-active green">Online</div>
                                        </div>
                                        <div className="glogo">
                                            <img src={DefultImg} className="img-fluid" alt="" />
                                        </div>
                                        <a href="#" className="Gtitle" onClick={showDetailsModal}>
                                            <h3>Mariana Cont</h3>
                                        </a>

                                        <div className="dk-frontJob-details">
                                            <li><i class="fas fa-briefcase"></i> Recruitment TL</li>
                                            <li><i class="fas fa-building"></i> DK Global Recruitment</li>
                                            <li><i class="fas fa-layer-group"></i> Career level</li>
                                            <li>
                                                <i class="fas fa-language"></i>
                                                <span className="dk-langImg">
                                                    <img src={IrelandFlag} className="img-fluid" alt="" />
                                                    <img src={IndiaFlag} className="img-fluid" />
                                                    <img src={UsFlag} className="img-fluid" alt="" />
                                                </span>
                                            </li>
                                        </div>

                                        <div className="dk-frontJob-info">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Location</div>
                                                    <div className="sbtitle">Ireland</div>
                                                </div>
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Gender</div>
                                                    <div className="sbtitle">F / 40</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="dk-frontJobInner-cols">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="dk-frontJob-times"><i class="far fa-clock"></i> May 5, 2021</div>
                                            <div className="tagStatus job-active red">Offline</div>
                                        </div>
                                        <div className="glogo">
                                            <img src={DefultImg2} className="img-fluid" alt="" />
                                        </div>
                                        <a href="#" className="Gtitle" onClick={showDetailsModal}>
                                            <h3>Deepak Kumar</h3>
                                        </a>
                                        <div className="dk-frontJob-details">
                                            <li><i class="fas fa-briefcase"></i> Recruitment TL</li>
                                            <li><i class="fas fa-building"></i> DK Global Recruitment</li>
                                            <li><i class="fas fa-layer-group"></i> Career level</li>
                                            <li>
                                                <i class="fas fa-language"></i>
                                                <span className="dk-langImg">
                                                    <img src={UsFlag} className="img-fluid" alt="" />
                                                    <img src={IndiaFlag} className="img-fluid" alt="" />
                                                    <img src={BritainFlag} className="img-fluid" alt="" />
                                                </span>
                                            </li>
                                        </div>

                                        <div className="dk-frontJob-info">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Location</div>
                                                    <div className="sbtitle">Ireland</div>
                                                </div>
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Gender</div>
                                                    <div className="sbtitle">M / 40</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="dk-frontJobInner-cols">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="dk-frontJob-times"><i class="far fa-clock"></i> May 5, 2021</div>
                                            <div className="tagStatus job-active green">Online</div>
                                        </div>
                                        <div className="glogo">
                                            <img src={DefultImg} className="img-fluid" alt="" />
                                        </div>
                                        <a href="#" className="Gtitle" onClick={showDetailsModal}>
                                            <h3>Mariana Cont</h3>
                                        </a>

                                        <div className="dk-frontJob-details">
                                            <li><i class="fas fa-briefcase"></i> Recruitment TL</li>
                                            <li><i class="fas fa-building"></i> DK Global Recruitment</li>
                                            <li><i class="fas fa-layer-group"></i> Career level</li>
                                            <li>
                                                <i class="fas fa-language"></i>
                                                <span className="dk-langImg">
                                                    <img src={UsFlag} className="img-fluid" alt="" />
                                                    <img src={IndiaFlag} className="img-fluid" alt="" />
                                                    <img src={BritainFlag} className="img-fluid" alt="" />
                                                </span>
                                            </li>
                                        </div>

                                        <div className="dk-frontJob-info">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Location</div>
                                                    <div className="sbtitle">Ireland</div>
                                                </div>
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Gender</div>
                                                    <div className="sbtitle">F / 40</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="dk-frontJobInner-cols">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="dk-frontJob-times"><i class="far fa-clock"></i> May 5, 2021</div>
                                            <div className="tagStatus job-active red">Offline</div>
                                        </div>
                                        <div className="glogo">
                                            <img src={DefultImg2} className="img-fluid" alt="" />
                                        </div>
                                        <a href="#" className="Gtitle" onClick={showDetailsModal}>
                                            <h3>Deepak Kumar</h3>
                                        </a>

                                        <div className="dk-frontJob-details">
                                            <li><i class="fas fa-briefcase"></i> Recruitment TL</li>
                                            <li><i class="fas fa-building"></i> DK Global Recruitment</li>
                                            <li><i class="fas fa-layer-group"></i> Career level</li>
                                            <li>
                                                <i class="fas fa-language"></i>
                                                <span className="dk-langImg">
                                                    <img src={IrelandFlag} className="img-fluid" alt="" />
                                                    <img src={IndiaFlag} className="img-fluid" />
                                                    <img src={UsFlag} className="img-fluid" alt="" />
                                                </span>
                                            </li>
                                        </div>

                                        <div className="dk-frontJob-info">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Location</div>
                                                    <div className="sbtitle">Ireland</div>
                                                </div>
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Gender</div>
                                                    <div className="sbtitle">M / 40</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="dk-frontJobInner-cols">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="dk-frontJob-times"><i class="far fa-clock"></i> May 5, 2021</div>
                                            <div className="tagStatus job-active green">Online</div>
                                        </div>
                                        <div className="glogo">
                                            <img src={DefultImg} className="img-fluid" alt="" />
                                        </div>
                                        <a href="#" className="Gtitle" onClick={showDetailsModal}>
                                            <h3>Mariana Cont</h3>
                                        </a>

                                        <div className="dk-frontJob-details">
                                            <li><i class="fas fa-briefcase"></i> Recruitment TL</li>
                                            <li><i class="fas fa-building"></i> DK Global Recruitment</li>
                                            <li><i class="fas fa-layer-group"></i> Career level</li>
                                            <li>
                                                <i class="fas fa-language"></i>
                                                <span className="dk-langImg">
                                                    <img src={IndiaFlag} className="img-fluid" alt="" />
                                                    <img src={UsFlag} className="img-fluid" alt="" />
                                                    <img src={BritainFlag} className="img-fluid" alt="" />
                                                </span>
                                            </li>
                                        </div>

                                        <div className="dk-frontJob-info">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Location</div>
                                                    <div className="sbtitle">Ireland</div>
                                                </div>
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Gender</div>
                                                    <div className="sbtitle">F / 40</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-2">
                                    <div className="dk-frontJobInner-cols">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="dk-frontJob-times"><i class="far fa-clock"></i> May 5, 2021</div>
                                            <div className="tagStatus job-active green">Online</div>
                                        </div>
                                        <div className="glogo">
                                            <img src={DefultImg} className="img-fluid" alt="" />
                                        </div>
                                        <a href="#" className="Gtitle" onClick={showDetailsModal}>
                                            <h3>Mariana Cont</h3>
                                        </a>

                                        <div className="dk-frontJob-details">
                                            <li><i class="fas fa-briefcase"></i> Recruitment TL</li>
                                            <li><i class="fas fa-building"></i> DK Global Recruitment</li>
                                            <li><i class="fas fa-layer-group"></i> Career level</li>
                                            <li>
                                                <i class="fas fa-language"></i>
                                                <span className="dk-langImg">
                                                    <img src={IndiaFlag} className="img-fluid" alt="" />
                                                    <img src={UsFlag} className="img-fluid" alt="" />
                                                    <img src={BritainFlag} className="img-fluid" alt="" />
                                                </span>
                                            </li>
                                        </div>

                                        <div className="dk-frontJob-info">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Location</div>
                                                    <div className="sbtitle">Ireland</div>
                                                </div>
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Gender</div>
                                                    <div className="sbtitle">F / 40</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="dk-frontJobInner-cols">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="dk-frontJob-times"><i class="far fa-clock"></i> May 5, 2021</div>
                                            <div className="tagStatus job-active red">Offline</div>
                                        </div>
                                        <div className="glogo">
                                            <img src={DefultImg2} className="img-fluid" alt="" />
                                        </div>
                                        <a href="#" className="Gtitle" onClick={showDetailsModal}>
                                            <h3>Deepak Kumar</h3>
                                        </a>
                                        <div className="dk-frontJob-details">
                                            <li><i class="fas fa-briefcase"></i> Recruitment TL</li>
                                            <li><i class="fas fa-building"></i> DK Global Recruitment</li>
                                            <li><i class="fas fa-layer-group"></i> Career level</li>
                                            <li>
                                                <i class="fas fa-language"></i>
                                                <span className="dk-langImg">
                                                    <img src={BritainFlag} className="img-fluid" alt="" />
                                                    <img src={IndiaFlag} className="img-fluid" />
                                                    <img src={UsFlag} className="img-fluid" alt="" />
                                                </span>
                                            </li>
                                        </div>

                                        <div className="dk-frontJob-info">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Location</div>
                                                    <div className="sbtitle">Ireland</div>
                                                </div>
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Gender</div>
                                                    <div className="sbtitle">M / 40</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="dk-frontJobInner-cols">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="dk-frontJob-times"><i class="far fa-clock"></i> May 5, 2021</div>
                                            <div className="tagStatus job-active green">Online</div>
                                        </div>
                                        <div className="glogo">
                                            <img src={DefultImg} className="img-fluid" alt="" />
                                        </div>
                                        <a href="#" className="Gtitle" onClick={showDetailsModal}>
                                            <h3>Mariana Cont</h3>
                                        </a>

                                        <div className="dk-frontJob-details">
                                            <li><i class="fas fa-briefcase"></i> Recruitment TL</li>
                                            <li><i class="fas fa-building"></i> DK Global Recruitment</li>
                                            <li><i class="fas fa-layer-group"></i> Career level</li>
                                            <li>
                                                <i class="fas fa-language"></i>
                                                <span className="dk-langImg">
                                                    <img src={BritainFlag} className="img-fluid" alt="" />
                                                    <img src={IndiaFlag} className="img-fluid" />
                                                    <img src={UsFlag} className="img-fluid" alt="" />
                                                </span>
                                            </li>
                                        </div>

                                        <div className="dk-frontJob-info">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Location</div>
                                                    <div className="sbtitle">Ireland</div>
                                                </div>
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Gender</div>
                                                    <div className="sbtitle">F / 40</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="dk-frontJobInner-cols">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="dk-frontJob-times"><i class="far fa-clock"></i> May 5, 2021</div>
                                            <div className="tagStatus job-active red">Offline</div>
                                        </div>
                                        <div className="glogo">
                                            <img src={DefultImg2} className="img-fluid" alt="" />
                                        </div>
                                        <a href="#" className="Gtitle" onClick={showDetailsModal}>
                                            <h3>Deepak Kumar</h3>
                                        </a>

                                        <div className="dk-frontJob-details">
                                            <li><i class="fas fa-briefcase"></i> Recruitment TL</li>
                                            <li><i class="fas fa-building"></i> DK Global Recruitment</li>
                                            <li><i class="fas fa-layer-group"></i> Career level</li>
                                            <li>
                                                <i class="fas fa-language"></i>
                                                <span className="dk-langImg">
                                                    <img src={IrelandFlag} className="img-fluid" alt="" />
                                                    <img src={IndiaFlag} className="img-fluid" alt="" />
                                                    <img src={UsFlag} className="img-fluid" alt="" />
                                                </span>
                                            </li>
                                        </div>

                                        <div className="dk-frontJob-info">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Location</div>
                                                    <div className="sbtitle">Ireland</div>
                                                </div>
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Gender</div>
                                                    <div className="sbtitle">M / 40</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="dk-frontJobInner-cols">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="dk-frontJob-times"><i class="far fa-clock"></i> May 5, 2021</div>
                                            <div className="tagStatus job-active green">Online</div>
                                        </div>
                                        <div className="glogo">
                                            <img src={DefultImg} className="img-fluid" alt="" />
                                        </div>
                                        <a href="#" className="Gtitle" onClick={showDetailsModal}>
                                            <h3>Mariana Cont</h3>
                                        </a>

                                        <div className="dk-frontJob-details">
                                            <li><i class="fas fa-briefcase"></i> Recruitment TL</li>
                                            <li><i class="fas fa-building"></i> DK Global Recruitment</li>
                                            <li><i class="fas fa-layer-group"></i> Career level</li>
                                            <li>
                                                <i class="fas fa-language"></i>
                                                <span className="dk-langImg">
                                                    <img src={BritainFlag} className="img-fluid" alt="" />
                                                    <img src={IndiaFlag} className="img-fluid" />
                                                    <img src={UsFlag} className="img-fluid" alt="" />
                                                </span>
                                            </li>
                                        </div>

                                        <div className="dk-frontJob-info">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Location</div>
                                                    <div className="sbtitle">Ireland</div>
                                                </div>
                                                <div className="dk-frontJob-info-cols">
                                                    <div className="title">Gender</div>
                                                    <div className="sbtitle">F / 40</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="dk-frontJob-pagination">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="dk-frontJob-showing">Showing 1 to 100 (of 186 entries)</div>

                            <div className="dk-frontJob-paginationNav">
                                <Route>
                                    <Link to="#" className="dk-arrow"><i class="fas fa-chevron-left"></i></Link>
                                    <Link to="#">1</Link>
                                    <Link to="#" className="active">2</Link>
                                    <Link to="#">3</Link>
                                    <Link to="#" className="dk-arrow"><i class="fas fa-chevron-right"></i></Link>
                                </Route>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default FrontJobSeekers;
