import React from 'react'
import { Accordion, Card } from 'react-bootstrap'


const SearchQualification = () => {
    return (
        <>
            <div className="dk-experinceAccordion">
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            <div className="dk-timeline-wrapper">
                                <div className="dk-timeline-time">
                                    <i className="fa fa-briefcase timeline-icon" aria-hidden="true"></i>
                                    <span>2006 - 2009</span>
                                </div>
                                <div className="dk-timeline-series cursor-pointer">
                                    <div className="dk-seriesBetween">
                                        <p className="gg mb-0"><i class="fas fa-graduation-cap mr-1"></i> Samsung Store, Customer Service <span> <i className="fa fa-angle-down"></i></span></p>
                                        <Accordion.Collapse eventKey="0">
                                            <div className="dk-dgd">
                                                <ul className="pt-2">
                                                    <li className="text-white pb-2">
                                                        <i className="fa fa-building mr-2" aria-hidden="true"></i>Collage Name
                                                    </li>
                                                    <li className="text-white">
                                                        <i className="fa fa-map-marker mr-2" aria-hidden="true"></i>Dublin, Ireland
                                                    </li>
                                                </ul>
                                                <p className="pt-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Toggle>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1">
                            <div className="dk-timeline-wrapper">
                                <div className="dk-timeline-time">
                                    <i className="fa fa-briefcase timeline-icon" aria-hidden="true"></i>
                                    <span>2006 - 2009</span>
                                </div>
                                <div className="dk-timeline-series cursor-pointer">
                                    <div className="dk-seriesBetween">
                                        <p className="gg mb-0"><i class="fas fa-graduation-cap mr-1"></i> Samsung Store, Customer Service <span> <i className="fa fa-angle-down"></i></span></p>
                                        <Accordion.Collapse eventKey="1">
                                            <div className="dk-dgd">
                                                <ul className="pt-2">
                                                    <li className="text-white pb-2">
                                                        <i className="fa fa-building mr-2" aria-hidden="true"></i>Collage Name
                                                    </li>
                                                    <li className="text-white">
                                                        <i className="fa fa-map-marker mr-2" aria-hidden="true"></i>Dublin, Ireland
                                                    </li>
                                                </ul>
                                                <p className="pt-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Toggle>
                    </Card>
                </Accordion>
            </div>
        </>
    )
}

export default SearchQualification;
