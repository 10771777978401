import React from 'react'
import { Link } from 'react-router-dom'
import './submenu.scss'

const JobsSubmenu = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="container-fluid">
                <div className="dk-ds-sub-menu col-md-12 pl-0 pr-0 ml-auto">
                    <div class="dk-ds-sub-menu-cols">
                        <ul>
                            <li>
                                <Link className={pathname === "/employer/standard/jobs/active-jobs" ? 'sub-active' : ''} to="/employer/standard/jobs/active-jobs"><span class="badge">0</span> Active Jobs</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/jobs/featured-jobs" ? 'sub-active' : ''} to="/employer/standard/jobs/featured-jobs"><span class="badge">0</span> Featured</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/jobs/expired-jobs" ? 'sub-active' : ''} to="/employer/standard/jobs/expired-jobs"><span class="badge red">0</span> Expired</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/jobs/post-new-jobs" ? 'sub-active' : ''} to="/employer/standard/jobs/post-new-jobs">Post New Job</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default JobsSubmenu;