import React from 'react'
import { Link } from 'react-router-dom'
import './standardNavbar.scss';
import Dropdown from 'react-bootstrap/Dropdown'
import { FaBell, FaCaretDown } from "react-icons/fa";
import Submenu from '../../submenu';
import { useDispatch } from 'react-redux';
import Tooltip from '../../../../../../../ui/tooltip';
import { logoutUser } from "../../../../../../../../slice/account/employer/auth/authSlice";
import Dkglobal from '../../../../../../../../assets/images/dkglobal-logo.png';

const StandardNavbar = () => {
    const dispatch = useDispatch()
    // const { userInfo } = useSelector(state => state.employerAuth)
    const logout = async () => {
        dispatch(logoutUser({}))
    }
    let pathname = window.location.pathname;
    return (
        <>
            <div className="dk-empstndrardCon">
                <div className="dk-ds-profile col-md-12">
                    <div className="dk-emp-LogoHeader d-flex align-items-enter justify-content-between">
                        <div className="dk-emplogoCon">
                            <img src={Dkglobal} alt="Dk Global Jobs Logo" />
                        </div>
                        <div className="dk-logoHeaderTitle">
                            <h3 class="title">EMPLOYERS STANDARD ACCOUNT</h3>
                        </div>
                        <div className="dk-navBarRightPanel">
                            <div className="navBarbellCon">
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <FaBell />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item to="##/action-1">Action</Dropdown.Item>
                                        <Dropdown.Item to="##/action-2">Another action</Dropdown.Item>
                                        <Dropdown.Item to="##/action-3">Something else</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="singoutbtnCon">
                                <Link class="sigoutbtn" to="#" onClick={logout}>Sign Out</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dk-ds-menu col-md-12">
                    <div class="dk-ds-menu-cols">
                        <ul class="standard_nav">
                            <li>
                                <Link className={`${pathname.match('/dashboard') ? 'active' : ''}`} to="/employer/standard/dashboard">Dashboard</Link>
                            </li>
                            {/* <li>
                                <Link class="" to="#/employer/standard/users">Users</Link>
                            </li> */}
                            <li>
                                <Link className={`${pathname.match('/jobs') ? 'active' : ''}`} to="/employer/standard/jobs/active-jobs">Jobs <FaCaretDown className="ml-2" /></Link>
                            </li>
                            <li>
                                <Link className={`${pathname.match('/searchcv') ? 'active' : ''}`} to="/employer/standard/searchcv/summary">Search CV <FaCaretDown className="ml-2" /></Link>
                            </li>
                            <li>
                                <Link className={`${pathname.match('/packages') ? 'active' : ''}`} to="/employer/standard/packages/subscription">Packages <FaCaretDown className="ml-2" /></Link>
                            </li>
                            <li>
                                <Link className={`${pathname.match('/billing') ? 'active' : ''}`} to="/employer/standard/billing/contact-person">Billing <FaCaretDown className="ml-2" /></Link>
                            </li>
                            <li>
                                <Link className={`${pathname.match('/support') ? 'active' : ''}`} to="/employer/standard/support/genral">Support <FaCaretDown className="ml-2" /></Link>
                            </li>
                            <li>
                                <Link className={`${pathname.match('/profile') ? 'active' : ''}`} to="/employer/standard/profile/owner">Profiles <FaCaretDown className="ml-2" /></Link>
                            </li>
                            <li>
                                <Link className={`${pathname.match('/dkg-serivces') ? 'active' : ''}`} to="/employer/standard/dkg-serivces/abouts">DKG Services<FaCaretDown className="ml-2" /></Link>
                            </li>
                            <li>
                                <Link className={`${pathname.match('/settings') ? 'active' : ''}`} to="/employer/standard/settings/faq">Settings <FaCaretDown className="ml-2" /></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Submenu path={pathname} />
        </>
    )
}
export default StandardNavbar;