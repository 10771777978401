import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import RangeSliderPack from "./RangeSliderPack";

const UpgradeTabs = () => {
    return (
        <>
            <div className="dk-packagesPlanMain">
                <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                    <Nav variant="pills">
                        <Nav.Item>
                            <Nav.Link eventKey="tabs_1"><i class="fas fa-briefcase"></i> Standard Subscription</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="tabs_2"><i class="fas fa-folder-plus"></i> Premium Subscription</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="tabs_1">
                            <div className="dk-planLeft-body">
                                <div className="dk-planLeft-row">
                                    <div className="title">Ads Post Credits </div>
                                    <div className="dk-packRangeSlide">
                                        <p>Total 100</p>
                                        <RangeSliderPack />
                                    </div>
                                </div>
                                <div className="dk-planLeft-row">
                                    <div className="title">Employers Posts</div>
                                    <div className="dk-packRangeSlide">
                                        <p>Total 100</p>
                                        <RangeSliderPack />
                                    </div>
                                </div>
                                <div className="dk-planLeft-row">
                                    <div className="title">CV Search Credits</div>
                                    <div className="dk-packRangeSlide">
                                        <p>Total 100</p>
                                        <RangeSliderPack />
                                    </div>
                                </div>
                                <div className="dk-planLeft-row">
                                    <div className="title">Social Media Campaign</div>
                                    <div className="dk-packRangeSlide">
                                        <p>Total 100</p>
                                        <RangeSliderPack />
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tabs_2">
                            <div className="dk-planLeft-body">
                                <div className="dk-planLeft-row">
                                    <div className="title">Ads Post Credits </div>
                                    <div className="dk-packRangeSlide">
                                        <p>Total 100</p>
                                        <RangeSliderPack />
                                    </div>
                                </div>
                                <div className="dk-planLeft-row">
                                    <div className="title">Employers Posts</div>
                                    <div className="dk-packRangeSlide">
                                        <p>Total 100</p>
                                        <RangeSliderPack />
                                    </div>
                                </div>
                                <div className="dk-planLeft-row">
                                    <div className="title">CV Search Credits</div>
                                    <div className="dk-packRangeSlide">
                                        <p>Total 100</p>
                                        <RangeSliderPack />
                                    </div>
                                </div>
                                <div className="dk-planLeft-row">
                                    <div className="title">Social Media Campaign</div>
                                    <div className="dk-packRangeSlide">
                                        <p>Total 100</p>
                                        <RangeSliderPack />
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </>
    )
}

export default UpgradeTabs;
