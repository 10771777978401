import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom'
import './employer.scss';
import { Nav } from 'react-bootstrap'
import SubmitButton from "../../../ui/submitButton";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import { loginUser, clearState } from "../../../../slice/account/jobseeker/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Constants from '../../../constants';

const JobseekerLogin = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { loginError, isLoading, isSuccess, isError, message } = useSelector(state => state.jobseekerAuth);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const accountType = 'standard';
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isAgree, setIsAgree] = useState(false);

    useEffect(() => {
        document.title = "Login Jobseeker Standard Account | DK Global Jobs"
        dispatch(clearMessage());
        dispatch(clearState());
    }, [dispatch])

    const validateForm = () => {
        let formIsValid = true;
        let pattern = '';

        if (!email || !password) {
            formIsValid = false;
            dispatch(showError({ msg: "Please fill all required details" }))
        }

        if (typeof email !== "undefined") {
            //regular expression for email validation
            pattern = new RegExp(Constants.SYSTEM_CONSTANTS.Validation.Email);
            if (!pattern.test(email)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Please enter valid email." }));
            }
        }
        return formIsValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validateForm()) {
            try {
                dispatch(clearMessage())
                await dispatch(loginUser({ accountType, email, password }))
                if (isLoading) {
                    setSuccess(false);
                    setLoading(true);
                }
            } catch (err) {
                setSuccess(false);
                setLoading(false);
                dispatch(showError({ msg: 'Something went wrong! Try again.' }))
            }
        }
    }

    useEffect(() => {
        if (isError) {
            setSuccess(false);
            setLoading(false);
            dispatch(clearState());
            if (Object.entries(loginError).length === 0) {
                dispatch(showError({ msg: message }))
            } else {
                if (loginError.emailVerify === 0) {
                    setTimeout(() => history.push('/email-not-verify?email=' + btoa(loginError.email) + '&account=' + btoa(loginError.account) + '&uid=' + btoa(loginError.uid)), 500);
                }
                if (loginError.gdpr === 0) {
                    setTimeout(() => history.push('/submission-gdpr/' + loginError.account), 500);
                }
                if (loginError.isDisable === 1 || loginError.isDeleted === 1 || loginError.status === 0) {
                    dispatch(showError({ msg: 'Your account is not active. Please contact with adminstration.' }))
                }
            }
        }
        if (isSuccess) {
            dispatch(clearState());
            setSuccess(true);
            setLoading(false);
            dispatch(showSuccess({ msg: "Login successfully." }))
            setTimeout(() => history.push('/jobseeker/standard/dashboard'), 500);
        }
    }, [isError, isSuccess, dispatch, message, history, loginError])

    return (
        <>
            <div className="dk-loginEmpMainBg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 m-auto col-sm-12">
                            <div className="dk-job-empFromCon">
                                <div className="dk-job-empFromTitle">
                                    <h2>LOGIN TO YOUR ACCOUNT</h2>
                                </div>
                                <div className="dk-job-empFromBox">
                                    <div className="dklogin-tabCon">
                                        <div className="row">
                                            <div className="col-12">
                                                <Nav variant="pills dk-loginTabs" className="flex-row">
                                                    <li>
                                                        <Link to="/login/jobseeker-login" className="emp-standard active">Jobseeker Standard</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/login/jobseeker-premium" className="emp-premium">Jobseeker Premium</Link>
                                                    </li>
                                                </Nav>
                                            </div>
                                            <div className="dk-loginTabpaneCon">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <input type="text" className="form-control" name="Email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                            </div>
                                                            <div className="form-group">
                                                                <input type="password" className="form-control" name="Password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                                            </div>
                                                            <div className="dklogin-checkBoxCon">
                                                                <input type="checkbox" class="form-check-input" id="exampleCheck1" defaultChecked={isAgree} onChange={(e) => setIsAgree(!isAgree)} />
                                                                <label class="form-check-label" for="exampleCheck1">Keep me signed in on this device.</label>
                                                            </div>
                                                            <div className="agree-termsCon">
                                                                <p>By signing in to your account, you agree to our
                                                                    <Link to="#">Terms of Services</Link> and
                                                                    <Link to="#">Privacy Policy.</Link>
                                                                </p>
                                                            </div>
                                                            <div className="loginsubmit-buttonCon">
                                                                <SubmitButton txt="Login" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="btn loginsubmit-btn" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="login-bottomSec">
                                    <li>
                                        <Link to="/sign-up/jobseeker-signup">Not a member? Create an account</Link>
                                    </li>
                                    <li>
                                        <Link to="/forget-password/jobseeker">Forgot Your Password?</Link>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JobseekerLogin;
