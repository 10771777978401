import React, { useState } from 'react';

const SavedResume = () => {
    return (
        <>

        </>
    )
}

export default SavedResume;
