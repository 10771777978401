import React from 'react';
import './fullprofile.scss'
import { Tab, Nav } from 'react-bootstrap';
import Summary from './fullprofilecomp/Summary'
import Experince from './fullprofilecomp/Experince'
import Achivements from './fullprofilecomp/Achivments'
import Doctments from './fullprofilecomp/Documents'

const FullProfile = () => {
    return (
        <>
            <div className="container-fluid dk-fullProfilePageCon">
                <div className="row dk-fullProfileCon">
                    <div className="col-md-11 pl-0 ml-auto">
                        <div className="row ml-0 mr-0">
                            <div className="col-md-12 m-auto">
                                <div className="dk-profileBg">
                                    <div className="dk-topIntroSec">
                                        <div className="dk-LeftIntroSec">
                                            <div className="dk-usrImgCon">
                                                <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20%2810%29.jpg" alt=""></img>
                                            </div>
                                            <div className="dk-usrNameDes">
                                                <h4 className="text-white">Martha Smith</h4>
                                                <div className="desText">
                                                    <ul>
                                                        <li className="dk-icon">
                                                            <i className="fa fa-briefcase mr-3"></i>Senior Manager, Logistic
                                                        </li>
                                                        <li className="dk-icon">
                                                            <i className="fa fa-map-marker mr-3" aria-hidden="true"></i>Dublin, Ireland
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dk-RighttIntroSec">
                                            <div className="dk-hertcheckboxCon">
                                                <div className="hertIcon">
                                                    <i className="far fa-heart"></i>
                                                </div>
                                                <div className="checkboxIcon">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="contactus-Text">
                                                <ul>
                                                    <li className="dk-icon">
                                                        <i className="fa fa-envelope mr-3"></i>Senior Manager, Logistic
                                                    </li>
                                                    <li className="dk-icon">
                                                        <i className="fa fa-phone mr-3"></i>Dublin, Ireland
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dk-fullProfhrTabsCon">
                                        <Tab.Container id="left-tabs-example" className="dk-fullProfhrTabs" defaultActiveKey="summary">
                                            <div className="row">
                                                <div className="col-12">
                                                    <Nav variant="pills" className="flex-row dk-fullProfhrTabspills">
                                                        <Nav.Item className="dk-fullProfileNavItem">
                                                            <Nav.Link eventKey="summary">Summary</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className="dk-fullProfileNavItem">
                                                            <Nav.Link eventKey="profile-skills">Profile Skills</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className="dk-fullProfileNavItem">
                                                            <Nav.Link eventKey="experince">Experince</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className="dk-fullProfileNavItem">
                                                            <Nav.Link eventKey="education">Education</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className="dk-fullProfileNavItem">
                                                            <Nav.Link eventKey="achivements">Achivements</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className="dk-fullProfileNavItem">
                                                            <Nav.Link eventKey="activities">Interest & Activities</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className="dk-fullProfileNavItem">
                                                            <Nav.Link eventKey="documents">Documents</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </div>
                                                <div className="col-12">
                                                    <Tab.Content className="dk-fullProfileTabContent">
                                                        <Tab.Pane className="dk-fullProfileTabPane" eventKey="summary">
                                                            <Summary />
                                                        </Tab.Pane>
                                                        <Tab.Pane className="dk-fullProfileTabPane" eventKey="profile-skills">
                                                            Profile Skills
                                                        </Tab.Pane>
                                                        <Tab.Pane className="dk-fullProfileTabPane p-0" eventKey="experince">
                                                            <Experince />
                                                        </Tab.Pane>
                                                        <Tab.Pane className="dk-fullProfileTabPane" eventKey="education">
                                                            <Experince />
                                                        </Tab.Pane>
                                                        <Tab.Pane className="dk-fullProfileTabPane" eventKey="achivements">
                                                            <Achivements />
                                                        </Tab.Pane>
                                                        <Tab.Pane className="dk-fullProfileTabPane" eventKey="activities">
                                                            Interset & Activities
                                                        </Tab.Pane>
                                                        <Tab.Pane className="dk-fullProfileTabPane" eventKey="documents">
                                                            <Doctments />
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </div>
                                            </div>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FullProfile;