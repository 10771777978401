import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Route, Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

export default function SearchFilter() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading}>Posted on</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div className="dk-searchPanelNew">
                            <div className="col pt-3 pb-3">
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="posted" />
                                    <label htmlFor="posted" className="custom-control-label">Posted on Me <span>241</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="today" />
                                    <label htmlFor="today" className="custom-control-label">Today <span>211</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="last24" />
                                    <label htmlFor="last24" className="custom-control-label">Last 24 Hrs <span>111</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="last30" />
                                    <label htmlFor="last30" className="custom-control-label">Last 30 Days <span>24</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="thisyear" />
                                    <label htmlFor="thisyear" className="custom-control-label">This Year <span>50</span></label>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography className={classes.heading}>Industry</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div className="dk-searchPanelNew">
                            <div className="col pt-3 pb-3">
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="posted2" />
                                    <label htmlFor="posted2" className="custom-control-label">Posted on Me <span>241</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="today2" />
                                    <label htmlFor="today2" className="custom-control-label">Today <span>211</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="last242" />
                                    <label htmlFor="last242" className="custom-control-label">Last 24 Hrs <span>111</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="last302" />
                                    <label htmlFor="last302" className="custom-control-label">Last 30 Days <span>24</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="thisyear2" />
                                    <label htmlFor="thisyear2" className="custom-control-label">This Year <span>50</span></label>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <Typography className={classes.heading}>Country</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div className="dk-searchPanelNew">
                            <div className="col pt-3 pb-3">
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="posted3" />
                                    <label htmlFor="posted3" className="custom-control-label">Posted on Me <span>241</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="today3" />
                                    <label htmlFor="today3" className="custom-control-label">Today <span>211</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="last243" />
                                    <label htmlFor="last243" className="custom-control-label">Last 24 Hrs <span>111</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="last303" />
                                    <label htmlFor="last303" className="custom-control-label">Last 30 Days <span>24</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="thisyear3" />
                                    <label htmlFor="thisyear3" className="custom-control-label">This Year <span>50</span></label>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography className={classes.heading}>City</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div className="dk-searchPanelNew">
                            <div className="col pt-3 pb-3">
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="posted4" />
                                    <label htmlFor="posted4" className="custom-control-label">Posted on Me <span>241</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="today4" />
                                    <label htmlFor="today4" className="custom-control-label">Today <span>211</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="last244" />
                                    <label htmlFor="last244" className="custom-control-label">Last 24 Hrs <span>111</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="last304" />
                                    <label htmlFor="last304" className="custom-control-label">Last 30 Days <span>24</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="thisyear4" />
                                    <label htmlFor="thisyear4" className="custom-control-label">This Year <span>50</span></label>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                >
                    <Typography className={classes.heading}>Language</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div className="dk-searchPanelNew">
                            <div className="col pt-3 pb-3">
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="posted5" />
                                    <label htmlFor="posted5" className="custom-control-label">Posted on Me <span>241</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="today5" />
                                    <label htmlFor="today5" className="custom-control-label">Today <span>211</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="last245" />
                                    <label htmlFor="last245" className="custom-control-label">Last 24 Hrs <span>111</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="last305" />
                                    <label htmlFor="last305" className="custom-control-label">Last 30 Days <span>24</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="thisyear5" />
                                    <label htmlFor="thisyear5" className="custom-control-label">This Year <span>50</span></label>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6bh-content"
                    id="panel6bh-header"
                >
                    <Typography className={classes.heading}>Job Title</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div className="dk-searchPanelNew">
                            <div className="col pt-3 pb-3">
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="posted6" />
                                    <label htmlFor="posted6" className="custom-control-label">Posted on Me <span>241</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="today6" />
                                    <label htmlFor="today6" className="custom-control-label">Today <span>211</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="last246" />
                                    <label htmlFor="last246" className="custom-control-label">Last 24 Hrs <span>111</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="last306" />
                                    <label htmlFor="last306" className="custom-control-label">Last 30 Days <span>24</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="thisyear6" />
                                    <label htmlFor="thisyear6" className="custom-control-label">This Year <span>50</span></label>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel7bh-content"
                    id="panel7bh-header"
                >
                    <Typography className={classes.heading}>Salary</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div className="dk-searchPanelNew">
                            <div className="col pt-3 pb-3">
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="posted7" />
                                    <label htmlFor="posted7" className="custom-control-label">Posted on Me <span>241</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="today7" />
                                    <label htmlFor="today7" className="custom-control-label">Today <span>211</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="last247" />
                                    <label htmlFor="last247" className="custom-control-label">Last 24 Hrs <span>111</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="last307" />
                                    <label htmlFor="last307" className="custom-control-label">Last 30 Days <span>24</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="thisyear7" />
                                    <label htmlFor="thisyear7" className="custom-control-label">This Year <span>50</span></label>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel8bh-content"
                    id="panel8bh-header"
                >
                    <Typography className={classes.heading}>Work Permit</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div className="dk-searchPanelNew">
                            <div className="col pt-3 pb-3">
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="posted8" />
                                    <label htmlFor="posted8" className="custom-control-label">Posted on Me <span>241</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="today8" />
                                    <label htmlFor="today8" className="custom-control-label">Today <span>211</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="last248" />
                                    <label htmlFor="last248" className="custom-control-label">Last 24 Hrs <span>111</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="last308" />
                                    <label htmlFor="last308" className="custom-control-label">Last 30 Days <span>24</span></label>
                                </div>
                                <div className="form-group custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="" id="thisyear8" />
                                    <label htmlFor="thisyear8" className="custom-control-label">This Year <span>50</span></label>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}