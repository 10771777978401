import React from 'react'
import Modal from 'react-bootstrap/Modal'
import './titleinfo.scss';
import { useSelector } from "react-redux";

const ViewDocument = ({ hidepopup }) => {
    const { dataDetails } = useSelector(state => state.contractsDocuments);
    return (
        <>
            <Modal
                size="lg"
                show={true}
                onHide={hidepopup}
                aria-labelledby="contained-modal-title-vcenter"
                className="dk-doctitleModal"
                centered
            >
                <Modal.Header closeButton className="dk-doctitleModalHeader">
                    <Modal.Title id="contained-modal-title-vcenter">
                        View
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-doctitleModalBody">
                    <p>{dataDetails.description}</p>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ViewDocument;
