import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const updateBasic = createAsyncThunk(
    'account/jobseeker/profile/update_basic_profile',
    async ({ uid, firstName, lastName, address, city, state, country, zipCode, mobile, profileImage }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkjobs-authentication"));
            const response = await fetch(process.env.REACT_APP_API_URL + '/account/jobseeker/profile/update/' + uid,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({
                        firstName,
                        lastName,
                        address,
                        city,
                        state,
                        country,
                        zipCode,
                        mobile,
                        profileImage
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const profileSlice = createSlice({
    name: "profile",
    initialState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: ""
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        }
    },
    extraReducers: {
        [updateBasic.pending]: (state) => {
            state.isLoading = true;
        },
        [updateBasic.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
        },
        [updateBasic.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.message = payload.message;
            }
            state.isLoading = false;
        },
    }
});
export const { clearState } = profileSlice.actions;
export default profileSlice.reducer;