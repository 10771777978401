import React, { useEffect } from 'react';
import './thankyouSignup.scss';
const ThankyouSignup = () => {
    useEffect(() => {
        document.title = "Thankyou For Signup | DK Global Jobs"
    }, [])

    return (
        <React.Fragment>
            <div className="dk-signupthankyou container-fluid">
                <div className="row">
                    <div className="col-md-6 col-sm-12 m-auto">
                        <div className="dk-topsingupCont pb-2">
                            <h2 className="title">Thank you For Signup</h2>
                        </div>
                        <div className="dk-FormContiner">
                            <div className="dk-formContent">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ThankyouSignup;