import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import './paymentmthod.scss';
import VisImg from '../../../../../../../assets/images/visa.png';
import ModalTitleInfo from './modal/TitleInfo';
import RemoveModal from './modal/RemoveModal';
import EditModal from './modal/EditModal';
import AddCardModal from './modal/AddCardModal';

const PaymentMethod = () => {
    const [isTitleInfo, setIsTitleInfo] = useState(false);
    const [isRemoveModal, setIsRemoveModal] = useState(false);
    const [isEditModal, setTsEditModal] = useState(false)
    const [isAddCardModal, setAddCardModal] = useState(false)
    const showtitleinfoModal = () => {
        setIsTitleInfo(true);
    }
    const hidetitleinfoModal = () => {
        setIsTitleInfo(false);
    }
    const showremoveModal = () => {
        setIsRemoveModal(true);
    }
    const hideremoveModal = () => {
        setIsRemoveModal(false);
    }
    const showeditModal = () => {
        setTsEditModal(true);
    }
    const hideeditModal = () => {
        setTsEditModal(false);
    }

    const showAddCardModal = () => {
        setAddCardModal(true);
    }
    const hideAddCardModal = () => {
        setAddCardModal(false);
    }
    return (
        <>
            {
                (isTitleInfo) ? <ModalTitleInfo hidepoup={hidetitleinfoModal} /> : null
            }

            {
                (isRemoveModal) ? <RemoveModal hidepoup={hideremoveModal} /> : null
            }
            {
                (isEditModal) ? <EditModal hidepoup={hideeditModal} /> : null
            }

            {
                (isAddCardModal) ? <AddCardModal hidepoup={hideAddCardModal} /> : null
            }
            <div className="dk-paymentMethodMain">
                <div className="dk-paymentHead">
                    <div className="d-flex align-items-center justify-content-center mb-2">
                        <h3>
                            Payment Method<span onClick={showtitleinfoModal}><i className="fas fa-info-circle"></i></span>
                        </h3>
                    </div>
                </div>
                <div className="dk-paymentMethod-cols">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="dk-paymentBox">
                                <div className="d-flex align-items-center justify-content-between cardImg-dropdown">
                                    <div className="dk-CardImg">
                                        <img src={VisImg} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dk-cardDropdown">
                                        <div data-test="dropdown" className="dropdown">
                                        </div>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                <i className="fas fa-ellipsis-h"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1" onClick={showeditModal}>Edit</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2" onClick={showremoveModal}>Remove</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between card-rating">
                                    <div className="dk-payment-star">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </div>
                                    <div className="dk-payment-star">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </div>
                                    <div className="dk-payment-star">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </div>
                                    <div className="dk-payment-star">2507</div>
                                </div>
                                <div className="dk-paymentFoot">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="dk-cardName">
                                            <span>Priority</span>Primary
                                        </div>
                                        <div className="dk-cardExpire">
                                            <span>Expires</span>05/22
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dk-paymentBox">
                                <div className="d-flex align-items-center justify-content-between cardImg-dropdown">
                                    <div className="dk-CardImg">
                                        <img src={VisImg} className="img-fluid" alt="" />
                                    </div>
                                    <div className="dk-cardDropdown">
                                        <div data-test="dropdown" className="dropdown">
                                        </div>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                <i className="fas fa-ellipsis-h"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1" onClick={showeditModal}>Edit</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2" onClick={showremoveModal}>Remove</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between card-rating">
                                    <div className="dk-payment-star">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </div>
                                    <div className="dk-payment-star">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </div>
                                    <div className="dk-payment-star">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </div>
                                    <div className="dk-payment-star">2507</div>
                                </div>
                                <div className="dk-paymentFoot">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="dk-cardName"><span>Priority</span>Primary</div>
                                        <div className="dk-cardExpire"><span>Expires</span>05/22</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="dk-paymentBox d-flex align-items-center justify-content-center">
                                <Link className="dk-addCard" to="#" onClick={showAddCardModal}>
                                    <span><i className="fas fa-plus"></i></span>Add New Card
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentMethod
