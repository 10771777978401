import React from 'react';
import { Link } from 'react-router-dom'
import { Tab, Nav } from 'react-bootstrap'

const LivejobsSec = () => {
    return (
        <>
            <Tab.Container id="dk-livejobTabContainer" defaultActiveKey="level">
                <div className="col-md-12 pl-0 pr-0">
                    <Nav variant="pills dk-livejobTabs" className="flex-row">
                        <Nav.Item className="dk-livejobTabItem jobtype">
                            <Nav.Link eventKey="jobtype">
                                <span className="tabText">Job Type</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="dk-livejobTabItem industry">
                            <Nav.Link eventKey="industry">
                                <span className="tabText">Industry</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="dk-livejobTabItem level">
                            <Nav.Link eventKey="level">
                                <span className="tabText">Level</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="dk-livejobTabItem jobtitle">
                            <Nav.Link eventKey="jobtitle">
                                <span className="tabText">Job Title</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="dk-livejobTabItem country">
                            <Nav.Link eventKey="country">
                                <span className="tabText">Country</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="dk-livejobTabItem city">
                            <Nav.Link eventKey="city">
                                <span className="tabText">City</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="dk-livejobTabItem language">
                            <Nav.Link eventKey="language">
                                <span className="tabText">Language</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="dk-livejobTabItem salary">
                            <Nav.Link eventKey="salary">
                                <span className="tabText">Salary</span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="dk-livejobTabItem workpermit">
                            <Nav.Link eventKey="workpermit">
                                <span className="tabText">Work Permit</span>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <div className="col-md-12">
                    <Tab.Content className="livejobsTabContent">
                        <Tab.Pane eventKey="jobtype">
                            <div className="dkTabePaneCon">
                                <div className="container dk-jobtypeMCont">
                                    <div className="dk-jobtypeCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">Full Time
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-jobtypeCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">Part Time
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-jobtypeCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">Freelancer
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-jobtypeCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">Remote
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-jobtypeCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">Internship
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-viewbtnCon">
                                        <button className="btn dk-viewbtn">View All Jobs</button>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="industry">
                            <div className="dkTabePaneCon">
                                <div className="container dk-industryMCont">
                                    <div className="dk-industryCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">It - Software
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Engineering/ Construction
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Internet / E-commerce
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Telecom / ISP
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Agriculture / Dairy
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Astrology
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Chemical/ Plastic/ Rubber/ Glass
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Export-Import/ Trading
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-industryCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">It - Software
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Engineering/ Construction
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Internet / E-commerce
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Telecom / ISP
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Agriculture / Dairy
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Astrology
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Chemical/ Plastic/ Rubber/ Glass
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Export-Import/ Trading
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-industryCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">It - Software
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Engineering/ Construction
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Internet / E-commerce
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Telecom / ISP
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Agriculture / Dairy
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Astrology
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Chemical/ Plastic/ Rubber/ Glass
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Export-Import/ Trading
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-industryCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">It - Software
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Engineering/ Construction
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Internet / E-commerce
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Telecom / ISP
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Agriculture / Dairy
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Astrology
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Chemical/ Plastic/ Rubber/ Glass
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Export-Import/ Trading
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-viewbtnCon">
                                        <button className="btn dk-viewbtn">View All Jobs</button>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="level">
                            <div className="dkTabePaneCon">
                                <div className="container dk-joblevelMCont">
                                    <div className="dk-joblevelCon">
                                        <ul className="dk-joblevelItem">
                                            <li>
                                                <Link to="#">Afghanistan
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Åland Islands
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Albania
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Algeria
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">American
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Hong Kong
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">India
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-joblevelCon">
                                        <ul className="dk-joblevelItem">
                                            <li>
                                                <Link to="#">Afghanistan
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Åland Islands
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Albania
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Algeria
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">American
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Hong Kong
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">India
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-joblevelCon">
                                        <ul className="dk-joblevelItem">
                                            <li>
                                                <Link to="#">Afghanistan
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Åland Islands
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Albania
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Algeria
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">American
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Hong Kong
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">India
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-joblevelCon">
                                        <ul className="dk-joblevelItem">
                                            <li>
                                                <Link to="#">Afghanistan
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Åland Islands
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Albania
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Algeria
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">American
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Hong Kong
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">India
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-joblevelCon">
                                        <ul className="dk-joblevelItem">
                                            <li>
                                                <Link to="#">Afghanistan
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Åland Islands
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Albania
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Algeria
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">American
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Hong Kong
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">India
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-joblevelCon">
                                        <ul className="dk-joblevelItem">
                                            <li>
                                                <Link to="#">Afghanistan
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Åland Islands
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Albania
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Algeria
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">American
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Hong Kong
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">India
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-viewbtnCon">
                                        <button className="btn dk-viewbtn">View All Jobs</button>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="jobtitle">
                            <div className="dkTabePaneCon">
                                <div className="container dk-joblevelMCont">
                                    <div className="dk-joblevelCon">
                                        <ul className="dk-joblevelItem">
                                            <li>
                                                <Link to="#">Branch Manager
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Recruitment Team Leader
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">QA, Researcher & Trainer
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Senior Recruitment
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Recruitment Consultant
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Software Developer
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Senior Administrator
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-joblevelCon">
                                        <ul className="dk-joblevelItem">
                                            <li>
                                                <Link to="#">Branch Manager
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Recruitment Team Leader
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">QA, Researcher & Trainer
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Senior Recruitment
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Recruitment Consultant
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Software Developer
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Senior Administrator
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-joblevelCon">
                                        <ul className="dk-joblevelItem">
                                            <li>
                                                <Link to="#">Branch Manager
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Recruitment Team Leader
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">QA, Researcher & Trainer
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Senior Recruitment
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Recruitment Consultant
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Software Developer
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Senior Administrator
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-joblevelCon">
                                        <ul className="dk-joblevelItem">
                                            <li>
                                                <Link to="#">Branch Manager
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Recruitment Team Leader
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">QA, Researcher & Trainer
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Senior Recruitment
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Recruitment Consultant
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Software Developer
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Senior Administrator
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-joblevelCon">
                                        <ul className="dk-joblevelItem">
                                            <li>
                                                <Link to="#">Branch Manager
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Recruitment Team Leader
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">QA, Researcher & Trainer
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Senior Recruitment
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Recruitment Consultant
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Software Developer
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Senior Administrator
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-joblevelCon">
                                        <ul className="dk-joblevelItem">
                                            <li>
                                                <Link to="#">Branch Manager
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Recruitment Team Leader
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">QA, Researcher & Trainer
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Senior Recruitment
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Recruitment Consultant
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Software Developer
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Senior Administrator
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="country">
                            <div className="dkTabePaneCon">
                                <div className="container dk-industryMCont">
                                    <div className="dk-industryCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">Ireland
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">India
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">America
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">England
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Newland
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Germany
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Itly
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Romanian
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-industryCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">Ireland
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">India
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">America
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">England
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Newland
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Germany
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Itly
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Romanian
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-industryCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">Ireland
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">India
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">America
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">England
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Newland
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Germany
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Itly
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Romanian
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-industryCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">Ireland
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">India
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">America
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">England
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Newland
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Germany
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Itly
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Romanian
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-viewbtnCon">
                                        <button className="btn dk-viewbtn">View All Jobs</button>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="city">
                            <div className="dkTabePaneCon">
                                <div className="container dk-industryMCont">
                                    <div className="dk-industryCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">Dublin
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Noida
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">New Delhi
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Bucharest
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Baia Mare
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Stara Zagora
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Galati
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Chisinau
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-industryCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">Dublin
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Noida
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">New Delhi
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Bucharest
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Baia Mare
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Stara Zagora
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Galati
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Chisinau
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-industryCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">Dublin
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Noida
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">New Delhi
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Bucharest
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Baia Mare
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Stara Zagora
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Galati
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Chisinau
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-industryCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">Dublin
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Noida
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">New Delhi
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Bucharest
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Baia Mare
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Stara Zagora
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Galati
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Chisinau
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-viewbtnCon">
                                        <button className="btn dk-viewbtn">View All Jobs</button>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="language">
                            <div className="dkTabePaneCon">
                                <div className="container dk-industryMCont">
                                    <div className="dk-industryCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">German
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">French
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Spainsh
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Portuguese
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Dutch
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Bulgarian
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Danish
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Polish
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-industryCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">German
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">French
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Spainsh
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Portuguese
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Dutch
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Bulgarian
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Danish
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Polish
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-industryCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">German
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">French
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Spainsh
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Portuguese
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Dutch
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Bulgarian
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Danish
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Polish
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-industryCon">
                                        <ul className="dk-jobtypeItem">
                                            <li>
                                                <Link to="#">German
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">French
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Spainsh
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Portuguese
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Dutch
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Bulgarian
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Danish
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">Polish
                                                    <span>241</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="dk-viewbtnCon">
                                        <button className="btn dk-viewbtn">View All Jobs</button>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="salary">
                            <div className="dkTabePaneCon">
                                <div className="container dk-SalaryMCont dk-industryMCont">
                                    <div className="dk-salrySwapTabsCon">
                                        <Tab.Container id="dk-salrySwapTabCon" defaultActiveKey="first">
                                            <div className="row">
                                                <div className="col-md-5 col-sm-12 m-auto">
                                                    <Nav variant="pills dk-salryTabsCon" className="flex-row">
                                                        <Nav.Item className="dk-salrySwapTab">
                                                            <Nav.Link eventKey="first">Jobs by Country</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className="dk-salrySwapTab">
                                                            <Nav.Link eventKey="second">Jobs by Title</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="first">
                                                            <div className="dkTabePaneCon">
                                                                <div className="container dk-joblevelMCont">
                                                                    <div className="dk-joblevelCon">
                                                                        <ul className="dk-joblevelItem">
                                                                            <li>
                                                                                <Link to="#">Afghanistan
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Åland Islands
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Albania
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Algeria
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">American
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Hong Kong
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">India
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="dk-joblevelCon">
                                                                        <ul className="dk-joblevelItem">
                                                                            <li>
                                                                                <Link to="#">Afghanistan
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Åland Islands
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Albania
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Algeria
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">American
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Hong Kong
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">India
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="dk-joblevelCon">
                                                                        <ul className="dk-joblevelItem">
                                                                            <li>
                                                                                <Link to="#">Afghanistan
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Åland Islands
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Albania
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Algeria
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">American
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Hong Kong
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">India
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="dk-joblevelCon">
                                                                        <ul className="dk-joblevelItem">
                                                                            <li>
                                                                                <Link to="#">Afghanistan
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Åland Islands
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Albania
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Algeria
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">American
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Hong Kong
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">India
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="dk-joblevelCon">
                                                                        <ul className="dk-joblevelItem">
                                                                            <li>
                                                                                <Link to="#">Afghanistan
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Åland Islands
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Albania
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Algeria
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">American
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Hong Kong
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">India
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="dk-joblevelCon">
                                                                        <ul className="dk-joblevelItem">
                                                                            <li>
                                                                                <Link to="#">Afghanistan
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Åland Islands
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Albania
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Algeria
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">American
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Hong Kong
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">India
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="dk-viewbtnCon">
                                                                        <button className="btn dk-viewbtn">View All Jobs</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="second">
                                                            <div className="dkTabePaneCon">
                                                                <div className="container dk-joblevelMCont">
                                                                    <div className="dk-joblevelCon">
                                                                        <ul className="dk-joblevelItem">
                                                                            <li>
                                                                                <Link to="#">Afghanistan
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Åland Islands
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Albania
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Algeria
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">American
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Hong Kong
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">India
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="dk-joblevelCon">
                                                                        <ul className="dk-joblevelItem">
                                                                            <li>
                                                                                <Link to="#">Afghanistan
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Åland Islands
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Albania
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Algeria
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">American
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Hong Kong
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">India
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="dk-joblevelCon">
                                                                        <ul className="dk-joblevelItem">
                                                                            <li>
                                                                                <Link to="#">Afghanistan
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Åland Islands
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Albania
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Algeria
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">American
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Hong Kong
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">India
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="dk-joblevelCon">
                                                                        <ul className="dk-joblevelItem">
                                                                            <li>
                                                                                <Link to="#">Afghanistan
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Åland Islands
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Albania
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Algeria
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">American
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Hong Kong
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">India
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="dk-joblevelCon">
                                                                        <ul className="dk-joblevelItem">
                                                                            <li>
                                                                                <Link to="#">Afghanistan
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Åland Islands
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Albania
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Algeria
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">American
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Hong Kong
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">India
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="dk-joblevelCon">
                                                                        <ul className="dk-joblevelItem">
                                                                            <li>
                                                                                <Link to="#">Afghanistan
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Åland Islands
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Albania
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Algeria
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">American
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Hong Kong
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">India
                                                                                    <span>241</span>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="dk-viewbtnCon">
                                                                        <button className="btn dk-viewbtn">View All Jobs</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </div>
                                            </div>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="workpermit">
                            <div className="dkTabePaneCon">
                                <div className="container dk-industryMCont">
                                    <div className="dk-viewbtnCon">
                                        <button className="btn dk-viewbtn">View All Jobs</button>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        </>
    )
}

export default LivejobsSec;
