import React from 'react'
import { Link } from 'react-router-dom'
import './gdpr.scss'

const GdprExplained = () => {
    return (
        <>
            <div className="container-fluid dk-gdprexpMainPage">
                <h1>GDPR <span>Explained</span></h1>
                <div className="dk-cookiesCols">
                    <h3>Lorem Ipsum is simply dummy text of the printing</h3>
                    <p>Lorem Ipsum is simply dummy text
                        of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                        e the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        It has survived not only five centuries, but also the leap into electronic type set ting, remaining
                        essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                        Lorem Ipsum passages, and more recently with desktop versions of Lorem Ipsum.
                    </p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                        into electronic type set ting, remaining essentially unchanged. It was popularised in the 1960s with the
                        release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop versions of
                        Lorem Ipsum.</p><h3>Scope of Services</h3><p>Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                    </p>
                    <ul>
                        <li>
                            <Link to="/gdpr-explained">Market and Industry Research</Link>
                        </li>
                        <li>
                            <Link to="/gdpr-explained">Competitive Analysis</Link>
                        </li>
                        <li>
                            <Link to="/gdpr-explained">Financial Projections and Investment Summary</Link>
                        </li>
                        <li>
                            <Link to="/gdpr-explained">Key Performance Indicators and Return on Investments Analysis</Link>
                        </li>
                        <li><Link to="/gdpr-explained">Concept Rationale and Recommendations</Link></li>
                    </ul>
                    <h3>Lorem Ipsum is simply dummy text</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                        into electronic type set ting, remaining essentially unchanged. It was popularised in the 1960s with the release
                        of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop versions of Lorem Ipsum.Lorem
                        Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                        dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries, but also the leap into electronic type set ting,
                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing
                        Lorem Ipsum passages, and more recently with desktop versions of Lorem Ipsum.
                    </p>
                </div>
            </div>
        </>
    )
}

export default GdprExplained
