import React from 'react';
import { Link } from 'react-router-dom'
import Cgi from '../../../assets/images/cgi.jpg';

const EmpagenciesBox = () => {
    return (
        <>
            <li className="dk-empagenceBox">
                <div className="flip_box">
                    <Link to="#">
                        <div className="imgBox">
                            <img src={Cgi} alt="Feature Employers" />
                        </div>
                        <div className="hover_sjob">
                            <span><b>Company Name</b>100 Jobs</span></div>
                    </Link>
                </div>
            </li>
        </>
    )
}

export default EmpagenciesBox;
