import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'
import './paymenthistory.scss';

const PaymentHistory = () => {
    return (
        <>
            <div className="dk-paymentHistoryMain">
                <div className="dk-paymentHead">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-payemntHistory-date d-md-flex align-items-end">
                            <label htmlFor="">
                                <span>From</span>
                                <input type="date" className="form-control dk-date" name="" id="" />
                            </label>
                            <label htmlFor="">
                                <span>To</span>
                                <input type="date" className="form-control dk-date" name="" id="" />
                            </label>
                            <button className="dk-go">Go</button>
                        </div>
                        <h3 className="title">Payment History</h3>
                        <div className="dk-paymentExport">
                            <button className="dk-rightBtn">Export All</button>
                        </div>
                    </div>
                </div>
                <div className="dk-paymentHistory-table">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>SNo.</th>
                                <th>Payment Status</th>
                                <th>Payment Date</th>
                                <th>Payment Due on</th>
                                <th>Order Category</th>
                                <th>Type of Order</th>
                                <th>Amount</th>
                                <th>Paid Via</th>
                                <th>View PO</th>
                                <th>Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>
                                    <div className="dk-statusDropdown">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                Active
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">Inactive</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </td>
                                <td>Aug 24, 2020</td>
                                <td></td>
                                <td></td>
                                <td>Renewal: 1 Month Clud Hosting</td>
                                <td>64.00€</td>
                                <td>Credit Card</td>
                                <td>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="button-tooltip-2">View PO</Tooltip>}
                                    >
                                        <Link to="#" className="dk-tooltipCols">
                                            <i class="fas fa-money-check"></i>
                                        </Link>
                                    </OverlayTrigger>

                                </td>
                                <td>
                                    <div className="dk-action">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip-2">View Invoice</Tooltip>}
                                        >
                                            <Link to="#" className="dk-tooltipCols">
                                                <i class="fas fa-eye"></i>
                                            </Link>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip-2">Download Invoice</Tooltip>}
                                        >
                                            <Link to="#" className="dk-tooltipCols">
                                                <i class="fas fa-download"></i>
                                            </Link>
                                        </OverlayTrigger>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="dk-paymentPagination">
                        <div class="d-flex align-items-center justify-content-end">
                            <button class="dk-paginationArrow"><i class="fas fa-angle-left"></i></button>
                            <button>1</button>
                            <button>2</button>
                            <button class="active">3</button>
                            <button>4</button>
                            <button class="dk-paginationArrow"><i class="fas fa-angle-right"></i></button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentHistory;
