import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CompanyLogo from "../../../assets/images/teleperformance.jpeg";
import CompanyLogo2 from "../../../assets/images/dhl.jpeg";
import CompanyLogo3 from "../../../assets/images/manpower.jpeg";
import CompanyLogo4 from "../../../assets/images/visteon.jpeg";

const CompanyJobsLogo = () => {
    return (
        <>
            <div className="cj-right">
                <Link to="#">
                    <img src={CompanyLogo} className="img-fluid" alt="" />
                </Link>
                <Link to="#">
                    <img src={CompanyLogo2} className="img-fluid" alt="" />
                </Link>
                <Link to="#">
                    <img src={CompanyLogo3} className="img-fluid" alt="" />
                </Link>
                <Link to="#">
                    <img src={CompanyLogo4} className="img-fluid" alt="" />
                </Link>
            </div>
        </>
    )
}

export default CompanyJobsLogo
