import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

const SubscriptionTabs = () => {
    return (
        <>
            <Nav variant="pills">
                <Nav.Item>
                    <Nav.Link eventKey="tabs_1">Jobseeker Account</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="tabs_2">Employer Account</Nav.Link>
                </Nav.Item>
            </Nav>
        </>
    )
}

export default SubscriptionTabs;
