import React from 'react';
import Ibmimg from '../../../assets/images/ibm.png'
import { Link } from 'react-router-dom'
const FeaturedJobs = () => {
    return (
        <>
            <li class="featured-jobs-block">
                <div class="job_box">
                    <div className="imgBox">
                        <img src={Ibmimg} alt="" />
                    </div>
                    <div className="textBox">
                        <span class="job_sdesc">
                            <Link to="#">Turkish Localisation QA Tester</Link>
                            <p><span>Package :</span> GBP Up to £26000 per annum</p>
                            <p><span>Languages :</span> French</p>
                        </span>
                        <span class="job_acs">
                            <span><i data-test="fa" class="fa fa-map-marker pr-1"></i> French</span>
                            <Link class="btn btn-sm btn-theme-dark" to="#">View</Link>
                        </span>
                    </div>
                </div>
            </li>
        </>
    )
}

export default FeaturedJobs;
