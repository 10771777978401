import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const DocInfo = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <span onClick={handleShow}><i class="fas fa-info-circle"></i></span>

            <Modal className="dk-searchDocInfoModal" centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>PROFILE DOCUMENTS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe accusamus delectus facere sit hic qui amet, blanditiis fugiat! Quo voluptate laboriosam eum quibusdam tempore animi illo obcaecati sit reprehenderit eligendi?
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DocInfo
