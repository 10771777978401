import React from 'react'
import { Link } from 'react-router-dom';
import './standardnav.scss';
import { FaCaretDown, FaBell } from "react-icons/fa";
import Dropdown from 'react-bootstrap/Dropdown';
import Submenu from '../../submenu';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '../../../../../../../ui/tooltip';
import { logoutUser } from "../../../../../../../../slice/account/jobseeker/auth/authSlice";

const StandardNavbar = () => {
    const dispatch = useDispatch()
    const { userInfo } = useSelector(state => state.jobseekerAuth)
    const logout = async () => {
        dispatch(logoutUser({}))
    }
    let pathname = window.location.pathname;
    return (
        <>
            <div className="jobseeker dk-ds-navbar jobseeker-standard-dashboard">
                <div className="dk-jobseeker-fluid container-fluid">
                    <div className="d-flex align-items-enter justify-content-between">
                        <div className="dk-ds-profile col-md-1">
                            <div className="dk-ds-profile-img">
                                <Tooltip type="img" title={userInfo.name} value={process.env.REACT_APP_IMAGE_BASE_URL + userInfo.profileImage} className="img-fluid" />
                            </div>
                        </div>
                        <div className="dk-ds-menu col-md-11 pl-0">
                            <div className="bell-singOutbtnCon">
                                <div className="bellCon">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                            <FaBell />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="sigoutbtnCon">
                                    <Link to="#" className="sigoutbtn" onClick={logout}>Sign Out</Link>
                                </div>
                            </div>
                            <div className="dk-ds-menu-cols">
                                <ul>
                                    <li>
                                        <Link className={`${pathname.match('/dashboard') ? 'active' : ''}`} to="/jobseeker/standard/dashboard">Dashboard</Link>
                                    </li>
                                    <li>
                                        <Link className={`${pathname.match('/search-box') ? 'active' : ''}`} to="/jobseeker/standard/search-box">Search Jobs</Link>
                                    </li>
                                    <li>
                                        <Link className={`${pathname.match('/saved-jobs') ? 'active' : ''}`} to="/jobseeker/standard/saved-jobs">
                                            <span data-test="badge" className="badge info badge-info mr-2">4</span>Saved Jobs
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`${pathname.match('/applied-jobs') ? 'active' : ''}`} to="/jobseeker/standard/applied-jobs">
                                            <span data-test="badge" className="badge info badge-info mr-2 redInfo">4</span>Applied Jobs
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={`${pathname.match('/profile') ? 'grey-active' : ''}`} to="/jobseeker/standard/profile/basic">My Profile <FaCaretDown className="ml-2" /></Link>
                                    </li>
                                    <li>
                                        <Link className={`${pathname.match('/my-resume') ? 'grey-active' : ''}`} to="/jobseeker/standard/my-resume/create-resume">My Resume <FaCaretDown className="ml-2" /></Link>
                                    </li>
                                    <li>
                                        <Link className={`${pathname.match('/upgrade') ? 'grey-active' : ''}`} to="/jobseeker/standard/upgrade/current">Upgrade A/c<FaCaretDown className="ml-2" /></Link>
                                    </li>
                                    <li>
                                        <Link className={`${pathname.match('/support') ? 'grey-active' : ''}`} to="/jobseeker/standard/support/genral">Support <FaCaretDown className="ml-2" /></Link>
                                    </li>
                                    <li>
                                        <Link className={`${pathname.match('/dkg-serivces') ? 'grey-active' : ''}`} to="/jobseeker/standard/dkg-serivces/abouts">DKG Services <FaCaretDown className="ml-2" /></Link>
                                    </li>
                                    <li>
                                        <Link className={`${pathname.match('/settings') ? 'grey-active' : ''}`} to="/jobseeker/standard/settings/faq">Settings <FaCaretDown className="ml-2" /></Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Submenu path={pathname} />
        </>
    )
}
export default StandardNavbar;