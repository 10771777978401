import React, { useState } from 'react';
import { Route, Link } from 'react-router-dom';
import JobFaqModal from "./JobFaqModal";
import JobsFilter from "./JobsFilter";
import FulltimeImg from "../../../../../../assets/images/fulltime.jpg";
import ParttimeImg from "../../../../../../assets/images/parttime.jpg";
import OthertimeImg from "../../../../../../assets/images/othertime.jpg";
import './searchjobs.scss';
import JobsCategory from './JobsCategory';
import JobsCompanyLogo from './JobsCompanyLogo';

const SearchJobs = () => {
    return (
        <React.Fragment>
            <div className="dk-searchBox-main">
                <div className="dk-jobseekerSt-searchMain">
                    <section>
                        <div className="container dk-headIngfindtext">
                            <div className="col-md-12 d-flex justify-content-center align-items-center">
                                <h2 className="section-title text-uppercase">Find Your Next Job Here</h2>
                                <div className="dk-faqCols">
                                    <JobFaqModal />
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-3 custom-w">
                                        <JobsFilter />
                                    </div>
                                    <div className="col-md-7 custom-w">
                                        <div className="dk-joblistingMain">
                                            <JobsCategory />
                                            <div className="dk-jobslisting-tagselect">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="dk-jobs-list">1-50 of 11326 Jobs</div>

                                                    <div className="dk-jobs-cate-link">
                                                        <Route>
                                                            <Link to="#" className="box">Last 24 hours <i class="fas fa-times"></i></Link>
                                                            <Link to="#" className="box">Full Time <i class="fas fa-times"></i></Link>
                                                            <Link to="#">Clear all</Link>
                                                        </Route>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="dk-boxes-cols">
                                                <div className="d-sm-flex align-items-center">
                                                    <div className="col-md-2">
                                                        <div className="dk-boxes-cols-img">
                                                            <img src={FulltimeImg} className="img-fluid" alt="" />
                                                            <Route>
                                                                <Link to="#" className="dk-boxes-btn fulltime">Full Time</Link>
                                                            </Route>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-lg-10 pr-0">
                                                        <div className="dk-boxes-cols-right">
                                                            <div className="d-sm-flex">
                                                                <div className="dk-boxes-cols-right-cols">
                                                                    <div className="title">
                                                                        <Route>
                                                                            <Link to="#">REGIONAL SALES MANAGER SOUTH EAST ASIA</Link>
                                                                        </Route>
                                                                    </div>
                                                                    <div className="dk-boxes-right-cols-desc">
                                                                        <ul>
                                                                            <li><i className="fas fa-briefcase"></i> Job Title</li>
                                                                            <li><i className="fas fa-euro-sign"></i> Salary</li>
                                                                            <li><i className="fas fa-building"></i> Employer Name</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li><i className="fas fa-language"></i> Language</li>
                                                                            <li><i className="fas fa-map-marker-alt"></i> Location</li>
                                                                            <li><i className="far fa-calendar-alt"></i> Start Date</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-12 col-lg-4 pr-0 custom-w">
                                                                    <div className="dk-boxes-cols-right-cols-viewjobs">
                                                                        <Route>
                                                                            <Link to="#"><i class="far fa-eye"></i> View Job</Link>
                                                                            <Link to="#"><i class="far fa-clock"></i> Full Time</Link>
                                                                            <Link to="#" className="wishlist">
                                                                                <i class="far fa-heart"></i> Favourite
                                                                            </Link>
                                                                        </Route>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="dk-boxes-cols">
                                                <div className="d-sm-flex align-items-center">
                                                    <div className="col-md-2">
                                                        <div className="dk-boxes-cols-img">
                                                            <img src={ParttimeImg} className="img-fluid" alt="" />
                                                            <Route>
                                                                <Link to="#" className="dk-boxes-btn fulltime">Part Time</Link>
                                                            </Route>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-lg-10 pr-0">
                                                        <div className="dk-boxes-cols-right">
                                                            <div className="d-sm-flex">
                                                                <div className="dk-boxes-cols-right-cols">
                                                                    <div className="title">
                                                                        <Route>
                                                                            <Link to="#">REGIONAL SALES MANAGER SOUTH EAST ASIA</Link>
                                                                        </Route>
                                                                    </div>
                                                                    <div className="dk-boxes-right-cols-desc">
                                                                        <ul>
                                                                            <li><i className="fas fa-briefcase"></i> Job Title</li>
                                                                            <li><i className="fas fa-euro-sign"></i> Salary</li>
                                                                            <li><i className="fas fa-building"></i> Employer Name</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li><i className="fas fa-language"></i> Language</li>
                                                                            <li><i className="fas fa-map-marker-alt"></i> Location</li>
                                                                            <li><i className="far fa-calendar-alt"></i> Start Date</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-12 col-lg-4 pr-0 custom-w">
                                                                    <div className="dk-boxes-cols-right-cols-viewjobs">
                                                                        <Route>
                                                                            <Link to="#"><i class="far fa-eye"></i> View Job</Link>
                                                                            <Link to="#"><i class="far fa-clock"></i> Full Time</Link>
                                                                            <Link to="#" className="wishlist">
                                                                                <i class="far fa-heart"></i> Favourite
                                                                            </Link>
                                                                        </Route>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="dk-boxes-cols">
                                                <div className="d-sm-flex align-items-center">
                                                    <div className="col-md-2">
                                                        <div className="dk-boxes-cols-img">
                                                            <img src={OthertimeImg} className="img-fluid" alt="" />
                                                            <Route>
                                                                <Link to="#" className="dk-boxes-btn fulltime">Other Time</Link>
                                                            </Route>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-lg-10 pr-0">
                                                        <div className="dk-boxes-cols-right">
                                                            <div className="d-sm-flex">
                                                                <div className="dk-boxes-cols-right-cols">
                                                                    <div className="title">
                                                                        <Route>
                                                                            <Link to="#">REGIONAL SALES MANAGER SOUTH EAST ASIA</Link>
                                                                        </Route>
                                                                    </div>
                                                                    <div className="dk-boxes-right-cols-desc">
                                                                        <ul>
                                                                            <li><i className="fas fa-briefcase"></i> Job Title</li>
                                                                            <li><i className="fas fa-euro-sign"></i> Salary</li>
                                                                            <li><i className="fas fa-building"></i> Employer Name</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li><i className="fas fa-language"></i> Language</li>
                                                                            <li><i className="fas fa-map-marker-alt"></i> Location</li>
                                                                            <li><i className="far fa-calendar-alt"></i> Start Date</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-12 col-lg-4 pr-0 custom-w">
                                                                    <div className="dk-boxes-cols-right-cols-viewjobs">
                                                                        <Route>
                                                                            <Link to="#"><i class="far fa-eye"></i> View Job</Link>
                                                                            <Link to="#"><i class="far fa-clock"></i> Full Time</Link>
                                                                            <Link to="#" className="wishlist">
                                                                                <i class="far fa-heart"></i> Favourite
                                                                            </Link>
                                                                        </Route>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="dk-boxes-cols">
                                                <div className="d-sm-flex align-items-center">
                                                    <div className="col-md-2">
                                                        <div className="dk-boxes-cols-img">
                                                            <img src={FulltimeImg} className="img-fluid" alt="" />
                                                            <Route>
                                                                <Link to="#" className="dk-boxes-btn fulltime">Full Time</Link>
                                                            </Route>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-lg-10 pr-0">
                                                        <div className="dk-boxes-cols-right">
                                                            <div className="d-sm-flex">
                                                                <div className="dk-boxes-cols-right-cols">
                                                                    <div className="title">
                                                                        <Route>
                                                                            <Link to="#">REGIONAL SALES MANAGER SOUTH EAST ASIA</Link>
                                                                        </Route>
                                                                    </div>
                                                                    <div className="dk-boxes-right-cols-desc">
                                                                        <ul>
                                                                            <li><i className="fas fa-briefcase"></i> Job Title</li>
                                                                            <li><i className="fas fa-euro-sign"></i> Salary</li>
                                                                            <li><i className="fas fa-building"></i> Employer Name</li>
                                                                        </ul>
                                                                        <ul>
                                                                            <li><i className="fas fa-language"></i> Language</li>
                                                                            <li><i className="fas fa-map-marker-alt"></i> Location</li>
                                                                            <li><i className="far fa-calendar-alt"></i> Start Date</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-12 col-lg-4 pr-0 custom-w">
                                                                    <div className="dk-boxes-cols-right-cols-viewjobs">
                                                                        <Route>
                                                                            <Link to="#"><i class="far fa-eye"></i> View Job</Link>
                                                                            <Link to="#"><i class="far fa-clock"></i> Full Time</Link>
                                                                            <Link to="#" className="wishlist">
                                                                                <i class="far fa-heart"></i> Favourite
                                                                            </Link>
                                                                        </Route>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-2 custom-w">
                                        <JobsCompanyLogo />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </React.Fragment>
    )
}
export default SearchJobs;
