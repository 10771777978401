import React from 'react'
import { Link } from 'react-router-dom'
import ResumeImg from '../../../../../../../../assets/images/resume_img.png';

const SoughtJobs = () => {
    return (
        <>
            <div className="row dk-workexpMainCon">
                <div className="col-md-8 dk-workexperienceLeftCon">
                    <div className="headingCon">
                        <h6 class="pt-0">Fill out the following criteria so that employers can even offer you jobs that have not yet been posted.</h6>
                    </div>
                    <form action="">
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Job positions sought*</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-4 dk-workexperienceRightCon">
                    <div className="dk-resumeImg-cols">
                        <Link to="#">
                            <div className="title">Preview</div>
                            <div className="resumeimgCon">
                                <img src={ResumeImg} alt="Resume" />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SoughtJobs;
