import React, { useEffect, useState } from 'react'
import SubmitButton from '../../../../../../../ui/submitButton';
import { clearState, updateContact } from '../../../../../../../../slice/account/employer/profile/profileSlice'
import { showSuccess, showError, clearMessage } from '../../../../../../../../slice/utils/message/messageSlice';
import { useDispatch, useSelector } from "react-redux";
import Facebook from '../../../../../../../../assets/images/facebook.png'
import Twitter from '../../../../../../../../assets/images/twitter.png'
import Instagram from '../../../../../../../../assets/images/instagram.png'
import Linkedin from '../../../../../../../../assets/images/linkedin.png'

const Contact = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.employerAuth);
    const { details, isLoading, isSuccess, isError, message } = useSelector(state => state.employerProfile);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [businessName, setBusinessName] = useState('');
    const [businessPhone, setBusinessPhone] = useState('');
    const [businessPhone2, setBusinessPhone2] = useState('');
    const [businessEmail1, setBusinessEmail1] = useState('');
    const [businessEmail2, setBusinessEmail2] = useState('');
    const [businessWebPage, setBusinessWebPage] = useState('');
    const [businessFacebook, setBusinessFacebook] = useState('');
    const [businessTwitter, setBusinessTwitter] = useState('');
    const [businessInstagram, setBusinessInstagram] = useState('');
    const [businessLinkedin, setBusinessLinkedin] = useState('');

    useEffect(() => {
        if (!isLoading) {
            setBusinessName(details.businessName)
            setBusinessPhone(details.businessPhone)
            setBusinessPhone2(details.businessPhone2)
            setBusinessEmail1(details.businessEmail1)
            setBusinessEmail2(details.businessEmail2)
            setBusinessWebPage(details.businessWebPage)
            setBusinessFacebook(details.businessFacebook)
            setBusinessTwitter(details.businessTwitter)
            setBusinessInstagram(details.businessInstagram)
            setBusinessLinkedin(details.businessLinkedin)
        }
        if (isSuccess) {
            setSuccess(true);
            setLoading(false);
            dispatch(showSuccess({ msg: 'Company Details Updated successfully' }))
            dispatch(clearState())
        }
    }, [isLoading, isSuccess])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            dispatch(clearMessage())
            setSuccess(false);
            setLoading(true);
            await dispatch(updateContact({ uid: userInfo.employerUid, businessName, businessPhone, businessPhone2, businessEmail1, businessEmail2, businessWebPage, businessFacebook, businessTwitter, businessInstagram, businessLinkedin }))
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again. ' + err }))
        }
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-4 col-sm-12">
                    <div className="form-group dk-contactUsFrmGrp">
                        <label className="title">Company Name</label>
                        <label>
                            <input type="text" className="form-control" value={businessName} onChange={(e) => setBusinessName(e.target.value)} />
                        </label>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="form-group dk-contactUsFrmGrp">
                        <label className="title">Phone 1</label>
                        <label>
                            <input type="text" className="form-control" value={businessPhone} onChange={(e) => setBusinessPhone(e.target.value)} />
                        </label>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="form-group dk-contactUsFrmGrp">
                        <label className="title">Phone 1</label>
                        <label>
                            <input type="text" className="form-control" value={businessPhone2} onChange={(e) => setBusinessPhone2(e.target.value)} />
                        </label>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="form-group dk-contactUsFrmGrp">
                        <label className="title">Email 1</label>
                        <label>
                            <input type="text" className="form-control" value={businessEmail1} onChange={(e) => setBusinessEmail1(e.target.value)} />
                        </label>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="form-group dk-contactUsFrmGrp">
                        <label className="title">Email 2</label>
                        <label>
                            <input type="text" className="form-control" value={businessEmail2} onChange={(e) => setBusinessEmail2(e.target.value)} />
                        </label>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="form-group dk-contactUsFrmGrp">
                        <label className="title">Website</label>
                        <label>
                            <input type="text" className="form-control" value={businessWebPage} onChange={(e) => setBusinessWebPage(e.target.value)} />
                        </label>
                    </div>
                </div>
                <div className="col-md-12 mt-4 dk-socialWithInput">
                    <label className="d-flex align-items-center mb-4">
                        <span className="dk-socailimgCon">
                            <img src={Facebook} alt="SocialImg" />
                        </span>
                        <input type="text" className="form-control" value={businessFacebook} onChange={(e) => setBusinessFacebook(e.target.value)} />
                    </label>
                    <label className="d-flex align-items-center mb-4">
                        <span className="dk-socailimgCon">
                            <img src={Twitter} alt="SocialImg" />
                        </span>
                        <input type="text" className="form-control" value={businessTwitter} onChange={(e) => setBusinessTwitter(e.target.value)} />
                    </label>
                    <label className="d-flex align-items-center mb-4">
                        <span className="dk-socailimgCon">
                            <img src={Instagram} alt="SocialImg" />
                        </span>
                        <input type="text" className="form-control" value={businessInstagram} onChange={(e) => setBusinessInstagram(e.target.value)} />
                    </label>
                    <label className="d-flex align-items-center mb-4">
                        <span className="dk-socailimgCon">
                            <img src={Linkedin} alt="SocialImg" />
                        </span>
                        <input type="text" className="form-control" value={businessLinkedin} onChange={(e) => setBusinessLinkedin(e.target.value)} />
                    </label>
                    <div className="dk-updatebtnCon">
                        <SubmitButton txt="Update Information" loading={loading} success={success} onClick={handleSubmit} position="" className="dk-updateBtn" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Contact;