import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'
import './emjobs.scss';
import AdminFilterModal from './AdminFilterModal';

const ActiveJobs = () => {
    return (
        <>
            <div className="dk-activeJobsMain">
                <div className="dk-myDocs-head">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex">
                            <div className="dk-searchFieldBox mr-2">
                                <input type="text" name="" placeholder="Search..." id="" className="form-control" />
                            </div>
                            <AdminFilterModal />
                        </div>
                        <h3 className="dk-tableTitle">ACTIVE JOBS</h3>
                        <div className="dk-myDocs-btn">
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    Job Status
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#">Action</Dropdown.Item>
                                    <Dropdown.Item href="#">Another action</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                <div className="dk-activeJobsTable">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Sn.</th>
                                    <th>Recruiter</th>
                                    <th>Status</th>
                                    <th>Job Ref</th>
                                    <th>Job Title</th>
                                    <th>New Apps</th>
                                    <th>All Apps</th>
                                    <th>All Views</th>
                                    <th>Posted on</th>
                                    <th>Expiring</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>
                                        <div className="dk-profileImg">
                                            <img src="http://admin.dkglobaljobs.com/uploads/profile_picture/dpk-img.png" className="img-fluid img-circle" alt="" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="active">
                                            <span>Active</span>
                                        </div>
                                    </td>
                                    <td>Google</td>
                                    <td>
                                        <div className="dkjobTitle">
                                            <Link title="Job Title" to="#">Software Developer, Dublin</Link>
                                        </div>
                                    </td>
                                    <td>10</td>
                                    <td>2</td>
                                    <td>55</td>
                                    <td>20 Dec 2020</td>
                                    <td>30 Dec 2020</td>
                                    <td>
                                        <div className="dk-actionBtn">
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip-2">Reload</Tooltip>}
                                            >
                                                <Link className="dkrepost" to="#">
                                                    <i className="fas fa-retweet"></i>
                                                </Link>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip-2">View</Tooltip>}
                                            >
                                                <Link className="dkview" to="#">
                                                    <i className="fas fa-eye"></i>
                                                </Link>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip-2">Edit</Tooltip>}
                                            >
                                                <Link className="dkedit" to="#">
                                                    <i className="fas fa-edit"></i>
                                                </Link>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip-2">Delete</Tooltip>}
                                            >
                                                <Link className="dkdelete" to="#">
                                                    <i className="fas fa-trash"></i>
                                                </Link>
                                            </OverlayTrigger>

                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ActiveJobs;
