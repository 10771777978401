import React from 'react'
import Modal from 'react-bootstrap/Modal'
import './titleinfo.scss';

const TitleInfo = ({ hidepopup }) => {
    return (
        <>
            <Modal
                size="lg"
                show={true}
                onHide={hidepopup}
                aria-labelledby="contained-modal-title-vcenter"
                className="dk-doctitleModal"
                centered
            >
                <Modal.Header closeButton className="dk-doctitleModalHeader">
                    <Modal.Title id="contained-modal-title-vcenter">
                        My Documents Title
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-doctitleModalBody">
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.
                    </p>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TitleInfo
