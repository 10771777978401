import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const verifyEmail = createAsyncThunk(
    'account/auth/verify_email',
    async ({ token, accountType }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/auth/' + accountType + '/verify_email',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                    },
                    body: JSON.stringify({
                        token
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const resendLink = createAsyncThunk(
    'account/auth/resend_link',
    async ({ email, id, account }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/auth/' + account + '/resend_verification_link',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                    },
                    body: JSON.stringify({
                        id,
                        email,
                        account
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const acountVerifySlice = createSlice({
    name: "acount_verify",
    initialState: {
        isLoading: false,
        isResendLoading: false,
        isSuccess: false,
        isError: false,
        message: ""
    },
    reducers: {
        clearState: (state) => {
            state.isResendLoading = false;
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        }
    },
    extraReducers: {
        [verifyEmail.pending]: (state) => {
            state.isLoading = true;
        },
        [verifyEmail.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
        },
        [verifyEmail.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.message = payload.message;
            }
            state.isLoading = false;
        },
        [resendLink.pending]: (state) => {
            state.isResendLoading = true;
        },
        [resendLink.fulfilled]: (state, { payload }) => {
            state.isResendLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
        },
        [resendLink.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.message = payload.message;
            }
            state.isResendLoading = false;
        },
    }
});
export const { clearState, logoutUser } = acountVerifySlice.actions;

export default acountVerifySlice.reducer;