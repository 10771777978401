import React, { useState } from 'react';

const JobsFilter = () => {
    return (
        <>
            <div className="dk-jobsfilter">
                <div className="dk-mobViewFilter-main">
                    <div className="dk-jobsfilter-search">
                        <label htmlFor="" className="d-flex align-items-center">
                            <input type="text" className="form-control" placeholder="Search Keywords" />
                            <button className="dk-filter-search-btn"><i class="fas fa-search"></i></button>
                        </label>
                    </div>

                    <div className="dk-jobsfilter-cols">
                        <div className="title">Posted On</div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="checkbox1"></input>
                                <label class="custom-control-label" for="checkbox1">Last 24 hours</label>
                            </div>
                            <span>233</span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="checkbox2"></input>
                                <label class="custom-control-label" for="checkbox2">Last 7 days</label>
                            </div>
                            <span>233</span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="checkbox3"></input>
                                <label class="custom-control-label" for="checkbox3">Last 14 days</label>
                            </div>
                            <span>233</span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="checkbox4"></input>
                                <label class="custom-control-label" for="checkbox4">Last 30 days</label>
                            </div>
                            <span>233</span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="checkbox5"></input>
                                <label class="custom-control-label" for="checkbox5">All Time</label>
                            </div>
                            <span>233</span>
                        </div>

                    </div>

                    <div className="dk-jobsfilter-cols">
                        <div className="title">Job Type</div>

                        <div className="d-flex align-items-center justify-content-between">
                            <div class="custom-control custom-checkbox fulltime">
                                <input type="checkbox" class="custom-control-input" id="jobtype1"></input>
                                <label class="custom-control-label" for="jobtype1">Full Time</label>
                            </div>
                            <span>233</span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                            <div class="custom-control custom-checkbox parttime">
                                <input type="checkbox" class="custom-control-input" id="jobtype2"></input>
                                <label class="custom-control-label" for="jobtype2">Part Time</label>
                            </div>
                            <span>233</span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                            <div class="custom-control custom-checkbox freelance">
                                <input type="checkbox" class="custom-control-input" id="jobtype3"></input>
                                <label class="custom-control-label" for="jobtype3">Contractor</label>
                            </div>
                            <span>233</span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                            <div class="custom-control custom-checkbox remote">
                                <input type="checkbox" class="custom-control-input" id="jobtype4"></input>
                                <label class="custom-control-label" for="jobtype4">Remote</label>
                            </div>
                            <span>233</span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                            <div class="custom-control custom-checkbox intership">
                                <input type="checkbox" class="custom-control-input" id="checkbox6"></input>
                                <label class="custom-control-label" for="checkbox6">Internship</label>
                            </div>
                            <span>233</span>
                        </div>

                    </div>

                    <div className="dk-jobsfilter-cols">
                        <div className="title">Salary</div>

                        <div className="d-flex align-items-center justify-content-between">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="salary1"></input>
                                <label class="custom-control-label" for="salary1">€ 0 - 20,000</label>
                            </div>
                            <span>233</span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="salary2"></input>
                                <label class="custom-control-label" for="salary2">€ 20,000 - 30,000</label>
                            </div>
                            <span>233</span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="salary3"></input>
                                <label class="custom-control-label" for="salary3">€ 30,000 - 40,000</label>
                            </div>
                            <span>233</span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="salary4"></input>
                                <label class="custom-control-label" for="salary4">€ 40,000 - 50,000</label>
                            </div>
                            <span>233</span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="salary5"></input>
                                <label class="custom-control-label" for="salary5">€ 50,000+</label>
                            </div>
                            <span>233</span>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default JobsFilter;
