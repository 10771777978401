import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const forgetPassword = createAsyncThunk(
    'account/auth/forget_password',
    async ({ account, email }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/auth/' + account + '/forgot_password',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                    },
                    body: JSON.stringify({
                        account, email
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const verifyOTP = createAsyncThunk(
    'auth/verify_OTP',
    async ({ account, email, otp }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/auth/' + account + '/verify_otp',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                    },
                    body: JSON.stringify({
                        account,
                        email,
                        otp
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const resetPassword = createAsyncThunk(
    'auth/reset_password',
    async ({ account, email, new_password }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/auth/' + account + '/reset_password',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                    },
                    body: JSON.stringify({
                        account,
                        email,
                        new_password
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const forgetPasswordSlice = createSlice({
    name: "forget_password",
    initialState: {
        isLoading: false,
        isSuccess: false,
        isError: false,
        isVerifyOTP: false,
        message: ""
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.isVerifyOTP = false;
            state.message = "";
        }
    },
    extraReducers: {
        [forgetPassword.pending]: (state) => {
            state.isLoading = true;
        },
        [forgetPassword.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
        },
        [forgetPassword.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.message = payload.message;
            }
            state.isLoading = false;
        },
        [verifyOTP.pending]: (state) => {
            state.isLoading = true;
        },
        [verifyOTP.fulfilled]: (state, { payload }) => {
            state.isVerifyOTP = true;
            state.isLoading = false;
            state.message = payload.message
        },
        [verifyOTP.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.message = payload.message;
            }
            state.isLoading = false;
        },
        [resetPassword.pending]: (state) => {
            state.isLoading = true;
        },
        [resetPassword.fulfilled]: (state, { payload }) => {
            state.isSuccess = true;
            state.isLoading = false;
            state.message = payload.message
        },
        [resetPassword.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.message = payload.message;
            }
            state.isLoading = false;
        },
    }
});
export const { clearState } = forgetPasswordSlice.actions;
export default forgetPasswordSlice.reducer;