import React from 'react';
import { Route } from 'react-router-dom';
import Layout from "../layout";
const MainRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => {
            return <Layout><Component {...props} /></Layout>
        }} />
    )
};
export default MainRoute;