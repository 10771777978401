import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './mycontracts.scss'
import { InputGroup, FormControl } from 'react-bootstrap'
import ModalTitleInfo from './modal/TitleInfo';
import ModalViewDocument from './modal/ViewDocument';
import ModalAddNewFile from './modal/AddNewFile';
import ModalEditFile from './modal/EditFile';
const MyDocuments = () => {
    const [isTitleInfo, setIsTitleInfo] = useState(false);
    const [isViewDocument, setIsViewDocument] = useState(false);
    const [isAddNewFile, setIsAddNewFile] = useState(false);
    const [isEditModal, setIsEditModal] = useState(false);
    const showModalTitleInfo = () => {
        setIsTitleInfo(true);
    }
    const hideModalTitleInfo = () => {
        setIsTitleInfo(false);
    }
    const showviewDocumentModal = () => {
        setIsViewDocument(true);
    }
    const hideviewDocumentModal = () => {
        setIsViewDocument(false);
    }
    const showaddnewfileModal = () => {
        setIsAddNewFile(true);
    }
    const hideaddnewfileModal = () => {
        setIsAddNewFile(false);
    }
    const showeditModal = () => {
        setIsEditModal(true);
    }
    const hideeditModal = () => {
        setIsEditModal(false);
    }
    return (
        <>
            {
                (isTitleInfo) ? <ModalTitleInfo hidepopup={hideModalTitleInfo} /> : null
            }
            {
                (isViewDocument) ? <ModalViewDocument hidepopup={hideviewDocumentModal} /> : null
            }
            {
                (isAddNewFile) ? <ModalAddNewFile hidepopup={hideaddnewfileModal} /> : null
            }
            {
                (isEditModal) ? <ModalEditFile hidepopup={hideeditModal} /> : null
            }
            <div className="container-fluid dk-settContractsPageMain">
                <div className="dk-mydocPageCon">
                    <div className="col-md-11 pl-0 ml-auto">
                        <div className="dk-mydocpageHeader">
                            <div className className="dk-mydocLeftCon">
                                <div className="dk-searchFieldBox">
                                    <InputGroup>
                                        <FormControl
                                            aria-label="Default"
                                            aria-describedby="inputGroup-sizing-default"
                                        />
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroup-sizing-default"><i className="fa fa-search"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                    </InputGroup>
                                </div>
                            </div>
                            <div className className="dk-mydocCentreCon">
                                <h3 className="dk-tableTitle">
                                    CONTRACTS & AGREEMENTS <span className="dk-infoModal" onClick={showModalTitleInfo}><i className="fa fa-info-circle"></i></span>
                                </h3>
                            </div>
                            <div className className="dk-mydocRightCon">
                                <div className="dk-myDocs-btnCon">
                                    <Link to="#" className="dk-myDocs-btn" onClick={showaddnewfileModal}>
                                        Add New File
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="dk-mydocumentTableCon table-responnsive">
                            <table className="dk-mydocumentTable table table-bordered">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Date</th>
                                        <th>File Name</th>
                                        <th>File Category</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>30 Nov 2021</td>
                                        <td>Deepak Passport</td>
                                        <td>myfile.pdf</td>
                                        <td>
                                            <div className="dk-actionsCols">
                                                <Link class="dk-view-btn mr-2" onClick={showviewDocumentModal} onClick={showviewDocumentModal} to="#/jobseeker/standard/settings/my_documents">View</Link>
                                                <Link class="dk-download-btn mr-2" to="#/jobseeker/standard/settings/my_documents">Download</Link>
                                                <Link class="dk-edit-btn mr-2" onClick={showeditModal} to="#/jobseeker/standard/settings/my_documents">Edit</Link>
                                                <Link class="dk-delete-btn" to="#/jobseeker/standard/settings/my_documents">Delete</Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>30 Nov 2021</td>
                                        <td>Deepak Passport</td>
                                        <td>myfile.pdf</td>

                                        <td>
                                            <div className="dk-actionsCols">
                                                <Link class="dk-view-btn mr-2" onClick={showviewDocumentModal} to="#/jobseeker/standard/settings/my_documents">View</Link>
                                                <Link class="dk-download-btn mr-2" to="#/jobseeker/standard/settings/my_documents">Download</Link>
                                                <Link class="dk-edit-btn mr-2" onClick={showeditModal} to="#/jobseeker/standard/settings/my_documents">Edit</Link>
                                                <Link class="dk-delete-btn" to="#/jobseeker/standard/settings/my_documents">Delete</Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>30 Nov 2021</td>
                                        <td>Deepak Passport</td>
                                        <td>myfile.pdf</td>

                                        <td>
                                            <div className="dk-actionsCols">
                                                <Link class="dk-view-btn mr-2" onClick={showviewDocumentModal} to="#/jobseeker/standard/settings/my_documents">View</Link>
                                                <Link class="dk-download-btn mr-2" to="#/jobseeker/standard/settings/my_documents">Download</Link>
                                                <Link class="dk-edit-btn mr-2" onClick={showeditModal} to="#/jobseeker/standard/settings/my_documents">Edit</Link>
                                                <Link class="dk-delete-btn" to="#/jobseeker/standard/settings/my_documents">Delete</Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>30 Nov 2021</td>
                                        <td>Deepak Passport</td>
                                        <td>myfile.pdf</td>

                                        <td>
                                            <div className="dk-actionsCols">
                                                <Link class="dk-view-btn mr-2" onClick={showviewDocumentModal} to="#/jobseeker/standard/settings/my_documents">View</Link>
                                                <Link class="dk-download-btn mr-2" to="#/jobseeker/standard/settings/my_documents">Download</Link>
                                                <Link class="dk-edit-btn mr-2" onClick={showeditModal} to="#/jobseeker/standard/settings/my_documents">Edit</Link>
                                                <Link class="dk-delete-btn" to="#/jobseeker/standard/settings/my_documents">Delete</Link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>30 Nov 2021</td>
                                        <td>Deepak Passport</td>
                                        <td>myfile.pdf</td>

                                        <td>
                                            <div className="dk-actionsCols">
                                                <Link class="dk-view-btn mr-2" onClick={showviewDocumentModal} to="#/jobseeker/standard/settings/my_documents">View</Link>
                                                <Link class="dk-download-btn mr-2" to="#/jobseeker/standard/settings/my_documents">Download</Link>
                                                <Link class="dk-edit-btn mr-2" onClick={showeditModal} to="#/jobseeker/standard/settings/my_documents">Edit</Link>
                                                <Link class="dk-delete-btn" to="#/jobseeker/standard/settings/my_documents">Delete</Link>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyDocuments
