import React from 'react'
import { Link } from 'react-router-dom'
import './submenu.scss'

const SettingSubmenu = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="container-fluid">
                <div className="dk-ds-sub-menu col-md-12 pl-0 pr-0 ml-auto">
                    <div class="dk-ds-sub-menu-cols">
                        <ul>
                            <li>
                                <Link className={pathname === "/employer/standard/settings/faq" ? 'sub-active' : ''} to="/employer/standard/settings/faq">FAQ</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/settings/notifications" ? 'sub-active' : ''} to="/employer/standard/settings/notifications">Notifications</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/settings/my_documents" ? 'sub-active' : ''} to="/employer/standard/settings/my_documents">My Documents</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/settings/contracts" ? 'sub-active' : ''} to="/employer/standard/settings/contracts">My Contracts</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/settings/compliance" ? 'sub-active' : ''} to="/employer/standard/settings/compliance">Compliance</Link>
                            </li>
                            {/* <li>
                                <Link className={pathname === "/employer/standard/settings/consent" ? 'sub-active' : ''} to="/employer/standard/settings/consent">Consent</Link>
                            </li> */}
                            <li>
                                <Link className={pathname === "/employer/standard/settings/change_logins" ? 'sub-active' : ''} to="/employer/standard/settings/change_logins">Change Logins</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/settings/disable_account" ? 'sub-active' : ''} to="/employer/standard/settings/disable_account">Disable Account</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/settings/delete-account" ? 'sub-active' : ''} to="/employer/standard/settings/delete-account">Delete Account</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingSubmenu
