import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const SavedJobsPagination = () => {
    return (
        <>
            <div className="dk-savedJobsPagination">
                <ul>
                    <li><Link to="#"><i class="fas fa-angle-double-left"></i></Link></li>
                    <li><Link to="#">1</Link></li>
                    <li><Link to="#">2</Link></li>
                    <li><Link to="#" className="active">3</Link></li>
                    <li><Link to="#">4</Link></li>
                    <li><Link to="#">5</Link></li>
                    <li><Link to="#"><i class="fas fa-angle-double-right"></i></Link></li>
                </ul>
            </div>
        </>
    )
}

export default SavedJobsPagination;
