import React from 'react';
import { Link } from 'react-router-dom';
import './jobindustrycard.scss'

const JobindustryCard = (props) => {
    return (
        <>
            <div className="dkjobs-induCarCon">
                <Link to="#">
                    <div className="cardImgCon">
                        <img src={props.cardImg} alt="" />
                    </div>
                    <h3>{props.cardTitle}</h3>
                    <p>{props.cardSubTitle}</p>
                </Link>
            </div>
        </>
    )
}

export default JobindustryCard;
