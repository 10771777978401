import React from 'react'
import './accessdata.scss'
const AccesyourData = () => {
    return (
        <>
            <div className="dk-accessdataMain">
                <div className="pageTitle">
                    <h2 className="section-title pb-0">Data Privacy Requests</h2>
                    <p className="text-center">Welcome to the Data Subject Access Request Portal</p>
                </div>
                <div className="dk-access-data">
                    <div className="dk-access-data-cols">
                        <form action="">
                            <div className="form-group">
                                <div className="title">I am a (an) *</div>
                                <div className="d-flex">
                                    <div className="dk-form-cols">
                                        <input type="radio" className="dk-radiobtn" name="dkradio" id="existingPartner" />
                                        <label for="existingPartner">Jobseeker</label>
                                    </div>
                                    <div className="dk-form-cols">
                                        <input type="radio" className="dk-radiobtn" name="dkradio" id="visitor" />
                                        <label for="visitor">Employer</label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="title">Select request type(s) *</div>
                                <div className="d-flex">
                                    <div className="dk-form-cols-checkbox-area">
                                        <input type="checkbox" className="dkcheckbox-btn" name="" id="optout" />
                                        <label for="optout">
                                            <span>Opt out</span>
                                        </label>
                                    </div>
                                    <div className="dk-form-cols-checkbox-area">
                                        <input type="checkbox" className="dkcheckbox-btn" name="" id="updatedata" />
                                        <label for="updatedata">
                                            <span>Update Data</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="dk-form-cols-checkbox-area">
                                        <input type="checkbox" className="dkcheckbox-btn" name="" id="request" />
                                        <label for="request">
                                            <span>Info Request</span>
                                        </label>
                                    </div>
                                    <div className="dk-form-cols-checkbox-area">
                                        <input type="checkbox" className="dkcheckbox-btn" name="" id="deletion" />
                                        <label for="deletion">
                                            <span>Data Deletion</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="dk-form-cols-checkbox-area">
                                        <input type="checkbox" className="dkcheckbox-btn" name="" id="processing" />
                                        <label for="processing">
                                            <span>Object to Processing</span>
                                        </label>
                                    </div>
                                    <div className="dk-form-cols-checkbox-area">
                                        <input type="checkbox" className="dkcheckbox-btn" name="" id="complaint" />
                                        <label for="complaint">
                                            <span>File a Complaint</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="dk-form-cols-checkbox-area">
                                        <input type="checkbox" className="dkcheckbox-btn" name="" id="processing" />
                                        <label for="processing">
                                            <span>Restrict Processing</span>
                                        </label>
                                    </div>
                                    <div className="dk-form-cols-checkbox-area">
                                        <input type="checkbox" className="dkcheckbox-btn" name="" id="portability" />
                                        <label for="portability">
                                            <span>Other</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="dk-form-fill">
                                <div className="form-group d-flex">
                                    <div className="dk-form-fill-cols">
                                        <div className="row">
                                            <div className="dk-form-fill-group-cols col-lg-6 col-sm-12">
                                                <label for="">First Name *</label>
                                                <label for="">
                                                    <input type="text" name="" className="form-control" id="" value="" />
                                                </label>
                                            </div>
                                            <div className="dk-form-fill-group-cols col-lg-6 col-sm-12">
                                                <label for="">Last Name *</label>
                                                <label for="">
                                                    <input type="text" name="" className="form-control" id="" value="" />
                                                </label>
                                            </div>
                                            <div className="dk-form-fill-group-cols col-lg-6 col-sm-12">
                                                <label for="">Email *</label>
                                                <label for="">
                                                    <input type="text" name="" className="form-control" id="" value="" />
                                                </label>
                                            </div>
                                            <div className="dk-form-fill-group-cols col-lg-6 col-sm-12">
                                                <label for="">Country *</label>
                                                <label for="">
                                                    <input type="text" name="" className="form-control" id="" value="" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group d-flex">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="dk-form-fill-cols p-0">
                                                <label for="">Request Details *</label>
                                                <label for="">
                                                    <textarea name="" id="" className="form-control"></textarea>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group d-flex justify-content-center">
                                    <button type="button" className="btn-default btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccesyourData
