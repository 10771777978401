import React, { useEffect, useState } from 'react'
import SubmitButton from '../../../../../../../ui/submitButton';
import { clearState, updateAbout } from '../../../../../../../../slice/account/employer/profile/profileSlice'
import { showSuccess, showError, clearMessage } from '../../../../../../../../slice/utils/message/messageSlice';
import { useDispatch, useSelector } from "react-redux";

const About = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.employerAuth);
    const { details, isLoading, isSuccess, isError, message } = useSelector(state => state.employerProfile);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [businessName, setBusinessName] = useState('');
    const [businessFounded, setBusinessFounded] = useState('');
    const [businessLocation, setBusinessLocation] = useState('');
    const [businessIndustry, setBusinessIndustry] = useState('');
    const [businessHeadquarters, setBusinessHeadquarters] = useState('');
    const [businessType, setBusinessType] = useState('');
    const [businessAreaServed, setBusinessAreaServed] = useState('');
    const [businessWebPage, setBusinessWebPage] = useState('');
    const [aboutUs, setAboutUs] = useState('');

    useEffect(() => {
        if (!isLoading) {
            setBusinessName(details.businessName)
            setBusinessFounded(details.businessFounded)
            setBusinessLocation(details.businessLocation)
            setBusinessIndustry(details.businessIndustry)
            setBusinessHeadquarters(details.businessHeadquarters)
            setBusinessType(details.businessType)
            setBusinessAreaServed(details.businessAreaServed)
            setBusinessWebPage(details.businessWebPage)
            setAboutUs(details.aboutUs)
        }
        if (isSuccess) {
            setSuccess(true);
            setLoading(false);
            dispatch(showSuccess({ msg: 'Company Details Updated successfully' }))
            dispatch(clearState())
        }
    }, [isLoading, isSuccess])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            dispatch(clearMessage())
            setSuccess(false);
            setLoading(true);
            await dispatch(updateAbout({ uid: userInfo.employerUid, businessName, businessFounded, businessLocation, businessIndustry, businessHeadquarters, businessType, businessAreaServed, businessWebPage, aboutUs }))
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again. ' + err }))
        }
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-6 dk-aboutsFromInputGrp">
                    <div className="form-group">
                        <label for="">Name of Company</label>
                        <input type="text" className="form-control" value={businessName} onChange={(e) => setBusinessName(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label for="">Founded</label>
                        <input type="text" className="form-control" value={businessFounded} onChange={(e) => setBusinessFounded(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label for="">Location</label>
                        <input type="text" className="form-control" value={businessLocation} onChange={(e) => setBusinessLocation(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label for="">Industry</label>
                        <input type="text" className="form-control" value={businessIndustry} onChange={(e) => setBusinessIndustry(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label for="">Headquarters</label>
                        <input type="text" className="form-control" value={businessHeadquarters} onChange={(e) => setBusinessHeadquarters(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label for="">Company Type</label>
                        <input type="text" className="form-control" value={businessType} onChange={(e) => setBusinessType(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label for="">Area Served</label>
                        <input type="text" className="form-control" value={businessAreaServed} onChange={(e) => setBusinessAreaServed(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label for="">Web Address</label>
                        <input type="text" className="form-control" value={businessWebPage} onChange={(e) => setBusinessWebPage(e.target.value)} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="dk-aboutUsRightPanel">
                        <div className="dk-ownerForm-note mb-3">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deleniti dolor modi, illo unde deserunt cupiditate.</div>
                        <div className="dk-textAreaCon">
                            <label className="title">About us text</label>
                            <label>
                                <textarea value={aboutUs} onChange={(e) => setAboutUs(e.target.value)}></textarea>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="dk-updateBtnCon">
                        <SubmitButton txt="Update Profile" loading={loading} success={success} onClick={handleSubmit} position="" className="dk-updateBtn" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default About;