import React from 'react';
import ExperinceAccord from './ExperinceAccord'

const Experince = () => {
    return (
        <>
            <div className="dk-experinceAccordion">
                <ExperinceAccord />
            </div>
        </>
    )
}

export default Experince;
