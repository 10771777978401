import React from 'react'
import { Link } from 'react-router-dom'
import ResumeImg from '../../../../../../../../assets/images/resume_img.png';

const Skills = () => {
    return (
        <>
            <div className="row dk-skillsMainCon">
                <div className="col-md-8 dk-skillsLeftCon">
                    <div className="headingCon">
                        <h3>Language skills</h3>
                    </div>
                    <form action="">
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Employer/Name of the company</label>
                            </div>
                            <div className="rightCols">
                                <label for="" className="d-flex">
                                    <select name="" className="form-control dk-skillsSelectPicker mr-2" id="">
                                        <option>Arabic</option>
                                        <option>Polish</option>
                                        <option>Portuguese</option>
                                        <option>Roma language</option>
                                        <option>Russian</option>
                                        <option>Serbian</option>
                                        <option>Slovak</option>
                                        <option>Slovenian</option>
                                        <option>Spanish</option>
                                    </select>
                                    <select name="" className="form-control dk-skillsSelectPicker mr-2" id="">
                                        <option>Beginner (A1)</option>
                                        <option>Elementary (A2)</option>
                                        <option>Intermediate (B1)</option>
                                        <option>Upper intermediate (B2)</option>
                                        <option>Advanced (C1)</option>
                                        <option>Proficiency (C2)</option>
                                    </select>
                                    <div className="dk-deleteAddBtnCon">
                                        <Link to="#" className="dk-deleteBtn">
                                            <i className="fas fa-trash dk-skillsSelectPicker-alt"></i>
                                        </Link>
                                        <Link to="#" className="dk-addBtn">
                                            <i className="fas fa-plus" dk-skillsSelectPicker></i>
                                        </Link>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-4 dk-skillsRightCon">
                    <div className="dk-resumeImg-cols">
                        <Link to="#">
                            <div className="title">Preview</div>
                            <div className="resumeimgCon">
                                <img src={ResumeImg} alt="Resume" />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Skills
