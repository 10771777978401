import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';

const FrontJobseekersFilter = () => {
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [open7, setOpen7] = useState(false);
    const [open8, setOpen8] = useState(false);
    const [open9, setOpen9] = useState(false);
    const [open10, setOpen10] = useState(false);
    const setOpenTab = (id) => {
        setOpen(false)
        setOpen2(false)
        setOpen3(false)
        setOpen4(false)
        setOpen5(false)
        setOpen6(false)
        setOpen7(false)
        setOpen8(false)
        setOpen9(false)
        setOpen10(false)
        switch (id) {
            case 1:
                setOpen(!open)
                break;

            case 2:
                setOpen2(!open2)
                break;

            case 3:
                setOpen3(!open3)
                break;

            case 4:
                setOpen4(!open4)
                break;

            case 5:
                setOpen5(!open5)
                break;

            case 6:
                setOpen6(!open6)
                break;

            case 7:
                setOpen7(!open7)
                break;

            case 8:
                setOpen8(!open8)
                break;

            case 9:
                setOpen9(!open9)
                break;

            case 10:
                setOpen10(!open10)
                break;
        }
    }
    return (
        <>
            <div className="dk-frontJobseekersFilter-main">
                <div className="dk-frontJobseekersFilter-nav">
                    <label htmlFor="">
                        <input type="text" placeholder="Search..." name="" id="" className="form-control" />
                    </label>
                    <Button
                        onClick={() => setOpenTab(1)}
                        aria-expanded={open}
                        className={(open) ? 'active' : null}
                    >
                        <i class="far fa-calendar-alt"></i>
                        Posted On
                    </Button>
                    <Button
                        onClick={() => setOpenTab(2)}
                        aria-expanded={open2}
                        className={(open2) ? 'active' : null}
                    >
                        <i class="fas fa-briefcase"></i>
                        Job Type
                    </Button>
                    <Button
                        onClick={() => setOpenTab(3)}
                        aria-expanded={open3}
                        className={(open3) ? 'active' : null}
                    >
                        <i class="fas fa-industry"></i>
                        Industry
                    </Button>
                    <Button
                        onClick={() => setOpenTab(4)}
                        aria-expanded={open4}
                        className={(open4) ? 'active' : null}
                    >
                        <i class="fas fa-user"></i>
                        Job Title
                    </Button>
                    <Button
                        onClick={() => setOpenTab(5)}
                        aria-expanded={open5}
                        className={(open5) ? 'active' : null}
                    >
                        <i class="fas fa-euro-sign"></i>
                        Salary
                    </Button>
                    <Button
                        onClick={() => setOpenTab(6)}
                        aria-expanded={open6}
                        className={(open6) ? 'active' : null}
                    >
                        <i class="fas fa-globe-americas"></i>
                        Country
                    </Button>
                    <Button
                        onClick={() => setOpenTab(7)}
                        aria-expanded={open7}
                        className={(open7) ? 'active' : null}
                    >
                        <i class="fas fa-city"></i>
                        City
                    </Button>
                    <Button
                        onClick={() => setOpenTab(8)}
                        aria-expanded={open8}
                        className={(open8) ? 'active' : null}
                    >
                        <i class="fas fa-language"></i>
                        Language
                    </Button>
                    <Button
                        onClick={() => setOpenTab(9)}
                        aria-expanded={open9}
                        className={(open9) ? 'active' : null}
                    >
                        <i class="far fa-building"></i>
                        Company
                    </Button>
                    <Button
                        onClick={() => setOpenTab(10)}
                        aria-expanded={open10}
                        className={(open10) ? 'active' : null}
                    >
                        <i class="far fa-address-card"></i>
                        Work Permit
                    </Button>
                </div>

                <div className="dk-frontJobseekersFilter-panel">
                    <Collapse in={open}>
                        <div className="dk-frontJobSeekers-panelBody">
                            <div className="d-flex justify-content-around">
                                <ul>
                                    <li>
                                        <Link to="#">Saurabh Yadav <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Elena Maria <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Maria Ciocas <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Adelina Coada <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Bianca Gherasim <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Philip Gichev <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Deepak Kumar <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Nishant <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Mariana Cont <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </Collapse>

                    <Collapse in={open2}>
                        <div className="dk-frontJobSeekers-panelBody">
                            <div className="d-flex justify-content-around">
                                <ul>
                                    <li>
                                        <Link to="#">Saurabh Yadav <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Elena Maria <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Maria Ciocas <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Adelina Coada <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Bianca Gherasim <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Philip Gichev <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Deepak Kumar <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Nishant <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Mariana Cont <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </Collapse>
                    <Collapse in={open3}>
                        <div className="dk-frontJobSeekers-panelBody">
                            <div className="d-flex justify-content-around">
                                <ul>
                                    <li>
                                        <Link to="#">Saurabh Yadav <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Elena Maria <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Maria Ciocas <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Adelina Coada <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Bianca Gherasim <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Philip Gichev <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Deepak Kumar <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Nishant <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Mariana Cont <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </Collapse>
                    <Collapse in={open4}>
                        <div className="dk-frontJobSeekers-panelBody">
                            <div className="d-flex justify-content-around">
                                <ul>
                                    <li>
                                        <Link to="#">Saurabh Yadav <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Elena Maria <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Maria Ciocas <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Adelina Coada <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Bianca Gherasim <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Philip Gichev <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Deepak Kumar <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Nishant <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Mariana Cont <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </Collapse>
                    <Collapse in={open5}>
                        <div className="dk-frontJobSeekers-panelBody">
                            <div className="d-flex justify-content-around">
                                <ul>
                                    <li>
                                        <Link to="#">Saurabh Yadav <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Elena Maria <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Maria Ciocas <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Adelina Coada <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Bianca Gherasim <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Philip Gichev <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Deepak Kumar <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Nishant <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Mariana Cont <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </Collapse>
                    <Collapse in={open6}>
                        <div className="dk-frontJobSeekers-panelBody">
                            <div className="d-flex justify-content-around">
                                <ul>
                                    <li>
                                        <Link to="#">Saurabh Yadav <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Elena Maria <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Maria Ciocas <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Adelina Coada <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Bianca Gherasim <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Philip Gichev <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Deepak Kumar <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Nishant <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Mariana Cont <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </Collapse>
                    <Collapse in={open7}>
                        <div className="dk-frontJobSeekers-panelBody">
                            <div className="d-flex justify-content-around">
                                <ul>
                                    <li>
                                        <Link to="#">Saurabh Yadav <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Elena Maria <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Maria Ciocas <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Adelina Coada <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Bianca Gherasim <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Philip Gichev <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Deepak Kumar <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Nishant <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Mariana Cont <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </Collapse>
                    <Collapse in={open8}>
                        <div className="dk-frontJobSeekers-panelBody">
                            <div className="d-flex justify-content-around">
                                <ul>
                                    <li>
                                        <Link to="#">Saurabh Yadav <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Elena Maria <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Maria Ciocas <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Adelina Coada <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Bianca Gherasim <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Philip Gichev <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Deepak Kumar <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Nishant <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Mariana Cont <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </Collapse>
                    <Collapse in={open9}>
                        <div className="dk-frontJobSeekers-panelBody">
                            <div className="d-flex justify-content-around">
                                <ul>
                                    <li>
                                        <Link to="#">Saurabh Yadav <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Elena Maria <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Maria Ciocas <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Adelina Coada <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Bianca Gherasim <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Philip Gichev <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Deepak Kumar <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Nishant <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Mariana Cont <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </Collapse>
                    <Collapse in={open10}>
                        <div className="dk-frontJobSeekers-panelBody">
                            <div className="d-flex justify-content-around">
                                <ul>
                                    <li>
                                        <Link to="#">Saurabh Yadav <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Elena Maria <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Maria Ciocas <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Adelina Coada <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Bianca Gherasim <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Philip Gichev <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Deepak Kumar <span className="text-orange">1</span></Link>
                                    </li>
                                    <li>
                                        <Link to="#">Nishant <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="#">Mariana Cont <span className="text-orange">1</span></Link>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </Collapse>
                </div>
            </div>
        </>
    )
}

export default FrontJobseekersFilter;
