import React, { useState } from 'react';
import { Route, Link } from 'react-router-dom';
import JobFaqModal from "./JobFaqModal";
import JobsFilter from "./JobsFilter";
import './searchjobs.scss';
import JobsCategory from './JobsCategory';
import JobsCompanyLogo from './JobsCompanyLogo';

const SearchByLanguage = () => {
    return (
        <React.Fragment>
            <div className="dk-searchBox-main">
                <div className="dk-jobseekerSt-searchMain">
                    <section>
                        <div className="container dk-headIngfindtext">
                            <div className="col-md-12 d-flex justify-content-center align-items-center">
                                <h2 className="section-title text-uppercase">Find Your Next Job Here</h2>
                                <div className="dk-faqCols">
                                    <JobFaqModal />
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-3 custom-w">
                                        <JobsFilter />
                                    </div>
                                    <div className="col-md-7 custom-w">
                                        <div className="dk-joblistingMain">
                                            <JobsCategory />
                                            <div className="dk-jobslisting-tagselect">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="dk-jobs-list">1-50 of 11326 Jobs</div>

                                                    <div className="dk-jobs-cate-link">
                                                        <Route>
                                                            <Link to="#" className="box">Last 24 hours <i class="fas fa-times"></i></Link>
                                                            <Link to="#" className="box">Full Time <i class="fas fa-times"></i></Link>
                                                            <Link to="#">Clear all</Link>
                                                        </Route>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="dk-boxes-cols">
                                                <div className="dk-searchByHead">
                                                    <div className="dk-bacjBtn">
                                                        <Link to="#"><i class="fa fa-arrow-left"></i> Back to Job List</Link>
                                                    </div>
                                                    <div className="title"><i class="fa fa-briefcase mr-2"></i> Job Search By Language</div>
                                                </div>
                                                <div className="dk-searchByPanel">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <ul>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <ul>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <ul>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="form-check">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" />
                                                                            City Name 1
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-2 custom-w">
                                        <JobsCompanyLogo />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </React.Fragment>
    )
}
export default SearchByLanguage;
