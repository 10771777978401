import React from 'react'
import { Link } from 'react-router-dom'
import ResumeImg from '../../../../../../../../assets/images/resume_img.png';

const Education = () => {
    return (
        <>
            <div className="row dk-educationCon">
                <div className="col-md-8 dk-educationLeftCon">
                    <form action="">
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Highest level of education attained*</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <select name="" id="" className="form-control">
                                        <option value="" className="emptyoption">Choose Education</option>
                                        <option value="">Primary education</option>
                                        <option value="">Secondary school student</option>
                                        <option value="">Secondary without school-leaving examination</option>
                                        <option value="">Secondary with school-leaving examination</option>
                                        <option value="">Follow-up/Higher Professional Education</option>
                                        <option value="">University student</option>
                                        <option value="">University education (Bachelor's degree)</option>
                                        <option value="">University education (Master's degree)</option>
                                        <option value="">Postgraduate (Doctorate)</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <h3>Personal details</h3>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">University/Faculty</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">City</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Field of study</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Year of entry</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Year of finishing</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <h3 className="mt-5">Secondary School</h3>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">School</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">City</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Field of study</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Year of entry</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="date" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Year of finishing</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="date" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <h3 className="mt-5">Courses and Training</h3>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Name of course/training</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Certificate title</label>
                            </div>
                            <div className="rightCols w-20">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Institution</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="text" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">From</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="date" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Till</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <input type="date" name="" id="" className="form-control" />
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group">
                            <div className="leftCols">
                                <label for="" className="textCols">Additional information on education</label>
                            </div>
                            <div className="rightCols">
                                <label for="">
                                    <textarea name="" id="" class="form-control dk-eductionteatArea"></textarea>
                                </label>
                            </div>
                        </div>
                        <div className="d-flex align-items-center form-group mt-4">
                            <div className="leftCols">
                                <Link className="dk-backBtn" to="#/jobseeker/standard/myresume/personal_details">
                                    <i className="fa fa-arrow-circle-left"></i> Back (1/6)
                                </Link>
                            </div>
                            <div className="rightCols">
                                <Link to="#/jobseeker/standard/myresume/personal_details">Next(3/6)
                                    <i className="fa fa-arrow-circle-right ml-2"></i>
                                </Link>
                                <Link to="#/jobseeker/standard/myresume/personal_details">Save All Changes
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-4 dk-educationRightCon">
                    <div className="dk-resumeImg-cols">
                        <Link to="#">
                            <div className="title">Preview</div>
                            <div className="resumeimgCon">
                                <img src={ResumeImg} alt="Resume" />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Education
