import React from 'react';
import { Link } from 'react-router-dom'
import './blogs.scss';
import Blogsimg from '../../../assets/images/blogs.jpg';
import BlogTeamCarousel from './BlogTeamCarousel';


const Blogs = () => {
    return (
        <>
            <div className="container-fluid dk-blogspageMainCon">
                <div className="row">
                    <div className="col-md-12">
                        <div className="pageHeadeTitle">
                            <h2 className="section-title pb-0">Our Blogs &amp; Articales</h2>
                        </div>
                    </div>
                    <div className="dk-blog-search-main">
                        <div className="row">
                            <div className="d-md-flex dk-blog-searchCon">
                                <div className="dk-blog-search">
                                    <input type="text" name="" id="" placeholder="Search here..." />
                                    <button type="button" className="btn-default btn">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                                <div className="dk-blog-search-filterbtn d-flex ml-auto">
                                    <div className="w-100">
                                        <select className="browser-default custom-select">
                                            <option>Timeline</option><option value="1">Option 1</option>
                                            <option value="2">Option 2</option><option value="3">Option 3</option>
                                        </select>
                                    </div>
                                    <div className="w-100 ml-2 mr-2">
                                        <select className="browser-default custom-select">
                                            <option>Type</option><option value="1">Option 1</option>
                                            <option value="2">Option 2</option>
                                            <option value="3">Option 3</option>
                                        </select>
                                    </div>
                                    <div className="w-100 mr-2">
                                        <select className="browser-default custom-select">
                                            <option>Category</option>
                                            <option value="1">Option 1</option>
                                            <option value="2">Option 2</option>
                                            <option value="3">Option 3</option>
                                        </select>
                                    </div>
                                    <div className="w-100">
                                        <select className="browser-default custom-select">
                                            <option>Writter</option><option value="1">Option 1</option>
                                            <option value="2">Option 2</option>
                                            <option value="3">Option 3</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="dk-topic-div ml-auto topic-btn-div">
                                    <div className="dk-article-modal-main"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dk-search-blog-div">
                        <div className="col-sm-11 m-auto">
                            <div className="row">
                                <div className="col-sm-12 col-md-6 mb-2rem pl-4 pr-4 list-blog">
                                    <Link to="/article/details">
                                        <div className="card blog-thumbnail">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-4 pr-0">
                                                    <div className="view" data-test="view">
                                                        <div className="Ripple-parent">
                                                            <img src={Blogsimg} className="img-fluid blog-post-img-tumbnail" />
                                                            <div className="mask rgba-white-slight"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-8 pl-0">
                                                    <div className="card-body">
                                                        <h3>British military courts use aginst busines cultural ...</h3>
                                                        <p className="card-text">This small Line of Text Here...</p>
                                                    </div>
                                                    <div className="card-footer">
                                                        <div className="row">
                                                            <div className="col-sm-2 text-center">
                                                                <img
                                                                    src={Blogsimg}
                                                                    alt=""
                                                                    className="posted_img"
                                                                    data-popper="popper"
                                                                />
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-thumbs-up fa-2x"></i>624
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-eye fa-2x"></i>1024
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-comment fa-2x"></i>1024
                                                            </div>
                                                            <div className="col-sm-4 text-center text-333">
                                                                <i className="far fa-calendar-alt fa-2x"></i>Jan 28, 2020
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-12 col-md-6 mb-2rem pl-4 pr-4 list-blog">
                                    <Link to="/article/details">
                                        <div className="card blog-thumbnail">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-4 pr-0">
                                                    <div className="view">
                                                        <div className="Ripple-parent">
                                                            <img src={Blogsimg}
                                                                className="img-fluid blog-post-img-tumbnail" />
                                                            <div className="mask rgba-white-slight"></div>
                                                            <div className="Ripple is-reppling"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-8 pl-0">
                                                    <div className="card-body">
                                                        <h3>British military courts use aginst busines cultural ...</h3>
                                                        <p className="card-text">This small Line of Text Here...</p>
                                                    </div>
                                                    <div className="card-footer">
                                                        <div className="row">
                                                            <div className="col-sm-2 text-center">
                                                                <img
                                                                    src={Blogsimg}
                                                                    alt=""
                                                                    className="posted_img"
                                                                    data-popper="popper"
                                                                />
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-thumbs-up fa-2x"></i>624</div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-eye fa-2x"></i>1024
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-comment fa-2x"></i>1024
                                                            </div>
                                                            <div className="col-sm-4 text-center text-333">
                                                                <i className="far fa-calendar-alt fa-2x"></i>Jan 28, 2020
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-12 col-md-6 mb-2rem pl-4 pr-4 list-blog">
                                    <Link href="#/article/details">
                                        <div className="card blog-thumbnail">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-4 pr-0">
                                                    <div className="view" >
                                                        <div >
                                                            <img src={Blogsimg} className="img-fluid blog-post-img-tumbnail" />
                                                            <div className="mask rgba-white-slight"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-8 pl-0">
                                                    <div className="card-body">
                                                        <h3>British military courts use aginst busines cultural ...</h3>
                                                        <p className="card-text">This small Line of Text Here...</p>
                                                    </div>
                                                    <div className="card-footer">
                                                        <div className="row">
                                                            <div className="col-sm-2 text-center">
                                                                <img
                                                                    src={Blogsimg}
                                                                    alt=""
                                                                    className="posted_img"
                                                                    data-popper="popper"
                                                                />
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-thumbs-up fa-2x"></i>624
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-eye fa-2x"></i>1024
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-comment fa-2x"></i>1024
                                                            </div>
                                                            <div className="col-sm-4 text-center text-333">
                                                                <i className="far fa-calendar-alt fa-2x"></i>Jan 28, 2020
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-12 col-md-6 mb-2rem pl-4 pr-4 list-blog">
                                    <Link href="#/article/details">
                                        <div className="card blog-thumbnail">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-4 pr-0">
                                                    <div className="view">
                                                        <div>
                                                            <img src={Blogsimg} className="img-fluid blog-post-img-tumbnail" />
                                                            <div className="mask rgba-white-slight"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-8 pl-0">
                                                    <div className="card-body">
                                                        <h3>British military courts use aginst busines cultural ...</h3>
                                                        <p className="card-text">This small Line of Text Here...</p>
                                                    </div>
                                                    <div className="card-footer">
                                                        <div data-test="row" className="row">
                                                            <div className="col-sm-2 text-center">
                                                                <img
                                                                    src={Blogsimg}
                                                                    alt=""
                                                                    className="posted_img"
                                                                    data-popper="popper"
                                                                />
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-thumbs-up fa-2x"></i>624</div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-eye fa-2x"></i>1024
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-comment fa-2x"></i>1024
                                                            </div>
                                                            <div className="col-sm-4 text-center text-333">
                                                                <i className="far fa-calendar-alt fa-2x"></i>Jan 28, 2020
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-12 col-md-6 mb-2rem pl-4 pr-4 list-blog">
                                    <Link href="#/article/details">
                                        <div className="card blog-thumbnail">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-4 pr-0">
                                                    <div className="view">
                                                        <div>
                                                            <img src={Blogsimg} className="img-fluid blog-post-img-tumbnail" />
                                                            <div className="mask rgba-white-slight"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-8 pl-0">
                                                    <div className="card-body">
                                                        <h3>British military courts use aginst busines cultural ...</h3>
                                                        <p className="card-text">This small Line of Text Here...</p>
                                                    </div>
                                                    <div className="card-footer">
                                                        <div className="row">
                                                            <div className="col-sm-2 text-center">
                                                                <img
                                                                    src={Blogsimg}
                                                                    alt=""
                                                                    className="posted_img"
                                                                    data-popper="popper"
                                                                />
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333"><i data-test="fa" className="far fa-thumbs-up fa-2x"></i>624</div>
                                                            <div className="col-sm-2 text-center text-333"><i data-test="fa" className="far fa-eye fa-2x"></i>1024</div>
                                                            <div className="col-sm-2 text-center text-333"><i data-test="fa" className="far fa-comment fa-2x"></i>1024</div>
                                                            <div className="col-sm-4 text-center text-333"><i data-test="fa" className="far fa-calendar-alt fa-2x"></i>Jan 28, 2020</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-12 col-md-6 mb-2rem pl-4 pr-4 list-blog">
                                    <Link to="/article/details">
                                        <div data-test="card" className="card blog-thumbnail">
                                            <div data-test="row" className="row">
                                                <div className="col-sm-12 col-md-4 pr-0">
                                                    <div className="view" data-test="view">
                                                        <div className="Ripple-parent">
                                                            <img src={Blogsimg} className="img-fluid blog-post-img-tumbnail" />
                                                            <div className="mask rgba-white-slight"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-8 pl-0">
                                                    <div className="card-body">
                                                        <h3>British military courts use aginst busines cultural ...</h3>
                                                        <p className="card-text">This small Line of Text Here...</p>
                                                    </div>
                                                    <div className="card-footer">
                                                        <div className="row">
                                                            <div className="col-sm-2 text-center">
                                                                <img
                                                                    src={Blogsimg}
                                                                    alt=""
                                                                    className="posted_img"
                                                                    data-popper="popper"
                                                                />
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-thumbs-up fa-2x"></i>624
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-eye fa-2x"></i>1024
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-comment fa-2x"></i>1024
                                                            </div>
                                                            <div className="col-sm-4 text-center text-333">
                                                                <i className="far fa-calendar-alt fa-2x"></i>Jan 28, 2020
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-12 col-md-6 mb-2rem pl-4 pr-4 list-blog">
                                    <Link to="/article/details">
                                        <div className="card blog-thumbnail">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-4 pr-0">
                                                    <div className="view">
                                                        <div>
                                                            <img src={Blogsimg} className="img-fluid blog-post-img-tumbnail" />
                                                            <div className="mask rgba-white-slight"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-8 pl-0">
                                                    <div className="card-body">
                                                        <h3>British military courts use aginst busines cultural ...</h3>
                                                        <p className="card-text">This small Line of Text Here...</p>
                                                    </div>
                                                    <div className="card-footer">
                                                        <div className="row">
                                                            <div className="col-sm-2 text-center">
                                                                <img
                                                                    src={Blogsimg}
                                                                    alt=""
                                                                    className="posted_img"
                                                                    data-popper="popper"
                                                                />
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-thumbs-up fa-2x"></i>624
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-eye fa-2x"></i>1024
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-comment fa-2x"></i>1024
                                                            </div>
                                                            <div className="col-sm-4 text-center text-333">
                                                                <i className="far fa-calendar-alt fa-2x"></i>Jan 28, 2020
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-12 col-md-6 mb-2rem pl-4 pr-4 list-blog">
                                    <Link to="/article/details">
                                        <div className="card blog-thumbnail">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-4 pr-0">
                                                    <div className="view">
                                                        <div>
                                                            <img src={Blogsimg} className="img-fluid blog-post-img-tumbnail" />
                                                            <div className="mask rgba-white-slight"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-8 pl-0">
                                                    <div className="card-body">
                                                        <h3>British military courts use aginst busines cultural ...</h3>
                                                        <p className="card-text">This small Line of Text Here...</p>
                                                    </div>
                                                    <div className="card-footer">
                                                        <div data-test="row" className="row">
                                                            <div className="col-sm-2 text-center">
                                                                <img
                                                                    src={Blogsimg}
                                                                    alt=""
                                                                    className="posted_img"
                                                                    data-popper="popper"
                                                                />
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-thumbs-up fa-2x"></i>624
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-eye fa-2x"></i>1024
                                                            </div>
                                                            <div className="col-sm-2 text-center text-333">
                                                                <i className="far fa-comment fa-2x"></i>1024
                                                            </div>
                                                            <div className="col-sm-4 text-center text-333">
                                                                <i className="far fa-calendar-alt fa-2x"></i>Jan 28, 2020
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="dk-emplistPageination text-center mt-4 pb-4">
                                <ul className="pagination-circle pagination justify-content-center">
                                    <li className="disabled page-item">
                                        <Link aria-label="Previous" className="page-link page-link">
                                            <span aria-hidden="true">«</span>
                                            <span className="sr-only">Previous</span>
                                        </Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link page-link">1 <span className="sr-only">(current)</span></Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link page-link">2</Link>
                                    </li>
                                    <li className="active page-item">
                                        <Link className="page-link page-link">3</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link page-link">4</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link page-link">5</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link page-link">»</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="dk-blog-team-main">
                                <div className="dk-team-header">
                                    <h1 className="text-center">Meet our Bloggers</h1>
                                    <p className="text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, modi aperiam! Dolore accusamus.</p>
                                </div>
                                <div className="dk-blog-team-carousel mt-5">
                                    {/* <BlogTeamCarousel /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blogs
