import React from 'react'
import './billingaddress.scss';
const BillingAddress = () => {
    return (
        <>
            <div className="dk-billingAddressMain">
                <div className="row">
                    <div className="col-md-4 mt-3">
                        <div className="dk-ownerForm-note">Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                            Deleniti dolor modi, illo unde deserunt cupiditate.
                        </div>
                    </div>
                    <div className="col-md-8">
                        <h3>INVOICE BILLING ADDRESS</h3>
                        <div className="dk-billingAddress-fluid mt-3">
                            <div className="dk-contactPerson-form">
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="">Name of Business or Person</label>
                                                <label for="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="">Email Address</label>
                                                <label for="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="">Company Registration No. Iif Available)</label>
                                                <label for="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="">VAT No. (If Available)</label>
                                                <label for="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="">Address</label>
                                                <label for="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="">City/Town</label>
                                                <label for="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="">State/Province</label>
                                                <label for="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="">Postcode</label>
                                                <label for="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="">Country</label>
                                                <label for="">
                                                    <input type="text" name="" id="" className="form-control" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group dk-saveChanges text-center mt-3">
                                                <button type="button" className="btn-default btn Ripple-parent">
                                                    Save Changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BillingAddress
