import React, { useState, useEffect } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import './delete.scss';
import Submit from "../submitButton";

const Delete = ({ id, loading, success, deleteItem, onHide }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (id != undefined) {
            setShow(true)
        } else {
            setShow(false)
        }
    }, [id])

    return (
        <React.Fragment>
            <Modal className="dk-deleteQueryMain" show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="dk-modal-title-color">Please confirm to continue.</h5>
                    <div className="d-flex justify-content-center">
                        <Submit txt="Continue" loading={loading} success={success} onClick={deleteItem} position="" className="" />
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default Delete;