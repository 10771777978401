import React from "react";
import Pagination from '@material-ui/lab/Pagination';
import './style.css';

const TablePagination = ({ count, onChange }) => {
    return (
        <React.Fragment>
            <Pagination count={count} shape="rounded" onChange={onChange} />
        </React.Fragment>
    )
}
export default TablePagination;