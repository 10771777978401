import React from 'react';
import { Link } from 'react-router-dom'

const SeeAllBox = (props) => {
    return (
        <>
            <li className="dk-empagenceBox">
                <div className="flip_lastbox">
                    <Link to="#">
                        {props.alltitle}
                    </Link>
                </div>
            </li>
        </>
    )
}

export default SeeAllBox;
