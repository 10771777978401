import React, { useEffect, useCallback, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import './ownerprofile.scss';
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from '../../../../../../ui/submitButton';
import AWS from 'aws-sdk';
import { clearState, profileDetails, updateProfile } from '../../../../../../../slice/account/employer/profile/userProfile/userProfileSlice'
import Modal from 'react-bootstrap/Modal'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { showSuccess, showError, clearMessage } from '../../../../../../../slice/utils/message/messageSlice';
import Constants from '../../../../../../constants';
import { StylesContext } from '@material-ui/styles';

const OwnerProfile = () => {
    const dispatch = useDispatch();

    const { userInfo } = useSelector(state => state.employerAuth);
    const { details, isLoading, isSuccess, isError, message } = useSelector(state => state.employerUserProfile);

    const [isImageUpdate, setIsImageUpdate] = useState(false)
    const [newImageData, setNewImageData] = useState('')
    const [profileImage, setProfileImage] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [country, setCountry] = useState('')
    const [city, setCity] = useState('')
    const [zip, setZip] = useState('')
    const [address, setAddress] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const fileInput = useRef();
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState(
        {
            unit: 'px',
            width: 150,
            height: 150,
            aspect: 1 / 1
        }
    );
    const [completedCrop, setCompletedCrop] = useState(null);
    const [cropModal, setCropModal] = useState(false)

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
            setCropModal(true)
        }
    }

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
        const base64Image = canvas.toDataURL("image/jpeg");

        setIsImageUpdate(true)
        setNewImageData(base64Image)
    }, [completedCrop]);

    const handleClose = () => {
        setCropModal(false);
        fileInput.current.value = ''
    }

    const handleCropDone = () => {
        setCropModal(false);
    }

    const cancelCrop = () => {
        setCropModal(false);
        setNewImageData('')
        setIsImageUpdate(false)
        setCompletedCrop(null)
    }

    useEffect(() => {
        document.title = "Employer User Profile | DK Global Jobs"
        dispatch(clearState())
        async function fetchData() {
            try {
                dispatch(profileDetails({ uid: userInfo.uid }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [dispatch])

    useEffect(() => {
        if (!isLoading) {
            setProfileImage(details.profileImage)
            setFirstName(details.firstName)
            setLastName(details.lastName)
            setCountry(details.country)
            setCity(details.city)
            setZip(details.zip)
            setAddress(details.address)
            setEmail(details.email)
            setPhone(details.phone)
        }
        if (isSuccess) {
            setSuccess(true);
            setLoading(false);
            dispatch(showSuccess({ msg: 'Profile Updated successfully' }))
            dispatch(clearState())
        }
    }, [isLoading, isSuccess])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            dispatch(clearMessage())
            setSuccess(false);
            setLoading(true);
            if (isImageUpdate) {
                let s3bucket = new AWS.S3({
                    accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
                    secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
                    Bucket: process.env.REACT_APP_BUCKET_NAME + '/users/employer/images'
                });
                let buf = new Buffer(newImageData.replace(/^data:image\/\w+;base64,/, ""), 'base64')
                s3bucket.createBucket(function () {
                    var params = {
                        Bucket: process.env.REACT_APP_BUCKET_NAME + '/users/employer/images',
                        Key: userInfo.uid + '-' + Date.now() + '.png',
                        ContentEncoding: 'base64',
                        ContentType: 'image/png',
                        Body: buf
                    };
                    s3bucket.upload(params, function (err, data) {
                        if (err) {
                            console.log(err)
                        } else {
                            setProfileImage("/" + data.key)
                            dispatch(updateProfile({ uid: details.uid, firstName, lastName, country, city, zip, address, phone, profileImage: "/" + data.key }))
                        }
                    });
                })
            } else {
                await dispatch(updateProfile({ uid: details.uid, firstName, lastName, country, city, zip, address, phone, profileImage }))
            }
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again. ' + err }))
        }
    }

    const clearForm = () => {
        setCompletedCrop(null)
        setIsImageUpdate(false)
        setNewImageData('')
        setProfileImage(details.profileImage)
        setFirstName(details.firstName)
        setLastName(details.lastName)
        setCountry(details.email)
        setCity(details.mobile)
        setZip(details.city)
        setAddress(details.zipCode)
        setPhone(details.state)
        fileInput.current.value = ''
    }

    return (
        <React.Fragment>
            {
                (cropModal) ?
                    <Modal className="dk-cropImgModal" show={true} onHide={handleClose}>
                        <Modal.Body>
                            <ReactCrop
                                src={upImg}
                                onImageLoaded={onLoad}
                                crop={crop}
                                onChange={(c) => setCrop(c)}
                                onComplete={(c) => setCompletedCrop(c)}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12">
                                <div class="d-flex justify-content-center btn-container">
                                    <button class="dk-cancelBtn" onClick={cancelCrop}>Cancel</button>
                                    <SubmitButton txt="Use This" loading={loading} success={success} onClick={handleCropDone} position="" className="dk-updateBtn" />
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal> : null
            }
            <div className="container-fluid dk-basicProfile">
                <div className="dk-basicProfileCon">
                    <div className="col-md-9 pl-0 pr-0 m-auto">
                        <div className="row ml-0 mr-0">
                            <div className="col-md-12 p-0 m-auto dk-boxsCon">
                                <h3>Employer Owner Profile</h3>
                                <form>
                                    <div className="row">
                                        <div className="d-md-flex align-items-center justify-content-between w-100 mb-4">
                                            <div className="col-md-6">
                                                <div className="form-group uplaod-image">
                                                    <div className="d-flex align-items-center">
                                                        <div className="dk-contactPerson-image">
                                                            {
                                                                (completedCrop) ?
                                                                    <canvas
                                                                        ref={previewCanvasRef}
                                                                    />
                                                                    : <img alt="Crop" id="cropImgId" className="profile-img" src={process.env.REACT_APP_IMAGE_BASE_URL + profileImage} />
                                                            }
                                                        </div>
                                                        <div className="dk-uploadBtnText">
                                                            <div className="dk-contactPerson-uploadBtn">
                                                                <input type="file" accept="image/*" name="jobseekerLogo" onChange={onSelectFile} ref={fileInput} />
                                                                <span>Upload Image</span>
                                                            </div>
                                                            <div className="dk-sbTitle text-center">
                                                                <Link to="#">Remove Photo</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="dk-informationCols">
                                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo officia illum repellat veniam porro.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="">First Name <span class="colorRequired">*</span></label>
                                                <label for="" className="d-flex">
                                                    <input type="text" className="form-control" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="">Last Name <span class="colorRequired">*</span></label>
                                                <label for="" className="d-flex">
                                                    <input type="text" className="form-control" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="">Country <span class="colorRequired">*</span></label>
                                                <label for="" className="d-flex">
                                                    <input type="text" className="form-control" value={country} onChange={(e) => setCountry(e.target.value)} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="">City <span class="colorRequired">*</span></label>
                                                <label for="" className="d-flex">
                                                    <input type="text" className="form-control" value={city} onChange={(e) => setCity(e.target.value)} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="">Zip/Postal Code <span class="colorRequired">*</span></label>
                                                <label for="" className="d-flex">
                                                    <input type="text" className="form-control" value={zip} onChange={(e) => setZip(e.target.value)} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="">Street Address <span class="colorRequired">*</span></label>
                                                <label for="" className="d-flex">
                                                    <input type="text" className="form-control" value={address} onChange={(e) => setAddress(e.target.value)} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="">Email <span class="colorRequired">*</span></label>
                                                <label for="" className="d-flex">
                                                    <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} readonly />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="">Phone Number <span class="colorRequired">*</span></label>
                                                <label for="" className="d-flex">
                                                    <input type="text" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div class="d-flex justify-content-center btn-container">
                                                <button class="dk-cancelBtn" onClick={clearForm}>Cancel</button>
                                                <SubmitButton txt="Update Profile" loading={loading} success={success} onClick={handleSubmit} position="" className="dk-updateBtn" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default OwnerProfile;