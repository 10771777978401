import React from 'react';
import Navbar from '../../pages/accounts/jobseeker/standard/elements/mainmenu/standard/StandardNavbar';

const Standard = ({ children }) => {
    return (
        <React.Fragment>
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default Standard;