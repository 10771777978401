import './App.css';
import { Switch } from "react-router-dom";
// Layouts
import MainRoute from "./components/hocs/MainRoute";
import JSStandardRoute from "./components/hocs/jobseeker/Standard";
import EmpStandardRoute from "./components/hocs/employer/Standard";
// Site Pages
import EmailNotVerify from './components/pages/signup/accountVerification/emailNotVerify';
import AccountVerify from './components/pages/signup/accountVerification';
import ContactUs from './components/pages/contactus/ContactUs'
import PrivacyContact from './components/pages/privacycontact/ContactPrivacy'
import AccessYourData from './components/pages/accesyourdata/AccesyourData'
import GDPRExplanied from './components/pages/gdprexplained/GdprExplained'
import PrivacyPolicy from './components/pages/privacypolicy/PrivacyPolicy'
import TermsUse from './components/pages/termsuse/TermsUse'
import ListCompany from './components/pages/employers/listcompany/ListCompany';
import CompanyJobs from './components/pages/companyjobs/CompanyJobs';
import FrontSubscription from './components/pages/frontsubscription/FrontSubscription';
import CompanyJobsAboutUs from './components/pages/companyjobs/CompanyJobsAboutUs';
import CompanyJobsContactUs from './components/pages/companyjobs/CompanyJobsContactUs';
import JobListing from './components/pages/jobs/joblisting/JobListing';
import JobSearchByIndustry from './components/pages/jobs/joblisting/JobSearchByIndustry';
import JobSearchByLevel from './components/pages/jobs/joblisting/JobSearchByLevel';
import JobSearchByProfile from './components/pages/jobs/joblisting/JobSearchByProfile';
import JobSearchByCountry from './components/pages/jobs/joblisting/JobSearchByCountry';
import JobSearchByCity from './components/pages/jobs/joblisting/JobSearchByCity';
import JobSearchByLanguage from './components/pages/jobs/joblisting/JobSearchByLanguage';
import JobSearchByCompany from './components/pages/jobs/joblisting/JobSearchByCompany';
import JobSearchByWorkPermit from './components/pages/jobs/joblisting/JobSearchByWorkPermit';
import JobDetails from './components/pages/jobdetails/JobDetails';
import FrontJobSeekers from './components/pages/frontjobseekers/FrontJobSeekers';
import FrontAboutUs from './components/pages/aboutus/FrontAboutUs';
import BlogDetails from './components/pages/blogs/BlogDetails';
import Blogs from './components/pages/blogs/Blogs';
import Home from './components/pages/home/Home';
// Signup Accounts 
import SingupMain from './components/pages/signup/SingupMain';
import JobseekerSignup from './components/pages/signup/jobseeker/JobseekerSignup';
import EmployerSignup from './components/pages/signup/employer/EmployerSignup';
import ThankyouSignup from './components/pages/thankyou/ThankyouSignup';
import Login from './components/pages/login/Login';
// Login Accounts
import JobseekerLogin from './components/pages/login/jobseeker/JobseekerLogin'
import JobseekerPremimum from './components/pages/login/jobseeker/JobseekerPremimum'
import EmployerLogin from './components/pages/login/employer/EmployerLogin'
import EmployerPremimum from './components/pages/login/employer/EmployerPremimum'
// Forget Account Password
import ForgetPasswordJobseeker from "./components/pages/forgetPassword/jobseeker"
import ForgetPasswordEmployer from "./components/pages/forgetPassword/employer"
import verifyOTP from "./components/pages/forgetPassword/verifyOTP"
import ResetPassword from "./components/pages/forgetPassword/ResetPassword"
// GDPR Submission
import GDPRSubmission from "./components/pages/accounts/gdpr";
//Start Accounts Module
//Start Jobseeker Standard Module
import JSDashborad from "./components/pages/accounts/jobseeker/standard/dashboard";
import JSBasicProfile from "./components/pages/accounts/jobseeker/standard/profile/BasicProfile";
import JSFullProfile from "./components/pages/accounts/jobseeker/standard/profile/FullProfile";
import JSSupport from "./components/pages/accounts/jobseeker/standard/supports";
import JSAppliedJobs from "./components/pages/accounts/jobseeker/standard/applied-jobs";
import JSResumeCreate from "./components/pages/accounts/jobseeker/standard/my-resume/create";
import JSSavedResume from "./components/pages/accounts/jobseeker/standard/my-resume/savedresume/SavedResume";
import JSCreateCover from "./components/pages/accounts/jobseeker/standard/my-resume/createcover/CreateCover";
import JSSavedCover from "./components/pages/accounts/jobseeker/standard/my-resume/savedcover/SavedCover";
import JSUpgradeCurrent from "./components/pages/accounts/jobseeker/standard/upgrade/current";
import JSUpgradeTo from "./components/pages/accounts/jobseeker/standard/upgrade/to";
import JSSettingsFaq from './components/pages/accounts/jobseeker/standard/settings/faq/Faq';
import JSSmyDocuments from './components/pages/accounts/jobseeker/standard/settings/mydocuments/MyDocuments';
import JSSmyContracts from './components/pages/accounts/jobseeker/standard/settings/mycontracts/MyContracts';
import JSSettingsNotifications from './components/pages/accounts/jobseeker/standard/settings/notifications';
import JSSettingsCompliance from './components/pages/accounts/jobseeker/standard/settings/compliance';
import JSSettingsConsent from './components/pages/accounts/jobseeker/standard/settings/consent';
import JSChangeLogin from './components/pages/accounts/jobseeker/standard/settings/changeLogin';
import JSDisableAccount from './components/pages/accounts/jobseeker/standard/settings/disableAccount';
import JSDeleteAccount from './components/pages/accounts/jobseeker/standard/settings/deleteaccount/DeleteAccount';
import JSServiceAboutUs from './components/pages/accounts/jobseeker/standard/dkservices/AboutUs'
import JSServiceRecuitment from './components/pages/accounts/jobseeker/standard/dkservices/recruitment/Recruitment'
import JSServiceJobBoard from './components/pages/accounts/jobseeker/standard/dkservices/jobboard/JobBoard'
import JSServiceTrading from './components/pages/accounts/jobseeker/standard/dkservices/trading/Trading'
import JSServiceITSolution from './components/pages/accounts/jobseeker/standard/dkservices/itsolutions/ITSolution'
import JSServiceAcademy from './components/pages/accounts/jobseeker/standard/dkservices/academy/Academy'
import JSServiceMarketing from './components/pages/accounts/jobseeker/standard/dkservices/marketing/Marketing'
//End Jobseeker Standard Module

import SearchJobs from './components/pages/accounts/jobseeker/standard/searchjobs/SearchJobs';
import SearchByLevel from './components/pages/accounts/jobseeker/standard/searchjobs/SearchByLevel';
import SearchByIndustry from './components/pages/accounts/jobseeker/standard/searchjobs/SearchByIndustry';
import SearchByCompany from './components/pages/accounts/jobseeker/standard/searchjobs/SearchByCompany';
import SearchByJobProfile from './components/pages/accounts/jobseeker/standard/searchjobs/SearchByJobProfile';
import SearchByCountry from './components/pages/accounts/jobseeker/standard/searchjobs/SearchByCountry';
import SearchByCity from './components/pages/accounts/jobseeker/standard/searchjobs/SearchByCity';
import SearchByLanguage from './components/pages/accounts/jobseeker/standard/searchjobs/SearchByLanguage';
import SearchByWorkPermit from './components/pages/accounts/jobseeker/standard/searchjobs/SearchByWorkPermit';
import SavedJobs from './components/pages/accounts/jobseeker/standard/savedjobs/SavedJobs';

//Start Employer Standard Module
//End Employer Standard Module
import EmpSDashborad from './components/pages/accounts/employer/standard/dashboard';
import EmpSActiveJobs from './components/pages/accounts/employer/standard/jobs/ActiveJobs';
import EmpSFeaturedJobs from './components/pages/accounts/employer/standard/jobs/FeaturedJobs';
import EmpSExpiredJobs from './components/pages/accounts/employer/standard/jobs/ExpiredJobs';
import EmpSPostNewJobs from './components/pages/accounts/employer/standard/jobs/PostNewJobs';
import EmpSSummary from './components/pages/accounts/employer/standard/searchcv/summary/Summary';
import EmpSSearchNew from './components/pages/accounts/employer/standard/searchcv/searchnew/SearchNew';
import EmpSMyCv from './components/pages/accounts/employer/standard/searchcv/mycv/MyCv';
import EmpSSubscriptions from './components/pages/accounts/employer/standard/packages/Subscriptions';
import CurrentPackages from './components/pages/accounts/employer/standard/packages/CurrentPackages';
import EmpOwnerProfile from "./components/pages/accounts/employer/standard/profile/userProfile/OwnerProfile";
import EmpCompanyProfile from "./components/pages/accounts/employer/standard/profile/companyProfile/CompanyProfile";
import EmpContactPerson from './components/pages/accounts/employer/standard/billing/contactperson/ContactPerson';
import EmpBillingAddress from './components/pages/accounts/employer/standard/billing/billingaddress/BillingAddress';
import EmppaymentTo from './components/pages/accounts/employer/standard/billing/paymentto/PaymentTo';
import EmppaymentMethod from './components/pages/accounts/employer/standard/billing/paymentmethod/PaymentMethod';
import EmpPaymentHistory from './components/pages/accounts/employer/standard/billing/paymenthistory/PaymentHistory';
import EmpSupport from "./components/pages/accounts/employer/standard/supports";
import EmpAccManager from './components/pages/accounts/employer/standard/supports/accountmanager/AccountManager';
import EmpSettingsFaq from './components/pages/accounts/employer/standard/settings/faq/Faq';
import EmpSettingsNotifation from './components/pages/accounts/employer/standard/settings/notifications';
import EmpSettingsDocument from './components/pages/accounts/employer/standard/settings/mydocuments/MyDocuments';
import EmpSettingsContracts from './components/pages/accounts/employer/standard/settings/mycontracts/MyContracts';
import EmpSettingsCompliance from './components/pages/accounts/employer/standard/settings/compliance';
import EmpChangeLogin from './components/pages/accounts/employer/standard/settings/changeLogin';
import EmpDisableAccount from './components/pages/accounts/employer/standard/settings/disableAccount';
import EmpDeleteAccount from './components/pages/accounts/employer/standard/settings/deleteaccount/DeleteAccount';
import EmpServiceAboutUs from './components/pages/accounts/employer/standard/dkservices/AboutUs'
import EmpServiceRecuitment from './components/pages/accounts/employer/standard/dkservices/recruitment/Recruitment'
import EmpServiceJobBoard from './components/pages/accounts/employer/standard/dkservices/jobboard/JobBoard'
import EmpServiceTrading from './components/pages/accounts/employer/standard/dkservices/trading/Trading'
import EmpServiceITSolution from './components/pages/accounts/employer/standard/dkservices/itsolutions/ITSolution'
import EmpServiceAcademy from './components/pages/accounts/employer/standard/dkservices/academy/Academy'
import EmpServiceMarketing from './components/pages/accounts/employer/standard/dkservices/marketing/Marketing'
//End Accounts Module
function App() {
  return (
    <div className="App">
      <Switch>
        <MainRoute exact path="/" component={Home} />
        <MainRoute path="/employers" component={ListCompany} />
        <MainRoute path="/company-jobs/about-us" component={CompanyJobsAboutUs} />
        <MainRoute path="/company-jobs/contact-us" component={CompanyJobsContactUs} />
        <MainRoute path="/company-jobs" component={CompanyJobs} />
        <MainRoute path="/subscription-plan" component={FrontSubscription} />
        <MainRoute path="/jobs/jobs-listing" component={JobListing} />
        <MainRoute path="/jobdetails/job-details" component={JobDetails} />
        <MainRoute path="/jobs/job-search-by-industry" component={JobSearchByIndustry} />
        <MainRoute path="/jobs/job-search-by-level" component={JobSearchByLevel} />
        <MainRoute path="/jobs/job-search-by-job-profile" component={JobSearchByProfile} />
        <MainRoute path="/jobs/job-search-by-country" component={JobSearchByCountry} />
        <MainRoute path="/jobs/job-search-by-city" component={JobSearchByCity} />
        <MainRoute path="/jobs/job-search-by-language" component={JobSearchByLanguage} />
        <MainRoute path="/jobs/job-search-by-company" component={JobSearchByCompany} />
        <MainRoute path="/jobs/job-search-by-work-permit" component={JobSearchByWorkPermit} />
        <MainRoute path="/article/details" component={BlogDetails} />
        <MainRoute path="/article" component={Blogs} />
        <MainRoute path="/front-jobseekers" component={FrontJobSeekers} />
        <MainRoute path="/about-us" component={FrontAboutUs} />
        <MainRoute path="/email-not-verify" component={EmailNotVerify} />
        <MainRoute path="/acount-verify" component={AccountVerify} />
        <MainRoute path="/contact-us" component={ContactUs} />
        <MainRoute path="/privacy-contact" component={PrivacyContact} />
        <MainRoute path="/access-your-data" component={AccessYourData} />
        <MainRoute path="/gdpr-explained" component={GDPRExplanied} />
        <MainRoute path="/privacy-policy" component={PrivacyPolicy} />
        <MainRoute path="/terms-of-use" component={TermsUse} />
        {/* Signup Accounts */}
        <MainRoute path="/sign-up/jobseeker-signup" component={JobseekerSignup} />
        <MainRoute path="/sign-up/employer-signup" component={EmployerSignup} />
        <MainRoute path="/sign-up/thankyou" component={ThankyouSignup} />
        <MainRoute path="/sign-up" component={SingupMain} />
        {/* Login Accounts */}
        <MainRoute path="/login/jobseeker-login" component={JobseekerLogin} />
        <MainRoute path="/login/jobseeker-premium" component={JobseekerPremimum} />
        <MainRoute path="/login/employer-login" component={EmployerLogin} />
        <MainRoute path="/login/employer-premium" component={EmployerPremimum} />
        <MainRoute path="/login" component={Login} />
        {/* Forget Account Password */}
        <MainRoute path="/forget-password/jobseeker" component={ForgetPasswordJobseeker} />
        <MainRoute path="/forget-password/employer" component={ForgetPasswordEmployer} />
        <MainRoute path="/forget-password/verify-otp/:account/:email/" component={verifyOTP} />
        <MainRoute path="/reset-password/:account/:email/" component={ResetPassword} />
        {/* GDPR Submission */}
        <MainRoute path="/submission-gdpr/:account/:id" component={GDPRSubmission} />
        {/* Start Jobseeker Module For Accounts */}
        <JSStandardRoute path="/jobseeker/standard/dashboard" component={JSDashborad} />
        <JSStandardRoute path="/jobseeker/standard/search-box" component={SearchJobs} />
        <JSStandardRoute path="/jobseeker/standard/search-by-level" component={SearchByLevel} />
        <JSStandardRoute path="/jobseeker/standard/search-by-industry" component={SearchByIndustry} />
        <JSStandardRoute path="/jobseeker/standard/search-by-company" component={SearchByCompany} />
        <JSStandardRoute path="/jobseeker/standard/search-by-job-profile" component={SearchByJobProfile} />
        <JSStandardRoute path="/jobseeker/standard/search-by-country" component={SearchByCountry} />
        <JSStandardRoute path="/jobseeker/standard/search-by-city" component={SearchByCity} />
        <JSStandardRoute path="/jobseeker/standard/search-by-language" component={SearchByLanguage} />
        <JSStandardRoute path="/jobseeker/standard/search-by-work-permit" component={SearchByWorkPermit} />
        <JSStandardRoute path="/jobseeker/standard/saved-jobs" component={SavedJobs} />
        <JSStandardRoute path="/jobseeker/standard/profile/basic" component={JSBasicProfile} />
        <JSStandardRoute path="/jobseeker/standard/profile/full" component={JSFullProfile} />
        <JSStandardRoute path="/jobseeker/standard/support/genral" component={JSSupport} />
        <JSStandardRoute path="/jobseeker/standard/support/upgrade" component={JSSupport} />
        <JSStandardRoute path="/jobseeker/standard/support/technical" component={JSSupport} />
        <JSStandardRoute path="/jobseeker/standard/support/contact" component={JSSupport} />
        <JSStandardRoute path="/jobseeker/standard/applied-jobs" component={JSAppliedJobs} />
        <JSStandardRoute path="/jobseeker/standard/my-resume/create-resume" component={JSResumeCreate} />
        <JSStandardRoute path="/jobseeker/standard/my-resume/saved-resume" component={JSSavedResume} />
        <JSStandardRoute path="/jobseeker/standard/my-resume/create-cover" component={JSCreateCover} />
        <JSStandardRoute path="/jobseeker/standard/my-resume/saved-cover" component={JSSavedCover} />
        <JSStandardRoute path="/jobseeker/standard/upgrade/current" component={JSUpgradeCurrent} />
        <JSStandardRoute path="/jobseeker/standard/upgrade/upgrade-to" component={JSUpgradeTo} />
        <JSStandardRoute path="/jobseeker/standard/settings/faq" component={JSSettingsFaq} />
        <JSStandardRoute path="/jobseeker/standard/settings/my-documents" component={JSSmyDocuments} />
        <JSStandardRoute path="/jobseeker/standard/settings/my-contracts" component={JSSmyContracts} />
        <JSStandardRoute path="/jobseeker/standard/settings/compliance" component={JSSettingsCompliance} />
        <JSStandardRoute path="/jobseeker/standard/settings/notifications" component={JSSettingsNotifications} />
        <JSStandardRoute path="/jobseeker/standard/settings/consent" component={JSSettingsConsent} />
        <JSStandardRoute path="/jobseeker/standard/settings/change_logins" component={JSChangeLogin} />
        <JSStandardRoute path="/jobseeker/standard/settings/disable_account" component={JSDisableAccount} />
        <JSStandardRoute path="/jobseeker/standard/settings/delete-account" component={JSDeleteAccount} />
        <JSStandardRoute path="/jobseeker/standard/dkg-serivces/abouts" component={JSServiceAboutUs} />
        <JSStandardRoute path="/jobseeker/standard/dkg-serivces/recruitment" component={JSServiceRecuitment} />
        <JSStandardRoute path="/jobseeker/standard/dkg-serivces/job-board" component={JSServiceJobBoard} />
        <JSStandardRoute path="/jobseeker/standard/dkg-serivces/trading" component={JSServiceTrading} />
        <JSStandardRoute path="/jobseeker/standard/dkg-serivces/it-solutions" component={JSServiceITSolution} />
        <JSStandardRoute path="/jobseeker/standard/dkg-serivces/academy" component={JSServiceAcademy} />
        <JSStandardRoute path="/jobseeker/standard/dkg-serivces/marketing" component={JSServiceMarketing} />
        {/* End Module For Accounts */}
        {/* Start Employer Module For Accounts */}
        <EmpStandardRoute path="/employer/standard/dashboard" component={EmpSDashborad} />
        <EmpStandardRoute path="/employer/standard/jobs/active-jobs" component={EmpSActiveJobs} />
        <EmpStandardRoute path="/employer/standard/jobs/featured-jobs" component={EmpSFeaturedJobs} />
        <EmpStandardRoute path="/employer/standard/jobs/expired-jobs" component={EmpSExpiredJobs} />
        <EmpStandardRoute path="/employer/standard/jobs/post-new-jobs" component={EmpSPostNewJobs} />
        <EmpStandardRoute path="/employer/standard/searchcv/summary" component={EmpSSummary} />
        <EmpStandardRoute path="/employer/standard/searchcv/search-new" component={EmpSSearchNew} />
        <EmpStandardRoute path="/employer/standard/searchcv/my-cv" component={EmpSMyCv} />
        <EmpStandardRoute path="/employer/standard/packages/subscription" component={EmpSSubscriptions} />
        <EmpStandardRoute path="/employer/standard/packages/current-package" component={CurrentPackages} />
        <EmpStandardRoute path="/employer/standard/profile/owner" component={EmpOwnerProfile} />
        <EmpStandardRoute path="/employer/standard/profile/company" component={EmpCompanyProfile} />
        <EmpStandardRoute path="/employer/standard/billing/contact-person" component={EmpContactPerson} />
        <EmpStandardRoute path="/employer/standard/billing/billing-address" component={EmpBillingAddress} />
        <EmpStandardRoute path="/employer/standard/billing/payment-to" component={EmppaymentTo} />
        <EmpStandardRoute path="/employer/standard/billing/payment-method" component={EmppaymentMethod} />
        <EmpStandardRoute path="/employer/standard/billing/payment-history" component={EmpPaymentHistory} />
        <EmpStandardRoute path="/employer/standard/support/genral" component={EmpSupport} />
        <EmpStandardRoute path="/employer/standard/support/ads" component={EmpSupport} />
        <EmpStandardRoute path="/employer/standard/support/users" component={EmpSupport} />
        <EmpStandardRoute path="/employer/standard/support/billing" component={EmpSupport} />
        <EmpStandardRoute path="/employer/standard/support/technical" component={EmpSupport} />
        <EmpStandardRoute path="/employer/standard/support/account-manager" component={EmpAccManager} />
        <EmpStandardRoute path="/employer/standard/settings/faq" component={EmpSettingsFaq} />
        <EmpStandardRoute path="/employer/standard/settings/notifications" component={EmpSettingsNotifation} />
        <EmpStandardRoute path="/employer/standard/settings/my_documents" component={EmpSettingsDocument} />
        <EmpStandardRoute path="/employer/standard/settings/contracts" component={EmpSettingsContracts} />
        <EmpStandardRoute path="/employer/standard/settings/compliance" component={EmpSettingsCompliance} />
        <EmpStandardRoute path="/employer/standard/settings/change_logins" component={EmpChangeLogin} />
        <EmpStandardRoute path="/employer/standard/settings/disable_account" component={EmpDisableAccount} />
        <EmpStandardRoute path="/employer/standard/settings/delete-account" component={EmpDeleteAccount} />
        <EmpStandardRoute path="/employer/standard/dkg-serivces/abouts" component={EmpServiceAboutUs} />
        <EmpStandardRoute path="/employer/standard/dkg-serivces/recruitment" component={EmpServiceRecuitment} />
        <EmpStandardRoute path="/employer/standard/dkg-serivces/job-board" component={EmpServiceJobBoard} />
        <EmpStandardRoute path="/employer/standard/dkg-serivces/trading" component={EmpServiceTrading} />
        <EmpStandardRoute path="/employer/standard/dkg-serivces/it-solutions" component={EmpServiceITSolution} />
        <EmpStandardRoute path="/employer/standard/dkg-serivces/academy" component={EmpServiceAcademy} />
        <EmpStandardRoute path="/employer/standard/dkg-serivces/marketing" component={EmpServiceMarketing} />
        {/* End Module For Accounts */}
      </Switch>
    </div>
  );
}
export default App;
