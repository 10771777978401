import React from 'react'
import { Link } from 'react-router-dom'
import './submenu.scss'

const SupportSubmenu = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="container-fluid">
                <div className="dk-ds-sub-menu col-md-11 pl-0 ml-auto">
                    <div class="dk-ds-sub-menu-cols">
                        <ul>
                            <li>
                                <Link className={pathname === "/jobseeker/standard/support/genral" ? 'sub-active' : ''} to="/jobseeker/standard/support/genral">Genral</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/jobseeker/standard/support/upgrade" ? 'sub-active' : ''} to="/jobseeker/standard/support/upgrade">Upgrade</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/jobseeker/standard/support/technical" ? 'sub-active' : ''} to="/jobseeker/standard/support/technical">Technical</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/jobseeker/standard/support/contact" ? 'sub-active' : ''} to="/jobseeker/standard/support/contact">Contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SupportSubmenu;