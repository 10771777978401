import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import './addnewfiledoc.scss';

const EditFile = ({ hidepopup }) => {
    return (
        <>
            <Modal
                size="lg"
                show={true}
                onHide={hidepopup}
                aria-labelledby="contained-modal-title-vcenter"
                className="dk-docaddnewFileModal"
                centered
            >
                <Modal.Header closeButton className="dk-docaddnewFileModalHeader">
                    <Modal.Title id="contained-modal-title-vcenter">
                        UPDATE DOCUMENT
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-docaddnewFileModalBody">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label for="">File Name</label>
                            <label for="">
                                <input type="text" name="" id="" className="form-control" defaultValue="Deepak Passport" />
                            </label>
                        </div>
                        <div className="form-group">
                            <label for="">File Category</label>
                            <label for="">
                                <select name="" id="" className="form-control">
                                    <option value="">PDF</option>
                                    <option value="">Doc</option>
                                    <option value="">Image</option>
                                </select>
                            </label>
                        </div>
                        <div className="form-group">
                            <label for="">
                                <input type="file" name="" id="" />
                            </label>
                        </div>
                        <div className="form-group">
                            <label for="">Description</label>
                            <label for="">
                                <textarea type="text" name="" id="" className="form-control" defaultValue="" />
                            </label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="dk-docaddnewFileModalFooter">
                    <Button className="closebtn">Close</Button>
                    <Button className="submitbtn">Update</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditFile
