import React from 'react';
import './aboutus.scss'
import LogoImg from '../../../../../../assets/images/mainlogo.png'

const AboutUs = () => {
    return (
        <>
            <div className="dk-servpageCon container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="dk-headingCon">
                            <h1 className="s">Welcome to <span>DK Global Employment Services</span></h1>
                        </div>
                        <div className="dk-textCon">
                            <p>
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Labore natus,
                                qui itaque voluptatum officiis dolor rerum totam ipsum aliquam recusandae explicabo
                                iusto voluptates aperiam incidunt sint aliquid minus provident necessitatibus.
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Labore natus,
                                qui itaque voluptatum officiis dolor rerum totam ipsum aliquam recusandae explicabo
                                iusto voluptates aperiam incidunt sint aliquid minus provident necessitatibus.
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Labore natus,
                                qui itaque voluptatum officiis dolor rerum totam ipsum aliquam recusandae explicabo
                                iusto voluptates aperiam incidunt sint aliquid minus provident necessitatibus.
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Labore natus,
                                qui itaque voluptatum officiis dolor rerum totam ipsum aliquam recusandae explicabo
                                iusto voluptates aperiam incidunt sint aliquid minus provident necessitatibus.
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Labore natus,
                                qui itaque voluptatum officiis dolor rerum totam ipsum aliquam recusandae explicabo
                                iusto voluptates aperiam incidunt sint aliquid minus provident necessitatibus.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dk-AboutLogoSec container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h2>DK Global <span>Group of Companies</span></h2>
                        <div className="logo_f">
                            <figure>
                                <img src={LogoImg} alt="Main_Logo" />
                            </figure>
                        </div>
                        <ul>
                            <li>
                                <figure>
                                    <img src={LogoImg} alt="Main_Logo" />
                                </figure>
                                DK Global Jobs
                            </li>
                            <li>
                                <figure>
                                    <img src={LogoImg} alt="Main_Logo" />
                                </figure>
                                DK Global Jobs
                            </li>
                            <li>
                                <figure>
                                    <img src={LogoImg} alt="Main_Logo" />
                                </figure>
                                DK Global Jobs
                            </li>
                            <li>
                                <figure>
                                    <img src={LogoImg} alt="Main_Logo" />
                                </figure>
                                DK Global Jobs
                            </li>
                            <li>
                                <figure>
                                    <img src={LogoImg} alt="Main_Logo" />
                                </figure>
                                DK Global Jobs
                            </li>
                            <li>
                                <figure>
                                    <img src={LogoImg} alt="Main_Logo" />
                                </figure>
                                DK Global Jobs
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="dk-corporateSec container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h2>Our Core <span>Corporate Values</span></h2>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="dk-cor-box">
                            <figure>
                                <img src="https://dkglobalrecruiters.com/admin/public/images/corporatemanagements/1603687100no-late-payments.png" alt="" />
                            </figure>
                            <h3>Transparency</h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry
                                's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it
                                to make a type specimen book. It has survived not only five centuries, but also the leap into electronic
                                typesetting, remaining essentially unchanged.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="dk-cor-box">
                            <figure>
                                <img src="https://dkglobalrecruiters.com/admin/public/images/corporatemanagements/1603687100no-late-payments.png" alt="" />
                            </figure>
                            <h3>Transparency</h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry
                                's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it
                                to make a type specimen book. It has survived not only five centuries, but also the leap into electronic
                                typesetting, remaining essentially unchanged.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="dk-cor-box">
                            <figure>
                                <img src="https://dkglobalrecruiters.com/admin/public/images/corporatemanagements/1603687100no-late-payments.png" alt="" />
                            </figure>
                            <h3>Transparency</h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry
                                's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it
                                to make a type specimen book. It has survived not only five centuries, but also the leap into electronic
                                typesetting, remaining essentially unchanged.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="dk-cor-box">
                            <figure>
                                <img src="https://dkglobalrecruiters.com/admin/public/images/corporatemanagements/1603687100no-late-payments.png" alt="" />
                            </figure>
                            <h3>Transparency</h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry
                                's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it
                                to make a type specimen book. It has survived not only five centuries, but also the leap into electronic
                                typesetting, remaining essentially unchanged.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dk-mvSec container-fluid">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <img src="https://dkglobalrecruiters.com/admin/public/images/joiningbenefits/1603686967mission.jpg" alt="" className="w-100" />
                    </div>
                    <div className="col-12 col-md-8 pl-5 pt-4 pr-5">
                        <h2>Our Vision</h2>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                            centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                            and more recently with desktop publishing. Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                            took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                            but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs;
