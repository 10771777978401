import React, { useEffect, useState } from 'react';
import './faq.scss';
import { Link } from 'react-router-dom'
import { Tab, Col } from 'react-bootstrap';
import { FaRegQuestionCircle } from "react-icons/fa";
import AccoridanCom from './AccoridanCom';
import { useDispatch, useSelector } from "react-redux";
import { listCategory, listAnswer } from '../../../../../../../slice/account/faqs/faqSlice';
import { showError } from '../../../../../../../slice/utils/message/messageSlice';

const Faq = () => {
    const dispatch = useDispatch();
    const { categoryList, answerList, catId, isCategoryLoading } = useSelector(state => state.accountFaq);
    const [faqCat, setFaqCat] = useState("0");

    useEffect(() => {
        async function fetchCategoryInfo() {
            try {
                await dispatch(listCategory({ account: 'employer' }))
            } catch (err) {
                dispatch(showError({ msg: err }))
            }
        }
        fetchCategoryInfo()
    }, [dispatch])

    useEffect(() => {
        if (!isCategoryLoading) {
            setFaqCat(catId)
        }
    }, [isCategoryLoading, catId])

    useEffect(() => {
        if (faqCat > "0") {
            dispatch(listAnswer({ catId: faqCat }))
        }
    }, [dispatch, faqCat])

    const getAnswer = (categoryId) => {
        setFaqCat(categoryId)
    }

    return (
        <>
            <div className="container-fluid dk-settFaqPageMain">
                <div className="dk-settFaqPageCon">
                    <div className="col-md-12 pl-0 pr-0 ml-auto">
                        <div className="row ml-0 mr-0">
                            <div className="d-flex w-100 dk-faqSideMainCon">
                                <Col sm={3} className="pl-0">
                                    <div className="dk-faqSideMenu flex-column">
                                        {
                                            categoryList.map((item, index) =>
                                                <Link to="#" key={index + 1} onClick={() => getAnswer(item.id)} className={`nav-link ${(faqCat === item.id) ? 'active' : null}`}>
                                                    <FaRegQuestionCircle className="mr-2" />{item.name}
                                                </Link>
                                            )
                                        }
                                    </div>
                                </Col>
                                <Col sm={9} className="pr-0">
                                    <div className="dk-settFaqAccCont">
                                        <Tab.Pane className="dk-faqSetTabpane" eventKey="first">
                                            <AccoridanCom answers={answerList} />
                                        </Tab.Pane>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Faq;
