import React from 'react';
import './empfeed.scss';
import Carousel from 'react-bootstrap/Carousel'
import EmpFeedbox from './EmpFeedbox';
const EmpFeed = () => {
    return (
        <>
            <div className="dk-empFeedCarousel">
                <Carousel variant="dark">
                    <Carousel.Item>
                        <div className="row pl-5 pr-5">
                            <div className="col-md-4">
                                <EmpFeedbox />
                            </div>
                            <div className="col-md-4">
                                <EmpFeedbox />
                            </div>
                            <div className="col-md-4">
                                <EmpFeedbox />
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="row pl-5 pr-5">
                            <div className="col-md-4">
                                <EmpFeedbox />
                            </div>
                            <div className="col-md-4">
                                <EmpFeedbox />
                            </div>
                            <div className="col-md-4">
                                <EmpFeedbox />
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="row pl-5 pr-5">
                            <div className="col-md-4">
                                <EmpFeedbox />
                            </div>
                            <div className="col-md-4">
                                <EmpFeedbox />
                            </div>
                            <div className="col-md-4">
                                <EmpFeedbox />
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>
        </>
    )
}

export default EmpFeed;
