import React from 'react'
import { Link } from 'react-router-dom'
import './listcomapny.scss';
import CompanyLogo from '../../../../assets/images/companylist.jpg';
import SearchInputDrpDwn from './dropdown/SearchInputDrpDwn';

const ListCompany = () => {
    return (
        <>
            <div className="dk-employerListPageMain">
                <section className="pb-3">
                    <h2 className="section-title text-uppercase pb-0">List of Employers</h2>
                </section>
                <div className="dk-listContainer">
                    <div className="col-sm-12 employers-cols mb-4">
                        <div className="d-flex align-items-center justify-content-between">
                            <label for="">
                                <input type="text" className="form-control" placeholder="Keyword" />
                            </label>
                            <label for="">
                                {/* <select name="" id="" className="form-control">
                                    <option value="">Company Type</option>
                                </select> */}
                                <div className="dk-emplistsrcDrpDwn">
                                    <SearchInputDrpDwn toggleTitle="Company Type" />
                                </div>
                            </label>
                            <label for="">
                                <div className="dk-emplistsrcDrpDwn">
                                    <SearchInputDrpDwn toggleTitle="Industry" />
                                </div>
                            </label>
                            <label for="">
                                <div className="dk-emplistsrcDrpDwn">
                                    <SearchInputDrpDwn toggleTitle="Country Type" />
                                </div>
                            </label>
                            <label for="">
                                <button data-test="button" type="button" className="btn-default btn Ripple-parent btn-danger">
                                    Search Companies <i className="fas fa-search"></i>
                                </button>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="dk-listContainer">
                    <div data-test="row" className="row">
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="#/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span><span className="font-size-sm"><Link to="/company-jobs">0 Job</Link></span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="#/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm"><Link className="company-name" to="/company-jobs">Sports Fuel</Link></span><span className="font-size-sm"><Link to="/company-jobs">0 Job</Link></span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="#/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name"
                                            to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="#/company-jobs">0 Job</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="/company-jobs">0 Job</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="#/company-jobs">0 Job</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="#/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="#/company-jobs">0 Job</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="#/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="#/company-jobs">0 Job</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="#/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="/company-jobs">0 Job</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="#/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="#/company-jobs">0 Job</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="#/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="/company-jobs">0 Job</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="#/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="#/company-jobs">0 Job</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="#/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="#/company-jobs">0 Job</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="#/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="/company-jobs">0 Job</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="#/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="/company-jobs">0 Job</Link></span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="#/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="/company-jobs">0 Job</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="#/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="/company-jobs">0 Job</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="#/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="/company-jobs">0 Job</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="#/company-jobs">0 Job</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="#/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="#/company-jobs">0 Job</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div data-test="col" className="col-sm-6 col-md-4 col-lg-3 featured-companies mb-4">
                            <div className="card">
                                <div className="card-head">
                                    <Link to="#/company-jobs">
                                        <img
                                            src={CompanyLogo}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="card-footer d-flex justify-content-between">
                                    <span className="font-size-sm">
                                        <Link className="company-name" to="#/company-jobs">Sports Fuel</Link>
                                    </span>
                                    <span className="font-size-sm">
                                        <Link to="/company-jobs">0 Job</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dk-emplistPageination text-center mt-4 pb-4">
                    <ul className="pagination-circle pagination justify-content-center">
                        <li className="disabled page-item">
                            <Link aria-label="Previous" className="page-link page-link">
                                <span aria-hidden="true">«</span>
                                <span className="sr-only">Previous</span>
                            </Link>
                        </li>
                        <li className="page-item">
                            <Link className="page-link page-link">1 <span className="sr-only">(current)</span></Link>
                        </li>
                        <li className="page-item">
                            <Link className="page-link page-link">2</Link>
                        </li>
                        <li className="active page-item">
                            <Link className="page-link page-link">3</Link>
                        </li>
                        <li className="page-item">
                            <Link className="page-link page-link">4</Link>
                        </li>
                        <li className="page-item">
                            <Link className="page-link page-link">5</Link>
                        </li>
                        <li className="page-item">
                            <Link className="page-link page-link">»</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default ListCompany
