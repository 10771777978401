import React, { useState } from 'react';
import Companies from "../../../../../../assets/images/companies.jpg";
import Companies2 from "../../../../../../assets/images/companies_2.jpg";
import Companies3 from "../../../../../../assets/images/companies_3.jpg";
import Companies4 from "../../../../../../assets/images/companies_4.jpg";

const JobsCompanyLogo = () => {
    return (
        <>
            <div className="dk-companiesBox">
                <img src={Companies} className="img-fluid" alt="" />
            </div>
            <div className="dk-companiesBox">
                <img src={Companies2} className="img-fluid" alt="" />
            </div>
            <div className="dk-companiesBox">
                <img src={Companies3} className="img-fluid" alt="" />
            </div>
            <div className="dk-companiesBox">
                <img src={Companies4} className="img-fluid" alt="" />
            </div>
            <div className="dk-companiesBox">
                <img src={Companies} className="img-fluid" alt="" />
            </div>
            <div className="dk-companiesBox">
                <img src={Companies2} className="img-fluid" alt="" />
            </div>
        </>
    )
}

export default JobsCompanyLogo;
