import React, { useState } from 'react';

const SavedCover = () => {
    return (
        <>

        </>
    )
}

export default SavedCover;
