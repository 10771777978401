import React from 'react'
import { Link } from 'react-router-dom'
import './submenu.scss'

const UpgradeSubmenu = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="container-fluid">
                <div className="dk-ds-sub-menu col-md-11 pl-0 ml-auto">
                    <div class="dk-ds-sub-menu-cols">
                        <ul>
                            <li>
                                <Link className={pathname === "/jobseeker/standard/upgrade/current" ? 'sub-active' : ''} to="/jobseeker/standard/upgrade/current">Current</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/jobseeker/standard/upgrade/upgrade-to" ? 'sub-active' : ''} to="/jobseeker/standard/upgrade/upgrade-to">Upgrade To</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpgradeSubmenu;
