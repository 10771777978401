import React, {useState} from 'react';
import { Link } from 'react-router-dom';

const JobsFilter = () => {
    return (
        <>
            <div className="dk-jobsFilterMain">
                <div className="dk-jobsfilter">
                    <div className="dk-mobViewFilter-main">
                        <div className="dk-jobsfilter-search">
                            <label htmlFor="" className="d-flex align-items-center">
                                <input type="text" placeholder="Search Keywords" className="form-control" />
                                <button className="dk-filter-search-btn">
                                    <i class="fas fa-search"></i>
                                </button>
                            </label>
                        </div>
                        <div className="dk-jobsfilter-cols">
                            <div className="title">Posted On</div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="checkbox1" />
                                    <label className="custom-control-label" htmlFor="checkbox1">Last 24 hours</label>
                                </div>
                                <span>233</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="checkbox2" />
                                    <label className="custom-control-label" htmlFor="checkbox2">Last 7 days</label>
                                </div>
                                <span>233</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="checkbox3" />
                                    <label className="custom-control-label" htmlFor="checkbox3">Last 14 days</label>
                                </div>
                                <span>233</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="checkbox4" />
                                    <label className="custom-control-label" htmlFor="checkbox4">Last 30 days</label>
                                </div>
                                <span>233</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="checkbox5" />
                                    <label class="custom-control-label" htmlFor="checkbox5">All Time</label>
                                </div>
                                <span>233</span>
                            </div>
                        </div>
                        <div className="dk-jobsfilter-cols">
                            <div className="title">Job Type</div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="custom-control custom-checkbox fulltime">
                                    <input type="checkbox" className="custom-control-input" id="jobtype1" />
                                    <label className="custom-control-label" htmlFor="jobtype1">Full Time</label>
                                </div>
                                <span>233</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="custom-control custom-checkbox parttime">
                                    <input type="checkbox" className="custom-control-input" id="jobtype2" />
                                    <label className="custom-control-label" htmlFor="jobtype2">Part Time</label>
                                </div>
                                <span>233</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="custom-control custom-checkbox freelance">
                                    <input type="checkbox" className="custom-control-input" id="jobtype3" />
                                    <label className="custom-control-label" htmlFor="jobtype3">Contractor</label>
                                </div>
                                <span>233</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="custom-control custom-checkbox remote">
                                    <input type="checkbox" className="custom-control-input" id="jobtype4" />
                                    <label className="custom-control-label" htmlFor="jobtype4">Remote</label>
                                </div>
                                <span>233</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="custom-control custom-checkbox intership">
                                    <input type="checkbox" className="custom-control-input" id="checkbox6" />
                                    <label className="custom-control-label" htmlFor="checkbox6">Internship</label>
                                </div>
                                <span>233</span>
                            </div>
                        </div>
                        <div className="dk-jobsfilter-cols">
                            <div className="title">Salary</div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="salary1" />
                                    <label className="custom-control-label" htmlFor="salary1">€ 0 - 20,000</label>
                                </div>
                                <span>233</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="salary2" />
                                    <label className="custom-control-label" htmlFor="salary2">€ 20,000 - 30,000</label>
                                </div>
                                <span>233</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="salary3" />
                                    <label className="custom-control-label" htmlFor="salary3">€ 30,000 - 40,000</label>
                                </div>
                                <span>233</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="salary4" />
                                    <label className="custom-control-label" htmlFor="salary4">€ 40,000 - 50,000</label>
                                </div>
                                <span>233</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="salary5" />
                                    <label className="custom-control-label" htmlFor="salary5">€ 50,000+</label>
                                </div>
                                <span>233</span>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>            
        </>
    )
}

export default JobsFilter
