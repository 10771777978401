import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CompanyJobsNavbar from './CompanyJobsNavbar';
import CompanyJobsFilter from './CompanyJobsFilter';
import CompanyLogo from "../../../assets/images/teleperformance.jpeg";
import CompanyJobsLogo from './CompanyJobsLogo';

const CompanyJobs = () => {
    return (
        <>
            <CompanyJobsNavbar />

            <div className="dk-companyJobs-main">
                <div className="row">
                    <div className="col-md-2">
                        <CompanyJobsFilter />
                    </div>
                    <div className="col-md-8">
                        <div className="dk-allJobsHead">
                            <div className="row">
                                <div className="col-sm-8 count">
                                    <div className="small_title">
                                        <ul>
                                            <li>Total: 6 Ads</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-4 pull-right">
                                    <ul className="joblistfilterul pull-right">
                                        <li className="m-0 mr-1">
                                            <a href="#" className="search_fiter">Full Time<i className="fa fa-times close-icon ml-2"></i></a>
                                        </li>
                                        <li className="m-0 mr-1">
                                            <a href="#" className="search_fiter">Ireland<i className="fa fa-times close-icon ml-2"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="joblistul">
                            <li>
                                <div className="jobtop">
                                    <div className="joblist-right flex-wrap">
                                        <img src={CompanyLogo} className="img-fluid" alt="" />
                                        <div className="joblist-right p-0">
                                            <button type="button" className="btn-success">
                                                Active
                                            </button>
                                        </div>
                                    </div>
                                    <div className="joblist-left d-flex">
                                        <div className="width-71">
                                            <h5 className="jtitle">German Technical Support Role in sunny Location</h5>
                                            <table>
                                                <tr>
                                                    <td width="80">Salary</td>
                                                    <td width="20">:</td>
                                                    <td>15-17k Gross Per Year + Performance Bonus PM + Flight repaid</td>
                                                </tr>
                                                <tr>
                                                    <td width="80">Category</td>
                                                    <td width="20">:</td>
                                                    <td>Customer Service, Technical Support</td>
                                                </tr>
                                                <tr>
                                                    <td width="80">Language</td>
                                                    <td width="20">:</td>
                                                    <td>German</td>
                                                </tr>
                                                <tr>
                                                    <td width="80">Location</td>
                                                    <td width="20">:</td>
                                                    <td>Dublin, Ireland</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="dk-company-viewjob flex-wrap d-flex align-items-start width-25 pt-3">
                                            <div>
                                                <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="#">View Job</a>
                                                <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="#"><i className="far fa-heart"></i></a><span className="mt-4">Posted Date: 20 Jan 2020</span><span>Closed Date: 15 Apr 2020</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </li>
                            <li>
                                <div className="jobtop">
                                    <div className="joblist-right flex-wrap">
                                        <img src={CompanyLogo} className="img-fluid" alt="" />
                                        <div className="joblist-right p-0">
                                            <button type="button" className="btn-danger">
                                                Inactive
                                            </button>
                                        </div>
                                    </div>
                                    <div className="joblist-left d-flex">
                                        <div className="width-71">
                                            <h5 className="jtitle">German Technical Support Role in sunny Location</h5>
                                            <table>
                                                <tr>
                                                    <td width="80">Salary</td>
                                                    <td width="20">:</td>
                                                    <td>15-17k Gross Per Year + Performance Bonus PM + Flight repaid</td>
                                                </tr>
                                                <tr>
                                                    <td width="80">Category</td>
                                                    <td width="20">:</td>
                                                    <td>Customer Service, Technical Support</td>
                                                </tr>
                                                <tr>
                                                    <td width="80">Language</td>
                                                    <td width="20">:</td>
                                                    <td>German</td>
                                                </tr>
                                                <tr>
                                                    <td width="80">Location</td>
                                                    <td width="20">:</td>
                                                    <td>Dublin, Ireland</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="dk-company-viewjob flex-wrap d-flex align-items-start width-25 pt-3">
                                            <div>
                                                <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="#">View Job</a>
                                                <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="#"><i className="far fa-heart"></i></a><span className="mt-4">Posted Date: 20 Jan 2020</span><span>Closed Date: 15 Apr 2020</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </li>
                            <li>
                                <div className="jobtop">
                                    <div className="joblist-right flex-wrap">
                                        <img src={CompanyLogo} className="img-fluid" alt="" />
                                        <div className="joblist-right p-0">
                                            <button type="button" className="btn-danger">
                                                Inactive
                                            </button>
                                        </div>
                                    </div>
                                    <div className="joblist-left d-flex">
                                        <div className="width-71">
                                            <h5 className="jtitle">German Technical Support Role in sunny Location</h5>
                                            <table>
                                                <tr>
                                                    <td width="80">Salary</td>
                                                    <td width="20">:</td>
                                                    <td>15-17k Gross Per Year + Performance Bonus PM + Flight repaid</td>
                                                </tr>
                                                <tr>
                                                    <td width="80">Category</td>
                                                    <td width="20">:</td>
                                                    <td>Customer Service, Technical Support</td>
                                                </tr>
                                                <tr>
                                                    <td width="80">Language</td>
                                                    <td width="20">:</td>
                                                    <td>German</td>
                                                </tr>
                                                <tr>
                                                    <td width="80">Location</td>
                                                    <td width="20">:</td>
                                                    <td>Dublin, Ireland</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="dk-company-viewjob flex-wrap d-flex align-items-start width-25 pt-3">
                                            <div>
                                                <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="#">View Job</a>
                                                <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="#"><i className="far fa-heart"></i></a><span className="mt-4">Posted Date: 20 Jan 2020</span><span>Closed Date: 15 Apr 2020</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </li>
                            <li>
                                <div className="jobtop">
                                    <div className="joblist-right flex-wrap">
                                        <img src={CompanyLogo} className="img-fluid" alt="" />
                                        <div className="joblist-right p-0">
                                            <button type="button" className="btn-success">
                                                Active
                                            </button>
                                        </div>
                                    </div>
                                    <div className="joblist-left d-flex">
                                        <div className="width-71">
                                            <h5 className="jtitle">German Technical Support Role in sunny Location</h5>
                                            <table>
                                                <tr>
                                                    <td width="80">Salary</td>
                                                    <td width="20">:</td>
                                                    <td>15-17k Gross Per Year + Performance Bonus PM + Flight repaid</td>
                                                </tr>
                                                <tr>
                                                    <td width="80">Category</td>
                                                    <td width="20">:</td>
                                                    <td>Customer Service, Technical Support</td>
                                                </tr>
                                                <tr>
                                                    <td width="80">Language</td>
                                                    <td width="20">:</td>
                                                    <td>German</td>
                                                </tr>
                                                <tr>
                                                    <td width="80">Location</td>
                                                    <td width="20">:</td>
                                                    <td>Dublin, Ireland</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="dk-company-viewjob flex-wrap d-flex align-items-start width-25 pt-3">
                                            <div>
                                                <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="#">View Job</a>
                                                <a className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" to="#"><i className="far fa-heart"></i></a><span className="mt-4">Posted Date: 20 Jan 2020</span><span>Closed Date: 15 Apr 2020</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </li>
                        </div>

                    </div>
                    <div className="col-md-2">
                        <CompanyJobsLogo />
                    </div>
                </div>
            </div >
        </>
    )
}

export default CompanyJobs;
