import React from 'react';
import moment from "moment-timezone";
const CustomDateFormat = ({ date, format }) => {
    return (
        <React.Fragment>
            {
                moment(date).tz("Europe/Dublin").format(format)
            }
        </React.Fragment>
    )
}
export default CustomDateFormat;