import React from 'react'
import './accountmanager.scss';
import AccountM from '../../../../../../../assets/images/deepak.png';

const AccountManager = () => {
    return (
        <>
            <div className="dk-billingHistroy dk-displayPage">
                <h3>DK GLOBAL ACCOUNT MANAGER</h3>
                <div className="dk-billingHistroy-fluid mt-3" style={{ minHeight: "350px" }}>
                    <div className="dk-contactPerson-form">
                        <form action="">
                            <div className="row">
                                <div className="d-md-flex align-items-center justify-content-between w-100">
                                    <div className="col-md-6">
                                        <div className="form-group uplaod-image">
                                            <div className="d-flex align-items-center">
                                                <div className="dk-contactPerson-image">
                                                    <img src={AccountM} className="img-fluid" alt="" />
                                                </div>
                                                <div className="dk-contactPerson-uploadBtn">
                                                    <input type="file" name="" id="" />
                                                    <span>Upload Image</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="dk-ownerForm-note">Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                                            Deleniti dolor modi, illo unde deserunt cupiditate.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="">Name</label>
                                        <label for="">
                                            <input type="text" name="" id="" className="form-control" />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="">Email Address</label>
                                        <label for="">
                                            <input type="text" name="" id="" className="form-control" />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="">Job Title</label>
                                        <label for="">
                                            <input type="text" name="" id="" className="form-control" />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="">Primary Phone No</label>
                                        <label for="">
                                            <input type="text" name="" id="" className="form-control" />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="">Emergency Phone No</label>
                                        <label for="">
                                            <input type="text" name="" id="" className="form-control" />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="">Contact Preferences</label>
                                        <label for="">
                                            <select name="" id="" className="form-control">
                                                <option value="">Select</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="">Address</label>
                                        <label for="">
                                            <input type="text" name="" id="" className="form-control" />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="">City/Town</label>
                                        <label for="">
                                            <input type="text" name="" id="" className="form-control" />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="">Country</label>
                                        <label for="">
                                            <input type="text" name="" id="" className="form-control" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccountManager
