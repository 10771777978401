import React, { useState, useEffect } from 'react'
import './style.scss';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import SubmitButton from "../../../../../../ui/submitButton";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../../../../slice/utils/message/messageSlice";
import { changeLogin, clearState } from "../../../../../../../slice/account/employer/auth/authSlice";
import Constants from '../../../../../../constants';

const Index = () => {
    const dispatch = useDispatch();
    const { userInfo, isLoading, isSuccess, isError, message } = useSelector(state => state.employerAuth);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [isShowOld, setIsShowOld] = useState(false)
    const [isShowNew, setIsShowNew] = useState(false)
    const [isShowConfirm, setIsShowConfirm] = useState(false)

    useEffect(() => {
        document.title = "Change Login Password | DK Global Jobs"
        dispatch(clearMessage());
        dispatch(clearState());
    }, [dispatch])

    const validateForm = () => {
        let formIsValid = true;
        let pattern = '';

        if (!oldPassword || !newPassword || !confirmPassword) {
            formIsValid = false;
            dispatch(showError({ msg: "Please fill all required details" }))
        } else {
            if (typeof newPassword !== "undefined") {
                //regular expression for Password validation
                pattern = new RegExp(Constants.SYSTEM_CONSTANTS.Validation.Password);
                if (!pattern.test(newPassword)) {
                    formIsValid = false;
                    dispatch(showError({ msg: "*Please enter secure and strong password." }));
                } else {
                    if (newPassword.length < 8) {
                        formIsValid = false;
                        dispatch(showError({ msg: "*Password should be atleast 8 character." }));
                    }
                }
            }

            if (typeof confirmPassword !== "undefined") {
                if (newPassword !== confirmPassword) {
                    formIsValid = false;
                    dispatch(showError({ msg: "*Confirm password do not matchwd with password." }));
                }
            }
        }
        return formIsValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validateForm()) {
            try {
                dispatch(clearMessage())
                await dispatch(changeLogin({ old_password: oldPassword, new_password: newPassword }))
                if (isLoading) {
                    setSuccess(false);
                    setLoading(true);
                }
            } catch (err) {
                setSuccess(false);
                setLoading(false);
                dispatch(showError({ msg: 'Something went wrong! Try again.' }))
            }
        }
    }

    const clearForm = async () => {
        setOldPassword('')
        setNewPassword('')
        setConfirmPassword('')
    }

    useEffect(() => {
        if (isError) {
            setSuccess(false);
            setLoading(false);
            dispatch(clearState());
            dispatch(showError({ msg: message }))
        }
        if (isSuccess) {
            setSuccess(false);
            setLoading(false);
            clearForm();
            dispatch(clearState());
            dispatch(showSuccess({ msg: message }))
        }
    }, [isError, isSuccess, message, dispatch])

    const showOldToggle = () => {
        setIsShowOld(!isShowOld)
    }

    const showNewToggle = () => {
        setIsShowNew(!isShowNew)
    }

    const showConfirmToggle = () => {
        setIsShowConfirm(!isShowConfirm)
    }

    return (
        <React.Fragment>
            <div className="container-fluid dk-changeLoginPage">
                <div className="dk-changeLoginPageCon">
                    <div className="col-md-11 pl-0 ml-auto">
                        <div className="row ml-0 mr-0">
                            <div className="col-md-10 m-auto dk-boxsCon">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="dk-informationCols">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo officia illum repellat veniam porro.
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="dk-complinceCon">
                                            <div className="title">Change Account Login</div>
                                            <div className="dk-complaince-Body p-4">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label for="">Old Password <span class="colorRequired">*</span></label>
                                                                <label for="" className="d-flex">
                                                                    {
                                                                        (isShowOld) ? <input type="text" className="form-control" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                                                                            : <input type="password" className="form-control" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                                                                    }
                                                                    <span className="cursor-pointer" onClick={showOldToggle}>
                                                                        {
                                                                            (isShowOld) ? <FaEyeSlash /> : <FaEye />
                                                                        }
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label for="">New Password <span class="colorRequired">*</span></label>
                                                                <label for="" className="d-flex">
                                                                    {
                                                                        (isShowNew) ? <input type="text" className="form-control" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                                                            : <input type="password" className="form-control" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                                                    }
                                                                    <span className="cursor-pointer" onClick={showNewToggle}>
                                                                        {
                                                                            (isShowNew) ? <FaEyeSlash /> : <FaEye />
                                                                        }
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label for="">Confirm Password <span class="colorRequired">*</span></label>
                                                                <label for="" className="d-flex">
                                                                    {
                                                                        (isShowConfirm) ? <input type="text" className="form-control" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                                                            : <input type="password" className="form-control" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                                                    }
                                                                    <span className="cursor-pointer" onClick={showConfirmToggle}>
                                                                        {
                                                                            (isShowConfirm) ? <FaEyeSlash /> : <FaEye />
                                                                        }
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div class="d-flex justify-content-center btn-container">
                                                                <button class="dk-cancelBtn" onClick={clearForm}>Cancel</button>
                                                                <SubmitButton txt="Update" loading={loading} success={success} onClick={handleSubmit} position="" className="dk-updateBtn" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Index;