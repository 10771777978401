import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const addNew = createAsyncThunk(
    'account/employer/support/add',
    async ({ accountType, queryType, uid, comment, name, email, phone, priority, message, subject }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkjobs-authentication"));
            const response = await fetch(process.env.REACT_APP_API_URL + '/account/support/add',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({
                        accountType,
                        queryType,
                        uid,
                        comment,
                        name,
                        email,
                        phone,
                        priority,
                        subject,
                        message
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const supportSlice = createSlice({
    name: "jobseeker_support",
    initialState: {
        insertId: '0',
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: ""
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        }
    },
    extraReducers: {
        [addNew.pending]: (state) => {
            state.isLoading = true;
        },
        [addNew.fulfilled]: (state, { payload }) => {
            state.insertId = payload.result.id
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
        },
        [addNew.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.message = payload.message;
            }
            state.isLoading = false;
        },
    }
})
export const { clearState } = supportSlice.actions;

export default supportSlice.reducer;