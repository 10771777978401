import React, { useEffect } from 'react';
import './home.scss'
import { Link, useHistory } from 'react-router-dom'
import SelectPicker from '../../ui/select/SelectPicker'
import Gift from '../../../assets/images/gift.png'
import EmpFeed from './EmpFeed';
import LivejobsSec from './LivejobsSec';
import JobindustryCard from './JobindustryCard';
import Itimg from '../../../assets/images/informtech.png'
import Bpoimg from '../../../assets/images/itesbpo.png'
import RecStaffingImg from '../../../assets/images/rec-staffing.png'
import Bankingimg from '../../../assets/images/banking.png'
import CSEnggImg from '../../../assets/images/csengg.png'
import Automobile from '../../../assets/images/automobile.png'
import Travelimg from '../../../assets/images/travel.png'
import Hospitalityimg from '../../../assets/images/hospitality.png'
import FeaturedJobs from './FeaturedJobs';
import EmpagenciesBox from './EmpagenciesBox';
import SeeAllBox from './SeeAllBox';
import Carousel from 'react-bootstrap/Carousel'
import TestcarouselItem from './TestcarouselItem';
import BlogcarouselItem from './BlogcarouselItem'

const Home = () => {
    const history = useHistory();

    const countryOptions = [
        { key: 'af', value: 'af', flag: 'af', text: 'Afghanistan' },
        { key: 'ax', value: 'ax', flag: 'ax', text: 'Aland Islands' },
        { key: 'al', value: 'al', flag: 'al', text: 'Albania' },
        { key: 'dz', value: 'dz', flag: 'dz', text: 'Algeria' },
        { key: 'as', value: 'as', flag: 'as', text: 'American Samoa' },
        { key: 'ad', value: 'ad', flag: 'ad', text: 'Andorra' },
        { key: 'ao', value: 'ao', flag: 'ao', text: 'Angola' },
        { key: 'ai', value: 'ai', flag: 'ai', text: 'Anguilla' },
        { key: 'ag', value: 'ag', flag: 'ag', text: 'Antigua' },
        { key: 'ar', value: 'ar', flag: 'ar', text: 'Argentina' },
        { key: 'am', value: 'am', flag: 'am', text: 'Armenia' },
        { key: 'aw', value: 'aw', flag: 'aw', text: 'Aruba' },
        { key: 'au', value: 'au', flag: 'au', text: 'Australia' },
        { key: 'at', value: 'at', flag: 'at', text: 'Austria' },
        { key: 'az', value: 'az', flag: 'az', text: 'Azerbaijan' },
        { key: 'bs', value: 'bs', flag: 'bs', text: 'Bahamas' },
        { key: 'bh', value: 'bh', flag: 'bh', text: 'Bahrain' },
        { key: 'bd', value: 'bd', flag: 'bd', text: 'Bangladesh' },
        { key: 'bb', value: 'bb', flag: 'bb', text: 'Barbados' },
        { key: 'by', value: 'by', flag: 'by', text: 'Belarus' },
        { key: 'be', value: 'be', flag: 'be', text: 'Belgium' },
        { key: 'bz', value: 'bz', flag: 'bz', text: 'Belize' },
        { key: 'bj', value: 'bj', flag: 'bj', text: 'Benin' },
    ]

    useEffect(() => {
        var query = new URLSearchParams(window.location.search)
        if (query.get('email') != null) {
            const token = query.get('token');
            const accountType = query.get('account');
            const accountEmail = query.get('email');
            const accountUid = query.get('id');
            setTimeout(() => history.push('/acount-verify?token=' + token + '&id=' + accountUid + '&email=' + accountEmail + '&account=' + accountType), 100);
        }
    }, [history])

    return (
        <>
            <section className="dkjobs-SerchSecHome container-fluid">
                <div className="row">
                    <div className="col-md-8 col-sm-12 m-auto">
                        <div className="dkjobs-topSrchCon">
                            <div className="dkjobs-srchtitleCon">
                                <h1 className="dkjobs-srchtitle">FIND YOUR NEXT JOB HERE</h1>
                            </div>
                            <div className="dkjobsSrchFormCon">
                                <div className="row">
                                    <div className="col-md-10 col-sm-12">
                                        <div className="dkjobsSrchForm">
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-4 col-sm-12">
                                                        <div className="form-group">
                                                            <label for="defaultFormWhat">Industry?</label>
                                                            <div className="dk-hmeselectPicker">
                                                                <SelectPicker options={countryOptions} placeholder="Search Industry" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-12">
                                                        <div className="form-group">
                                                            <label for="defaultFormWhat">Location?</label>
                                                            <div className="dk-hmeselectPicker">
                                                                <SelectPicker options={countryOptions} placeholder="Search Location" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-12">
                                                        <div className="form-group">
                                                            <label for="defaultFormWhat">Language?</label>
                                                            <div className="dk-hmeselectPicker">
                                                                <SelectPicker options={countryOptions} placeholder="Search Language" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-sm-12">
                                        <div className="dkjobs-srchbtn">
                                            <button>
                                                <i className="fa fa-search mr-1"></i>
                                                search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dkjobs-advBtnCon">
                                <Link to="#"><i className="fa fa-search mr-1"></i>ADVANCED SEARCH</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="dkjobs-yellow-section container-fluid">
                <div className="row">
                    <div className="col-md-8 m-auto col-sm-12">
                        <ul className="dk-itemsCon">
                            <li>
                                <Link to="#">
                                    <span>
                                        <img src={Gift} alt="gift" className="dk-yellogift" />
                                        Jobseeker Benefits
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    <span>
                                        <img src={Gift} alt="gift" className="dk-yellogift" />
                                        Company Benefits
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    <span>
                                        <img src={Gift} alt="gift" className="dk-yellogift" />
                                        Recruitment Agency Benefits
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="employe-feeds-section container-fluid">
                <div className="row">
                    <div className="col-12">
                        <EmpFeed />
                    </div>
                    <div className="col-12 mt-4">
                        <div className="dk-empfeedbtnCon">
                            <button className="btn dk-viewallpostBtn">View All Posts</button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="dk-livejobs-section container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="secTitle">
                            <h2 >Live Jobs On Site</h2>
                        </div>
                    </div>
                </div>
                <div className="row dk-liveJobsTabsCon">
                    <LivejobsSec />
                </div>
            </section>
            <section className="dk-popularjob-section container-fluid">
                <div className="dk-sectitleCon">
                    <h2>Popular Job Industries</h2>
                </div>
                <div className="dk-cardsecCon">
                    <div className="row">
                        <div className="col-md-3 col-sm-12">
                            <JobindustryCard cardImg={Itimg} cardTitle="Information Technology" cardSubTitle="300 Jobs" />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <JobindustryCard cardImg={Bpoimg} cardTitle="ITES/BPO/KPO" cardSubTitle="300 Jobs" />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <JobindustryCard cardImg={RecStaffingImg} cardTitle="Recruitment/Staffing" cardSubTitle="300 Jobs" />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <JobindustryCard cardImg={Bankingimg} cardTitle="Banking" cardSubTitle="300 Jobs" />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <JobindustryCard cardImg={CSEnggImg} cardTitle="Computer Engineer" cardSubTitle="300 Jobs" />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <JobindustryCard cardImg={Automobile} cardTitle="Automobile" cardSubTitle="300 Jobs" />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <JobindustryCard cardImg={Travelimg} cardTitle="Travel" cardSubTitle="300 Jobs" />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <JobindustryCard cardImg={Hospitalityimg} cardTitle="Hospitality" cardSubTitle="300 Jobs" />
                        </div>
                    </div>
                </div>
                <div className="dk-viewindustryBtnCon">
                    <button className="btn dk-viewindustryBtn">View All Industries</button>
                </div>
            </section>
            <div className="dk-featuredjob-section container-fluid">
                <h2 class="section-title"><i class="fa fa-briefcase pr-2"></i>Featured Jobs</h2>
                <div className="feature-jobs-section-ul">
                    <FeaturedJobs />
                    <FeaturedJobs />
                    <FeaturedJobs />
                    <FeaturedJobs />
                    <FeaturedJobs />
                    <FeaturedJobs />
                    <FeaturedJobs />
                    <FeaturedJobs />
                    <FeaturedJobs />
                    <FeaturedJobs />
                    <FeaturedJobs />
                    <FeaturedJobs />
                </div>
            </div>
            <div className="dk-feature-employers-section container-fluid">
                <div className="section-titleCon">
                    <h2>Featured Direct Employers</h2>
                </div>
                <div className="dk-empagenceBoxSection">
                    <ul className="dk-empagenceBoxCon">
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <SeeAllBox alltitle="See All Employer" />
                    </ul>
                </div>
            </div>
            <div className="dk-feature-employers-section container-fluid">
                <div className="section-titleCon">
                    <h2>FEATURED RECRUITMENT AGENCIES</h2>
                </div>
                <div className="dk-empagenceBoxSection">
                    <ul className="dk-empagenceBoxCon">
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <EmpagenciesBox />
                        <SeeAllBox alltitle="See All Recruitment Agencies" />
                    </ul>
                </div>
            </div>
            <div className="dk-home-testimonials-section container-fluid">
                <div className="sectitleCon">
                    <h2 className="title">TESTIMONIALS</h2>
                </div>
                <div className="testimonalsCon">
                    <Carousel>
                        <Carousel.Item interval={10000}>
                            <TestcarouselItem />
                        </Carousel.Item>
                        <Carousel.Item interval={10000}>
                            <TestcarouselItem />
                        </Carousel.Item>
                        <Carousel.Item interval={10000}>
                            <TestcarouselItem />
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className="dk-testviewAllBtnCon">
                    <button className="btn dk-testviewAllBtn">View All Testimonials</button>
                </div>
            </div>
            <div className="dk-home-blog-section container-fluid">
                <div className="sectitleCon">
                    <h2 className="title">OUR BLOGS & ARTICLES</h2>
                </div>
                <div className="dk-home-blogCon">
                    <Carousel>
                        <Carousel.Item interval={100000}>
                            <BlogcarouselItem />
                        </Carousel.Item>
                        <Carousel.Item interval={100000}>
                            <BlogcarouselItem />
                        </Carousel.Item>
                        <Carousel.Item interval={100000}>
                            <BlogcarouselItem />
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
        </>
    )
}

export default Home;
