import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const listCategory = createAsyncThunk(
    'account/faqs/list_of_category',
    async ({ account }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkjobs-authentication"));
            const response = await fetch(process.env.REACT_APP_API_URL + '/admin/faqs/category/list/' + account,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                        'Authorization': 'Bearer ' + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const listAnswer = createAsyncThunk(
    'account/faqs/list_of_answer',
    async ({ catId }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkjobs-authentication"));
            const response = await fetch(process.env.REACT_APP_API_URL + '/admin/faqs/question/list_by_category/' + catId,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                        'Authorization': 'Bearer ' + token,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const faqSlice = createSlice({
    name: 'account_faqs',
    initialState: {
        categoryList: [],
        answerList: [],
        catId: 0,
        isCategoryLoading: false,
        isLoading: false,
        isError: false,
        message: ''
    },
    reducers: {
        clearState: (state) => {
            state.isCategoryLoading = false;
            state.isLoading = false;
            state.isError = false;
            state.message = '';
        }
    },
    extraReducers: {
        [listCategory.pending]: (state) => {
            state.isCategoryLoading = true;
        },
        [listCategory.fulfilled]: (state, { payload }) => {
            state.isCategoryLoading = false;
            state.categoryList = payload.result;
            state.catId = payload.result[0].id;
        },
        [listCategory.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.message = payload.message;
            }
            state.isCategoryLoading = false;
        },
        [listAnswer.pending]: (state) => {
            state.isLoading = true;
        },
        [listAnswer.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.answerList = payload.result;
        },
        [listAnswer.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.message = payload.message;
            }
            state.isLoading = false;
        },
    }
});
export const { clearState } = faqSlice.actions;

export default faqSlice.reducer;