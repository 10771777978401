import React from 'react';
import { Route, Link } from 'react-router-dom';

const JobsCategory = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="dk-jobslisting-category">
                <div className="d-flex align-items-center justify-content-between">
                    <Route>
                        <ul className="filterUl">
                            <li>
                                <Link to="/jobseeker/standard/search-box" className={`${pathname.match('/jobseeker/standard/search-box') ? 'active' : ''}`}>All Jobs</Link>
                            </li>
                            <li><Link to="/jobseeker/standard/search-by-level" className={`${pathname.match('/jobseeker/standard/search-by-level') ? 'active' : ''}`}>Level</Link></li>
                            <li><Link to="/jobseeker/standard/search-by-industry" className={`${pathname.match('/jobseeker/standard/search-by-industry') ? 'active' : ''}`}>Industry</Link></li>
                            <li><Link to="/jobseeker/standard/search-by-company" className={`${pathname.match('/jobseeker/standard/search-by-company') ? 'active' : ''}`}>Company</Link></li>
                            <li><Link to="/jobseeker/standard/search-by-job-profile" className={`${pathname.match('/jobseeker/standard/search-by-job-profile') ? 'active' : ''}`}>Job Profile</Link></li>
                            <li><Link to="/jobseeker/standard/search-by-country" className={`${pathname.match('/jobseeker/standard/search-by-country') ? 'active' : ''}`}>Country</Link></li>
                            <li><Link to="/jobseeker/standard/search-by-city" className={`${pathname.match('/jobseeker/standard/search-by-city') ? 'active' : ''}`}>City</Link></li>
                            <li><Link to="/jobseeker/standard/search-by-language" className={`${pathname.match('/jobseeker/standard/search-by-language') ? 'active' : ''}`}>Language</Link></li>
                            <li><Link to="/jobseeker/standard/search-by-work-permit" className={`${pathname.match('/jobseeker/standard/search-by-work-permit') ? 'active' : ''}`}>Work Permit</Link></li>
                        </ul>
                    </Route>
                </div>
            </div>
        </>
    )
}

export default JobsCategory;
