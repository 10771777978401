import React from 'react';
import './consent.scss'

const DeleteAccount = () => {
    return (
        <>
            <div className="container-fluid dk-settDelPageMain">
                <div className="dk-settDelPageCon">
                    <div className="col-md-11 pl-0 ml-auto">
                        <div className="row ml-0 mr-0">
                            <div className="col-md-10 m-auto dk-boxsCon">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="dk-informationCols">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo officia illum repellat veniam porro.
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="dk-complinceCon consent">
                                            <div className="title">Withdraw Consent</div>
                                            <h4>This will Delete your account permanently</h4>
                                            <div className="dk-complaince-Body pt-0">
                                                <p>Withdrawing consent from our "Privacy Policy" and "Terms & Use" means that you would no longer be able to user DK Global Jobs site have use employer account. Your account will be deleted permanently, and all you data will be deleted from website. We may keep some of your basic information under state tax laws for the purpose of accounting & invoices.</p>
                                                <div className="d-flex justify-content-center align-items-center mt-4 checkboxCon mb-4">
                                                    <input type="checkbox" name="" id="" />
                                                    <label for="" className="pl-1">I understand that i will lose contents and account access</label>
                                                </div>
                                                <div class="dk-complaince-field m-auto">
                                                    <div class="d-flex justify-content-center">
                                                        <input type="text" name="" placeholder="Password Confirmation Required" class="form-control txt-password" id="" />
                                                        <button class="dk-confirmBtn">Confirm</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteAccount;
