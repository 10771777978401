import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SearchFilter from './SearchFilter';
import ProfileImg from "../../../../../../../assets/images/deepak.png";
import './searchnew.scss';
import SearchCvViewModal from './SearchCvViewModal';

const SearchNew = () => {
    const [SearchView, setSearchViewstate] = useState(false)
    const showSearchCvModal = () => {
        setSearchViewstate(true)
    }
    const hideEditModal = () => {
        setSearchViewstate(false)
    }
    return (
        <>
            {
                SearchView ?
                    <SearchCvViewModal handleClose={hideEditModal} /> : null
            }
            <div className="dk-searchNewMain">
                <div className="dk-searchNew-head">
                    <div className="dk-searchBoxFilter">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="dk-jobs-list">
                                <div className="dk-jobsSearchBox">
                                    <input type="text" name="" id="" placeholder="Search..." className="form-control" />
                                </div>
                            </div>
                            <div className="dk-jobs-cate-link">
                                <Link to="#">Last 24 hours <i className="fas fa-times"></i></Link>
                                <Link to="#">Full Time <i className="fas fa-times"></i></Link>
                                <Link to="#">FAQ</Link>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="d-md-flex">
                    <div className="dk-searchFilterPanel">
                        <SearchFilter />
                    </div>
                    <div className="dk-searchNew-detailPanel">
                        <div className="dk-searchNew-header">
                            <div className="d-md-flex align-items-center justify-content-between">
                                <div className="childTitle">
                                    <div className="dk-jobs-list">1-50 of 11326 Jobs</div>
                                </div>
                                <div className="childTitle-right">
                                    <div className="childTitle">Salary</div>
                                    <div className="childTitle">Skill</div>
                                    <div className="childTitle">Language</div>
                                    <div className="childTitle">Location</div>
                                    <div className="childTitle">Logins</div>
                                    <div className="childTitle">Actions</div>
                                </div>
                            </div>
                        </div>

                        <div className="dk-searchNew-col-12 odd">
                            <div className="d-md-flex align-items-center justify-content-between">
                                <div className="dk-searchNew-col-left">
                                    <div className="d-flex align-items-center">
                                        <div className="dk-searchNew-col-img">
                                            <img src={ProfileImg} className="cursor-pointer" alt="" />
                                        </div>
                                        <div className="dk-searchNew-col-detail">
                                            <div className="title">
                                                <Link to="#">
                                                    Wanda Montgomery
                                                    <i className="fas fa-check-circle"></i>
                                                </Link>
                                            </div>
                                            <div className="textDetails"><li>Charted Accountant</li></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-searchNew-col-right">
                                    <div className="dk-searchNew-rightCols"><span>10 Euro</span></div>
                                    <div className="dk-searchNew-rightCols"><span>Software Developer</span></div>
                                    <div className="dk-searchNew-rightCols"><span>German</span><span>English</span></div>
                                    <div className="dk-searchNew-rightCols"><span>Ireland</span><span>India</span></div>
                                    <div className="dk-searchNew-rightCols"><span>10 Nov 2020</span><span>19 Dec 2020</span></div>
                                    <div className="dk-searchNew-rightCols">
                                        <div className="saveTag skyblue"><i className="far fa-snowflake"></i></div>
                                        <Link to="#" onClick={showSearchCvModal}>View</Link>
                                        <Link to="#">Save</Link>
                                        <Link to="#">Block</Link>
                                        <Link to="#">Download</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="dk-searchNew-col-12 even">
                            <div className="d-md-flex align-items-center justify-content-between">
                                <div className="dk-searchNew-col-left">
                                    <div className="d-flex align-items-center">
                                        <div className="dk-searchNew-col-img">
                                            <img src={ProfileImg} className="cursor-pointer" alt="" />
                                        </div>
                                        <div className="dk-searchNew-col-detail">
                                            <div className="title">
                                                <Link to="#">Wanda Montgomery <i className="red-fa fas fa-times-circle"></i></Link>
                                            </div>
                                            <div className="textDetails"><li>Charted Accountant</li></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-searchNew-col-right">
                                    <div className="dk-searchNew-rightCols"><span>10 Euro</span></div>
                                    <div className="dk-searchNew-rightCols"><span>Software Developer</span></div>
                                    <div className="dk-searchNew-rightCols"><span>German</span><span>English</span></div>
                                    <div className="dk-searchNew-rightCols"><span>Ireland</span><span>India</span></div>
                                    <div className="dk-searchNew-rightCols"><span>10 Nov 2020</span><span>19 Dec 2020</span></div>
                                    <div className="dk-searchNew-rightCols">
                                        <div className="saveTag red"><i className="fab fa-hotjar"></i></div>
                                        <Link to="#" onClick={showSearchCvModal}>View</Link>
                                        <Link to="#">Save</Link>
                                        <Link to="#">Block</Link>
                                        <Link to="#">Download</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Pagination */}
                        <div className="dk-searchJobs-pagination">
                            <div className="dk-totalRecord">Showing 1 to 2 (of 20 entries)</div>
                            <ul className="pagination-circle pagination justify-content-center">
                                <li className="page-item">
                                    <Link to="#"><i class="fas fa-angle-double-left"></i></Link>
                                </li>
                                <li className="page-item">
                                    <Link to="#">1</Link>
                                </li>
                                <li className="page-item">
                                    <Link to="#">2</Link>
                                </li>
                                <li className="page-item">
                                    <Link to="#" className="active">3</Link>
                                </li>
                                <li className="page-item">
                                    <Link to="#">4</Link>
                                </li>
                                <li className="page-item">
                                    <Link to="#">5</Link>
                                </li>
                                <li className="page-item">
                                    <Link to="#"><i class="fas fa-angle-double-right"></i></Link>
                                </li>
                            </ul>
                        </div>
                        {/* end */}


                    </div>
                </div>


            </div>
        </>
    )
}

export default SearchNew;
