import React, { useEffect, useCallback, useState, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from '../../../../../../../ui/submitButton';
import AWS from 'aws-sdk';
import Modal from 'react-bootstrap/Modal'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { clearState, profileDetails, updateLogo } from '../../../../../../../../slice/account/employer/profile/profileSlice'
import { showSuccess, showError, clearMessage } from '../../../../../../../../slice/utils/message/messageSlice';

const Logo = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.employerAuth);
    const { details, isLoading, isSuccess, isError, message } = useSelector(state => state.employerProfile);

    const [isImageUpdate, setIsImageUpdate] = useState(false)
    const [newImageData, setNewImageData] = useState('')
    const [profileImage, setProfileImage] = useState('')

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const fileInput = useRef();
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState(
        {
            unit: 'px',
            width: 130,
            height: 40,
            aspect: 1 / 1
        }
    );
    const [completedCrop, setCompletedCrop] = useState(null);
    const [cropModal, setCropModal] = useState(false)

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
            setCropModal(true)
        }
    }

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
        const base64Image = canvas.toDataURL("image/jpeg");

        setIsImageUpdate(true)
        setNewImageData(base64Image)
    }, [completedCrop]);

    const handleClose = () => {
        setCropModal(false);
        fileInput.current.value = ''
    }

    const handleCropDone = () => {
        setCropModal(false);
    }

    const cancelCrop = () => {
        setCropModal(false);
        setNewImageData('')
        setIsImageUpdate(false)
        setCompletedCrop(null)
    }

    useEffect(() => {
        dispatch(clearState())
        async function fetchData() {
            try {
                dispatch(profileDetails({ uid: userInfo.employerUid }))
            } catch (err) {
                console.log(err)
            }
        }
        fetchData()
    }, [dispatch])

    useEffect(() => {
        if (!isLoading) {
            setProfileImage(details.companyImage)
        }
        if (isSuccess) {
            setSuccess(true);
            setLoading(false);
            dispatch(showSuccess({ msg: 'Logo Updated successfully' }))
            dispatch(clearState())
        }
    }, [isLoading, isSuccess])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            dispatch(clearMessage())
            setSuccess(false);
            setLoading(true);
            if (isImageUpdate) {
                let s3bucket = new AWS.S3({
                    accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
                    secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
                    Bucket: process.env.REACT_APP_BUCKET_NAME + '/users/employer/images/company'
                });
                let buf = new Buffer(newImageData.replace(/^data:image\/\w+;base64,/, ""), 'base64')
                s3bucket.createBucket(function () {
                    var params = {
                        Bucket: process.env.REACT_APP_BUCKET_NAME + '/users/employer/images/company',
                        Key: userInfo.uid + '-' + Date.now() + '.png',
                        ContentEncoding: 'base64',
                        ContentType: 'image/png',
                        Body: buf
                    };
                    s3bucket.upload(params, function (err, data) {
                        if (err) {
                            console.log(err)
                        } else {
                            setProfileImage("/" + data.key)
                            dispatch(updateLogo({ uid: userInfo.employerUid, companyImage: "/" + data.key }))
                        }
                    });
                })
            }
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again. ' + err }))
        }
    }

    return (
        <React.Fragment>
            {
                (cropModal) ?
                    <Modal className="dk-cropImgModal" show={true} onHide={handleClose}>
                        <Modal.Body>
                            <ReactCrop
                                src={upImg}
                                onImageLoaded={onLoad}
                                crop={crop}
                                onChange={(c) => setCrop(c)}
                                onComplete={(c) => setCompletedCrop(c)}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12">
                                <div class="d-flex justify-content-center btn-container">
                                    <button class="dk-cancelBtn" onClick={cancelCrop}>Cancel</button>
                                    <SubmitButton txt="Use This" loading={loading} success={success} onClick={handleCropDone} position="" className="dk-updateBtn" />
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal> : null
            }
            <div className="row">
                <div className="col-md-6 dk-uploadlogopanel">
                    <div className="d-flex align-items-center">
                        <div className="dk-stProfile">
                            {
                                (completedCrop) ?
                                    <canvas
                                        ref={previewCanvasRef}
                                    />
                                    : <img alt="Crop" id="cropImgId" src={process.env.REACT_APP_IMAGE_BASE_URL + profileImage} />
                            }
                        </div>
                        <div className="dk-stImg-uploadBtn">
                            <input type="file" accept="image/*" name="companyLogo" onChange={onSelectFile} ref={fileInput} />
                            <span>Change Logo</span>
                        </div>
                        {
                            (isImageUpdate) ?
                                <div className="dk-stImg-updateLogoBtn ml-2">
                                    <SubmitButton txt="Update Logo" loading={loading} success={success} onClick={handleSubmit} position="" className="dk-updateBtn" />
                                </div>
                                : null
                        }
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="dk-ownerForm-note">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deleniti dolor modi,
                        illo unde deserunt cupiditate.
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Logo;