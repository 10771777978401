import React from 'react'
import { Link } from 'react-router-dom'
import ResumeImg from '../../../../../../../../assets/images/resume_img.png';

const ApperanceCV = () => {
    return (
        <>
            <div className="dk-apperenceCvCon row mb-5">
                <div className="dk-headingCon col-md-12 col-12">
                    <h6>Choose the form of your CV that will be sent after a reaction and that you will be able to save as a PDF or DOC.</h6>
                </div>
                <div className="col-md-3 dk-apperance-CVBox">
                    <div className="dk-profileImgCols">
                        <Link to="#/jobseeker/standard/myresume/create-resume">
                            <img src={ResumeImg} className="img-fluid" alt="" />
                            <figure>
                                <div className="dk-checkIcon">
                                    <i className="fa fa-check fa-3x" aria-hidden="true"></i>
                                </div>
                                <div className="footSec">
                                    <button>Select and Continue</button>
                                </div>
                            </figure>
                        </Link>
                    </div>
                </div>
                <div className="col-md-3 dk-apperance-CVBox">
                    <div className="dk-profileImgCols">
                        <Link to="#/jobseeker/standard/myresume/create-resume">
                            <img src={ResumeImg} className="img-fluid" alt="" />
                            <figure>
                                <div className="dk-checkIcon">
                                    <i className="fa fa-check fa-3x" aria-hidden="true"></i>
                                </div>
                                <div className="footSec">
                                    <button>Select and Continue</button>
                                </div>
                            </figure>
                        </Link>
                    </div>
                </div>
                <div className="col-md-3 dk-apperance-CVBox">
                    <div className="dk-profileImgCols">
                        <Link to="#/jobseeker/standard/myresume/create-resume">
                            <img src={ResumeImg} className="img-fluid" alt="" />
                            <figure>
                                <div className="dk-checkIcon">
                                    <i className="fa fa-check fa-3x" aria-hidden="true"></i>
                                </div>
                                <div className="footSec">
                                    <button>Select and Continue</button>
                                </div>
                            </figure>
                        </Link>
                    </div>
                </div>
                <div className="col-md-3 dk-apperance-CVBox">
                    <div className="dk-profileImgCols">
                        <Link to="#/jobseeker/standard/myresume/create-resume">
                            <img src={ResumeImg} className="img-fluid" alt="" />
                            <figure>
                                <div className="dk-checkIcon">
                                    <i className="fa fa-check fa-3x" aria-hidden="true"></i>
                                </div>
                                <div className="footSec">
                                    <button>Select and Continue</button>
                                </div>
                            </figure>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApperanceCV;
