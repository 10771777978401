import React from 'react'
import Navbar from '../../components/elements/navgation/Navgation';
import Footer from '../../components/elements/footer/Footer';

const index = ({ children }) => {
    return (
        <React.Fragment>
            <Navbar />
            {children}
            <Footer />
        </React.Fragment>
    )
}
export default index;