import React from 'react'
import { Link } from 'react-router-dom'
import './submenu.scss'

const DKServiceSubmenu = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="container-fluid">
                <div className="dk-ds-sub-menu col-md-12 pl-0 pr-0 ml-auto">
                    <div className="dk-ds-sub-menu-cols">
                        <ul>
                            <li>
                                <Link className={pathname === "/employer/standard/dkg-serivces/abouts" ? 'sub-active' : ''} to="/employer/standard/dkg-serivces/abouts">About us</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/dkg-serivces/recruitment" ? 'sub-active' : ''} to="/employer/standard/dkg-serivces/recruitment">Recruitment</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/dkg-serivces/job-board" ? 'sub-active' : ''} to="/employer/standard/dkg-serivces/job-board">Job Board</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/dkg-serivces/trading" ? 'sub-active' : ''} to="/employer/standard/dkg-serivces/trading">Trading</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/dkg-serivces/it-solutions" ? 'sub-active' : ''} to="/employer/standard/dkg-serivces/it-solutions">IT Solutions</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/dkg-serivces/academy" ? 'sub-active' : ''} to="/employer/standard/dkg-serivces/academy">Academy</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/dkg-serivces/marketing" ? 'sub-active' : ''} to="/employer/standard/dkg-serivces/marketing">Marketing</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DKServiceSubmenu
