import React from 'react'
import { Link } from 'react-router-dom'
import './submenu.scss'

const SearchCvSubmenu = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="container-fluid">
                <div className="dk-ds-sub-menu col-md-12 pl-0 pr-0 ml-auto">
                    <div class="dk-ds-sub-menu-cols">
                        <ul>
                            <li>
                                <Link className={pathname === "/employer/standard/searchcv/summary" ? 'sub-active' : ''} to="/employer/standard/searchcv/summary">Summary</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/searchcv/search-new" ? 'sub-active' : ''} to="/employer/standard/searchcv/search-new">Search New</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/searchcv/my-cv" ? 'sub-active' : ''} to="/employer/standard/searchcv/my-cv">My CV's</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SearchCvSubmenu;