import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const contractsList = createAsyncThunk(
    'account/contracts/list',
    async ({ uid }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkjobs-authentication"));
            const response = await fetch(process.env.REACT_APP_API_URL + '/contracts/accounts/' + uid,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                        'Authorization': 'Bearer ' + token,
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const contractsAdd = createAsyncThunk(
    'account/contracts/add',
    async ({ uid, accountType, name, category, description }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkjobs-authentication"));
            const response = await fetch(process.env.REACT_APP_API_URL + '/contracts/accounts',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({
                        uid,
                        accountType,
                        name,
                        category,
                        description
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const contractsDetails = createAsyncThunk(
    'account/contracts/details',
    async ({ ref, id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkjobs-authentication"));
            const response = await fetch(process.env.REACT_APP_API_URL + '/contracts/accounts/' + ref + '/' + id,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                        'Authorization': 'Bearer ' + token,
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const contractsDelete = createAsyncThunk(
    'account/contracts/delete',
    async ({ id }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkjobs-authentication"));
            const response = await fetch(process.env.REACT_APP_API_URL + '/contracts/accounts/' + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                        'Authorization': 'Bearer ' + token,
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const contractsSlice = createSlice({
    name: "accounts_contracts",
    initialState: {
        dataList: [],
        dataDetails: {},
        insertId: '0',
        isSuccess: false,
        isLoading: false,
        isInsert: false,
        isUpdate: false,
        isDelete: false,
        isError: false,
        msg: ""
    },
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isInsert = false;
            state.isUpdate = false;
            state.isDelete = false;
            state.isError = false;
            state.msg = "";
        }
    },
    extraReducers: {
        [contractsList.pending]: (state) => {
            state.isLoading = true;
        },
        [contractsList.fulfilled]: (state, { payload }) => {
            state.dataList = payload.result;
            state.isLoading = false;
            state.msg = payload.message;
        },
        [contractsList.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.msg = payload.message;
            }
            state.isLoading = false;
        },
        [contractsDetails.pending]: (state) => {
            state.isSuccess = false;
        },
        [contractsDetails.fulfilled]: (state, { payload }) => {
            state.dataDetails = payload.result[0];
            state.isSuccess = true;
            state.msg = payload.message;
        },
        [contractsDetails.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.msg = payload.message;
            }
            state.isSuccess = false;
        },
        [contractsAdd.pending]: (state) => {
            state.isLoading = true;
        },
        [contractsAdd.fulfilled]: (state, { payload }) => {
            state.insertId = payload.result.id
            state.dataList.unshift(payload.result);
            state.isInsert = true;
            state.isLoading = false;
            state.msg = payload.message;
        },
        [contractsAdd.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.msg = payload.message;
            }
            state.isLoading = false;
        },
        [contractsDelete.pending]: (state) => {
            state.isDelete = false;
        },
        [contractsDelete.fulfilled]: (state, action) => {
            state.dataList = state.dataList.filter((item) => item.id !== action.meta.arg.id);
            state.isDelete = true;
            state.msg = action.payload.message;
        },
        [contractsDelete.rejected]: (state, action) => {
            if (!action.payload.status) {
                state.isError = true;
                state.msg = action.payload.message;
            }
            state.isDelete = false;
        },
    }
});
export const { clearState } = contractsSlice.actions;

export default contractsSlice.reducer;