import React from 'react';
import "./singupfaq.scss";
import Modal from 'react-bootstrap/Modal'
import FaqAccordion from './FaqAccordion';

const SingupFaqModal = ({ onHide }) => {
    return (
        <>
            <Modal
                show={true}
                onHide={onHide}
                dialogClassName="dksignUpFaqModal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="dk-signupHeader">
                    <Modal.Title>SIGN UP FAQ</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-signupBody">
                    <FaqAccordion />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SingupFaqModal;
