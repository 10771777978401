import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'
import './emjobs.scss';
import { showSuccess, showError, clearMessage } from '../../../../../../slice/utils/message/messageSlice';
import { postNew } from '../../../../../../slice/utils/message/messageSlice';
import SubmitButton from './SubmitButton';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const PostNewJobs = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.employerAuth);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const employerId = userInfo.employerUid;
    const creditUsed = 0;
    const createdBy = userInfo.uid;
    const updatedBy = userInfo.uid;
    const postingDate = moment(new Date()).format("DD-MM-YYYY");
    const expireDate = moment(new Date()).add(1, 'M').format("DD-MM-YYYY");
    const [companyName, setCompanyName] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [jobType, setJobType] = useState('');
    const [jobRef, setJobRef] = useState('');
    const [jobSummary, setJobSummary] = useState('');
    const [jobDetails, setJobDetails] = useState('');
    const [jobSalary, setJobSalary] = useState('');
    const [jobCity, setJobCity] = useState('');
    const [jobCountry, setJobCountry] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            console.log(postingDate + " == " + expireDate)
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            // dispatch(showError({ msg: 'Something went wrong! Try again. ' + err }))
        }
    }

    return (
        <>
            <div className="dk-postNewJobMain">
                <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                    <div className="d-md-flex">
                        <div className="dk-postNew-leftPanel">
                            <Nav variant="pills">
                                <Nav.Item>
                                    <Nav.Link eventKey="tabs_1">Posting Form</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tabs_2">Suggestions</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tabs_3">Conditions</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tabs_4">Credits</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="dk-postNew-Rightpanel">
                            <Tab.Content>
                                <Tab.Pane eventKey="tabs_1">
                                    <div className="dk-postingPanel">
                                        <div className="dk-sdpost-main-cols">
                                            <div className="dk-sdpost-top">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="dk-sdpost-top-box">
                                                        <li className="textorange"><span>Posted by</span> - Deepak Kumar</li>
                                                        <li className="textgreen"><span>Available Credits</span> - 20</li>
                                                    </div>
                                                    <div className="dk-sdpost-top-box text-center title">JOB POSTING FORM</div>
                                                    <div className="dk-sdpost-top-box dk-sdpost-rightbox text-right">
                                                        <li><span>Posting on</span> - 01 May 2020</li>
                                                        <li><span>Expired on</span> - 01 May 2020</li>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="dk-postNewJob-cols">
                                            <div className="dk-newJobsCols">
                                                <div className="col-md-4">
                                                    <div className="dk-formBox">
                                                        <div className="justify-content-center mt-4 mb-4 d-flex align-items-center">
                                                            <label className="grey-text">Company:<span className="colorRequired">*</span></label><input type="text" className="form-control" />
                                                        </div>
                                                        <div className="justify-content-center mt-3 mb-3 d-flex align-items-center">
                                                            <label className="grey-text">Job Type:<span className="colorRequired">*</span></label>
                                                            <select className="form-control"></select>
                                                        </div>
                                                        <div className="justify-content-center mb-4 d-flex align-items-center">
                                                            <label className="grey-text">Salary:<span className="colorRequired">*</span></label>
                                                            <div data-test="input-group" className="input-group">
                                                                <input data-test="input" type="text" className="form-control" aria-disabled="false" value="" /><label className="" data-error="" data-success="" id="" aria-labelledby=""> </label>
                                                                <div className="input-group-append"><span className="input-group-text">Euro</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="justify-content-center mb-4 d-flex align-items-center">
                                                            <label className="grey-text">Job City:<span className="colorRequired">*</span></label>
                                                            <select className="form-control"></select>
                                                        </div>
                                                        <div className="justify-content-center mb-4 d-flex align-items-center">
                                                            <label className="grey-text">Job Country:<span className="colorRequired">*</span></label>
                                                            <select className="form-control"></select>
                                                        </div>
                                                        <div className="justify-content-center mb-4 d-flex align-items-center">
                                                            <label className="grey-text">Language 1:<span className="colorRequired">*</span></label>
                                                            <select className="form-control"></select>
                                                        </div>
                                                        <div className="justify-content-center mb-4 d-flex align-items-center">
                                                            <label className="grey-text">Language 2:</label>
                                                            <select className="form-control"></select>
                                                        </div>
                                                        <div className="justify-content-center mb-4 d-flex align-items-center">
                                                            <label className="grey-text">Job Industry:<span className="colorRequired">*</span></label>
                                                            <select className="form-control"></select>
                                                        </div>
                                                        <div className="justify-content-center mb-4 d-flex align-items-center">
                                                            <label className="grey-text">Department:<span className="colorRequired">*</span></label><input type="text" className="form-control" />
                                                        </div>
                                                        <div className="justify-content-center mb-4 d-flex align-items-center">
                                                            <label className="grey-text">Qualification:<span className="colorRequired">*</span></label>
                                                            <select className="form-control"></select>
                                                        </div>
                                                        <div className="justify-content-center mb-4 d-flex align-items-center">
                                                            <label className="grey-text">Experince:<span className="colorRequired">*</span></label>
                                                            <select className="form-control"></select>
                                                        </div>
                                                        <div className="justify-content-center mb-4 d-flex align-items-center">
                                                            <label className="grey-text">Career Level:<span className="colorRequired">*</span></label><input type="text" className="form-control" />
                                                        </div>
                                                        <div className="justify-content-center mt-4 mb-4 d-flex align-items-center infoDiv">
                                                            <label className="grey-text">Job Profile:<span className="colorRequired">*</span></label><input type="text" className="form-control" /><span className="dk-infoTag"><i className="fas fa-question"></i></span>
                                                            <div className="dk-infoModalContainer"></div>
                                                        </div>
                                                        <div className="justify-content-center mb-4 d-flex align-items-center">
                                                            <label className="grey-text">Work Permit:<span className="colorRequired">*</span></label>
                                                            <select className="form-control"></select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="dk-formRightPanel">
                                                        <form action="">
                                                            <div className="row justify-content-center mb-3">
                                                                <div data-test="col" className="col-3">
                                                                    <label className="grey-text">Job Ref:
                                                                        <span className="colorRequired">*</span>
                                                                    </label>
                                                                    <input type="text" className="form-control" />
                                                                </div>
                                                                <div data-test="col" className="col-9">
                                                                    <label className="grey-text">Job Title:
                                                                        <span className="colorRequired">*</span>
                                                                    </label>
                                                                    <input type="text" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div data-test="row" class="row justify-content-center mb-3">
                                                                <div data-test="col" class="col-12">
                                                                    <label class="grey-text">Job Summary:</label>
                                                                    <textarea class="form-control"></textarea>
                                                                </div>
                                                            </div>
                                                            <div data-test="row" class="row justify-content-center mb-3 standard-job-post-editor">
                                                                <div data-test="col" class="col-12">
                                                                    <CKEditor
                                                                        editor={ClassicEditor}
                                                                        data="<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>"
                                                                        onInit={editor => {
                                                                            console.log('Editor is ready to use!', editor);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-center text-center job-post-footer p-2">
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip id="button-tooltip-2">VIEW AD</Tooltip>}
                                                                >
                                                                    <button className="previewbtn">
                                                                        View Ad <i class="far fa-eye fa-lg"></i>
                                                                    </button>
                                                                </OverlayTrigger>
                                                                <SubmitButton txt="Post Ad" loading={loading} success={success} onClick={handleSubmit} position="d-flex justify-content-center" className="dk-updateBtn" />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tabs_2">
                                    <div className="dk-postOtherPanel">
                                        <h2>Suggestions</h2>
                                        <div className="dk-panelCols">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, eum eaque dignissimos at dolores repellat nihil, molestias aliquid incidunt officiis in, esse labore! Unde deleniti ratione optio illum. Dicta, maxime!</p>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis obcaecati rerum dignissimos exercitationem, doloremque voluptates maxime dolorum sed facilis esse harum magni repellendus aliquid aliquam id, dolore vitae possimus. Rem! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni similique cupiditate asperiores fuga expedita, consequatur laborum distinctio id repudiandae enim illo quo quidem quas mollitia. Deserunt beatae eveniet culpa labore.</p>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tabs_3">
                                    <div className="dk-postOtherPanel">
                                        <h2>Conditions</h2>
                                        <div className="dk-panelCols">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, eum eaque dignissimos at dolores repellat nihil, molestias aliquid incidunt officiis in, esse labore! Unde deleniti ratione optio illum. Dicta, maxime!</p>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis obcaecati rerum dignissimos exercitationem, doloremque voluptates maxime dolorum sed facilis esse harum magni repellendus aliquid aliquam id, dolore vitae possimus. Rem! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni similique cupiditate asperiores fuga expedita, consequatur laborum distinctio id repudiandae enim illo quo quidem quas mollitia. Deserunt beatae eveniet culpa labore.</p>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tabs_4">
                                    <div className="dk-postOtherPanel">
                                        <h2>Credits</h2>
                                        <div className="dk-panelCols">
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus, eum eaque dignissimos at dolores repellat nihil, molestias aliquid incidunt officiis in, esse labore! Unde deleniti ratione optio illum. Dicta, maxime!</p>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis obcaecati rerum dignissimos exercitationem, doloremque voluptates maxime dolorum sed facilis esse harum magni repellendus aliquid aliquam id, dolore vitae possimus. Rem! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni similique cupiditate asperiores fuga expedita, consequatur laborum distinctio id repudiandae enim illo quo quidem quas mollitia. Deserunt beatae eveniet culpa labore.</p>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </>
    )
}

export default PostNewJobs;
