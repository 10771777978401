import React, { useEffect, useState } from 'react';
import './forgot.scss';
import { useHistory } from "react-router-dom";
import SubmitButton from "../../ui/submitButton";
import Constants from '../../constants';
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, clearState } from "../../../slice/account/forgetPassword/forgetPasswordSlice";
import { showSuccess, showError, clearMessage } from "../../../slice/utils/message/messageSlice";

const ResetPassword = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { isLoading, isSuccess, isError, message } = useSelector(state => state.forgetPassword)

    const { account, email } = props.match.params;

    const [accountType, setAccountType] = useState('');
    const [accountEmail, setAccountEmail] = useState('');

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setAccountType(atob(account))
        setAccountEmail(atob(email))

        document.title = "Reset Password | DK Global Jobs"
        dispatch(clearMessage());
        dispatch(clearState());

    }, [dispatch, account, email])

    const validateForm = () => {
        let formIsValid = true;
        let pattern = '';

        if (!password || !confirmPassword) {
            formIsValid = false;
            dispatch(showError({ msg: "Please fill all required details" }))
        }

        if (typeof password !== "undefined") {
            //regular expression for Password validation
            pattern = new RegExp(Constants.SYSTEM_CONSTANTS.Validation.Password);
            if (!pattern.test(password)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Please enter secure and strong password." }));
            } else {
                if (password.length < 8) {
                    formIsValid = false;
                    dispatch(showError({ msg: "*Password should be atleast 8 character." }));
                }
            }
        }

        if (typeof confirmPassword !== "undefined") {
            if (password !== confirmPassword) {
                formIsValid = false;
                dispatch(showError({ msg: "*Confirm password do not matchwd with password." }));
            }
        }
        return formIsValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validateForm()) {
            try {
                dispatch(clearMessage())
                await dispatch(resetPassword({ account: accountType, email: accountEmail, new_password: password }))
                if (isLoading) {
                    setSuccess(false);
                    setLoading(true);
                }
            } catch (err) {
                setSuccess(false);
                setLoading(false);
                dispatch(showError({ msg: 'Something went wrong! Try again.' }))
            }
        }
    }

    useEffect(() => {
        if (isError) {
            setSuccess(false);
            setLoading(false);
            dispatch(clearState());
            dispatch(showError({ msg: message }))
        }
        if (isSuccess) {
            dispatch(clearState());
            dispatch(showSuccess({ msg: message }))
            setSuccess(true);
            setLoading(false);
            if (accountType === "jobseeker") {
                setTimeout(() =>
                    history.push('/login/jobseeker-login'), 1000);
            } else {
                setTimeout(() =>
                    history.push('/login/employer-login'), 1000);
            }
        }
    }, [isError, isSuccess, dispatch, message, account, email, history])

    return (
        <React.Fragment>
            <div className="dk-forgotPassMain">
                <div className="dk-forgotCols">
                    <h2 className="title">Reset Password</h2>
                    <div className="dk-forgotContainer">
                        <form action="">
                            <label htmlFor="">
                                <input type="Password" placeholder="New Password*" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </label>
                            <label htmlFor="">
                                <input type="Password" placeholder="Confirm Password*" className="form-control" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                            </label>
                            <label htmlFor="" className="text-center mt-3 mb-0">
                                <SubmitButton txt="Set Password" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="dk-sendBtn" />
                            </label>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ResetPassword;