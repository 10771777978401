import React, {useState} from 'react';
import Company from "../../../../assets/images/companies.jpg";
import Company2 from "../../../../assets/images/companies_2.jpg";
import Company3 from "../../../../assets/images/companies_3.jpg";
import Company4 from "../../../../assets/images/companies_4.jpg";

const CompanyLogo = () => {
    return (
        <>
            <div className="dk-companiesMain">
                <div className="dk-companiesBox">
                    <img src={Company} className="img-fluid" alt="" />
                </div>
                <div className="dk-companiesBox">
                    <img src={Company2} className="img-fluid" alt="" />
                </div>
                <div className="dk-companiesBox">
                    <img src={Company3} className="img-fluid" alt="" />
                </div>
                <div className="dk-companiesBox">
                    <img src={Company4} className="img-fluid" alt="" />
                </div>
                <div className="dk-companiesBox">
                    <img src={Company} className="img-fluid" alt="" />
                </div>
                <div className="dk-companiesBox">
                    <img src={Company2} className="img-fluid" alt="" />
                </div>
            </div>   
        </>
    )
}

export default CompanyLogo;
