import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import SubmitButton from "../../../../../ui/submitButton";
import { showSuccess, showError, clearMessage } from "../../../../../../slice/utils/message/messageSlice";
import { addNew, clearState } from "../../../../../../slice/account/jobseeker/support/supportSlice";
import { insertAttachment } from "../../../../../../slice/attachmentSlice";
import { addNotification } from "../../../../../../slice/notification/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
import AWS from 'aws-sdk';

const Index = () => {
    const fileInput = useRef();
    const dispatch = useDispatch();
    const { insertId, isLoading, isSuccess, isError, message } = useSelector(state => state.jobseekerSupport);
    const { userInfo } = useSelector(state => state.jobseekerAuth);

    let pathname = window.location.pathname;

    pathname = pathname.substring(pathname.lastIndexOf('/') + 1)

    const accountType = 'Jobseeker';
    const queryType = pathname.charAt(0).toUpperCase() + pathname.slice(1);
    const uid = userInfo.uid;

    const [name, setName] = useState(userInfo.name)
    const [email, setEmail] = useState(userInfo.email)
    const [phone, setPhone] = useState(userInfo.mobile)
    const [priority, setPriority] = useState('Low')
    const [subject, setSubject] = useState('')
    const [messageTxt, setMessageTxt] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [isAttachment, setIsAttachment] = useState(false);
    const [reference, setReference] = useState('support_queries')
    const [reference_id, setReference_id] = useState(insertId)
    const [file_name, setFile_name] = useState('')

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        document.title = queryType + " Support Jobseeker Standard Account | DK Global Jobs"
        dispatch(clearMessage());
        dispatch(clearState());
    }, [dispatch, queryType])

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
        setIsAttachment(true)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            dispatch(clearMessage())

            if (isAttachment) {
                let s3bucket = new AWS.S3({
                    accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
                    secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
                    Bucket: process.env.REACT_APP_BUCKET_NAME + '/users/documents/support'
                });
                s3bucket.createBucket(function () {
                    var params = {
                        Bucket: process.env.REACT_APP_BUCKET_NAME + '/users/documents/support',
                        Key: Date.now() + '-' + (selectedFile.name).replace(/ /g, "_"),
                        Body: selectedFile
                    };
                    s3bucket.upload(params, function (err, data) {
                        if (err) {
                            console.log(err)
                        } else {
                            setFile_name("/" + data.key)
                            dispatch(addNew({ accountType, queryType, uid, comment: '0', name, email, phone, priority, subject, message: messageTxt }))
                        }
                    });
                });
            } else {
                await dispatch(addNew({ accountType, queryType, uid, comment: '0', name, email, phone, priority, subject, message: messageTxt }))
            }
            if (isLoading) {
                setSuccess(false);
                setLoading(true);
            }
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }
    }

    useEffect(() => {
        if (isError) {
            setSuccess(false);
            setLoading(false);
            dispatch(clearState());
        }
        if (isSuccess) {
            dispatch(clearState());
            setSuccess(true);
            setLoading(false);
            setName(userInfo.name)
            setEmail(userInfo.email)
            setPhone(userInfo.mobile)
            setPriority('Low')
            setSubject('')
            setMessageTxt('')
            fileInput.current.value = ''
            dispatch(addNotification({ type: 'Query', category: 'Users', subcategory: 'Jobseeker', notifyFor: '0' }))
            dispatch(showSuccess({ msg: "Query submitted successfully. We'll get back to you soon." }))
            if (isAttachment) {
                dispatch(insertAttachment({ reference, reference_id: insertId, file_name }))
                setIsAttachment(false);
                setReference_id('0');
                setFile_name('');
            }
        }
    }, [isError, isSuccess, dispatch, message, userInfo])

    const clearForm = (e) => {
        e.preventDefault()
        setName(userInfo.name)
        setEmail(userInfo.email)
        setPhone(userInfo.mobile)
        setPriority('Low')
        setSubject('')
        setMessageTxt('')
        fileInput.current.value = ''
    }

    return (
        <React.Fragment>
            <div className="container-fluid dk-supportPage">
                <div className="dk-supportPageCon">
                    <div className="col-md-11 pl-0 ml-auto">
                        <div className="row ml-0 mr-0">
                            <div className="col-md-12 p-0 m-auto dk-boxsCon">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="dk-informationCols">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo officia illum repellat veniam porro.
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="dk-complinceCon">
                                            <div className="title">{queryType} Support</div>
                                            <div className="dk-complaince-Body p-4">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label for="">Name of Jobseeker <span class="colorRequired">*</span></label>
                                                                <label for="" className="d-flex">
                                                                    <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label for="">Email <span class="colorRequired">*</span></label>
                                                                <label for="" className="d-flex">
                                                                    <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label for="">Jobseeker ID <span class="colorRequired">*</span></label>
                                                                <label for="" className="d-flex">
                                                                    <input type="text" className="form-control" value={uid} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label for="">System Email <span class="colorRequired">*</span></label>
                                                                <label for="" className="d-flex">
                                                                    <input type="text" className="form-control" value={`${uid}@dkglobaljobs.com`} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label for="">Priority</label>
                                                                <select className="form-control" value={priority} onChange={(e) => setPriority(e.target.value)}>
                                                                    <option value="Low">Low</option>
                                                                    <option value="Medium">Medium</option>
                                                                    <option value="High">High</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label for="">Contact Number <span class="colorRequired">*</span></label>
                                                                <label for="" className="d-flex">
                                                                    <input type="text" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label for="">Subject <span class="colorRequired">*</span></label>
                                                                <label for="" className="d-flex">
                                                                    <input type="text" className="form-control" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label for="">Attach File</label>
                                                                <input type="file" className="form-control file" onChange={handleFileInput} ref={fileInput} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row border-message pt-3">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label for="" className="d-flex">
                                                                    <textarea className="form-control" value={messageTxt} onChange={(e) => setMessageTxt(e.target.value)}></textarea>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div class="d-flex justify-content-center btn-container">
                                                            <button class="dk-cancelBtn" onClick={clearForm}>Cancel</button>
                                                            <SubmitButton txt="Submit" loading={loading} success={success} onClick={handleSubmit} position="" className="dk-updateBtn" />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Index;