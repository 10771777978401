import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CompanyJobsNavbar from './CompanyJobsNavbar';
import CompanyJobsLogo from './CompanyJobsLogo';

const CompanyJobsContactUs = () => {
    return (
        <>
            <CompanyJobsNavbar />

            <div className="dk-companyJobs-main">
                <div className="row">
                    <div className="col-md-10">
                        <div className="dk-companyJobsContactus">
                            <div className="d-flex align-items-center">
                                <div className="dk-visualcontact-cols bg_blue">
                                    <div className="title">DK Global Jobs</div>
                                    <ul>
                                        <li><i className="fas fa-map-marker-alt mr-2"></i> Gloucester Square, Railway street Dublin 1 Ireland</li>
                                        <li><i className="fas fa-phone-alt mr-2"></i> +353 1551 2444</li>
                                        <li><i className="fas fa-phone-alt mr-2"></i> +353 1551 2444</li>
                                        <li><i className="fas fa-envelope mr-2"></i> Office@dkglobalrecruitment.com</li>
                                        <li><i className="fas fa-envelope mr-2"></i> Office@dkglobalrecruitment.com</li>
                                        <li><i className="fas fa-external-link-alt mr-2"></i> www.dkglobalrecruitment.com</li>
                                    </ul>
                                    <div className="dk-socialIcon">
                                        <div className="d-flex align-items-center">
                                            <Link to="#" title="Facebook"><i className="fab fa-facebook-f"></i></Link>
                                            <Link to="#" title="Twitter"><i className="fab fa-twitter"></i></Link>
                                            <Link to="#" title="Instagram"><i className="fab fa-instagram"></i></Link>
                                            <Link to="#" title="Youtube"><i className="fab fa-youtube"></i></Link>
                                            <Link to="#" title="Linkedin"><i className="fab fa-linkedin-in"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-visualcontact-cols dk-form">
                                    <div className="title">Contact Us</div>
                                    <form action="">
                                        <div className="form-group">
                                            <label for="">
                                                <input type="text" placeholder="Your Name" name="" id="" />
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label for="">
                                                <input type="text" placeholder="Your Email" name="" id="" />
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label for="">
                                                <input type="text" placeholder="Your Phone" name="" id="" />
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label for="">
                                                <textarea name="" id="" placeholder="Message" className="form-control"></textarea>
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <div className="d-flex align-items-center">
                                                <input type="checkbox" name="" id="" />
                                                <label for="">I agree to the <Link to="#"> Terms of Use</Link> and <Link to="#">Privacy policy</Link></label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label for=""><button>Send Message</button></label>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-2">
                        <CompanyJobsLogo />
                    </div>
                </div>
            </div >
        </>
    )
}

export default CompanyJobsContactUs;
