import React from "react";
import ProfileSubmenu from "./standard/ProfileSubmenu";
import ResumeSubmenu from "./standard/ResumeSubmenu";
import SupportSubmenu from "./standard/SupportSubmenu";
import UpgradeSubmenu from './standard/UpgradeSubmenu';
import DKServiceSubmenu from './standard/DKServiceSubmenu';
import SettingsSubmenu from "./standard/SettingSubmenu";

const Submenu = ({ path }) => {
    const renderSubmenu = () => {
        switch (path.split("/")[3]) {
            case 'profile':
                return <ProfileSubmenu />

            case 'my-resume':
                return <ResumeSubmenu />

            case 'support':
                return <SupportSubmenu />

            case 'upgrade':
                return <UpgradeSubmenu />

            case 'dkg-serivces':
                return <DKServiceSubmenu />

            case 'settings':
                return <SettingsSubmenu />

            default:
                break;
        }
    }

    return (
        <React.Fragment>
            {renderSubmenu()}
        </React.Fragment>
    )
}
export default Submenu;