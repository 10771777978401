import React, { useState } from 'react';
import './notifications.scss';
import { Link } from 'react-router-dom';

const Index = () => {
    const [switch1, setSwitch1] = useState(true)
    const [switch2, setSwitch2] = useState(true)

    const handleSwitchChange = (nr) => {
        if (nr == '1') {
            setSwitch1(!switch1)
        } else {
            setSwitch2(!switch2)
        }
    }

    return (
        <React.Fragment>
            <div className="container-fluid dk-notificationPage">
                <h2 className="text-center">Notification Prefrences <i className="fa fa-question-circle"></i></h2>
                <div className="dk-prefrenceCols mb-5">
                    <div className="dk-emailNotification">
                        <div class="sbtitle">Email Notifications</div>
                        <p>We will use the email <Link to="#">deepak@dkglobalrecruitment.com</Link> from your profile information for Notifications.</p>
                    </div>
                    <div className="dk-emailNotification-cols">
                        <div className="dk-emailNotification-box">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="dk-emailColsLeft">
                                    <h4>New Features and Company Related News</h4>
                                    <p>Newsletters, surveys and other informative messages.</p>
                                </div>
                                <div className="dk-emailColsRight">
                                    <div className="custom-control custom-switch">
                                        <input
                                            type='checkbox'
                                            className='custom-control-input'
                                            id='customSwitches1'
                                            checked={switch1}
                                            onChange={() => handleSwitchChange(1)}
                                            readOnly
                                        />
                                        <label className='custom-control-label' htmlFor='customSwitches1'></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dk-emailNotification-box">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="dk-emailColsLeft">
                                    <h4>New Features and Company Related News</h4>
                                    <p>Newsletters, surveys and other informative messages.</p>
                                </div>
                                <div className="dk-emailColsRight">
                                    <div className="custom-control custom-switch">
                                        <input
                                            type='checkbox'
                                            className='custom-control-input'
                                            id='customSwitches2'
                                            checked={switch2}
                                            onChange={() => handleSwitchChange(2)}
                                            readOnly
                                        />
                                        <label className='custom-control-label' htmlFor='customSwitches2'></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Index;