import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const listQuestion = createAsyncThunk(
    'account/gdpr/list',
    async ({ account }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/admin/gdpr_questions/list/' + account,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const submitGdpr = createAsyncThunk(
    'account/gdpr/submission',
    async ({ account, id }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/auth/' + account + '/gdpr/update_status/' + id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                    }
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const gdprSlice = createSlice({
    name: 'account_gdpr',
    initialState: {
        isInsert: false,
        questionList: [],
        isLoading: false
    },
    reducers: {
        clearState: (state) => {
            state.isInsert = false
            state.isLoading = false
        }
    },
    extraReducers: {
        [listQuestion.pending]: (state) => {
            state.isLoading = true;
        },
        [listQuestion.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.questionList = payload.result;
        },
        [listQuestion.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
                state.message = payload.message;
            }
            state.isLoading = false;
        },
        [submitGdpr.pending]: (state) => {
            state.isLoading = true;
        },
        [submitGdpr.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isInsert = true;
        },
        [submitGdpr.rejected]: (state, { payload }) => {
            if (!payload.status) {
                state.isError = true;
            }
            state.isLoading = false;
        },
    }
});

export const { clearState } = gdprSlice.actions;

export default gdprSlice.reducer;