import React from 'react';
import { Navbar, Nav, Container, } from 'react-bootstrap';
import '../../../assets/css/style.scss'
import Logo from '../../../assets/images/mainlogo.png'
import { Link } from 'react-router-dom'




const Navgation = () => {
    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="dkjobsNavBarMainCon">
                <Container fluid className="dkjobsNavBarCon">
                    <Navbar.Brand className="dk-dkjobsLogoCon" href="/">
                        <img src={Logo} className="dkjobslogo" alt="DK Global Jobs" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto ml-auto">
                            <Link to="/">Home</Link>
                            <Link to="/jobs/jobs-listing">Jobs</Link>
                            <Link to="/front-jobseekers">Jobseekers</Link>
                            <Link to="/employers">Employers</Link>
                            <Link to="/#">Pricing</Link>
                            <Link to="/subscription-plan">Subscription</Link>
                            <Link to="/article">Blogs</Link>
                        </Nav>
                        <Nav className="ml-auto dk-jobsnls_bg">
                            <Nav.Link href="/sign-up" className="dk-jobsheaderbtn">
                                <i className="fa fa-user mr-1" aria-hidden="true"></i>
                                Sign Up
                            </Nav.Link>
                            <Nav.Link href="/login" className="dk-jobsheaderbtn">
                                <i className="fa fa-sign-out mr-1" aria-hidden="true"></i>
                                Login
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Navgation;
