import React from 'react';
import { Link } from 'react-router-dom'
import './blogs.scss';
import Blogsimg from '../../../assets/images/blogs.jpg';


const BlogDetails = () => {
    return (
        <>
            <div className="container-fluid dk-blogspageMainCon">
                <div className="row">
                    <div className="col-md-12">
                        <div className="pageHeadeTitle">
                            <h2 className="section-title pb-0">Our Blogs &amp; Articales</h2>
                        </div>
                    </div>
                    <div className="dk-blog-search-main">
                        <div className="row">
                            <div className="d-md-flex dk-blog-searchCon">
                                <div className="dk-blog-search">
                                    <input type="text" name="" id="" placeholder="Search here..." />
                                    <button type="button" className="btn-default btn">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                                <div className="dk-blog-search-filterbtn d-flex ml-auto">
                                    <div className="w-100">
                                        <select className="browser-default custom-select">
                                            <option>Timeline</option><option value="1">Option 1</option>
                                            <option value="2">Option 2</option><option value="3">Option 3</option>
                                        </select>
                                    </div>
                                    <div className="w-100 ml-2 mr-2">
                                        <select className="browser-default custom-select">
                                            <option>Type</option><option value="1">Option 1</option>
                                            <option value="2">Option 2</option>
                                            <option value="3">Option 3</option>
                                        </select>
                                    </div>
                                    <div className="w-100 mr-2">
                                        <select className="browser-default custom-select">
                                            <option>Category</option>
                                            <option value="1">Option 1</option>
                                            <option value="2">Option 2</option>
                                            <option value="3">Option 3</option>
                                        </select>
                                    </div>
                                    <div className="w-100">
                                        <select className="browser-default custom-select">
                                            <option>Writter</option><option value="1">Option 1</option>
                                            <option value="2">Option 2</option>
                                            <option value="3">Option 3</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="dk-topic-div ml-auto topic-btn-div">
                                    <div className="dk-article-modal-main"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid dk-articledetail-container mt-4 mb-4">
                <div className="row">
                    <div className="col-md-10 m-auto">
                        <div className="dk-art-detail-col">
                            <h2 className="blog-title">German Speaking Technical Support Job in Sunny Greece. Apply Now !
                            </h2>
                            <div className="dk-rt-container-cols">
                                <div className="row">
                                    <div className="col-4 pr-4 mb-2">
                                        <img src={Blogsimg} className="img-fluid blog-thumbnail" />
                                    </div>
                                    <div className="col-8 pl-4">
                                        <p className="bold-text">
                                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident explicabo architecto natus praesentium repellat assumenda labore illo molestias facilis fuga neque obcaecati, vel temporibus recusandae
                                            facere exercitationem magni! Provident, dolorum. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis beatae sequi accusantium magni ullam debitis excepturi odit obcaecati voluptatum
                                            consequuntur. Impedit aspernatur quas dolorem iure ipsa ipsum, amet officia voluptatum. Reiciendis beatae sequi accusantium magni ullam debitis excepturi odit obcaecati.
                                        </p>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <p>
                                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident explicabo architecto natus praesentium repellat assumenda labore illo molestias facilis fuga neque obcaecati, vel temporibus recusandae
                                            facere exercitationem magni! Provident, dolorum. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis beatae sequi accusantium magni ullam debitis excepturi odit obcaecati voluptatum
                                            consequuntur. Impedit aspernatur quas dolorem iure ipsa ipsum, amet officia voluptatum. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Provident explicabo architecto natus praesentium repellat
                                            assumenda labore illo molestias facilis fuga neque obcaecati, vel temporibus recusandae facere exercitationem magni! Provident, dolorum. Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                                            Reiciendis beatae sequi accusantium magni ullam debitis excepturi odit obcaecati voluptatum consequuntur. Impedit aspernatur quas dolorem iure ipsa ipsum, amet officia voluptatum.
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex top-detail-rt mt-3">
                                    <div className="dk-art-detail-profile">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="dk-art-detail-img">
                                                <img
                                                    src={Blogsimg}
                                                    className="img-fluid img-circle"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="dk-art-detail-text">
                                                <div className="title">By Elena Maria</div>
                                                <Link to="/article/details">View Profile</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dk-art-detail-box">
                                        <div className="title">
                                            <i className="far fa-calendar-alt fa-2x blog-icon-color"></i>
                                            <span className="icon-title">Posted On</span>
                                        </div>
                                        <div className="dk-rt-detail-star">Jan 28, 2020</div>
                                    </div>
                                    <div className="dk-art-detail-box">
                                        <div className="title">
                                            <button>
                                                <i title="Shared" className="fa fa-share-alt fa-2x blog-icon-color"></i>
                                            </button>Shared
                                        </div>
                                        <div className="dk-rt-detail-star">1.6K</div>
                                    </div>
                                    <div className="dk-art-detail-box">
                                        <div className="title">
                                            <i className="far fa-comment fa-2x blog-icon-color"></i>
                                            <span className="icon-title">Comments</span>
                                        </div>
                                        <div className="dk-rt-detail-star">1.2K</div>
                                    </div>
                                    <div className="dk-art-detail-box">
                                        <div className="title"><i className="far fa-eye fa-2x blog-icon-color"></i><span className="icon-title">Views</span></div>
                                        <div className="dk-rt-detail-star">1.6K</div>
                                    </div>
                                    <div className="dk-art-detail-box">
                                        <div className="title">
                                            <button>
                                                <i titile="Likes" className="far fa-thumbs-up fa-2x blog-icon-color"></i>
                                            </button>Like it
                                        </div>
                                        <div className="dk-rt-detail-star">1.6K</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dk-art-detail-col mt-3">
                            <div className="dk-rt-social justify-content-left">
                                <Link to="#" title="Facebook" className="dk_facebook">
                                    <i className="fab fa-facebook-f"></i>Share on Facebook</Link><Link to="#" title="Twitter" className="dk_twitter"><i className="fab fa-twitter"></i>Share on Twitter
                                </Link>
                                <Link to="#" title="Linkedin" className="dk_linkedin">
                                    <i className="fab fa-linkedin-in"></i>Share on Linkedin
                                </Link>
                                <Link to="#" title="Instagram" className="dk_instagram"><i className="fab fa-instagram"></i>Share on Instagram
                                </Link>
                                <Link to="#" title="Send via Email" className="dk_email">
                                    <i className="far fa-envelope"></i> Send via Email
                                </Link>
                            </div>
                        </div>
                        <div className="dk-article-commentbox"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogDetails;
