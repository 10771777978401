import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const SearchJobsSummary = () => {
    return (
        <>
            <div class="dk-viewall-form">
                <div class="row">
                    <div class="col-md-6 pr-3 dk-formright-panel">
                        <label class="title" htmlFor="">My Profile Summary</label>
                        <label htmlFor="">
                            <textarea name="" id=""></textarea>
                        </label>
                    </div>
                    <div class="col-md-6 pl-3 dk-formright-panel">
                        <label class="title" htmlFor="">
                            Message to Employers</label>
                        <label htmlFor="">
                            <textarea name="" id=""></textarea>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div className="col-md-12">
                        <div class="dk-searchClass">
                            <div class="col-md-6 pr-4">
                                <div class="d-flex align-items-center mb-1">
                                    <label class="mr-3" htmlFor="">Gender</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control" readonly="" name="" id="" value="Male" />
                                    </label>
                                </div>
                                <div class="d-flex align-items-center mb-1">
                                    <label class="mr-3" htmlFor="">Date of Birth</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control" readonly="" name="" id="" value="31 July 1981" />
                                    </label>
                                </div>
                                <div class="d-flex align-items-center mb-1">
                                    <label class="mr-3" htmlFor="">Nationality</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control" readonly="" name="" id="" value="Irish" />
                                    </label>
                                </div>
                                <div class="d-flex align-items-center mb-1">
                                    <label class="mr-3" htmlFor="">Current City</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control" readonly="" name="" id="" value="Dublin" />
                                    </label>
                                </div>
                                <div class="d-flex align-items-center mb-1">
                                    <label class="mr-3" htmlFor="">Current Country</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control" readonly="" name="" id="" value="Ireland" />
                                    </label>
                                </div>
                                <div class="d-flex align-items-center mb-1">
                                    <label class="mr-3" htmlFor="">Contact Prefrences</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control" readonly="" name="" id="" value="Anytime betweeb 10 to 7pm" />
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-6 pl-4">
                                <div class="d-flex align-items-center mb-1">
                                    <label class="mr-3" htmlFor="">Native Language</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control" readonly="" name="" id="" value="Hindi" />
                                    </label>
                                </div>
                                <div class="d-flex align-items-center mb-1">
                                    <label class="mr-3" htmlFor="">Fluent Language</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control" readonly="" name="" id="" value="English" />
                                    </label>
                                </div>
                                <div class="d-flex align-items-center mb-1">
                                    <label class="mr-3" htmlFor="">Work Permit</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control" readonly="" name="" id="" value="European Passport" />
                                    </label>
                                </div>
                                <div class="d-flex align-items-center mb-1">
                                    <label class="mr-3" htmlFor="">Career Level</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control" readonly="" name="" id="" value="First Level" />
                                    </label>
                                </div>
                                <div class="d-flex align-items-center mb-1">
                                    <label class="mr-3" htmlFor="">Current Salary</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control" readonly="" name="" id="" value="1000 €" />
                                    </label>
                                </div>
                                <div class="d-flex align-items-center mb-1">
                                    <label class="mr-3" htmlFor="">Expected Salary</label>
                                    <label htmlFor="">
                                        <input type="text" className="form-control" readonly="" name="" id="" value="1300 €" />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SearchJobsSummary;
