import { createAsyncThunk } from '@reduxjs/toolkit';

export const insertAttachment = createAsyncThunk(
    'attachemnt/add',
    async ({ reference, reference_id, file_name }, thunkAPI) => {
        try {
            const { token } = JSON.parse(localStorage.getItem("dkjobs-authentication"));
            const response = await fetch(process.env.REACT_APP_API_URL + '/account/attachment/add',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        'platform': process.env.REACT_APP_PLATEFORM,
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({
                        reference,
                        reference_id,
                        file_name
                    }),
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);