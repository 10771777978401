import React from 'react'
import './createresume.scss';
import { Nav, Tab } from 'react-bootstrap'
import ApperanceCV from './apperancecv/ApperanceCV';
import PersonalDetails from './personaldetails/PersonalDetails';
import Education from './education/Education';
import WorkExperience from './workexperience/WorkExperience';
import Skills from './skills/Skills';
import SoughtJobs from './soughtjobs/SoughtJobs';

const Index = () => {
    return (
        <>
            <div className="container-fluid dk-createResumeMain">
                <Tab.Container id="left-tabs-example" defaultActiveKey="appearance-cv">
                    <div className="col-12">
                        <Nav variant="pills" className="flex-row dk-createResumeNavPills">
                            <Nav.Item className="dk-createResumeNav-Item">
                                <Nav.Link eventKey="appearance-cv"><span className="tabcounter">1</span> Appearance of the CV</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="dk-createResumeNav-Item">
                                <Nav.Link eventKey="personal-details"><span className="tabcounter">2</span> Personal Details</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="dk-createResumeNav-Item">
                                <Nav.Link eventKey="education"><span className="tabcounter">3</span>Education</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="dk-createResumeNav-Item">
                                <Nav.Link eventKey="work-experience"><span className="tabcounter">4</span> Work Experience</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="dk-createResumeNav-Item">
                                <Nav.Link eventKey="skills"><span className="tabcounter">5</span>Skills</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="dk-createResumeNav-Item">
                                <Nav.Link eventKey="sought-jobs"><span className="tabcounter">6</span>Sought Jobs</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="col-12">
                        <Tab.Content className="dk-createResumeTabContent">
                            <Tab.Pane className="dk-createResumeTab-Pane" eventKey="appearance-cv">
                                <ApperanceCV />
                            </Tab.Pane>
                            <Tab.Pane className="dk-createResumeTab-Pane" eventKey="personal-details">
                                <PersonalDetails />
                            </Tab.Pane>
                            <Tab.Pane className="dk-createResumeTab-Pane" eventKey="education">
                                <Education />
                            </Tab.Pane>
                            <Tab.Pane className="dk-createResumeTab-Pane" eventKey="work-experience">
                                <WorkExperience />
                            </Tab.Pane>
                            <Tab.Pane className="dk-createResumeTab-Pane" eventKey="skills">
                                <Skills />
                            </Tab.Pane>
                            <Tab.Pane className="dk-createResumeTab-Pane" eventKey="sought-jobs">
                                <SoughtJobs />
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
        </>
    )
}
export default Index;