import React from 'react'
import './contactus.scss'
import { Link } from 'react-router-dom'
import Facebook from '../../../assets/images/facebook.png'
import Instagram from '../../../assets/images/instagram.png'
import Linkedin from '../../../assets/images/linkedin.png'
import Youtubeicon from '../../../assets/images/youtube-icon.png'
import Twittericon from '../../../assets/images/twitter-icon.png'

const ContactUs = () => {
    return (
        <>
            <section className="dkjobs-contactUS container-fluid">
                <div className="row">
                    <div className="col-md-7 col-sm-12 m-auto" style={{ paddingBottom: "4rem" }}>
                        <h2 className="section-title text-uppercase">Contact Us</h2>
                        <div className="dk-contact-cols row">
                            <div className="col-md-6">
                                <div className="dk-contact-textarea">
                                    <div className="title">DK Global Jobs</div>
                                    <ul>
                                        <li>
                                            <i className="fa fa-map-marker mr-3" aria-hidden="true"></i>
                                            Gloucester Square, Railway street Dublin 1 Ireland
                                        </li>
                                        <li>
                                            <i className="fa fa-phone mr-3" aria-hidden="true"></i> +353 1551 2444
                                        </li>
                                        <li>
                                            <i className="fa fa-envelope mr-3"></i>
                                            <Link to="#/mailto:Office@dkglobalrecruitment.com">
                                                Office@dkglobalrecruitment.com
                                            </Link>
                                        </li>
                                        <li>
                                            <i className="fa fa-external-link mr-3" aria-hidden="true"></i>
                                            <Link target="_blank" to="/www.dkglobalrecruitment.com">
                                                www.dkglobalrecruitment.com
                                            </Link>
                                        </li>
                                    </ul>
                                    <div className="dk-contact-socialicon d-flex align-items-center">
                                        <Link to="#/contact-us">
                                            <img src={Facebook} className="img-fluid" alt="" />
                                        </Link>
                                        <Link to="#/contact-us">
                                            <img src={Instagram} className="img-fluid" alt="" />
                                        </Link>
                                        <Link to="#/contact-us">
                                            <img src={Linkedin} className="img-fluid" alt="" />
                                        </Link>
                                        <Link to="#/contact-us">
                                            <img src={Youtubeicon} className="img-fluid" alt="" />
                                        </Link>
                                        <Link to="#/contact-us">
                                            <img src={Twittericon} className="img-fluid" alt="" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="dk-contact-form">
                                    <div className="title">Get In Touch</div>
                                    <form action="">
                                        <div className="form-group">
                                            <label for="">
                                                <input type="text" className="form-control" placeholder="Your Name" />
                                            </label>
                                            <label for="">
                                                <input type="text" className="form-control" placeholder="Your Email" />
                                            </label>
                                            <label for="">
                                                <input type="text" className="form-control" placeholder="Your Phone" />
                                            </label>
                                            <label for="">
                                                <input type="text" className="form-control" placeholder="Subject" />
                                            </label>
                                            <label for="">
                                                <textarea name="" id="" placeholder="Message" className="form-control"></textarea>
                                            </label>
                                            <label for="" className="terms-dflex">
                                                <input type="checkbox" name="" id="" />
                                                <span>I agree to the
                                                    <Link to="/contact-us">&nbsp;Terms of Use &nbsp;</Link>and
                                                    <Link to="/contact-us">&nbsp;Privacy policy</Link>
                                                </span>
                                            </label>
                                            <label for="">
                                                <button type="button" className="btn-default btn">Submit</button>
                                            </label>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUs
