import React from 'react'
import Carousel from 'react-bootstrap/Carousel'

const Achivments = () => {
    return (
        <>
            <div className="dk-achivementTabCon row">
                <div className="dk-archiveBoxCon">
                    <div className="col-md-4 pl-0">
                        <div className="dk-achiveCarosel">
                            <Carousel fade>
                                <Carousel.Item interval={1000}>
                                    <img
                                        className="d-block w-100"
                                        src="https://mdbootstrap.com/img/Photos/Slides/img%20(130).jpg"
                                        alt=""
                                    />
                                </Carousel.Item>
                                <Carousel.Item interval={500}>
                                    <img
                                        className="d-block w-100"
                                        src="https://mdbootstrap.com/img/Photos/Slides/img%20(129).jpg"
                                        alt="Second slide"
                                    />
                                </Carousel.Item>
                                <Carousel.Item interval={500}>
                                    <img
                                        className="d-block w-100"
                                        src="https://mdbootstrap.com/img/Photos/Slides/img%20(70).jpg"
                                        alt="Third slide"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="dk-achiveRightPanel">
                            <div className="topCon">
                                <label>Title :</label>
                                <span> Recruiter of the Year 2019</span>
                            </div>
                            <div className="topCon">
                                <label>Company :</label>
                                <span>DK Global Recruitment</span>
                            </div>
                            <div className="topCon">
                                <label>Date Awarded :</label>
                                <span>20th Dec 2019</span>
                            </div>
                            <div className="dk-sliderdesc">
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority
                                    have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dk-archiveBoxCon">
                    <div className="col-md-4 pl-0">
                        <div className="dk-achiveCarosel">
                            <Carousel fade>
                                <Carousel.Item interval={1000}>
                                    <img
                                        className="d-block w-100"
                                        src="https://mdbootstrap.com/img/Photos/Slides/img%20(130).jpg"
                                        alt=""
                                    />
                                </Carousel.Item>
                                <Carousel.Item interval={500}>
                                    <img
                                        className="d-block w-100"
                                        src="https://mdbootstrap.com/img/Photos/Slides/img%20(129).jpg"
                                        alt="Second slide"
                                    />
                                </Carousel.Item>
                                <Carousel.Item interval={500}>
                                    <img
                                        className="d-block w-100"
                                        src="https://mdbootstrap.com/img/Photos/Slides/img%20(70).jpg"
                                        alt="Third slide"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="dk-achiveRightPanel">
                            <div className="topCon">
                                <label>Title :</label>
                                <span> Recruiter of the Year 2019</span>
                            </div>
                            <div className="topCon">
                                <label>Company :</label>
                                <span>DK Global Recruitment</span>
                            </div>
                            <div className="topCon">
                                <label>Date Awarded :</label>
                                <span>20th Dec 2019</span>
                            </div>
                            <div className="dk-sliderdesc">
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority
                                    have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Achivments
