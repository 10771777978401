import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import './login.scss'
import InfoModal from './modal/InfoModal';
import { FaUserTie, FaRegBuilding } from "react-icons/fa";

const Login = () => {
    const [isInfoModal, setIsInfoModal] = useState(false);
    const hideInfoModalModal = () => {
        setIsInfoModal(false)
    }
    return (
        <>
            {
                (isInfoModal) ? <InfoModal onHide={hideInfoModalModal} /> : null
            }
            <div className="dk-jobsloginMain">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="dk-jobslogincard card card-body mt-1">
                                <div className="title">LOGIN ACCOUNT AS
                                    {/* <Link onClick={showInfoModalModal} class="ml-3"><i class="fa fa-question-circle"></i></Link> */}
                                </div>
                                <div className="dkjobs-tabContent pt-1">
                                    <div class="sbtitle">Choose account type:</div>
                                    <div className="LoginBlock">
                                        <Link to="/login/jobseeker-login" className="LoginBox">
                                            <span><FaUserTie />Jobseeker</span>
                                        </Link>
                                        <Link to="/login/employer-login" className="LoginBox">
                                            <span>
                                                <FaRegBuilding />
                                                Employer</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;
