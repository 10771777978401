import React from "react";
import ProfileSubmenu from "./standard/ProfileSubmenu";
import BillingSubmenu from './standard/BillingSubmenu';
import SupportSubmenu from "./standard/SupportSubmenu";
import DKServiceSubmenu from './standard/DKServiceSubmenu'
import SettingsSubmenu from "./standard/SettingSubmenu";
import JobsSubmenu from "./standard/JobsSubmenu";
import SearchCvSubmenu from "./standard/SearchCvSubmenu";
import PackagesSubmenu from "./standard/PackagesSubmenu";

const Submenu = ({ path }) => {
    const renderSubmenu = () => {
        switch (path.split("/")[3]) {
            case 'jobs':
                return <JobsSubmenu />

            case 'searchcv':
                return <SearchCvSubmenu />

            case 'packages':
                return <PackagesSubmenu />

            case 'profile':
                return <ProfileSubmenu />

            case 'billing':
                return <BillingSubmenu />

            case 'support':
                return <SupportSubmenu />

            case 'dkg-serivces':
                return <DKServiceSubmenu />

            case 'settings':
                return <SettingsSubmenu />

            default:
                break;
        }
    }

    return (
        <React.Fragment>
            {renderSubmenu()}
        </React.Fragment>
    )
}
export default Submenu;