import React, { useEffect, useState } from 'react';
import { Link } from '@material-ui/core';
import './employersignup.scss'
import SubmitButton from "../../../ui/submitButton";
import { showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import { signupUser, clearState } from "../../../../slice/account/employer/auth/authSlice";
import { addNotification } from "../../../../slice/notification/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
import Constants from '../../../constants';
import { useHistory } from 'react-router-dom';

const EmployerSignup = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { isLoading, isSuccess, isError, message } = useSelector(state => state.employerAuth);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const accountType = Constants.SYSTEM_CONSTANTS.JobSeeker.Account.AccountType;
    const accountTrail = Constants.SYSTEM_CONSTANTS.JobSeeker.Account.AccountTrail;

    const [businessName, setBusinessName] = useState('');
    const [businessType, setBusinessType] = useState('');
    const [businessIndustry, setBusinessIndustry] = useState('');
    const [businessWebPage, setBusinessWebPage] = useState('');
    const [businessCity, setBusinessCity] = useState('');
    const [businessCountry, setBusinessCountry] = useState('');
    const [businessAdderss, setBusinessAdderss] = useState('');
    const [businessPhone, setBusinessPhone] = useState('');
    const [adminName, setAdminName] = useState('');
    const [currentPosition, setCurrentPosition] = useState('');
    const [adminCity, setAdminCity] = useState('');
    const [adminPhone, setAdminPhone] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [preferedContact, setPreferedContact] = useState('');
    const [hearUs, setHearUs] = useState('');

    const clearValues = () => {
        setBusinessName('')
        setBusinessType('')
        setBusinessIndustry('')
        setBusinessWebPage('')
        setBusinessCity('')
        setBusinessCountry('')
        setBusinessAdderss('')
        setBusinessPhone('')
        setAdminName('')
        setCurrentPosition('')
        setAdminCity('')
        setAdminPhone('')
        setAdminEmail('')
        setPreferedContact('')
        setHearUs('')
    }

    useEffect(() => {
        document.title = "Create Employer Account | DK Global Jobs"
        dispatch(clearMessage());
        dispatch(clearState());
    }, [dispatch])

    useEffect(() => {
        if (isError) {
            setSuccess(false);
            setLoading(false);
            dispatch(clearState());
            dispatch(showError({ msg: message }))
        }
        if (isSuccess) {
            dispatch(clearState());
            clearValues();
            setSuccess(true);
            setLoading(false);
            dispatch(addNotification({ type: 'Query', category: 'Signup', subcategory: 'Employer', notifyFor: '0' }))
            setTimeout(() => history.push('/sign-up/thankyou'), 300);
        }
    }, [isError, isSuccess, dispatch, message, history])

    const validateForm = () => {
        let formIsValid = true;
        let pattern = '';

        if (!businessName || !businessType || !businessIndustry || !businessWebPage || !businessCity || !businessCountry || !businessAdderss || !businessPhone || !adminName || !currentPosition || !adminCity || !adminPhone || !adminEmail || !preferedContact || !hearUs) {
            formIsValid = false;
            dispatch(showError({ msg: "Please fill all required details" }))
        }
        if (businessName.length < 2) {
            formIsValid = false;
            dispatch(showError({ msg: "*Business Name should be atleast 3 character." }));
        }
        if (businessType.length < 2) {
            formIsValid = false;
            dispatch(showError({ msg: "*Business Name should be atleast 3 character." }));
        }
        if (businessIndustry.length < 2) {
            formIsValid = false;
            dispatch(showError({ msg: "*Business Name should be atleast 3 character." }));
        }
        if (businessType.length < 2) {
            formIsValid = false;
            dispatch(showError({ msg: "*Business Name should be atleast 3 character." }));
        }
        if (typeof businessWebPage !== "undefined") {
            if (!businessWebPage.match(Constants.SYSTEM_CONSTANTS.Validation.Web_url)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Please enter valid business website." }));
            }
        }
        if (typeof businessCity !== "undefined") {
            if (!businessCity.match(Constants.SYSTEM_CONSTANTS.Validation.Aplphabet)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Invalid City." }));
            }
        }
        if (typeof businessCountry !== "undefined") {
            if (!businessCity.match(Constants.SYSTEM_CONSTANTS.Validation.Aplphabet)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Invalid Country." }));
            }
        }
        if (typeof adminName !== "undefined") {
            if (!adminName.match(Constants.SYSTEM_CONSTANTS.Validation.Aplphabet)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Please enter alphabet characters only on First Name." }));
            } else {
                if (adminName.length < 3) {
                    formIsValid = false;
                    dispatch(showError({ msg: "*Admin name should be atleast 3 character." }));
                }
            }
        }
        if (typeof currentPosition !== "undefined") {
            if (currentPosition.length < 2) {
                formIsValid = false;
                dispatch(showError({ msg: "*Admin current position should be atleast 3 character." }));
            }
        }
        if (typeof adminCity !== "undefined") {
            if (!adminCity.match(Constants.SYSTEM_CONSTANTS.Validation.Aplphabet)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Invalid Admin City/Country." }));
            }
        }
        if (typeof adminPhone !== "undefined") {
            //regular expression for Phone validation
            pattern = new RegExp(Constants.SYSTEM_CONSTANTS.Validation.Phone);
            if (!pattern.test(adminPhone)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Please enter valid admin phone number." }));
            }
        }
        if (typeof businessPhone !== "undefined") {
            //regular expression for Phone validation
            pattern = new RegExp(Constants.SYSTEM_CONSTANTS.Validation.Phone);
            if (!pattern.test(businessPhone)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Please enter valid business phone number." }));
            }
        }
        if (typeof adminEmail !== "undefined") {
            //regular expression for email validation
            pattern = new RegExp(Constants.SYSTEM_CONSTANTS.Validation.Email);
            if (!pattern.test(adminEmail)) {
                formIsValid = false;
                dispatch(showError({ msg: "*Please enter valid admin email." }));
            }
        }
        return formIsValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validateForm()) {
            try {
                dispatch(clearMessage())
                await dispatch(signupUser({ accountType, accountTrail, businessName, businessType, businessIndustry, businessWebPage, businessCity, businessCountry, businessAdderss, businessPhone, adminName, currentPosition, adminCity, adminPhone, adminEmail, preferedContact, hearUs }))
                if (isLoading) {
                    setSuccess(false);
                    setLoading(true);
                }
            } catch (err) {
                setSuccess(false);
                setLoading(false);
                dispatch(showError({ msg: 'Something went wrong! Try again.' }))
            }
        }
    }

    return (
        <>
            <div className="dk-EmployerMain container-fluid">
                <div className="row">
                    <div className="col-md-8 col-sm-12 m-auto">
                        <div className="dk-empsingupCont">
                            <h2 className="title">Create Employer Account</h2>
                        </div>
                        <div className="dk-EmpFormContiner">
                            <div className="dk-EmpFormContent">
                                <form>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="dk-empFrmtitle">
                                                <h3>Business Dtails</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Name of Business*" value={businessName} onChange={(e) => setBusinessName(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Type of Business*" value={businessType} onChange={(e) => setBusinessType(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Industry of Business*" value={businessIndustry} onChange={(e) => setBusinessIndustry(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="city" placeholder="City/Town*" value={businessCity} onChange={(e) => setBusinessCity(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="country" placeholder="Country*" value={businessCountry} onChange={(e) => setBusinessCountry(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="website" placeholder="Website" value={businessWebPage} onChange={(e) => setBusinessWebPage(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <textarea className="form-control" id="textArea" rows="3" placeholder="Business Address*" value={businessAdderss} onChange={(e) => setBusinessAdderss(e.target.value)}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="dk-empFrmtitle">
                                                <h3>Admin Dtails</h3>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="full name" placeholder="Admin Full Name*" value={adminName} onChange={(e) => setAdminName(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="currentPosition" placeholder="Admin Current Position*" value={currentPosition} onChange={(e) => setCurrentPosition(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="adminLocation" placeholder="Admin Location City/Country*" value={adminCity} onChange={(e) => setAdminCity(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="phonenumber" placeholder="Admin Phone Number*" value={adminPhone} onChange={(e) => setAdminPhone(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="currentPosition" placeholder="Business Phone Number*" value={businessPhone} onChange={(e) => setBusinessPhone(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="adminLocation" placeholder="Admin Email Address*" value={adminEmail} onChange={(e) => setAdminEmail(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="email" className="form-control" id="prfcontactmtd" placeholder="Preferred Contact Method*" value={preferedContact} onChange={(e) => setPreferedContact(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <input type="email" className="form-control" id="mobile" placeholder="How did you hear about us ?*" value={hearUs} onChange={(e) => setHearUs(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="dk-termsplicytextCon">
                                                <p className="pt-2">By continuing, you acknowledge that you accept our
                                                    <Link to="#">Terms &amp; Conditions</Link> and
                                                    <Link to="#">Privacy Policy.</Link>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="dk-formsubmit-buttonCon mt-2">
                                            <SubmitButton txt="Create Account" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="btn formsubmit-button" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployerSignup;
