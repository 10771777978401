import React from 'react'
import { Link } from 'react-router-dom'
import './submenu.scss'

const BillingSubmenu = () => {
    let pathname = window.location.pathname;
    return (
        <>
            <div className="container-fluid">
                <div className="dk-ds-sub-menu col-md-12 pl-0 pr-0 ml-auto">
                    <div class="dk-ds-sub-menu-cols">
                        <ul>
                            <li>
                                <Link className={pathname === "/employer/standard/billing/contact-person" ? 'sub-active' : ''} to="/employer/standard/billing/contact-person">Contact Person</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/billing/billing-address" ? 'sub-active' : ''} to="/employer/standard/billing/billing-address">Billing Address</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/billing/payment-to" ? 'sub-active' : ''} to="/employer/standard/billing/payment-to">Payment To</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/billing/payment-method" ? 'sub-active' : ''} to="/employer/standard/billing/payment-method">Payment Method</Link>
                            </li>
                            <li>
                                <Link className={pathname === "/employer/standard/billing/payment-history" ? 'sub-active' : ''} to="/employer/standard/billing/payment-history">Payment History</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BillingSubmenu;