import React, { useState } from 'react';

const SearchCvInterests = () => {
    return (
        <>
            <div className="dk-interestsMain">
                <div className="dk-interestsCols">
                    <div className="d-flex align-items-center">
                        <div className="title">Topics of Interest :</div>
                        <div className="dk-interestsBtn"><span>B.Tech</span><span>MBA</span><span>BCA</span><span>BA</span></div>
                    </div>
                </div>
                <div className="dk-interestsCols">
                    <div className="d-flex align-items-center">
                        <div className="title">Free time Activities :</div>
                        <div className="dk-interestsBtn"><span>B.Tech</span><span>MBA</span><span>BCA</span><span>BA</span></div>
                    </div>
                </div>
                <div className="dk-interestsCols">
                    <div className="d-flex align-items-center">
                        <div className="title">I am Passionate About :</div>
                        <div className="dk-interestsBtn"><span>B.Tech</span><span>MBA</span><span>BCA</span><span>BA</span></div>
                    </div>
                </div>
                <div className="dk-interestsCols">
                    <div className="d-flex align-items-center">
                        <div className="title">My Favorite Quote :</div>
                        <div className="dk-interestsBtn"><input type="text" className="form-control" /></div>
                    </div>
                </div>
                <div className="dk-interestsCols">
                    <div className="d-flex align-items-center">
                        <div className="title">One Professional Wish :</div>
                        <div className="dk-interestsBtn"><input type="text" className="form-control" /></div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SearchCvInterests;
