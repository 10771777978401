import React, { useStat } from 'react';
import DocInfo from './DocInfo';

const SearchCvDownload = () => {
    return (
        <>
            <div className="dk-searchCvDownload">
                <h6>My Profile Documents <DocInfo /></h6>

                <div className="">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Name of Document</th>
                                <th>File Type</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Cover Letter</td>
                                <td>
                                    <div className="dk-cvIcon"><i class="far fa-file-pdf fa-2x"></i></div>
                                </td>
                                <td>
                                    <button className="viewBtn">View</button>
                                    <button className="downloadBtn">Download</button>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Cover Letter</td>
                                <td>
                                    <div className="dk-cvIcon"><i class="far fa-file-pdf fa-2x"></i></div>
                                </td>
                                <td>
                                    <button className="viewBtn">View</button>
                                    <button className="downloadBtn">Download</button>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Cover Letter</td>
                                <td>
                                    <div className="dk-cvIcon"><i class="far fa-file-pdf fa-2x"></i></div>
                                </td>
                                <td>
                                    <button className="viewBtn">View</button>
                                    <button className="downloadBtn">Download</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default SearchCvDownload;
