import React from 'react';
import './privacypolicy.scss';
import FaqAccordion from '../signup/FaqAccordion';

const PrivacyPolicy = () => {
    return (
        <>
            <div className="container-fluid dk-privacyPolicyMain">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="pageTitle">
                            <h1 className="text-center">Privacy <span>Policy</span></h1>
                        </div>
                        <div className="dk-privacyAccordiaon">
                            <FaqAccordion />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy
