import React, { useEffect, useState, useRef } from 'react'
import { Modal, Button } from 'react-bootstrap'
import './addnewfiledoc.scss';
import { useDispatch, useSelector } from "react-redux";
import { insertAttachment } from "../../../../../../../../slice/attachmentSlice";
import { documentsAdd, clearState } from "../../../../../../../../slice/account/documents/documentsSlice";
import { showSuccess, showError, clearMessage } from "../../../../../../../../slice/utils/message/messageSlice";
import AWS from 'aws-sdk';
import SubmitButton from "../../../../../../../ui/submitButton";

const AddNewFile = ({ hidepopup }) => {
    const dispatch = useDispatch();
    const { insertId, isLoading, isInsert, isError, msg } = useSelector(state => state.accountDocuments);
    const { userInfo } = useSelector(state => state.employerAuth);

    const accountType = 'Employer';
    const uid = userInfo.uid;
    const fileInput = useRef();
    const [isAttachment, setIsAttachment] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [name, setName] = useState('')
    const [category, setCategory] = useState('')
    const [description, setDescription] = useState('')
    const [reference, setReference] = useState('documents')
    const [file_name, setFile_name] = useState('')

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        document.title = " My Documents Employer Standard Account | DK Global Jobs"
        dispatch(clearMessage());
        dispatch(clearState());
        fileInput.current.value = ''
        setName('')
        setCategory('')
        setDescription('')
    }, [])

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
        setIsAttachment(true)
    }

    const clearForm = (e) => {
        e.preventDefault()
        fileInput.current.value = ''
        setName('')
        setCategory('')
        setDescription('')
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            dispatch(clearMessage())
            if (isAttachment) {
                setSuccess(false);
                setLoading(true);
                let s3bucket = new AWS.S3({
                    accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
                    secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
                    Bucket: process.env.REACT_APP_BUCKET_NAME + '/users/documents/employer'
                });
                s3bucket.createBucket(function () {
                    var params = {
                        Bucket: process.env.REACT_APP_BUCKET_NAME + '/users/documents/employer',
                        Key: Date.now() + '-' + (selectedFile.name).replace(/ /g, "_"),
                        Body: selectedFile
                    };
                    s3bucket.upload(params, function (err, data) {
                        if (err) {
                            console.log(err)
                        } else {
                            setFile_name("/" + data.key)
                            dispatch(documentsAdd({ uid, accountType, name, category, description }))
                        }
                    });
                });
            } else {
                dispatch(showError({ msg: 'Please fill all input.' }))
            }
        } catch (err) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }
    }

    useEffect(() => {
        if (isError) {
            setSuccess(false);
            setLoading(false);
            dispatch(clearState());
            dispatch(showError({ msg }))
        }
        if (isInsert) {
            dispatch(clearState());
            setSuccess(true);
            setLoading(false);
            fileInput.current.value = ''
            setName('')
            setCategory('')
            setDescription('')
            if (isAttachment) {
                dispatch(insertAttachment({ reference, reference_id: insertId, file_name }))
                setIsAttachment(false);
                setFile_name('');
                hidepopup()
                dispatch(showSuccess({ msg }))
            }
        }
    }, [isError, isInsert, dispatch, msg])

    return (
        <>
            <Modal
                size="lg"
                show={true}
                onHide={hidepopup}
                aria-labelledby="contained-modal-title-vcenter"
                className="dk-docaddnewFileModal"
                centered
            >
                <Modal.Header closeButton className="dk-docaddnewFileModalHeader">
                    <Modal.Title id="contained-modal-title-vcenter">
                        UPLOAD DOCUMENT
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-docaddnewFileModalBody">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label for="">File Name</label>
                            <label for="">
                                <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                            </label>
                        </div>
                        <div className="form-group">
                            <label for="">File Category</label>
                            <label for="">
                                <input type="text" className="form-control" value={category} onChange={(e) => setCategory(e.target.value)} />
                            </label>
                        </div>
                        <div className="form-group">
                            <label for="">
                                <input type="file" className="file" onChange={handleFileInput} ref={fileInput} />
                            </label>
                        </div>
                        <div className="form-group">
                            <label for="">Description</label>
                            <label for="">
                                <textarea type="text" className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} />
                            </label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="dk-docaddnewFileModalFooter">
                    <Button className="closebtn" onClick={clearForm}>Close</Button>
                    <SubmitButton txt="Add" loading={loading} success={success} onClick={handleSubmit} position="" className="submitbtn" />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddNewFile
