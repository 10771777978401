import React from 'react'
import Modal from 'react-bootstrap/Modal'
import './paymentreomove.scss';

const RemoveModal = ({ hidepoup }) => {
    return (
        <div>
            <Modal
                show={true}
                onHide={hidepoup}
                aria-labelledby="contained-modal-title-vcenter"
                className="dk-paymenremoveModal"
            >
                <Modal.Header closeButton className="dk-paymenremoveModalHeader">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Remove Card
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-paymenremoveModalBody">
                    <p>
                        Are you sure to remove this card?
                    </p>
                    <div className="dk-continuebtnCon">
                        <button type="button" class="btn-primary btn dk-continuebtn">Continue</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default RemoveModal;
