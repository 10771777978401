import React from 'react';
import { Dropdown } from 'semantic-ui-react'
import './selectpicker.scss'

const SelectPicker = (props) => {

    return (
        <>
            <div className="dkjobs-SelectPickerM">
                <Dropdown
                    clearable
                    fluid
                    multiple
                    search
                    selection
                    options={props.options}
                    // options={countryOptions}
                    placeholder={props.placeholder}
                />
            </div>
        </>
    )
}

export default SelectPicker;
