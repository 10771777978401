import React from 'react'
import { Link } from 'react-router-dom'
import '../recruitment/recruitment.scss';
import LogoImg from '../../../../../../../assets/images/mainlogo.png'


const Trading = () => {
    return (
        <>
            <div className="dk-recruitmentMain">
                <div className="dk-recruitmentCols">
                    <div className="container">
                        <h1 className="text-center">About <span>DK Global Recruitment</span></h1>
                        <p className="text-center">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit eaque temporibus repellendus quasi totam quae voluptatibus quibusdam rerum optio odit nemo enim culpa, necessitatibus incidunt deleniti ipsum modi hic
                            praesentium? Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque quae numquam distinctio animi dolor tempore reprehenderit reiciendis earum quod magni, eum optio recusandae est quaerat ab architecto repellat,
                            pariatur itaque.
                        </p>
                    </div>
                </div>
                <div className="dk-whitis">
                    <h1 className="text-center">What Is the <span>Business</span></h1>
                    <div className="container mt-5">
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet dolorem quis, corporis nam laborum modi ut enim nisi provident veritatis est ratione natus, perspiciatis, dolor ullam rerum atque exercitationem. Odio? Lorem
                            ipsum dolor sit, amet consectetur adipisicing elit. Eveniet amet voluptatum harum aspernatur quasi illo quidem quia sed dolorum consectetur cumque quis similique deserunt iste, quae placeat quaerat qui molestiae.
                        </p>
                        <div className="row mt-4">
                            <div className="col-md-4">
                                <div className="dk-recruitmentBox">
                                    <h3>LOREM IPSUM IS SIMPLY</h3>
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="dk-recruitmentBox">
                                    <h3>LOREM IPSUM IS SIMPLY</h3>
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="dk-recruitmentBox">
                                    <h3>LOREM IPSUM IS SIMPLY</h3>
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="dk-recruitmentBox">
                                    <h3>LOREM IPSUM IS SIMPLY</h3>
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="dk-recruitmentBox">
                                    <h3>LOREM IPSUM IS SIMPLY</h3>
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="dk-recruitmentBox">
                                    <h3>LOREM IPSUM IS SIMPLY</h3>
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dk-contactDetail">
                    <h1 className="text-center">Contact <span>Details</span></h1>
                    <div className="col-md-8 m-auto">
                        <div className="row mt-5">
                            <div className="col-md-6">
                                <div className="dk-aboutLogo">
                                    <img src={LogoImg} className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="dk-contactText">
                                    <li><i className="fas fa-map-marker-alt"></i><strong>DK Global Jobs</strong>Gloucester Square, Railway street Dublin 1 Ireland</li>
                                    <li><i className="fas fa-phone-alt"></i><a href="/jobseeker/standard/dkgservices/recruitment">+353 1551 2444</a></li>
                                    <li><i className="fas fa-envelope"></i><a href="/jobseeker/standard/dkgservices/recruitment">Office@dkglobaljobs.com</a></li>
                                    <li><i className="fas fa-external-link-alt"></i><a href="/jobseeker/standard/dkgservices/recruitment">www.dkglobalrecruitment.com</a></li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Trading;
