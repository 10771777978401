import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./frontsubscription.scss";
import SubscriptionTabs from './SubscriptionTabs';
import Tab from 'react-bootstrap/Tab';
import JobseekerAccountPlan from './JobseekerAccountPlan';
import EmployerAccountPlan from './EmployerAccountPlan';

const FrontSubscription = () => {
    return (
        <>
            <div className="dk-frontSubscription-main">
                <div className="container">
                    <div className="col-md-9 m-auto">
                        <div className="dk-subscription-title">ACCOUNT SUBSCRIPTION PLANS</div>
                        <div className="dk-subscription-cols">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
                                <div className="dk-subscriptionTabs">
                                    <SubscriptionTabs />
                                </div>
                                <div className="dk-subscriptionTabs-panel">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="tabs_1">
                                            <JobseekerAccountPlan />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tabs_2">
                                            <EmployerAccountPlan />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FrontSubscription;
