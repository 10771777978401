import React, { useState } from 'react';
import { Route, Link } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './stdashboard.scss';

const Index = () => {
    const now = 50;
    return (
        <React.Fragment>
            <div className="dk-standardDashboard">
                <div className="dk-dashboardGraph">
                    <div className="dk-dashboardFilter">
                        <h3 className="title d-none">Jobseeker  Standard account</h3>
                        <div className="d-flex justify-content-end">
                            <Route>
                                <li>
                                    <Link to="#">Today</Link>
                                </li>
                                <li>
                                    <Link to="#">Last 7 days</Link>
                                </li>
                                <li>
                                    <Link to="#">Last 30 days</Link>
                                </li>
                                <li>
                                    <Link to="#" className="active">Total</Link>
                                </li>
                            </Route>
                        </div>
                    </div>
                    <div className="dk-dashboardBar">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="dk-barLeftPanel">
                                    <div className="title">DK Global Recruitment</div>
                                    <ul>
                                        <li><i class="fas fa-envelope"></i> deepak@dkglobal.com</li>
                                        <li><i class="fas fa-map-marker-alt"></i> Dublin, Ireland</li>
                                        <li><i class="fas fa-phone-alt"></i> +353 1 551 2444</li>
                                        <li><i class="fas fa-eye"></i> 0</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="dk-barRightPanel">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Route>
                                            <li>
                                                <Link to="#">Profile Created</Link>
                                                <span>17 Nov 2020</span>
                                            </li>
                                            <li>
                                                <Link to="#">Last Updated</Link>
                                                <span>17 Nov 2020</span>
                                            </li>
                                            <li>
                                                <Link to="#">Last Logged In</Link>
                                                <span>17 Nov 2020</span>
                                            </li>
                                        </Route>
                                    </div>
                                    <div className="dk-dashboardProgressbar">
                                        <div className="subtitle">Profile Filled</div>
                                        <ProgressBar label={`${now}%`} variant="info" now={50} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="jobseeker-standard-dashboard-block">
                        <div className="row">
                            <div className="col">
                                <div className="dk-dashboardBox-sec-Cols">
                                    OPEN TO WORK
                                    <span>Profile Status</span>
                                </div>
                            </div>
                            <div className="col">
                                <div className="dk-dashboardBox-sec-Cols">
                                    HIDDEN
                                    <span>Public Profile</span>
                                </div>
                            </div>
                            <div className="col">
                                <div className="dk-dashboardBox-sec-Cols">
                                    50%
                                    <span>Profile Filled</span>
                                </div>
                            </div>
                            <div className="col">
                                <div className="dk-dashboardBox-sec-Cols">
                                    120
                                    <span>Profile Viewed</span>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '30px' }}>
                            <div className="col">
                                <div className="dk-dashboardBox-sec-Cols">
                                    0
                                    <span>Jobs Applied</span>
                                </div>
                            </div>
                            <div className="col">
                                <div className="dk-dashboardBox-sec-Cols">
                                    0
                                    <span>Jobs Saved</span>
                                </div>
                            </div>
                            <div className="col">
                                <div className="dk-dashboardBox-sec-Cols">
                                    0
                                    <span>Following Agencies</span>
                                </div>
                            </div>
                            <div className="col">
                                <div className="dk-dashboardBox-sec-Cols">
                                    0
                                    <span>Following Companies</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Index;