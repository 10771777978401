import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './joblisting.scss';
import JobsFilter from './JobsFilter';
import JobListingCategory from './JobListingCategory';
import CompanyLogo from './CompanyLogo';
import JobFaqModal from './JobFaqModal';

const JobSearchByProfile = () => {
    return (
        <>
            <div className="dk-jobListingMain">
                <div className="dk-jobListing-head">
                    <div className="container">
                        <h2 className="title">FIND YOUR NEXT JOB HERE <JobFaqModal /></h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        <JobsFilter />
                    </div>
                    <div className="col-md-8">
                        <div className="dk-jobslisting">
                            <div className="dk-jobslisting-category">
                                <JobListingCategory />
                            </div>
                            <div className="dk-jobslisting-tagselect">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="dk-jobs-list">1-50 of 11326 Jobs</div>
                                    <div className="dk-jobs-cate-link">
                                        <Link className="box" to="#">Last 24 hours <i className="fas fa-times"></i></Link>
                                        <Link className="box" to="#">Full Time <i className="fas fa-times"></i></Link>
                                        <Link to="#">Clear all</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="dk-boxes-cols">
                                <div className="dk-searchByHead">
                                    <div className="dk-bacjBtn">
                                        <Link to="#"><i class="fa fa-arrow-left"></i> Back to Job List</Link>
                                    </div>
                                    <div className="title"><i class="fa fa-briefcase mr-2"></i> Job Search By Job Title</div>
                                </div>
                                <div className="dk-searchByPanel">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <ul>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-4">
                                            <ul>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-4">
                                            <ul>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" />
                                                            Job Name 1
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>                            

                        </div>
                    </div>
                    <div className="col-md-2 dk-col-md-2">
                        <CompanyLogo />
                    </div>
                </div>
            </div>
        </>
    )
}

export default JobSearchByProfile;
