import React from 'react';
import { Link } from 'react-router-dom'
import Blogimg from '../../../assets/images/blog.jpg'
import Deepakimg from '../../../assets/images/deepak.png'
const TestcarouselItem = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <Link to="#">
                            <div className="home_blog_card">
                                <div className="home_blog_imgCon">
                                    <img src={Blogimg} className="home_blog_img" alt="Blog" />
                                </div>
                                <div className="home_blog_Content">
                                    <h3>British military courts use aginst protesters cultural...</h3>
                                </div>
                                <div className="home_blog_bottomContent">
                                    <Link to="#" className="blog_bottomContent1">
                                        <div className="dk-imgCon">
                                            <img src={Deepakimg} alt="BlogerImg" />
                                        </div>
                                    </Link>
                                    <Link to="#" className="blog_bottomContent2">
                                        <i class="fa fa-thumbs-up"></i>
                                        <span>624</span>
                                    </Link>
                                    <Link to="#" className="blog_bottomContent3">
                                        <i class="fa fa-eye"></i>
                                        <span>624</span>
                                    </Link>
                                    <Link to="#" className="blog_bottomContent4">
                                        <i class="fa fa-comment"></i>
                                        <span>624</span>
                                    </Link>
                                    <Link to="#" className="blog_bottomContent5">
                                        <i class="fa fa-calendar"></i>
                                        <span>Sep 17, 2020</span>
                                    </Link>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <Link to="#">
                            <div className="home_blog_card">
                                <div className="home_blog_imgCon">
                                    <img src={Blogimg} className="home_blog_img" alt="Blog" />
                                </div>
                                <div className="home_blog_Content">
                                    <h3>British military courts use aginst protesters cultural...</h3>
                                </div>
                                <div className="home_blog_bottomContent">
                                    <Link to="#" className="blog_bottomContent1">
                                        <div className="dk-imgCon">
                                            <img src={Deepakimg} alt="BlogerImg" />
                                        </div>
                                    </Link>
                                    <Link to="#" className="blog_bottomContent2">
                                        <i class="fa fa-thumbs-up"></i>
                                        <span>624</span>
                                    </Link>
                                    <Link to="#" className="blog_bottomContent3">
                                        <i class="fa fa-eye"></i>
                                        <span>624</span>
                                    </Link>
                                    <Link to="#" className="blog_bottomContent4">
                                        <i class="fa fa-comment"></i>
                                        <span>624</span>
                                    </Link>
                                    <Link to="#" className="blog_bottomContent5">
                                        <i class="fa fa-calendar"></i>
                                        <span>Sep 17, 2020</span>
                                    </Link>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <Link to="#">
                            <div className="home_blog_card">
                                <div className="home_blog_imgCon">
                                    <img src={Blogimg} className="home_blog_img" alt="Blog" />
                                </div>
                                <div className="home_blog_Content">
                                    <h3>British military courts use aginst protesters cultural...</h3>
                                </div>
                                <div className="home_blog_bottomContent">
                                    <Link to="#" className="blog_bottomContent1">
                                        <div className="dk-imgCon">
                                            <img src={Deepakimg} alt="BlogerImg" />
                                        </div>
                                    </Link>
                                    <Link to="#" className="blog_bottomContent2">
                                        <i class="fa fa-thumbs-up"></i>
                                        <span>624</span>
                                    </Link>
                                    <Link to="#" className="blog_bottomContent3">
                                        <i class="fa fa-eye"></i>
                                        <span>624</span>
                                    </Link>
                                    <Link to="#" className="blog_bottomContent4">
                                        <i class="fa fa-comment"></i>
                                        <span>624</span>
                                    </Link>
                                    <Link to="#" className="blog_bottomContent5">
                                        <i class="fa fa-calendar"></i>
                                        <span>Sep 17, 2020</span>
                                    </Link>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TestcarouselItem;
