import React from 'react';
import Downimg from '../../../assets/images/down.png'
import Testmonialuser1 from '../../../assets/images/testi1.jpg'


const TestcarouselItem = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <div className="testmoinal_card card">
                            <div className="testmoinal_cardbody card-body">
                                <div className="testmoinal_text">
                                    <p>It is a long established fact that a reader wil be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal</p>
                                </div>
                            </div>
                        </div>
                        <div className="dk-dwoncornerimg">
                            <img src={Downimg} alt="Testimonial" className="img-fluid" />
                        </div>
                        <div className="testmonialUser">
                            <img src={Testmonialuser1} alt="Testimonal_User" />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="testmoinal_card card">
                            <div className="testmoinal_cardbody card-body">
                                <div className="testmoinal_text">
                                    <p>It is a long established fact that a reader wil be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal</p>
                                </div>
                            </div>
                        </div>
                        <div className="dk-dwoncornerimg">
                            <img src={Downimg} alt="Testimonial" className="img-fluid" />
                        </div>
                        <div className="testmonialUser">
                            <img src={Testmonialuser1} alt="Testimonal_User" />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="testmoinal_card card">
                            <div className="testmoinal_cardbody card-body">
                                <div className="testmoinal_text">
                                    <p>It is a long established fact that a reader wil be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that is has a more-or-less normal</p>
                                </div>
                            </div>
                        </div>
                        <div className="dk-dwoncornerimg">
                            <img src={Downimg} alt="Testimonial" className="img-fluid" />
                        </div>
                        <div className="testmonialUser">
                            <img src={Testmonialuser1} alt="Testimonal_User" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TestcarouselItem;
