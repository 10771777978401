import React, { useEffect, useState } from 'react';
import './style.scss';
import { showError, clearMessage } from '../../../../../../../slice/utils/message/messageSlice';
import { useDispatch, useSelector } from "react-redux";
import { disableProfile, logoutUser } from '../../../../../../../slice/account/jobseeker/auth/authSlice';

const DeleteAccount = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.jobseekerAuth)
    const [isCheck, setIsCheck] = useState(false)

    const checkBoxEvent = () => {
        setIsCheck(!isCheck)
    }

    useEffect(() => {
        document.title = "Disable Jobseeker Standard Account | DK Global Jobs"
        dispatch(clearMessage())
    }, [dispatch])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (!isCheck) {
                dispatch(clearMessage())
                dispatch(showError({ msg: 'Please accpet terms.' }))
            } else {
                dispatch(disableProfile({ isDisable: 1, uid: userInfo.uid }))
                dispatch(logoutUser({}))
            }
        } catch (err) {
            dispatch(showError({ msg: 'Something went wrong! Try again.' }))
        }
    }

    return (
        <>
            <div className="container-fluid dk-settDelPageMain">
                <div className="dk-settDelPageCon">
                    <div className="col-md-11 pl-0 ml-auto">
                        <div className="row ml-0 mr-0">
                            <div className="col-md-10 m-auto dk-boxsCon">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="dk-informationCols">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo officia illum repellat veniam porro.
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="dk-complinceCon">
                                            <div className="title">Disable Account</div>
                                            <div className="dk-complaince-Body">
                                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut reprehenderit amet quae atque aut,
                                                    consequuntur veritatis eius quis dicta modi, vitae minima. Quam facilis, unde aut minus a vitae magni?
                                                </p>
                                                <p className="sb-title">Delete Account</p>
                                                <ul>
                                                    <li>Your public profile will be down from website.</li>
                                                    <li>Any user will not be able to login except super admin.</li>
                                                    <li>All your active ads will be disabled automatically.</li>
                                                    <li>Tp renew your account, you just log back in.</li>
                                                </ul>
                                                <div className="d-flex align-items-center mt-3 checkboxCon">
                                                    <input type="checkbox" onClick={checkBoxEvent} />
                                                    <label for="" className="ml-2">I understand that i will lose contents and account access</label>
                                                </div>
                                                <div class="dk-complaince-field">
                                                    <div class="d-flex justify-content-center">
                                                        {
                                                            (isCheck) ?
                                                                <button class="dk-deleteBtn hvr-float-shadow" onClick={handleSubmit}>Disable Account</button>
                                                                :
                                                                <button class="dk-deleteBtn-disabled hvr-float-shadow" onClick={handleSubmit}>Disable Account</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteAccount;
