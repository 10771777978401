import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import ProfilePic from "../../../assets/images/profile_pic.png";

const JobseekerDetails = ({ handleClose }) => {
    return (
        <>
            <Modal className="dk-jobseekerDetails-modal" centered show={true} onHide={handleClose}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-4 pr-0">
                            <div className="dk-frontCandiadteModal-box">
                                <div className="dk-onofTags"><span>Online</span></div>
                                <div className="dk-frontCandiadteModal-img">
                                    <img src={ProfilePic} className="img-fluid" alt="" />
                                </div>
                                <div className="dk-frontCandiadteModal-text">
                                    <div className="title">Mariana Cont</div>
                                    <label htmlFor="" className="dk-user"><input type="text" name="" id="" className="form-control" value="8851589655" /></label>
                                    <label htmlFor="" className="dk-email"><input type="text" name="" id="" className="form-control" value="deepak@dkglobaljobs.com" /></label>
                                    <label htmlFor="" className="dk-sendMessage">
                                        <span><i className="fas fa-envelope"></i></span><Link to="#">SEND ME MESSAGE</Link>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 pl-0">
                            <div className="dk-frontModal-rightPanel">
                                <div className="title">Add Title</div>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut excepturi ab ad, consequuntur reiciendis similique incidunt nulla, vel facilis beatae exercitationem non unde consectetur assumenda et delectus ea ipsa ipsum?</p>
                            </div>
                            <div className="dk-frontJob-form">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="d-flex">
                                            <label htmlFor="">My Job Title</label><label htmlFor=""><input type="text" name="" id="" className="form-control" /></label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex">
                                            <label htmlFor="">Notice Period</label><label htmlFor="" className="dk-dates"><input type="text" name="" id="" className="form-control" /></label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex">
                                            <label htmlFor="">My Industry</label><label htmlFor=""><input type="text" name="" id="" className="form-control" /></label>
                                        </div >
                                    </div >
                                    <div className="col-md-6">
                                        <div className="d-flex">
                                            <label htmlFor="">Availability</label><label htmlFor="" className="dk-dates"><input type="text" name="" id="" className="form-control" /></label>
                                        </div >
                                    </div >
                                    <div className="col-md-6">
                                        <div className="d-flex">
                                            <label htmlFor="">My Salary</label><label htmlFor=""><input type="text" name="" id="" className="form-control" /></label>
                                        </div >
                                    </div >
                                    <div className="col-md-6">
                                        <div className="d-flex">
                                            <label htmlFor="">Posted on</label><label htmlFor="" className="dk-dates"><input type="text" name="" id="" className="form-control" /></label>
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div >
                        <div className="col-md-12 mt-1">
                            <div className="d-flex">
                                <div className="dk-frontCandiadteModal-foot">
                                    <div className="title">Current Profile</div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="dk-modalFoot-cols">Seniority :</div>
                                        <div className="dk-modalFoot-cols">Write Something here</div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="dk-modalFoot-cols">Nationality :</div>
                                        <div className="dk-modalFoot-cols">Write Something here</div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="dk-modalFoot-cols">Work Permit :</div>
                                        <div className="dk-modalFoot-cols">Write Something here</div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="dk-modalFoot-cols">Current Location :</div>
                                        <div className="dk-modalFoot-cols">Write Something here</div>
                                    </div>
                                </div>
                                <div className="dk-frontJob-rightPanel-foot">
                                    <div className="d-flex">
                                        <div className="dk-rightPanel-foot-cols light">
                                            <div className="title">Profile Skills</div>
                                            <div className="dk-progressBar techSkill"><span>Technical Skills</span></div>
                                            <div className="dk-progressBar manaSkill"><span>Management Skills</span></div>
                                            <div className="dk-progressBar timeSkill"><span>Time Keeping Skills</span></div>
                                            <div className="dk-progressBar teamSkill"><span>Team Working Skills</span></div>
                                        </div>
                                        <div className="dk-rightPanel-foot-cols">
                                            <div className="dk-frontCandiadteModal-foot">
                                                <div className="title">Requirement</div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="dk-modalFoot-cols">Job Required :</div>
                                                    <div className="dk-modalFoot-cols">Write Something here</div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="dk-modalFoot-cols">Salary Required :</div>
                                                    <div className="dk-modalFoot-cols">Write Something here</div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="dk-modalFoot-cols">Industry Required :</div>
                                                    <div className="dk-modalFoot-cols">Write Something here</div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="dk-modalFoot-cols">Location Required :</div>
                                                    <div className="dk-modalFoot-cols">Write Something here</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >

                </Modal.Body >
            </Modal >
        </>
    )
}

export default JobseekerDetails;
