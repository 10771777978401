import React from 'react'
import './empfeedbox.scss'
import { Link } from 'react-router-dom'
import Feedlogo from '../../../assets/images/feed-logo.png'
import PostImg from '../../../assets/images/post.jpeg'
const EmpFeedbox = () => {
    return (
        <>
            <div className="dk-empfeedboxCon">
                <div className="card dkjobs-posts">
                    <div className="card-body">
                        <Link to="#">
                            <div className="dk-FistfeedRow">
                                <Link to="#">
                                    <img src={Feedlogo} className="feedlogo" alt="Feed_Logo" />
                                </Link>
                                <Link to="#" className="ml-2 dk-topfeedtitle">T-Series</Link>
                                <Link to="#" className="ml-2 dk-topfeedtitle">7 hours ago</Link>
                            </div>
                            <div className="row pt-2">
                                <div className="col-md-7 pr-0">
                                    <div className="discription">
                                        <p>Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text. Some quick example text to build on the card title and make up the bulk...</p>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="dkpost_img">
                                        <img src={PostImg} alt="PostImgUser" />
                                    </div>
                                </div>
                            </div>
                            <div className="dk-commentFeedSec">
                                <div className="like-viewSec">
                                    <ul className="socila-like-section">
                                        <li>
                                            <Link to="#"><i className="fa fa-thumbs-up fa-lg pr-1"></i>21K</Link>
                                        </li>
                                        <li className="pl-3">
                                            <Link to="#"><i className="fa fa-thumbs-down fa-lg pr-1"></i>2K</Link>
                                        </li>
                                        <li className="pl-3"><Link to="#"><i className="fa fa-eye fa-lg pr-1"></i>50K</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="comentSec">
                                    <ul>
                                        <li><Link to="#">comments 1.9K</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmpFeedbox;
