import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import './gdpr.scss';
import { useDispatch, useSelector } from "react-redux";
import { listQuestion, submitGdpr, clearState } from '../../../../slice/account/gdpr/gdprSlice';
import UseAnimations from "react-useanimations";
import SubmitButton from "../../../ui/submitButton";
import { showError, showSuccess } from '../../../../slice/utils/message/messageSlice';
import $ from "jquery";

function List({ id, question }) {
    return (
        <React.Fragment>
            <div className="dklogin-checkBoxCon">
                <input type="checkbox" class="form-check-input chkCandidate" name="chkCandidate[]" id={id} />
                <label class="form-check-label" for={id}>{question}</label>
            </div>
        </React.Fragment>
    )
}

const Index = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams()
    const accountType = params.account;
    const accountId = params.id;
    const { isLoading, isInsert, questionList } = useSelector(state => state.gdpr);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        document.title = "GDPR consent submission | DK Global Jobs"
        dispatch(listQuestion({ account: accountType }))
    }, [dispatch, accountType])

    const handleSubmit = async (e) => {
        e.preventDefault()
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
        if (checkedBoxCount < totalCheckboxes) {
            dispatch(showError({ msg: 'Please check all checkbox.' }))
        } else {
            setSuccess(false);
            setLoading(true);
            await dispatch(submitGdpr({ account: accountType, id: atob(accountId) }))
        }
    }

    useEffect(() => {
        if (isInsert) {
            dispatch(showSuccess({ msg: 'GDPR Submit successfully.' }))
            dispatch(clearState())
            setTimeout(() => history.push('/login/' + accountType + '-login'), 500);
        }
    }, [isInsert])

    return (
        <React.Fragment>
            <div className="dk-darMain">
                <div className="sm-container">
                    <div className="title">GDPR consent</div>
                    {
                        (isLoading) ?
                            <div className="dk-cols-12">
                                <UseAnimations className="dk-loading"
                                    animationKey="loading"
                                    size={56}
                                    style={{ padding: 100 }}
                                />
                            </div>
                            :
                            <div className="dk-cols-12">
                                <form>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                questionList.map((item, index) =>
                                                    <List key={index + 1} question={item.questions} id={item.id} />
                                                )
                                            }
                                            <div className="loginsubmit-buttonCon">
                                                <SubmitButton txt="Submit" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" className="btn loginsubmit-btn" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
export default Index;