import React from 'react'

const Summary = () => {
    return (
        <>
            <div className="row">
                <div className="col-md-6 pr-5 dk-profilesummLeft">
                    <div className="d-flex align-items-center mb-1">
                        <label className="mr-3" for="">Gender</label>
                        <input type="text" name="" id="" value="Male" readOnly />
                    </div>
                    <div className="d-flex align-items-center mb-1">
                        <label className="mr-3" for="">Date of Birth</label>

                        <input type="text" name="" id="" value="31 July 1981" readOnly />
                    </div>
                    <div className="d-flex align-items-center mb-1">
                        <label className="mr-3" for="">Current City</label>

                        <input type="text" name="" id="" value="Dublin" readOnly />
                    </div>
                    <div className="d-flex align-items-center mb-1">
                        <label className="mr-3" for="">Current Country</label>

                        <input type="text" name="" id="" value="Ireland" readOnly />
                    </div>
                    <div className="d-flex align-items-center mb-1">
                        <label className="mr-3" for="">Nationality</label>

                        <input type="text" name="" id="" value="Irish" readOnly />
                    </div>
                    <div className="d-flex align-items-center mb-1">
                        <label className="mr-3" for="">Native Language</label>

                        <input type="text" name="" id="" value="English" readOnly />
                    </div>
                    <div className="d-flex align-items-center mb-1">
                        <label className="mr-3" for="">Fluent Language</label>

                        <input type="text" name="" id="" value="English" readOnly />
                    </div>
                    <div className="d-flex align-items-center mb-1">
                        <label className="mr-3" for="">Work Permit</label>

                        <input type="text" name="" id="" value="European Passport" readOnly />
                    </div>
                    <div className="d-flex align-items-center mb-1">
                        <label className="mr-3" for="">Contact Prefrences</label>

                        <input type="text" name="" id="" value="Anytime betweeb 10 to 7pm" readOnly />
                    </div>
                </div>
                <div className="col-md-6 dk-formright-panel pr-0">
                    <div className="dk-suMMtextAreaCon">
                        <label className="title" for="">Profile Summary</label>
                        <textarea name="" id="" className="dk-profsumm11" />
                    </div>
                    <div className="dk-suMMtextAreaCon mt-4">
                        <label className="title" for="">Profile Summary</label>
                        <textarea name="" id="" className="dk-profsumm11" />
                    </div>
                </div>
                <div className="col-md-12 dk-bottom-panelCon pr-0 mt-4">
                    <div className="dk-suMMtextAreaCon">
                        <label className="title" for="">Technical Skills</label>
                        <textarea name="" id="" className="dk-profsumm11" />
                    </div>
                    <div className="dk-suMMtextAreaCon mt-4">
                        <label className="title" for="">Other Career Skills</label>
                        <textarea name="" id="" className="dk-profsumm11" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Summary
