import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from "../../../assets/images/dkglobal-logo.png";
import ProfilePic from "../../../assets/images/profile_pic.png";
import './jobdetails.scss';

const JobDetails = () => {
    return (
        <>
            <div className="dk-jobDetails-main">
                <div className="dk-job-backbtn">
                    <Link to="#"><i className="fas fa-arrow-left"></i> Back to job list</Link>
                </div>
                <div className="container-fluid pl-5 pr-5 mt-4">
                    <div className="row">
                        <div className="col-lg-9 col-md-12">
                            <div className="dk-job-leftpanel">
                                <div className="dk-job-leftpanel-profile">
                                    <div className="d-flex flex-wrap">
                                        <div className="col-md-9">
                                            <div className="row">
                                                <div className="dk-job-leftpanel-text d-flex flex-wrap">
                                                    <div className="dk-job-leftpanel-text-cols">
                                                        <div className="title">Job Reference</div>
                                                        <div className="jobnumber">MXCH1234</div>
                                                    </div>
                                                    <div className="dk-job-leftpanel-text-cols">
                                                        <div className="title">Job Reference</div>
                                                        <div className="jobnumber">MXCH1234</div>
                                                    </div>
                                                    <div className="dk-job-leftpanel-text-cols">
                                                        <div className="title">Job Reference</div>
                                                        <div className="jobnumber">MXCH1234</div>
                                                    </div>
                                                    <div className="dk-job-leftpanel-text-cols">
                                                        <div className="title">Job Reference</div>
                                                        <div className="jobnumber">MXCH1234</div>
                                                    </div>
                                                    <div className="dk-job-leftpanel-text-cols">
                                                        <div className="title">Job Reference</div>
                                                        <div className="jobnumber">MXCH1234</div>
                                                    </div>
                                                    <div className="dk-job-leftpanel-text-cols">
                                                        <div className="title">Job Reference</div>
                                                        <div className="jobnumber">MXCH1234</div>
                                                    </div>
                                                    <div className="dk-job-leftpanel-text-cols">
                                                        <div className="title">Job Reference</div>
                                                        <div className="jobnumber">MXCH1234</div>
                                                    </div>
                                                    <div className="dk-job-leftpanel-text-cols">
                                                        <div className="title">Job Reference</div>
                                                        <div className="jobnumber">MXCH1234</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="row">
                                                <div className="dk-leftpanel-cols-profile d-flexCols">
                                                    <div className="dk-jobs-btn">
                                                        <img src={Logo} alt="" />
                                                    </div>
                                                    <div className="dk-viewCols">
                                                        <div className="d-flex">
                                                            <li><b>Job Views</b>13,346</li>
                                                            <li><b>Applications</b>2600</li>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-job-leftpanel-content">
                                    <h1>German Speaking Technical support role in Athens Greece, Apply now for irnrnediate interviews.</h1>

                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure, illum. Perspiciatis, facere neque! Rerum dignissimos dicta qui aut repellendus eum, beatae natus necessitatibus nostrum aliquam ratione cumque nulla, sunt doloribus?</p>

                                    <h3>Hiring Company</h3>
                                    <p>
                                        Hiring Company DK Global Recruitment is Dublin based Irish. personalized recommendations for products their custorners love, rnaking sure the best and most interesting products are shown to their users in the app and their
                                        inbox often before they know they want it sophisticated algorithms, wish.com creates personalized
                                    </p>
                                    <div className="d-md-flex justify-content-between">
                                        <div className="dkjobLeftCols pt-3">
                                            <ul>
                                                <h3>Requirements</h3>
                                                <li>Able to speak English</li>
                                                <li>Willing to relocate</li>
                                                <li>Able to speak German</li>
                                                <li>2 years of experience Is must requirement</li>
                                                <li>Driving License is also a requirement</li>
                                            </ul>
                                            <ul>
                                                <h3>Responsibilities</h3>
                                                <li>Able to speak English</li>
                                                <li>Willing to relocate</li>
                                                <li>Able to speak German</li>
                                                <li>2 years of experience Is must requirement</li>
                                                <li>Driving License is also a requirement</li>
                                            </ul>
                                            <ul>
                                                <h3>Benefits</h3>
                                                <li>Able to speak English</li>
                                                <li>Willing to relocate</li>
                                                <li>Able to speak German</li>
                                                <li>2 years of experience Is must requirement</li>
                                                <li>Driving License is also a requirement</li>
                                            </ul>
                                            <ul>
                                                <h3>Other Benefits</h3>
                                                <li>Able to speak English</li>
                                                <li>Willing to relocate</li>
                                                <li>Able to speak German</li>
                                                <li>2 years of experience Is must requirement</li>
                                                <li>Driving License is also a requirement</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-12">
                            <div className="dk-leftPanelJobs">
                                <div className="dk-leftPanelJobs-cols">
                                    <div className="dk-jobProfile">
                                        <div className="dk-jobProfileImg">
                                            <img src={ProfilePic} className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="dk-jobsText">
                                        <div className="dk-Jobs-profileTitle">
                                            <h4>Mariana Cant</h4>
                                            <p>Recruitment Consultant</p>
                                            <div className="dk-socialIcon">
                                                <Link to="#"><i className="fas fa-comments"></i></Link>
                                                <Link to="#"><i className="fas fa-phone-alt"></i></Link>
                                                <Link to="#"><i className="fas fa-envelope"></i></Link>
                                                <Link to="#"><i className="fab fa-linkedin-in"></i></Link>
                                                <Link to="#"><i className="fab fa-skype"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dk-job-rightpanel mt-3">
                                <div className="dk-job-rightpanel-cols">
                                    <div className="dk-job-leftpanel-text d-flex flex-wrap">
                                        <div className="dk-job-leftpanel-text-cols">
                                            <div className="title">Job Reference</div>
                                            <div className="jobnumber">MXCH1234</div>
                                        </div>
                                        <div className="dk-job-leftpanel-text-cols">
                                            <div className="title">Job Reference</div>
                                            <div className="jobnumber">MXCH1234</div>
                                        </div>
                                        <div className="dk-job-leftpanel-text-cols">
                                            <div className="title">Job Reference</div>
                                            <div className="jobnumber">MXCH1234</div>
                                        </div>
                                        <div className="dk-job-leftpanel-text-cols">
                                            <div className="title">Job Reference</div>
                                            <div className="jobnumber">MXCH1234</div>
                                        </div>
                                        <div className="dk-job-leftpanel-text-cols">
                                            <div className="title">Job Reference</div>
                                            <div className="jobnumber">MXCH1234</div>
                                        </div>
                                        <div className="dk-job-leftpanel-text-cols">
                                            <div className="title">Job Reference</div>
                                            <div className="jobnumber">MXCH1234</div>
                                        </div>
                                        <div className="dk-job-leftpanel-text-cols">
                                            <div className="title">Job Reference</div>
                                            <div className="jobnumber">MXCH1234</div>
                                        </div>
                                        <div className="dk-job-leftpanel-text-cols">
                                            <div className="title">Job Reference</div>
                                            <div className="jobnumber">MXCH1234</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="dk-blankBox">
                            <div className="d-flex">
                                <div className="dk-boxPanel">
                                    <div className="title">Attached Files</div>
                                    <div className="dk-attachedFile">
                                        <div className="dk-modal-top-cols">
                                            <div className="dk-attachment">
                                                <div className="title">Add Resume</div>
                                                <div className="dk-attachCols"><input type="file" name="no file selected" className="form-control" id="" /><span>Browse</span></div>
                                            </div>
                                        </div>
                                        <div className="dk-modal-top-cols">
                                            <div className="dk-attachment">
                                                <div className="title">Add Cover Letter</div>
                                                <div className="dk-attachCols"><input type="file" name="" className="form-control" id="" /><span>Browse</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-boxPanel">
                                    <div className="title">Jobseeker Contact Info</div>
                                    <div className="dk-infoCols">
                                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Minima non, libero error, repudiandae vitae rem dolorum.</p>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="dk-infozBox">
                                                <label for="">Phone No</label><label for=""><input type="text" name="" id="" className="form-control" /></label>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="dk-infozBox">
                                                <label for="">Email Address</label><label for=""><input type="text" name="" id="" className="form-control" /></label>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="dk-infozBox">
                                                <label for="">Via Whats App</label>
                                                <div className="dk-yesnoCheckbox"><input type="radio" name="radioBx" id="yes" /><label for="yes">Yes</label><input type="radio" name="radioBx" id="no" /><label for="no">No</label></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dk-boxPanel">
                                    <div className="title">Message to Employer</div>
                                    <div className="dk-infoCols">
                                        <label for="" className="boxTeaxtarea">
                                            <div className="sbTitle">Write Message with Application......</div>
                                            <textarea name="" id="" placeholder="" className="form-control"></textarea>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="saveJobCols">
                                <div className="d-flex justify-content-center mt-3">
                                    <div className="checkboxCols"><input type="checkbox" name="checkBox" id="checkBox" /><label for="checkBox">Please Accept GDPR Consent</label></div>
                                </div>
                                <div className="d-flex justify-content-center"><button>SAVE JOB</button><button>APPLY JOB</button></div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <h2 className="dk-similarjobsTitle">Similar Jobs</h2>
                            <div className="dk-similerjobs">

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default JobDetails;
