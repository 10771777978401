import React from 'react';
import { Accordion, Card } from 'react-bootstrap'
import { FaChevronDown } from "react-icons/fa";
const AccoridanCom = ({ answers }) => {
    return (
        <>
            <Accordion defaultActiveKey="0" className="dk-faqAccordion">
                {
                    answers.map((item, index) =>
                        <Card className="dk-faqAccCard" key={index + 1}>
                            <Accordion.Toggle as={Card.Header} className="dk-faqAccHeader" eventKey={item.id}>
                                <span className="title">{item.question}</span>
                                <span className="icon"><FaChevronDown /></span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={item.id}>
                                <Card.Body className="dk-faqAccCardBody">
                                    {item.answer}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )
                }
            </Accordion>
        </>
    )
}

export default AccoridanCom;